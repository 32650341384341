import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog-detail-left',
  templateUrl: './blog-detail-left.component.html',
  styleUrls: ['./blog-detail-left.component.css']
})
export class BlogDetailLeftComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
