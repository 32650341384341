import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-listing4',
  templateUrl: './listing4.component.html',
  styleUrls: ['./listing4.component.css']
})
export class Listing4Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
