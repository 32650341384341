<div class="page-404">
	
<section>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="404-img">
            <img src="assets/images/404-img.png" alt="image">
          </div>
          <div class="caption">
            <h5 class="text-custom-white rubik full-width">Opps! Page Not Found. <a href="javascript:void(0)" class="text-custom-white">Try Again</a></h5>
            <a routerLink="/" class="btn-second orange-btn mt-4">Back to home</a>
          </div>
        </div>
      </div>
    </div>
  </section>
	
</div>