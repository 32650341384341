<div class="mobile-menu-second">
<app-header4></app-header4>	
<!-- Sub header -->
  <div class="sub-header-sec p-relative">
    <img src="assets/images/blog/banner/banner-1.jpg" class="img-fluid" alt="Sub header">
    <div class="sub-header-overlay"></div>
    <div class="sub-header-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-xl-12">
            <div class="sub-header-text-wrapper text-center">
              <h1 class="text-custom-white">In Media</h1>
              <ul class="breadcrumbs">
                <li><a routerLink="/" class="text-custom-white">Home</a>
                </li>
                <li><span class="text-custom-white">In Media</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Sub header -->
  <!-- In Media -->
	<section class="in-media-sec">
			<div class="row">
				<!-- sec 1 -->
				<div class="in-media-box">
					<div class="grid-box large-img-box">
						<img src="assets/images/socialmedia/social1.jpg" class="img-fluid" alt="In media">
						<div class="in-media-box-caption video-btn">
							<div class="main-icon">
								<a routerLink="/media">	<i class="fab fa-instagram"></i>
								</a>
							</div>
							<div class="media-box-caption-wrapper ">
								<p class="text-light-black">The new Rims - Not ordinary. But thrilling.</p>
								<div class="icon-box row">
									<div class="col-12">
										<img src="assets/images/user-1.jpg" alt="image">	<span class="text-light-black">Rims</span>
										<a routerLink="/media">	<i class="fab fa-instagram"></i>
										</a>
									</div>
								</div>	<a routerLink="/car-page" class="btn-first orange-btn full-width">View Category</a>
							</div>
						</div>
					</div>
					<div class="grid-box grid-box-small small-img-box padding-right-5">
						<img src="assets/images/socialmedia/social3.jpg" class="img-fluid" alt="In media">
						<div class="in-media-box-caption">
							<div class="main-icon">
								<a routerLink="/media">	<i class="fab fa-twitter"></i>
								</a>
							</div>
							<div class="media-box-caption-wrapper">
								<div class="icon-box row">
									<div class="col-12">
										<img src="assets/images/user-2.jpg" alt="image">	<span class="text-light-black d-block">Rims</span>	
									</div>
								</div>
								<p class="text-light-black">The new Rims
									<a routerLink="/media">	<i class="fab fa-twitter"></i>
									</a>
								</p>
							</div>
						</div>
					</div>
					<div class="grid-box grid-box-small small-img-box ">
						<img src="assets/images/socialmedia/social4.jpg" class="img-fluid" alt="In media">
						<div class="in-media-box-caption">
							<div class="main-icon">
								<a routerLink="/media">	<i class="fab fa-facebook-f"></i>
								</a>
							</div>
							<div class="media-box-caption-wrapper">
								<div class="icon-box row">
									<div class="col-12">
										<img src="assets/images/user-3.jpg" alt="image">	<span class="text-light-black d-block">Rims</span>	
									</div>
								</div>
								<p class="text-light-black">The new Rims
									<a routerLink="/media">	<i class="fab fa-facebook-f"></i>
									</a>
								</p>
							</div>
						</div>
					</div>
					<!-- sec 1 -->
					<!-- sec 2 -->
					<div class="grid-box grid-box-small small-img-box padding-right-5">
						<img src="assets/images/socialmedia/social9.jpg" class="img-fluid" alt="In media">
						<div class="in-media-box-caption">
							<div class="main-icon">
								<a routerLink="/media">	<i class="fab fa-instagram"></i>
								</a>
							</div>
							<div class="media-box-caption-wrapper">
								<div class="icon-box row">
									<div class="col-12">
										<img src="assets/images/user-2.jpg" alt="image">	<span class="text-light-black d-block">Rims</span>	
									</div>
								</div>
								<p class="text-light-black">The new Rims
									<a routerLink="/media">	<i class="fab fa-instagram"></i>
									</a>
								</p>
							</div>
						</div>
					</div>
					<div class="grid-box grid-box-small small-img-box">
						<img src="assets/images/socialmedia/social6.jpg" class="img-fluid" alt="In media">
						<div class="in-media-box-caption">
							<div class="main-icon">
								<a routerLink="/media">	<i class="fab fa-facebook-f"></i>
								</a>
							</div>
							<div class="media-box-caption-wrapper">
								<div class="icon-box row">
									<div class="col-12">
										<img src="assets/images/user-1.jpg" alt="image">	<span class="text-light-black d-block">Rims</span>	
									</div>
								</div>
								<p class="text-light-black">The new Rims
									<a routerLink="/media">	<i class="fab fa-facebook-f"></i>
									</a>
								</p>
							</div>
						</div>
					</div>
					<div class="grid-box large-img-box">
						<img src="assets/images/socialmedia/social2.jpg" class="img-fluid" alt="In media">
						<div class="in-media-box-caption video-btn">
							<div class="main-icon">
								<a routerLink="/media">	<i class="fab fa-youtube"></i>
								</a>
							</div>
							<div class="media-box-caption-wrapper">
								<p class="text-light-black">The new Rims - Not ordinary. But thrilling.</p>
								<div class="icon-box row">
									<div class="col-6">
										<img src="assets/images/user-3.jpg" alt="image">	<span class="text-light-black">Rims</span>
									</div>
									<div class="col-6">
										<a routerLink="/media">	<i class="fab fa-youtube"></i>
										</a>
									</div>
								</div>	<a routerLink="/media" class="btn-first orange-btn full-width">Learn More</a>
							</div>
						</div>
					</div>
					<!-- sec 2 -->
					<!-- sec 3 -->
					<div class="grid-box large-img-box">
						<img src="assets/images/socialmedia/social8.jpg" class="img-fluid" alt="In media">
						<div class="in-media-box-caption">
							<div class="main-icon">
								<a routerLink="/media">	<i class="fab fa-instagram"></i>
								</a>
							</div>
							<div class="media-box-caption-wrapper">
								<p class="text-light-black">The new Rims - Not ordinary. But thrilling.</p>
								<div class="icon-box row">
									<div class="col-6">
										<img src="assets/images/user-1.jpg" alt="image">	<span class="text-light-black">Rims</span>
									</div>
									<div class="col-6">
										<a routerLink="/media">	<i class="fab fa-instagram"></i>
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="grid-box grid-box-small small-img-box padding-right-5">
						<img src="assets/images/socialmedia/social7.jpg" class="img-fluid" alt="In media">
						<div class="in-media-box-caption">
							<div class="main-icon">
								<a routerLink="/media">	<i class="fab fa-twitter"></i>
								</a>
							</div>
							<div class="media-box-caption-wrapper">
								<div class="icon-box row">
									<div class="col-12">
										<img src="assets/images/user-3.jpg" alt="image">	<span class="text-light-black d-block">Rims</span>	
									</div>
								</div>
								<p class="text-light-black">The new Rims
									<a routerLink="/media">	<i class="fab fa-twitter"></i>
									</a>
								</p>
							</div>
						</div>
					</div>
					<div class="grid-box grid-box-small small-img-box">
						<img src="assets/images/socialmedia/social6.jpg" class="img-fluid" alt="In media">
						<div class="in-media-box-caption">
							<div class="main-icon">
								<a routerLink="/media">	<i class="fab fa-facebook-f"></i>
								</a>
							</div>
							<div class="media-box-caption-wrapper">
								<div class="icon-box row">
									<div class="col-12">
										<img src="assets/images/user-2.jpg" alt="image">	<span class="text-light-black d-block">Rims</span>	
									</div>
								</div>
								<p class="text-light-black">The new Rims
									<a routerLink="/media">	<i class="fab fa-facebook-f"></i>
									</a>
								</p>
							</div>
						</div>
					</div>
				</div>
				<!-- sec 3 -->
			</div>

		</section>	
  <!-- In Media -->
	<app-footer4></app-footer4>
</div>