<div class="side-bar">
            <!-- social -->
            <div class="sidebar-widget">
              <h4 class="text-light-black widget-title"><i class="flaticon-search"></i> Search</h4>
              <div class="widget-content">
                <div class="search-button-blog full-width">
                  <div class="input-group ">
                    <input type="text" class="form-control form-control-custom" placeholder="keywords...">
                    <div class="input-group-append">
                      <button class="btn-second orange-btn" type="button">Search</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- social -->
            <!-- Recent Posts -->
            <div class="sidebar-widget">
              <h4 class="text-light-black widget-title"><i class="flaticon-eye"></i> Recent Posts</h4>
              <div class="widget-content">
                <!--./ thumb-wrap -->
                <article class="side-post">
                  <div class="thumb-img">
                    <a routerLink="/blog-standard">
                      <img src="assets/images/blog/blog-1.jpg" alt="thumb-img">
                    </a>  <span class="count">1</span>
                  </div>
                  <div class="content-wrap">
                    <div class="entry-meta-content">
                      <div class="entry-date"> <span class="text-dark-white">In 25 December 2020</span>
                      </div>
                    </div>
                    <h6 class="entry-title no-margin">
                                          <a routerLink="/blog-standard" class="text-light-black">the love of power is the demon of men & women</a>
                                        </h6>
                  </div>
                </article>
                <!--./ thumb-wrap -->
                <!--./ thumb-wrap -->
                <article class="side-post">
                  <div class="thumb-img">
                    <a routerLink="/blog-standard">
                      <img src="assets/images/blog/blog-2.jpg" alt="thumb-img">
                    </a>  <span class="count">2</span>
                  </div>
                  <div class="content-wrap">
                    <div class="entry-meta-content">
                      <div class="entry-date"> <span class="text-dark-white">In 25 December 2020</span>
                      </div>
                    </div>
                    <h6 class="entry-title no-margin">
                                          <a routerLink="/blog-standard" class="text-light-black">The PC has improved the world in just about every area</a>
                                        </h6>
                  </div>
                </article>
                <!--./ thumb-wrap -->
                <!--./ thumb-wrap -->
                <article class="side-post">
                  <div class="thumb-img">
                    <a routerLink="/blog-standard">
                      <img src="assets/images/blog/blog-3.jpg" alt="thumb-img">
                    </a>  <span class="count">3</span>
                  </div>
                  <div class="content-wrap">
                    <div class="entry-meta-content">
                      <div class="entry-date"> <span class="text-dark-white">In 25 December 2020</span>
                      </div>
                    </div>
                    <h6 class="entry-title no-margin">
                                          <a routerLink="/blog-standard" class="text-light-black">Life is hard, and a lot of people come home tired from work</a>
                                        </h6>
                  </div>
                </article>
                <!--./ thumb-wrap -->
                <!--./ thumb-wrap -->
                <article class="side-post">
                  <div class="thumb-img">
                    <a routerLink="/blog-standard">
                      <img src="assets/images/blog/blog-4.jpg" alt="thumb-img">
                    </a>  <span class="count">4</span>
                  </div>
                  <div class="content-wrap">
                    <div class="entry-meta-content">
                      <div class="entry-date"> <span class="text-dark-white">In 25 December 2020</span>
                      </div>
                    </div>
                    <h6 class="entry-title no-margin">
                                          <a routerLink="/blog-standard" class="text-light-black">the love of power is the demon of men & women</a>
                                        </h6>
                  </div>
                </article>
                <!--./ thumb-wrap -->
              </div>
            </div>
            <!-- Recent Posts -->
            <!-- Popular Post -->
            <div class="sidebar-widget">
              <h4 class="text-light-black widget-title"><i class="flaticon-checklist"></i> Popular Post</h4>
              <div class="widget-content">
                <!--./ thumb-wrap -->
                <article class="side-post">
                  <div class="thumb-img">
                    <a routerLink="/blog-standard">
                      <img src="assets/images/blog/blog-5.jpg" alt="thumb-img">
                    </a>  <span class="count">1</span>
                  </div>
                  <div class="content-wrap">
                    <div class="entry-meta-content">
                      <div class="entry-date"> <span class="text-dark-white">In 25 December 2020</span>
                      </div>
                    </div>
                    <h6 class="entry-title no-margin">
                                          <a routerLink="/blog-standard" class="text-light-black">the love of power is the demon of men & women</a>
                                        </h6>
                  </div>
                </article>
                <!--./ thumb-wrap -->
                <!--./ thumb-wrap -->
                <article class="side-post">
                  <div class="thumb-img">
                    <a routerLink="/blog-standard">
                      <img src="assets/images/blog/blog-6.jpg" alt="thumb-img">
                    </a>  <span class="count">2</span>
                  </div>
                  <div class="content-wrap">
                    <div class="entry-meta-content">
                      <div class="entry-date"> <span class="text-dark-white">In 25 December 2020</span>
                      </div>
                    </div>
                    <h6 class="entry-title no-margin">
                                          <a routerLink="/blog-standard" class="text-light-black">The PC has improved the world in just about every area</a>
                                        </h6>
                  </div>
                </article>
                <!--./ thumb-wrap -->
                <!--./ thumb-wrap -->
                <article class="side-post">
                  <div class="thumb-img">
                    <a routerLink="/blog-standard">
                      <img src="assets/images/blog/blog-7.jpg" alt="thumb-img">
                    </a>  <span class="count">3</span>
                  </div>
                  <div class="content-wrap">
                    <div class="entry-meta-content">
                      <div class="entry-date"> <span class="text-dark-white">In 25 December 2020</span>
                      </div>
                    </div>
                    <h6 class="entry-title no-margin">
                                          <a routerLink="/blog-standard" class="text-light-black">Life is hard, and a lot of people come home tired from work</a>
                                        </h6>
                  </div>
                </article>
                <!--./ thumb-wrap -->
                <!--./ thumb-wrap -->
                <article class="side-post">
                  <div class="thumb-img">
                    <a routerLink="/blog-standard">
                      <img src="assets/images/blog/blog-8.jpg" alt="thumb-img">
                    </a>  <span class="count">4</span>
                  </div>
                  <div class="content-wrap">
                    <div class="entry-meta-content">
                      <div class="entry-date"> <span class="text-dark-white">In 25 December 2020</span>
                      </div>
                    </div>
                    <h6 class="entry-title no-margin">
                                          <a routerLink="/blog-standard" class="text-light-black">the love of power is the demon of men & women</a>
                                        </h6>
                  </div>
                </article>
                <!--./ thumb-wrap -->
              </div>
            </div>
            <!-- Popular Post -->
            <!-- tags -->
            <div class="sidebar-widget">
              <h4 class="text-light-black widget-title"><i class="flaticon-price-tag"></i> Tags</h4>
              <div class="widget-content">
                <div class="tag-cloud">
                  <a href="javascript:void(0)">#luxury car</a>
                  <a href="javascript:void(0)">#excutive car</a>
                  <a href="javascript:void(0)">#economy car</a>
                  <a href="javascript:void(0)">#sports car</a>
                  <a href="javascript:void(0)">#vintage car</a>
                </div>
              </div>
            </div>
            <!-- tags -->
            <!-- Categories -->
            <div class="sidebar-widget">
              <h4 class="text-light-black widget-title"><i class="flaticon-settings"></i> Top Categories</h4>
              <div class="widget-content">
                <ul class="top-cat">
                  <li><a href="javascript:void(0)" class="text-light-black">Luxury Car <span>60</span></a>
                  </li>
                  <li><a href="javascript:void(0)" class="text-light-black">Sports Car <span>100</span></a>
                  </li>
                  <li><a href="javascript:void(0)" class="text-light-black">Vintage Car <span>210</span></a>
                  </li>
                  <li><a href="javascript:void(0)" class="text-light-black">Excutive Car <span>50</span></a>
                  </li>
                  <li><a href="javascript:void(0)" class="text-light-black">Economy Car <span>10</span></a>
                  </li>
                </ul>
              </div>
            </div>
            <!-- Categories -->
          </div>