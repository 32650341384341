<app-header3></app-header3>
<!-- Slider Section -->
  <section class="p-relative">
    <div class="main-slider">
      <div class="slider-item">
        <img src="assets/images/banner/bannerh10.jpg" alt="Slider" class="img-fluid full-width">
        <div class="contant-wrapper">
          <div class="container">
            <div class="row">
              <div class="col-xl-7 col-lg-7 col-md-8 col-sm-12">
                <div class="banner-text">
                  <p class="text-custom-white  wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">Find A Car of Your Dream</p>
                  <h1 class="text-custom-white fw-900  wow fadeInUp" data-wow-duration="2s" data-wow-delay="1s">Discover the car <br><span class="text-orange">that is right for </span> you!</h1>
                  <div class="space-30"></div> <a routerLink="/homepage3" class="btn-second orange-btn  wow fadeInUp" data-wow-duration="2s" data-wow-delay="1.5s">View Specs</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="slider-overlay"></div>
      </div>
      <div class="slider-item">
        <img src="assets/images/banner/bannerh4.jpg" alt="Slider" class="img-fluid full-width">
        <div class="contant-wrapper">
          <div class="container">
            <div class="row">
              <div class="col-xl-7 col-lg-7 col-md-8 col-sm-12">
                <div class="banner-text">
                  <p class="text-custom-white  wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">Get Your Dream Car</p>
                  <h1 class="text-custom-white fw-900  wow fadeInUp" data-wow-duration="2s" data-wow-delay="1s">Lexus M8 2018 <br><span class="text-orange">$ 299</span> Monthly</h1>
                  <div class="space-30"></div> <a routerLink="/homepage3" class="btn-second orange-btn  wow fadeInUp" data-wow-duration="2s" data-wow-delay="1.5s">Test Drive Today</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="slider-overlay"></div>
      </div>
      <div class="slider-item">
        <img src="assets/images/banner/bannerh11.jpg" alt="Slider" class="img-fluid full-width">
        <div class="contant-wrapper">
          <div class="container">
            <div class="row">
              <div class="col-xl-7 col-lg-7 col-md-8 col-sm-12">
                <div class="banner-text">
                  <p class="text-custom-white  wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">Find A Car of Your Dream</p>
                  <h1 class="text-custom-white fw-900  wow fadeInUp" data-wow-duration="2s" data-wow-delay="1s">Discover and Buy the car <br><span class="text-orange">that is right for </span> you!</h1>
                  <div class="space-30"></div> <a routerLink="/homepage3" class="btn-second black-btn  wow fadeInUp" data-wow-duration="2s" data-wow-delay="1.5s">Book Now</a><a routerLink="/homepage3" class="btn-second orange-btn  wow fadeInUp" data-wow-duration="2s" data-wow-delay="1.5s">View Specs</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="slider-overlay"></div>
      </div>
      <div class="slider-item">
        <img src="assets/images/banner/bannerh2.jpg" alt="Slider" class="img-fluid full-width">
        <div class="contant-wrapper">
          <div class="container">
            <div class="row">
              <div class="col-xl-7 col-lg-7 col-md-8 col-sm-12">
                <div class="banner-text">
                  <p class="text-custom-white  wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">Get Your Dream Car</p>
                  <h1 class="text-custom-white fw-900  wow fadeInUp" data-wow-duration="2s" data-wow-delay="1s">Audi R8 Marine <br><span class="text-orange">$ 199</span> Monthly</h1>
                  <div class="space-30"></div> <a routerLink="/homepage3" class="btn-second orange-btn  wow fadeInUp" data-wow-duration="2s" data-wow-delay="1.5s">Test Drive Today</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="slider-overlay"></div>
      </div>
    </div>
    <div class="main-slider-arrows">
      <div class="main-slider-prevtab slick-arrow"> <i class="flaticon-left-chevron"></i>
      </div>
      <div class="main-slider-nexttab slick-arrow"> <i class="flaticon-right-chevron"></i>
      </div>
    </div>
  </section>
  <!-- Slider Section -->
  <!-- Car Models -->
  <section class="section-padding car-model-sec">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="section-header">
            <div class="text-wrapper text-center">
              <h3 class="fw-500 no-margin">Discover Our Featured Models</h3>
              <span class="line-grey"></span><span class="line-orange"></span><span class="line-grey"></span> 
              <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort. A masterful combination of style, power, sporty handling and comfort.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-4 col-lg-4 col-md-6">
          <div class="car-model text-center">
            <div class="car-sec car-bottom-space">
              <div class="car-title">
                <h4 class="text-light-black">Ghibli</h4>
                <h5 class="text-light-black">YOU'RE NOT LIKE EVERYONE ELSE</h5>
                <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p>
              </div>
              <img src="assets/images/showcar9.jpg" class="img-fluid full-width" alt="Car Model Image">
              <div class="car-details"> <a routerLink="/car-detail" class="btn-third transparent-btn">View Specs</a>
                <a routerLink="/car-detail" class="btn-second orange-btn">Discover More</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6">
          <div class="car-model text-center">
            <div class="car-sec car-bottom-space">
              <div class="car-title">
                <h4 class="text-light-black">Levante</h4>
                <h5 class="text-light-black">YOU'RE NOT LIKE EVERYONE ELSE</h5>
                <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p>
              </div>
              <img src="assets/images/showcar8.jpg" class="img-fluid full-width" alt="Car Model Image">
              <div class="car-details"> <a routerLink="/car-detail" class="btn-third transparent-btn">View Specs</a>
                <a routerLink="/car-detail" class="btn-second orange-btn">Discover More</a>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-6">
          <div class="car-model text-center">
            <div class="car-sec">
              <div class="car-title">
                <h4 class="text-light-black">Quattroporte</h4>
                <h5 class="text-light-black">YOU'RE NOT LIKE EVERYONE ELSE</h5>
                <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p>
              </div>
              <img src="assets/images/showcar2.jpg" class="img-fluid full-width" alt="Car Model Image">
              <div class="car-details"> <a routerLink="/car-detail" class="btn-third transparent-btn">View Specs</a>
                <a routerLink="/car-detail" class="btn-second orange-btn">Discover More</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Car Models -->
  <!-- Featured Brands -->
  <section class="section-padding bg-light-theme">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="section-header">
            <div class="text-wrapper text-center">
              <h3 class="fw-500 no-margin">Featured Brands</h3>
              <span class="line-grey"></span><span class="line-orange"></span><span class="line-grey"></span> 
              <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort. A masterful combination of style, power, sporty handling and comfort.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <!-- Car Tabs -->
          <div class="car-tabs">
            <ul class="nav nav-tabs" role="tablist">
              <li class="nav-item"> <a class="nav-link active" data-toggle="tab" href="#ghibli">Ghibli</a>
              </li>
              <li class="nav-item"> <a class="nav-link" data-toggle="tab" href="#levante">Levante</a>
              </li>
              <li class="nav-item"> <a class="nav-link" data-toggle="tab" href="#quattroporte">Quattroporte</a>
              </li>
              <li class="nav-item"> <a class="nav-link" data-toggle="tab" href="#granturismo">GranTurismo</a>
              </li>
            </ul>
          </div>
          <!-- Car Tabs -->
          <!-- Car Tabs Contant -->
          <div class="car-tab-box">
            <div class="tab-content">
              <div id="ghibli" class="container tab-pane active">
                <div class="row">
                  <div class="col-xl-4 col-lg-4 col-md-6 car-border">
                    <div class="car-box align-item-center">
                      <h4><a routerLink="/car-detail" class="text-light-black">Ghibli</a></h4>
                      <span class="tag">2020</span>
                      <a routerLink="/car-detail">
                        <img src="assets/images/showcar9.jpg" class="img-fluid full-width" alt="Car Model Image">
                      </a> <a routerLink="/car-detail" class="btn-third transparent-btn"><span>$ 22,000</span><span>Booking Now</span></a>
                    </div>
                    <div class="car-description">
                      <ul>
                        <li class="text-dark-grey">Engine Layout <strong class="text-light-black">V6</strong>
                        </li>
                        <li class="text-dark-grey">Displacement <strong class="text-light-black">2979 cc</strong>
                        </li>
                        <li class="text-dark-grey">Acceleration <strong class="text-light-black">5.5 sec</strong>
                        </li>
                        <li class="text-dark-grey">Max Speed <strong class="text-light-black">270 km/h</strong>
                        </li>
                        <li class="text-dark-grey">Max Power <strong class="text-light-black">350 HP</strong>
                        </li>
                        <li class="text-dark-grey">Traction <strong class="text-light-black">RWD</strong>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-6 car-border">
                    <div class="car-box align-item-center">
                      <h4><a routerLink="/car-detail" class="text-light-black">Ghibli S</a></h4>
                      <span class="tag">2020</span>
                      <a routerLink="/car-detail">
                        <img src="assets/images/showcar8.jpg" class="img-fluid full-width" alt="Car Model Image">
                      </a> <a routerLink="/car-detail" class="btn-third transparent-btn"><span>$ 22,000</span><span>Booking Now</span></a>
                    </div>
                    <div class="car-description">
                      <ul>
                        <li class="text-dark-grey">Engine Layout <strong class="text-light-black">V6</strong>
                        </li>
                        <li class="text-dark-grey">Displacement <strong class="text-light-black">5979 cc</strong>
                        </li>
                        <li class="text-dark-grey">Acceleration <strong class="text-light-black">4.5 sec</strong>
                        </li>
                        <li class="text-dark-grey">Max Speed <strong class="text-light-black">270 km/h</strong>
                        </li>
                        <li class="text-dark-grey">Max Power <strong class="text-light-black">550 HP</strong>
                        </li>
                        <li class="text-dark-grey">Traction <strong class="text-light-black">RWD</strong>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-6">
                    <div class="car-box align-item-center">
                      <h4><a routerLink="/car-detail" class="text-light-black">Ghibli S Q4</a></h4>
                      <span class="tag">2020</span>
                      <a routerLink="/car-detail">
                        <img src="assets/images/showcar7.jpg" class="img-fluid full-width" alt="Car Model Image">
                      </a> <a routerLink="/car-detail" class="btn-third transparent-btn"><span>$ 22,000</span><span>Booking Now</span></a>
                    </div>
                    <div class="car-description ">
                      <ul>
                        <li class="text-dark-grey">Engine Layout <strong class="text-light-black">V8</strong>
                        </li>
                        <li class="text-dark-grey">Displacement <strong class="text-light-black">2639 cc</strong>
                        </li>
                        <li class="text-dark-grey">Acceleration <strong class="text-light-black">6.5 sec</strong>
                        </li>
                        <li class="text-dark-grey">Max Speed <strong class="text-light-black">270 km/h</strong>
                        </li>
                        <li class="text-dark-grey">Max Power <strong class="text-light-black">450 HP</strong>
                        </li>
                        <li class="text-dark-grey">Traction <strong class="text-light-black">RWD</strong>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div id="levante" class="container tab-pane fade">
                <div class="row">
                  <div class="col-xl-4 col-lg-4 col-md-6 car-border">
                    <div class="car-box align-item-center">
                      <h4><a routerLink="/car-detail" class="text-light-black">Levante</a></h4>
                      <span class="tag">2020</span>
                      <a routerLink="/car-detail">
                        <img src="assets/images/showcar9.jpg" class="img-fluid full-width" alt="Car Model Image">
                      </a> <a routerLink="/car-detail" class="btn-third transparent-btn"><span>$ 22,000</span><span>Booking Now</span></a>
                    </div>
                    <div class="car-description">
                      <ul>
                        <li class="text-dark-grey">Engine Layout <strong class="text-light-black">V6</strong>
                        </li>
                        <li class="text-dark-grey">Displacement <strong class="text-light-black">2979 cc</strong>
                        </li>
                        <li class="text-dark-grey">Acceleration <strong class="text-light-black">5.5 sec</strong>
                        </li>
                        <li class="text-dark-grey">Max Speed <strong class="text-light-black">270 km/h</strong>
                        </li>
                        <li class="text-dark-grey">Max Power <strong class="text-light-black">350 HP</strong>
                        </li>
                        <li class="text-dark-grey">Traction <strong class="text-light-black">RWD</strong>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-6 car-border">
                    <div class="car-box align-item-center">
                      <h4><a routerLink="/car-detail" class="text-light-black">Levante S</a></h4>
                      <span class="tag">2020</span>
                      <a routerLink="/car-detail">
                        <img src="assets/images/showcar8.jpg" class="img-fluid full-width" alt="Car Model Image">
                      </a> <a routerLink="/car-detail" class="btn-third transparent-btn"><span>$ 22,000</span><span>Booking Now</span></a>
                    </div>
                    <div class="car-description">
                      <ul>
                        <li class="text-dark-grey">Engine Layout <strong class="text-light-black">V6</strong>
                        </li>
                        <li class="text-dark-grey">Displacement <strong class="text-light-black">2979 cc</strong>
                        </li>
                        <li class="text-dark-grey">Acceleration <strong class="text-light-black">5.5 sec</strong>
                        </li>
                        <li class="text-dark-grey">Max Speed <strong class="text-light-black">270 km/h</strong>
                        </li>
                        <li class="text-dark-grey">Max Power <strong class="text-light-black">350 HP</strong>
                        </li>
                        <li class="text-dark-grey">Traction <strong class="text-light-black">RWD</strong>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-6">
                    <div class="car-box align-item-center">
                      <h4><a routerLink="/car-detail" class="text-light-black">Levante 250 Diesel</a></h4>
                      <span class="tag">2020</span>
                      <a routerLink="/car-detail">
                        <img src="assets/images/showcar7.jpg" class="img-fluid full-width" alt="Car Model Image">
                      </a> <a routerLink="/car-detail" class="btn-third transparent-btn"><span>$ 22,000</span><span>Booking Now</span></a>
                    </div>
                    <div class="car-description">
                      <ul>
                        <li class="text-dark-grey">Engine Layout <strong class="text-light-black">V6</strong>
                        </li>
                        <li class="text-dark-grey">Displacement <strong class="text-light-black">2979 cc</strong>
                        </li>
                        <li class="text-dark-grey">Acceleration <strong class="text-light-black">5.5 sec</strong>
                        </li>
                        <li class="text-dark-grey">Max Speed <strong class="text-light-black">270 km/h</strong>
                        </li>
                        <li class="text-dark-grey">Max Power <strong class="text-light-black">350 HP</strong>
                        </li>
                        <li class="text-dark-grey">Traction <strong class="text-light-black">RWD</strong>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div id="quattroporte" class="container tab-pane fade">
                <div class="row">
                  <div class="col-xl-4 col-lg-4 col-md-6 car-border">
                    <div class="car-box align-item-center">
                      <h4><a routerLink="/car-detail" class="text-light-black">Quattroporte</a></h4>
                      <span class="tag">2020</span>
                      <a routerLink="/car-detail">
                        <img src="assets/images/showcar7.jpg" class="img-fluid full-width" alt="Car Model Image">
                      </a> <a routerLink="/car-detail" class="btn-third transparent-btn"><span>$ 22,000</span><span>Booking Now</span></a>
                    </div>
                    <div class="car-description">
                      <ul>
                        <li class="text-dark-grey">Engine Layout <strong class="text-light-black">V6</strong>
                        </li>
                        <li class="text-dark-grey">Displacement <strong class="text-light-black">2979 cc</strong>
                        </li>
                        <li class="text-dark-grey">Acceleration <strong class="text-light-black">5.5 sec</strong>
                        </li>
                        <li class="text-dark-grey">Max Speed <strong class="text-light-black">270 km/h</strong>
                        </li>
                        <li class="text-dark-grey">Max Power <strong class="text-light-black">350 HP</strong>
                        </li>
                        <li class="text-dark-grey">Traction <strong class="text-light-black">RWD</strong>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-6 car-border">
                    <div class="car-box align-item-center">
                      <h4><a routerLink="/car-detail" class="text-light-black">Quattroporte S</a></h4>
                      <span class="tag">2020</span>
                      <a routerLink="/car-detail">
                        <img src="assets/images/showcar9.jpg" class="img-fluid full-width" alt="Car Model Image">
                      </a> <a routerLink="/car-detail" class="btn-third transparent-btn"><span>$ 22,000</span><span>Booking Now</span></a>
                    </div>
                    <div class="car-description">
                      <ul>
                        <li class="text-dark-grey">Engine Layout <strong class="text-light-black">V6</strong>
                        </li>
                        <li class="text-dark-grey">Displacement <strong class="text-light-black">2979 cc</strong>
                        </li>
                        <li class="text-dark-grey">Acceleration <strong class="text-light-black">5.5 sec</strong>
                        </li>
                        <li class="text-dark-grey">Max Speed <strong class="text-light-black">270 km/h</strong>
                        </li>
                        <li class="text-dark-grey">Max Power <strong class="text-light-black">350 HP</strong>
                        </li>
                        <li class="text-dark-grey">Traction <strong class="text-light-black">RWD</strong>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-6">
                    <div class="car-box align-item-center">
                      <h4><a routerLink="/car-detail" class="text-light-black">Quattroporte S Q4</a></h4>
                      <span class="tag">2020</span>
                      <a routerLink="/car-detail">
                        <img src="assets/images/showcar8.jpg" class="img-fluid full-width" alt="Car Model Image">
                      </a> <a routerLink="/car-detail" class="btn-third transparent-btn"><span>$ 22,000</span><span>Booking Now</span></a>
                    </div>
                    <div class="car-description">
                      <ul>
                        <li class="text-dark-grey">Engine Layout <strong class="text-light-black">V6</strong>
                        </li>
                        <li class="text-dark-grey">Displacement <strong class="text-light-black">2979 cc</strong>
                        </li>
                        <li class="text-dark-grey">Acceleration <strong class="text-light-black">5.5 sec</strong>
                        </li>
                        <li class="text-dark-grey">Max Speed <strong class="text-light-black">270 km/h</strong>
                        </li>
                        <li class="text-dark-grey">Max Power <strong class="text-light-black">350 HP</strong>
                        </li>
                        <li class="text-dark-grey">Traction <strong class="text-light-black">RWD</strong>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div id="granturismo" class="container tab-pane fade">
                <div class="row">
                  <div class="col-xl-4 col-lg-4 col-md-6 car-border">
                    <div class="car-box align-item-center">
                      <h4><a routerLink="/car-detail" class="text-light-black">Granturismo</a></h4>
                      <span class="tag">2020</span>
                      <a routerLink="/car-detail">
                        <img src="assets/images/showcar9.jpg" class="img-fluid full-width" alt="Car Model Image">
                      </a> <a routerLink="/car-detail" class="btn-third transparent-btn"><span>$ 22,000</span><span>Booking Now</span></a>
                    </div>
                    <div class="car-description">
                      <ul>
                        <li class="text-dark-grey">Engine Layout <strong class="text-light-black">V6</strong>
                        </li>
                        <li class="text-dark-grey">Displacement <strong class="text-light-black">2979 cc</strong>
                        </li>
                        <li class="text-dark-grey">Acceleration <strong class="text-light-black">5.5 sec</strong>
                        </li>
                        <li class="text-dark-grey">Max Speed <strong class="text-light-black">270 km/h</strong>
                        </li>
                        <li class="text-dark-grey">Max Power <strong class="text-light-black">350 HP</strong>
                        </li>
                        <li class="text-dark-grey">Traction <strong class="text-light-black">RWD</strong>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-6 car-border">
                    <div class="car-box align-item-center">
                      <h4><a routerLink="/car-detail" class="text-light-black">Granturismo MC</a></h4>
                      <span class="tag">2020</span>
                      <a routerLink="/car-detail">
                        <img src="assets/images/showcar8.jpg" class="img-fluid full-width" alt="Car Model Image">
                      </a> <a routerLink="/car-detail" class="btn-third transparent-btn"><span>$ 22,000</span><span>Booking Now</span></a>
                    </div>
                    <div class="car-description">
                      <ul>
                        <li class="text-dark-grey">Engine Layout <strong class="text-light-black">V6</strong>
                        </li>
                        <li class="text-dark-grey">Displacement <strong class="text-light-black">2979 cc</strong>
                        </li>
                        <li class="text-dark-grey">Acceleration <strong class="text-light-black">5.5 sec</strong>
                        </li>
                        <li class="text-dark-grey">Max Speed <strong class="text-light-black">270 km/h</strong>
                        </li>
                        <li class="text-dark-grey">Max Power <strong class="text-light-black">350 HP</strong>
                        </li>
                        <li class="text-dark-grey">Traction <strong class="text-light-black">RWD</strong>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-6">
                    <div class="car-box align-item-center">
                      <h4><a routerLink="/car-detail" class="text-light-black">Granturismo Sport</a></h4>
                      <span class="tag">2020</span>
                      <a routerLink="/car-detail">
                        <img src="assets/images/showcar7.jpg" class="img-fluid full-width" alt="Car Model Image">
                      </a> <a routerLink="/car-detail" class="btn-third transparent-btn"><span>$ 22,000</span><span>Booking Now</span></a>
                    </div>
                    <div class="car-description">
                      <ul>
                        <li class="text-dark-grey">Engine Layout <strong class="text-light-black">V6</strong>
                        </li>
                        <li class="text-dark-grey">Displacement <strong class="text-light-black">2979 cc</strong>
                        </li>
                        <li class="text-dark-grey">Acceleration <strong class="text-light-black">5.5 sec</strong>
                        </li>
                        <li class="text-dark-grey">Max Speed <strong class="text-light-black">270 km/h</strong>
                        </li>
                        <li class="text-dark-grey">Max Power <strong class="text-light-black">350 HP</strong>
                        </li>
                        <li class="text-dark-grey">Traction <strong class="text-light-black">RWD</strong>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Car Tabs Contant -->
        </div>
      </div>
    </div>
  </section>
  <!-- Featured Brands -->
  <!-- Car Categories -->
  <section class="section-padding car-categories-sec">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="section-header">
            <div class="text-wrapper text-center">
              <h3 class="fw-500 no-margin">Find Your Best Match</h3>
              <span class="line-grey"></span><span class="line-orange"></span><span class="line-grey"></span> 
              <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort. A masterful combination of style, power, sporty handling and comfort.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 p-relative">
          <div class="category-slider">
            <div class="car-categories">
              <a routerLink="/car-page">
                <img src="assets/images/match/match-1.jpg" class="img-fluid full-width" alt="Category Image" />
              </a>
              <div class="category-caption">
                <div class="category-wrapper">
                  <a routerLink="/car-page">
                    <h4 class="text-custom-white">Practice <span class="brand-logo">
                      <img src="assets/images/bmw_logo.png" class="img-fluid" alt="brand logo">
                    </span></h4>
                  </a>
                  <p class="text-custom-white no-margin">A masterful combination of style, power, sporty handling and comfort.</p>
                </div>
              </div>
            </div>
            <div class="car-categories">
              <a routerLink="/car-page">
                <img src="assets/images/match/match-2.jpg" class="img-fluid full-width" alt="Category Image" />
              </a>
              <div class="category-caption">
                <div class="category-wrapper">
                  <a routerLink="/car-page">
                    <h4 class="text-custom-white">Sports Car
                    <span class="brand-logo">
                      <img src="assets/images/wv_logo.png" class="img-fluid" alt="brand logo">
                    </span></h4>
                  </a>
                  <p class="text-custom-white no-margin">A masterful combination of style, power, sporty handling and comfort.</p>
                </div>
              </div>
            </div>
            <div class="car-categories">
              <a routerLink="/car-page">
                <img src="assets/images/match/match-3.jpg" class="img-fluid full-width" alt="Category Image" />
              </a>
              <div class="category-caption">
                <div class="category-wrapper">
                  <a routerLink="/car-page">
                    <h4 class="text-custom-white">Luxury
                    <span class="brand-logo">
                      <img src="assets/images/bmw_logo.png" class="img-fluid" alt="brand logo">
                    </span></h4>
                  </a>
                  <p class="text-custom-white no-margin">A masterful combination of style, power, sporty handling and comfort.</p>
                </div>
              </div>
            </div>
            <div class="car-categories">
              <a routerLink="/car-page">
                <img src="assets/images/match/match-4.jpg" class="img-fluid full-width" alt="Category Image" />
              </a>
              <div class="category-caption">
                <div class="category-wrapper">
                  <a routerLink="/car-page">
                    <h4 class="text-custom-white">Fmaily Car
                    <span class="brand-logo">
                      <img src="assets/images/wv_logo.png" class="img-fluid" alt="brand logo">
                    </span></h4>
                  </a>
                  <p class="text-custom-white no-margin">A masterful combination of style, power, sporty handling and comfort.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="category-slider-arrows">
            <div class="category-slider-prevtab slick-arrow" style=""> <i class="flaticon-left-chevron"></i>
            </div>
            <div class="category-slider-nexttab slick-arrow" style=""> <i class="flaticon-right-chevron"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Car Categories -->
  <!-- News Sec Desktop -->
  <section class="section-padding news-sec-desktop bg-light-theme d-none d-md-block">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="section-header">
            <div class="text-wrapper text-center">
              <h3 class="fw-500 no-margin">News and Technology</h3>
              <span class="line-grey"></span><span class="line-orange"></span><span class="line-grey"></span> 
              <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort. A masterful combination of style, power, sporty handling and comfort.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-8 col-lg-8 col-md-8">
          <div class="row">
            <div class="col-12">
              <div class="news-sec-img p-relative">
                <a routerLink="/blog-standard">
                  <img src="assets/images/news/news-3.jpg" class="img-fluid full-width" alt="News Image">
                </a>
                <div class="news-caption">
                  <div class="news-wrapper">
                    <a routerLink="/blog-standard">
                      <h4 class="text-custom-white text-capitalize">the love of power is the demon of men & women</h4>
                    </a>
                    <p class="text-custom-white no-margin">A masterful combination of style, power, sporty handling and comfort.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="news-sec-img p-relative">
                <a routerLink="/blog-standard">
                  <img src="assets/images/news/news-1.jpg" class="img-fluid full-width" alt="News Image">
                </a>
                <div class="news-caption">
                  <div class="news-wrapper">
                    <a routerLink="/blog-standard">
                      <h4 class="text-custom-white text-capitalize">Life is hard, and a lot of people come home tired from work</h4>
                    </a>
                    <p class="text-custom-white no-margin">A masterful combination of style, power, sporty handling and comfort.</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="news-sec-img p-relative">
                <a routerLink="/blog-standard">
                  <img src="assets/images/news/news-2.jpg" class="img-fluid full-width" alt="News Image">
                </a>
                <div class="news-caption">
                  <div class="news-wrapper">
                    <a routerLink="/blog-standard">
                      <h4 class="text-custom-white text-capitalize">The PC has improved the world in just about every area</h4>
                    </a>
                    <p class="text-custom-white no-margin">A masterful combination of style, power, sporty handling and comfort.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4 col-md-4">
          <div class="news-sec-img news-last-image">
            <a routerLink="/blog-standard">
              <img src="assets/images/news/news-4.jpg" class="img-fluid full-width" alt="News Image">
            </a>
            <div class="news-caption">
              <div class="news-wrapper">
                <a routerLink="/blog-standard">
                  <h4 class="text-custom-white text-capitalize">the love of power is the demon of men & women</h4>
                </a>
                <p class="text-custom-white no-margin">A masterful combination of style, power, sporty handling and comfort.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- News Sec Desktop -->
  <!-- News Sec Mobile -->
  <section class="section-padding news-sec-mobile bg-light-theme d-block d-md-none">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="section-header">
            <div class="text-wrapper text-center">
              <h3 class="fw-500 no-margin">News</h3>
              <span class="line-grey"></span><span class="line-orange"></span><span class="line-grey"></span> 
              <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort. A masterful combination of style, power, sporty handling and comfort.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="news-mobile-slider">
            <div class="news-sec-img p-relative">
              <a routerLink="/blog-standard">
                <img src="assets/images/news/news-1.jpg" class="img-fluid full-width" alt="News Image">
              </a>
              <div class="news-caption">
                <div class="news-wrapper">
                  <a routerLink="/blog-standard">
                    <h4 class="text-custom-white text-capitalize">the love of power is the demon of men & women</h4>
                  </a>
                  <p class="text-custom-white no-margin">A masterful combination of style, power, sporty handling and comfort.</p>
                </div>
              </div>
            </div>
            <div class="news-sec-img p-relative">
              <a routerLink="/blog-standard">
                <img src="assets/images/news/news-2.jpg" class="img-fluid full-width" alt="News Image">
              </a>
              <div class="news-caption">
                <div class="news-wrapper">
                  <a routerLink="/blog-standard">
                    <h4 class="text-custom-white text-capitalize">The PC has improved the world in just about every area</h4>
                  </a>
                  <p class="text-custom-white no-margin">A masterful combination of style, power, sporty handling and comfort.</p>
                </div>
              </div>
            </div>
            <div class="news-sec-img p-relative">
              <a routerLink="/blog-standard">
                <img src="assets/images/news/news-1.jpg" class="img-fluid full-width" alt="News Image">
              </a>
              <div class="news-caption">
                <div class="news-wrapper">
                  <a routerLink="/blog-standard">
                    <h4 class="text-custom-white text-capitalize">Life is hard, and a lot of people come home tired from work</h4>
                  </a>
                  <p class="text-custom-white no-margin">A masterful combination of style, power, sporty handling and comfort.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Video Sec -->
  <section class="section-padding video-sec pb-0" id="video-box">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="section-header">
            <div class="text-wrapper text-center">
              <h3 class="fw-500 no-margin">Live Events Happening</h3>
              <span class="line-grey"></span><span class="line-orange"></span><span class="line-grey"></span> 
              <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort. A masterful combination of style, power, sporty handling and comfort.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid no-padding">
      <div class="row">
        <div class="col-12">
          <div class="jarallax" data-jarallax data-jarallax-video="https://www.youtube.com/embed/AdZrEIo6UYU?autoplay=1&rel=0">
            <div class="demo-table">
              <div class="demo-table-cell">
                <h3>Our Fleet, Your Fleet</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                <h5 class="rubik">Call Now (345)-123-4567-8</h5>
                <a routerLink="/contact" class="btn-second orange-btn">Request a Quote</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Video Sec -->
  <!-- Testimonials -->
  <section class="section-padding testimonials">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="section-header">
            <div class="text-wrapper text-center">
              <h3 class="fw-500 no-margin">What Our Satisfied Customers Say</h3>
              <span class="line-grey"></span><span class="line-orange"></span><span class="line-grey"></span> 
              <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort. A masterful combination of style, power, sporty handling and comfort.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="testimonial-slider">
            <div class="testimonial-wrapper">
              <div class="text-wrap">
                <blockquote>
                  <p>AutoImage is nisi aliquip consequat duis velit esse cillum dolore fugiat nulla pariatur excepteur sint occaecat lorem ipsum dolor sit amt consectetur adipisicing elit sed eiusmod tempor incididuntu labore et dolore magna aliqua enimad minim veniam quis nostrud exercitation sed ipsum ullamco laboris nisi aliquip.</p>
                  <div class="infor"> <cite class="name text-orange">John Stephan</cite>
                    <cite class="position text-dark-white">Vehicle Buyer</cite>
                  </div>
                </blockquote>
              </div>
            </div>
            <div class="testimonial-wrapper">
              <div class="text-wrap">
                <blockquote>
                  <p>No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally.</p>
                  <div class="infor"> <cite class="name text-orange">Nick William</cite>
                    <cite class="position text-dark-white">Customer</cite>
                  </div>
                </blockquote>
              </div>
            </div>
            <div class="testimonial-wrapper">
              <div class="text-wrap">
                <blockquote>
                  <p>Our support and response has been amazing, helping me with several issues I came across and got them solved almost the same day. A pleasure to work with them!</p>
                  <div class="infor"> <cite class="name text-orange">Smith John</cite>
                    <cite class="position text-dark-white">Happy Customer</cite>
                  </div>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Testimonials -->
<app-footer1></app-footer1>
  <div class="modal fade" id="register">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title text-left no-margin"><i class="fas fa-unlock-alt mr-2"></i> <span id="signup-text">Sign Up</span><span
             id="signin-text" class="none">Sign In</span></h6>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        <!-- Sign up form -->
        <div class="modal-body" id="signup-form">
          <form>
            <div class="form-group">
              <input type="email" class="form-control" name="#" placeholder="Email">
            </div>
            <div class="form-group">
              <input type="password" class="form-control" name="#" placeholder="Password">
            </div>
            <div class="form-group">
              <button type="submit" class="btn-second orange-btn full-width">Sign Up</button>
            </div>
          </form>
          <div class="form-excerpts">
            <ul>
              <li>Already a member? <a href="javascript:void(0)" id="signin-btn">Sign In</a>
              </li>
              <li><a routerLink="/homepage3">Recover Password</a>
              </li>
            </ul>
          </div>
          <div class="social-login"> <span>Or Signup with</span>
            <div class="social-btn"> <a routerLink="/homepage3" class="facebook"><i class="fab fa-facebook-f"></i> Facebook</a>
              <a routerLink="/homepage3" class="google"><i class="fab fa-google-plus-g"></i> Google</a>
            </div>
          </div>
        </div>
        <!-- Sign up form -->
        <!-- Sign in form -->
        <div class="modal-body none" id="signin-form">
          <form>
            <div class="form-group">
              <input type="text" class="form-control" name="#" placeholder="Username or Email">
            </div>
            <div class="form-group">
              <input type="password" class="form-control" name="#" placeholder="Password">
            </div>
            <div class="form-group">
              <label class="custom-checkbox">
                <input type="checkbox" name="#">Keep me signed in <span class="checkmark"></span>
              </label>
            </div>
            <div class="form-group">
              <button type="submit" class="btn-second orange-btn full-width">Sign In</button>
            </div>
          </form>
          <div class="form-excerpts">
            <ul>
              <li>Not a member? <a href="javascript:void(0)" id="signup-btn">Sign Up</a>
              </li>
              <li><a routerLink="/homepage3">Recover Password</a>
              </li>
            </ul>
          </div>
          <div class="social-login"> <span>Or connect with</span>
            <div class="social-btn"> <a routerLink="/homepage3" class="facebook"><i class="fab fa-facebook-f"></i> Facebook</a>
              <a routerLink="/homepage3" class="google"><i class="fab fa-google-plus-g"></i> Google</a>
            </div>
          </div>
        </div>
        <!-- Sign in form -->
      </div>
    </div>
  </div>