<div class="mobile-menu-second">
<app-header4></app-header4>	
<!-- Sub header -->
  <div class="sub-header-sec p-relative">
    <img src="assets/images/banner/bannerh8.jpg" class="img-fluid" alt="Sub header">
    <div class="sub-header-overlay"></div>
    <div class="sub-header-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-xl-12">
            <div class="sub-header-text-wrapper text-center">
              <h1 class="text-custom-white">Login</h1>
              <ul class="breadcrumbs">
                <li><a routerLink="/" class="text-custom-white">Home</a>
                </li>
                <li><a routerLink="/login" class="text-custom-white">Pages</a>
                </li>
                <li><span class="text-custom-white">Login</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Sub header -->
  <!-- login -->
  <section class="section-padding">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="modal show" id="register-page">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h6 class="modal-title text-left no-margin"><i class="fas fa-unlock-alt mr-2"></i><span>Sign In</span></h6>
                </div>
                <!-- Sign in form -->
                <div class="modal-body">
                  <form>
                    <div class="form-group">
                      <input type="text" class="form-control form-control-custom" name="#" placeholder="Username or Email">
                    </div>
                    <div class="form-group">
                      <input type="password" class="form-control form-control-custom" name="#" placeholder="Password">
                    </div>
                    <div class="form-group">
                      <label class="custom-checkbox">
                        <input type="checkbox" name="#">Keep me signed in <span class="checkmark"></span>
                      </label>
                    </div>
                    <div class="form-group">
                      <button type="submit" class="btn-second orange-btn full-width">Sign In</button>
                    </div>
                  </form>
                  <div class="form-excerpts">
                    <ul>
                      <li>Not a member? <a routerLink="/register">Sign Up</a>
                      </li>
                      <li><a routerLink="/login">Recover Password</a>
                      </li>
                    </ul>
                  </div>
                  <div class="social-login">  <span>Or connect with</span>
                    <div class="social-btn">  <a routerLink="/login" class="facebook"><i class="fab fa-facebook-f"></i> Facebook</a>
                      <a routerLink="/login" class="google"><i class="fab fa-google-plus-g"></i> Google</a>
                    </div>
                  </div>
                </div>
                <!-- Sign in form -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- login -->
	<app-footer4></app-footer4>
</div>