<div class="mobile-menu-second">
	<app-header4></app-header4>
<!-- Sub header -->
  <div class="sub-header-sec p-relative">
    <img src="assets/images/banner/bannerh13.jpg" class="img-fluid" alt="Sub header">
    <div class="sub-header-overlay"></div>
    <div class="sub-header-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-xl-12">
            <div class="sub-header-text-wrapper text-center">
              <h1 class="text-custom-white">Listing</h1>
              <ul class="breadcrumbs">
                <li><a routerLink="/" class="text-custom-white">Home</a>
                </li>
                <li><a routerLink="/listing" class="text-custom-white">Pages</a>
                </li>
                <li><span class="text-custom-white">Listing</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Sub header -->
  <!-- listing -->
  <section class="section-padding listing-with-map listing-with-map-3">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="search-filter-options">
            <div class="row align-items-end">
              <div class="col-lg-3 col-md-6">
                <div class="products-ordering-list">  <span>Sort By</span>
                  <select class="select-design-2 custom-select sort">
                    <option>Default</option>
                    <option>Luxury Car</option>
                    <option>Sports Car</option>
                    <option>Vintage Car</option>
                    <option>Excutive Car</option>
                    <option>Economy Car</option>
                  </select>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="products-ordering-list">  <span>Price</span>
                  <select class="select-design-2 custom-select price">
                    <option value="1">Low to High</option>
                    <option value="2">High to Low</option>
                    <option value="3">Price High</option>
                    <option value="4">Price Low</option>
                  </select>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="products-ordering-list">  <span>Category</span>
                  <select class="select-design-2 custom-select cat">
                    <option>Default</option>
                    <option>Luxury Car</option>
                    <option>Sports Car</option>
                    <option>Vintage Car</option>
                    <option>Excutive Car</option>
                    <option>Economy Car</option>
                  </select>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="filter-by-grid-list"> <span>View As</span>
                  <div class="filter-tab-area">
                    <ul class="nav nav-tabs" role="tablist">
                      <li> <a data-toggle="tab" href="#listing_grid" role="tab"><i class="fa fa-th-large"></i></a>
                      </li>
                      <li> <a class="active" data-toggle="tab" href="#listing_list" role="tab"><i class="fa fa-bars"></i></a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="sort-by-tags">
                  <div class="tags-by">Tags By</div>
                  <div class="tags-group">
                    <label class="custom-checkbox">
                      <input type="checkbox" name="#">Luxury car <span class="checkmark"></span>
                    </label>
                    <label class="custom-checkbox">
                      <input type="checkbox" name="#">Sports car <span class="checkmark"></span>
                    </label>
                    <label class="custom-checkbox">
                      <input type="checkbox" name="#">Vintage car  <span class="checkmark"></span>
                    </label>
                    <label class="custom-checkbox">
                      <input type="checkbox" name="#">Excutive car <span class="checkmark"></span>
                    </label>
                    <label class="custom-checkbox">
                      <input type="checkbox" name="#">Economy car  <span class="checkmark"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tab-content">
        <div id="listing_list" class="tab-pane active">
          <div class="row">
            <div class="col-lg-6">
              <div class="car-model-layout-list">
                <div class="car-thumbnail-area">
                  <div class="item-thumb">
                    <img src="assets/images/car/car-1.jpg" class="img-fluid full-width" alt="image">
                  </div>
                  <div class="car-overlay-info">
                    <div class="car-type luxury">Luxury</div>
                    <div class="car-description-bottom"> <a routerLink="/listing" class="text-custom-white"><i class="fa fa-heart"></i>save</a>
                      <a routerLink="/listing" class="text-custom-white"><i class="fa fa-image"></i>4 photos</a>
                    </div>
                  </div>
                </div>
                <div class="car-description-area">
                  <div class="car-description-content">
                    <h5 class="title"><a routerLink="/car-detail" class="text-light-black">Luxury Car</a></h5>
                    <div class="car-rating">
                      <div class="rating-value"> <span>4.8</span>16 Ratings</div>
                      <div class="rating-icon">
                        <ul>
                          <li class="text-yellow">$</li>
                          <li class="text-yellow">$</li>
                          <li class="text-yellow">$</li>
                          <li>$</li>
                        </ul>
                      </div>
                    </div>
                    <div class="car-meta">
                      <div class="car-location"> <i class="fas fa-map-marker-alt"></i>
                        Barcelona, ES</div>
                      <div class="car-number"> <i class="fas fa-phone-alt"></i>
                        +34 2 2458752</div>
                    </div>
                    <div class="car-summary">
                      <p>The path of the righteous man is beset on all side by the iniquities. Nam in mauris quis libero sod eleifend spectra online.</p>
                    </div>
                  </div>
                  <div class="car-description-footer">  <a routerLink="/listing" class="book text-light-black"><i class="flaticon-steering-wheel"></i> Test Drive</a>
                    <a routerLink="/car-detail" class="view">Learn more</a>
                  </div>
                </div>
              </div>
              <div class="car-model-layout-list">
                <div class="car-thumbnail-area">
                  <div class="item-thumb">
                    <img src="assets/images/car/car-2.jpg" class="img-fluid full-width" alt="image">
                  </div>
                  <div class="car-overlay-info">
                    <div class="car-type luxury">Sports</div>
                    <div class="car-description-bottom"> <a routerLink="/listing" class="text-custom-white"><i class="fa fa-heart"></i>save</a>
                      <a routerLink="/listing" class="text-custom-white"><i class="fa fa-image"></i>4 photos</a>
                    </div>
                  </div>
                </div>
                <div class="car-description-area">
                  <div class="car-description-content">
                    <h5 class="title"><a routerLink="/car-detail" class="text-light-black">Sports Car</a></h5>
                    <div class="car-rating">
                      <div class="rating-value"> <span>4.5</span>14 Ratings</div>
                      <div class="rating-icon">
                        <ul>
                          <li class="text-yellow">$</li>
                          <li class="text-yellow">$</li>
                          <li class="text-yellow">$</li>
                          <li>$</li>
                        </ul>
                      </div>
                    </div>
                    <div class="car-meta">
                      <div class="car-location"> <i class="fas fa-map-marker-alt"></i>
                        New York</div>
                      <div class="car-number"> <i class="fas fa-phone-alt"></i>
                        +34 2 2458752</div>
                    </div>
                    <div class="car-summary">
                      <p>The path of the righteous man is beset on all side by the iniquities. Nam in mauris quis libero sod eleifend spectra online.</p>
                    </div>
                  </div>
                  <div class="car-description-footer">  <a routerLink="/listing" class="book text-light-black"><i class="flaticon-steering-wheel"></i> Test Drive</a>
                    <a routerLink="/car-detail" class="view">Learn more</a>
                  </div>
                </div>
              </div>
              <div class="car-model-layout-list">
                <div class="car-thumbnail-area">
                  <div class="item-thumb">
                    <img src="assets/images/car/car-3.jpg" class="img-fluid full-width" alt="image">
                  </div>
                  <div class="car-overlay-info">
                    <div class="car-type luxury">Vintage</div>
                    <div class="car-description-bottom"> <a routerLink="/listing" class="text-custom-white"><i class="fa fa-heart"></i>save</a>
                      <a routerLink="/listing" class="text-custom-white"><i class="fa fa-image"></i>4 photos</a>
                    </div>
                  </div>
                </div>
                <div class="car-description-area">
                  <div class="car-description-content">
                    <h5 class="title"><a routerLink="/car-detail" class="text-light-black">Vintage Car</a></h5>
                    <div class="car-rating">
                      <div class="rating-value"> <span>5</span>20 Ratings</div>
                      <div class="rating-icon">
                        <ul>
                          <li class="text-yellow">$</li>
                          <li class="text-yellow">$</li>
                          <li class="text-yellow">$</li>
                          <li class="text-yellow">$</li>
                          <li class="text-yellow">$</li>
                        </ul>
                      </div>
                    </div>
                    <div class="car-meta">
                      <div class="car-location"> <i class="fas fa-map-marker-alt"></i>
                        London</div>
                      <div class="car-number"> <i class="fas fa-phone-alt"></i>
                        +34 2 2458752</div>
                    </div>
                    <div class="car-summary">
                      <p>The path of the righteous man is beset on all side by the iniquities. Nam in mauris quis libero sod eleifend spectra online.</p>
                    </div>
                  </div>
                  <div class="car-description-footer">  <a routerLink="/listing" class="book text-light-black"><i class="flaticon-steering-wheel"></i> Test Drive</a>
                    <a routerLink="/car-detail" class="view">Learn more</a>
                  </div>
                </div>
              </div>
              <div class="car-model-layout-list">
                <div class="car-thumbnail-area">
                  <div class="item-thumb">
                    <img src="assets/images/car/car-4.jpg" class="img-fluid full-width" alt="image">
                  </div>
                  <div class="car-overlay-info">
                    <div class="car-type luxury">Excutive</div>
                    <div class="car-description-bottom"> <a routerLink="/listing" class="text-custom-white"><i class="fa fa-heart"></i>save</a>
                      <a routerLink="/listing" class="text-custom-white"><i class="fa fa-image"></i>4 photos</a>
                    </div>
                  </div>
                </div>
                <div class="car-description-area">
                  <div class="car-description-content">
                    <h5 class="title"><a routerLink="/car-detail" class="text-light-black">Excutive Car</a></h5>
                    <div class="car-rating">
                      <div class="rating-value"> <span>3.2</span>10 Ratings</div>
                      <div class="rating-icon">
                        <ul>
                          <li class="text-yellow">$</li>
                          <li class="text-yellow">$</li>
                          <li>$</li>
                          <li>$</li>
                        </ul>
                      </div>
                    </div>
                    <div class="car-meta">
                      <div class="car-location"> <i class="fas fa-map-marker-alt"></i>
                        France</div>
                      <div class="car-number"> <i class="fas fa-phone-alt"></i>
                        +34 2 2458752</div>
                    </div>
                    <div class="car-summary">
                      <p>The path of the righteous man is beset on all side by the iniquities. Nam in mauris quis libero sod eleifend spectra online.</p>
                    </div>
                  </div>
                  <div class="car-description-footer">  <a routerLink="/listing" class="book text-light-black"><i class="flaticon-steering-wheel"></i> Test Drive</a>
                    <a routerLink="/car-detail" class="view">Learn more</a>
                  </div>
                </div>
              </div>
              <div class="car-model-layout-list">
                <div class="car-thumbnail-area">
                  <div class="item-thumb">
                    <img src="assets/images/car/car-5.jpg" class="img-fluid full-width" alt="image">
                  </div>
                  <div class="car-overlay-info">
                    <div class="car-type luxury">Economy</div>
                    <div class="car-description-bottom"> <a routerLink="/listing" class="text-custom-white"><i class="fa fa-heart"></i>save</a>
                      <a routerLink="/listing" class="text-custom-white"><i class="fa fa-image"></i>4 photos</a>
                    </div>
                  </div>
                </div>
                <div class="car-description-area">
                  <div class="car-description-content">
                    <h5 class="title"><a routerLink="/car-detail" class="text-light-black">Economy Car</a></h5>
                    <div class="car-rating">
                      <div class="rating-value"> <span>4.1</span>15 Ratings</div>
                      <div class="rating-icon">
                        <ul>
                          <li class="text-yellow">$</li>
                          <li class="text-yellow">$</li>
                          <li class="text-yellow">$</li>
                          <li>$</li>
                        </ul>
                      </div>
                    </div>
                    <div class="car-meta">
                      <div class="car-location"> <i class="fas fa-map-marker-alt"></i>
                        Germany</div>
                      <div class="car-number"> <i class="fas fa-phone-alt"></i>
                        +34 2 2458752</div>
                    </div>
                    <div class="car-summary">
                      <p>The path of the righteous man is beset on all side by the iniquities. Nam in mauris quis libero sod eleifend spectra online.</p>
                    </div>
                  </div>
                  <div class="car-description-footer">  <a routerLink="/listing" class="book text-light-black"><i class="flaticon-steering-wheel"></i> Test Drive</a>
                    <a routerLink="/car-detail" class="view">Learn more</a>
                  </div>
                </div>
              </div>
              <div class="car-model-layout-list">
                <div class="car-thumbnail-area">
                  <div class="item-thumb">
                    <img src="assets/images/car/car-6.jpg" class="img-fluid full-width" alt="image">
                  </div>
                  <div class="car-overlay-info">
                    <div class="car-type luxury">Luxury</div>
                    <div class="car-description-bottom"> <a routerLink="/listing" class="text-custom-white"><i class="fa fa-heart"></i>save</a>
                      <a routerLink="/listing" class="text-custom-white"><i class="fa fa-image"></i>4 photos</a>
                    </div>
                  </div>
                </div>
                <div class="car-description-area">
                  <div class="car-description-content">
                    <h5 class="title"><a routerLink="/car-detail" class="text-light-black">Luxury Car</a></h5>
                    <div class="car-rating">
                      <div class="rating-value"> <span>4.8</span>16 Ratings</div>
                      <div class="rating-icon">
                        <ul>
                          <li class="text-yellow">$</li>
                          <li class="text-yellow">$</li>
                          <li class="text-yellow">$</li>
                          <li>$</li>
                        </ul>
                      </div>
                    </div>
                    <div class="car-meta">
                      <div class="car-location"> <i class="fas fa-map-marker-alt"></i>
                        Barcelona, ES</div>
                      <div class="car-number"> <i class="fas fa-phone-alt"></i>
                        +34 2 2458752</div>
                    </div>
                    <div class="car-summary">
                      <p>The path of the righteous man is beset on all side by the iniquities. Nam in mauris quis libero sod eleifend spectra online.</p>
                    </div>
                  </div>
                  <div class="car-description-footer">  <a routerLink="/listing" class="book text-light-black"><i class="flaticon-steering-wheel"></i> Test Drive</a>
                    <a routerLink="/car-detail" class="view">Learn more</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="car-model-layout-list">
                <div class="car-thumbnail-area">
                  <div class="item-thumb">
                    <img src="assets/images/car/car-7.jpg" class="img-fluid full-width" alt="image">
                  </div>
                  <div class="car-overlay-info">
                    <div class="car-type luxury">Luxury</div>
                    <div class="car-description-bottom"> <a routerLink="/listing" class="text-custom-white"><i class="fa fa-heart"></i>save</a>
                      <a routerLink="/listing" class="text-custom-white"><i class="fa fa-image"></i>4 photos</a>
                    </div>
                  </div>
                </div>
                <div class="car-description-area">
                  <div class="car-description-content">
                    <h5 class="title"><a routerLink="/car-detail" class="text-light-black">Luxury Car</a></h5>
                    <div class="car-rating">
                      <div class="rating-value"> <span>4.8</span>16 Ratings</div>
                      <div class="rating-icon">
                        <ul>
                          <li class="text-yellow">$</li>
                          <li class="text-yellow">$</li>
                          <li class="text-yellow">$</li>
                          <li>$</li>
                        </ul>
                      </div>
                    </div>
                    <div class="car-meta">
                      <div class="car-location"> <i class="fas fa-map-marker-alt"></i>
                        Barcelona, ES</div>
                      <div class="car-number"> <i class="fas fa-phone-alt"></i>
                        +34 2 2458752</div>
                    </div>
                    <div class="car-summary">
                      <p>The path of the righteous man is beset on all side by the iniquities. Nam in mauris quis libero sod eleifend spectra online.</p>
                    </div>
                  </div>
                  <div class="car-description-footer">  <a routerLink="/listing" class="book text-light-black"><i class="flaticon-steering-wheel"></i> Test Drive</a>
                    <a routerLink="/car-detail" class="view">Learn more</a>
                  </div>
                </div>
              </div>
              <div class="car-model-layout-list">
                <div class="car-thumbnail-area">
                  <div class="item-thumb">
                    <img src="assets/images/car/car-8.jpg" class="img-fluid full-width" alt="image">
                  </div>
                  <div class="car-overlay-info">
                    <div class="car-type luxury">Sports</div>
                    <div class="car-description-bottom"> <a routerLink="/listing" class="text-custom-white"><i class="fa fa-heart"></i>save</a>
                      <a routerLink="/listing" class="text-custom-white"><i class="fa fa-image"></i>4 photos</a>
                    </div>
                  </div>
                </div>
                <div class="car-description-area">
                  <div class="car-description-content">
                    <h5 class="title"><a routerLink="/car-detail" class="text-light-black">Sports Car</a></h5>
                    <div class="car-rating">
                      <div class="rating-value"> <span>4.5</span>14 Ratings</div>
                      <div class="rating-icon">
                        <ul>
                          <li class="text-yellow">$</li>
                          <li class="text-yellow">$</li>
                          <li class="text-yellow">$</li>
                          <li>$</li>
                        </ul>
                      </div>
                    </div>
                    <div class="car-meta">
                      <div class="car-location"> <i class="fas fa-map-marker-alt"></i>
                        New York</div>
                      <div class="car-number"> <i class="fas fa-phone-alt"></i>
                        +34 2 2458752</div>
                    </div>
                    <div class="car-summary">
                      <p>The path of the righteous man is beset on all side by the iniquities. Nam in mauris quis libero sod eleifend spectra online.</p>
                    </div>
                  </div>
                  <div class="car-description-footer">  <a routerLink="/listing" class="book text-light-black"><i class="flaticon-steering-wheel"></i> Test Drive</a>
                    <a routerLink="/car-detail" class="view">Learn more</a>
                  </div>
                </div>
              </div>
              <div class="car-model-layout-list">
                <div class="car-thumbnail-area">
                  <div class="item-thumb">
                    <img src="assets/images/car/car-9.jpg" class="img-fluid full-width" alt="image">
                  </div>
                  <div class="car-overlay-info">
                    <div class="car-type luxury">Vintage</div>
                    <div class="car-description-bottom"> <a routerLink="/listing" class="text-custom-white"><i class="fa fa-heart"></i>save</a>
                      <a routerLink="/listing" class="text-custom-white"><i class="fa fa-image"></i>4 photos</a>
                    </div>
                  </div>
                </div>
                <div class="car-description-area">
                  <div class="car-description-content">
                    <h5 class="title"><a routerLink="/car-detail" class="text-light-black">Vintage Car</a></h5>
                    <div class="car-rating">
                      <div class="rating-value"> <span>5</span>20 Ratings</div>
                      <div class="rating-icon">
                        <ul>
                          <li class="text-yellow">$</li>
                          <li class="text-yellow">$</li>
                          <li class="text-yellow">$</li>
                          <li class="text-yellow">$</li>
                          <li class="text-yellow">$</li>
                        </ul>
                      </div>
                    </div>
                    <div class="car-meta">
                      <div class="car-location"> <i class="fas fa-map-marker-alt"></i>
                        London</div>
                      <div class="car-number"> <i class="fas fa-phone-alt"></i>
                        +34 2 2458752</div>
                    </div>
                    <div class="car-summary">
                      <p>The path of the righteous man is beset on all side by the iniquities. Nam in mauris quis libero sod eleifend spectra online.</p>
                    </div>
                  </div>
                  <div class="car-description-footer">  <a routerLink="/listing" class="book text-light-black"><i class="flaticon-steering-wheel"></i> Test Drive</a>
                    <a routerLink="/car-detail" class="view">Learn more</a>
                  </div>
                </div>
              </div>
              <div class="car-model-layout-list">
                <div class="car-thumbnail-area">
                  <div class="item-thumb">
                    <img src="assets/images/car/car-10.jpg" class="img-fluid full-width" alt="image">
                  </div>
                  <div class="car-overlay-info">
                    <div class="car-type luxury">Excutive</div>
                    <div class="car-description-bottom"> <a routerLink="/listing" class="text-custom-white"><i class="fa fa-heart"></i>save</a>
                      <a routerLink="/listing" class="text-custom-white"><i class="fa fa-image"></i>4 photos</a>
                    </div>
                  </div>
                </div>
                <div class="car-description-area">
                  <div class="car-description-content">
                    <h5 class="title"><a routerLink="/car-detail" class="text-light-black">Excutive Car</a></h5>
                    <div class="car-rating">
                      <div class="rating-value"> <span>3.2</span>10 Ratings</div>
                      <div class="rating-icon">
                        <ul>
                          <li class="text-yellow">$</li>
                          <li class="text-yellow">$</li>
                          <li>$</li>
                          <li>$</li>
                        </ul>
                      </div>
                    </div>
                    <div class="car-meta">
                      <div class="car-location"> <i class="fas fa-map-marker-alt"></i>
                        France</div>
                      <div class="car-number"> <i class="fas fa-phone-alt"></i>
                        +34 2 2458752</div>
                    </div>
                    <div class="car-summary">
                      <p>The path of the righteous man is beset on all side by the iniquities. Nam in mauris quis libero sod eleifend spectra online.</p>
                    </div>
                  </div>
                  <div class="car-description-footer">  <a routerLink="/listing" class="book text-light-black"><i class="flaticon-steering-wheel"></i> Test Drive</a>
                    <a routerLink="/car-detail" class="view">Learn more</a>
                  </div>
                </div>
              </div>
              <div class="car-model-layout-list">
                <div class="car-thumbnail-area">
                  <div class="item-thumb">
                    <img src="assets/images/car/car-11.jpg" class="img-fluid full-width" alt="image">
                  </div>
                  <div class="car-overlay-info">
                    <div class="car-type luxury">Economy</div>
                    <div class="car-description-bottom"> <a routerLink="/listing" class="text-custom-white"><i class="fa fa-heart"></i>save</a>
                      <a routerLink="/listing" class="text-custom-white"><i class="fa fa-image"></i>4 photos</a>
                    </div>
                  </div>
                </div>
                <div class="car-description-area">
                  <div class="car-description-content">
                    <h5 class="title"><a routerLink="/car-detail" class="text-light-black">Economy Car</a></h5>
                    <div class="car-rating">
                      <div class="rating-value"> <span>4.1</span>15 Ratings</div>
                      <div class="rating-icon">
                        <ul>
                          <li class="text-yellow">$</li>
                          <li class="text-yellow">$</li>
                          <li class="text-yellow">$</li>
                          <li>$</li>
                        </ul>
                      </div>
                    </div>
                    <div class="car-meta">
                      <div class="car-location"> <i class="fas fa-map-marker-alt"></i>
                        Germany</div>
                      <div class="car-number"> <i class="fas fa-phone-alt"></i>
                        +34 2 2458752</div>
                    </div>
                    <div class="car-summary">
                      <p>The path of the righteous man is beset on all side by the iniquities. Nam in mauris quis libero sod eleifend spectra online.</p>
                    </div>
                  </div>
                  <div class="car-description-footer">  <a routerLink="/listing" class="book text-light-black"><i class="flaticon-steering-wheel"></i> Test Drive</a>
                    <a routerLink="/car-detail" class="view">Learn more</a>
                  </div>
                </div>
              </div>
              <div class="car-model-layout-list">
                <div class="car-thumbnail-area">
                  <div class="item-thumb">
                    <img src="assets/images/car/car-12.jpg" class="img-fluid full-width" alt="image">
                  </div>
                  <div class="car-overlay-info">
                    <div class="car-type luxury">Luxury</div>
                    <div class="car-description-bottom"> <a routerLink="/listing" class="text-custom-white"><i class="fa fa-heart"></i>save</a>
                      <a routerLink="/listing" class="text-custom-white"><i class="fa fa-image"></i>4 photos</a>
                    </div>
                  </div>
                </div>
                <div class="car-description-area">
                  <div class="car-description-content">
                    <h5 class="title"><a routerLink="/car-detail" class="text-light-black">Luxury Car</a></h5>
                    <div class="car-rating">
                      <div class="rating-value"> <span>4.8</span>16 Ratings</div>
                      <div class="rating-icon">
                        <ul>
                          <li class="text-yellow">$</li>
                          <li class="text-yellow">$</li>
                          <li class="text-yellow">$</li>
                          <li>$</li>
                        </ul>
                      </div>
                    </div>
                    <div class="car-meta">
                      <div class="car-location"> <i class="fas fa-map-marker-alt"></i>
                        Barcelona, ES</div>
                      <div class="car-number"> <i class="fas fa-phone-alt"></i>
                        +34 2 2458752</div>
                    </div>
                    <div class="car-summary">
                      <p>The path of the righteous man is beset on all side by the iniquities. Nam in mauris quis libero sod eleifend spectra online.</p>
                    </div>
                  </div>
                  <div class="car-description-footer">  <a routerLink="/listing" class="book text-light-black"><i class="flaticon-steering-wheel"></i> Test Drive</a>
                    <a routerLink="/car-detail" class="view">Learn more</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="listing_grid" class="tab-pane fade">
          <div class="row">
            <div class="col-lg-12">
              <div class="right-search-filter-box">
                <div class="car-model-sec">
                  <div class="row">
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                      <div class="car-model text-center">
                        <div class="car-sec car-bottom-space">
                          <div class="car-title">
                            <h4 class="text-light-black">Ghibli</h4>
                            <h5 class="text-light-black">YOU'RE NOT LIKE EVERYONE ELSE</h5>
                            <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p>
                          </div>
                          <img src="assets/images/car/car-9.jpg" class="img-fluid full-width" alt="Car Model Image">
                          <div class="car-details"> <a routerLink="/car-detail" class="btn-third transparent-btn">Discover More</a>
                            <a routerLink="/listing" class="btn-second orange-btn">Build &amp; Price</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                      <div class="car-model text-center">
                        <div class="car-sec car-bottom-space">
                          <div class="car-title">
                            <h4 class="text-light-black">Levante</h4>
                            <h5 class="text-light-black">YOU'RE NOT LIKE EVERYONE ELSE</h5>
                            <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p>
                          </div>
                          <img src="assets/images/car/car-9.jpg" class="img-fluid full-width" alt="Car Model Image">
                          <div class="car-details"> <a routerLink="/car-detail" class="btn-third transparent-btn">Discover More</a>
                            <a routerLink="/listing" class="btn-second orange-btn">Build &amp; Price</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                      <div class="car-model text-center">
                        <div class="car-sec">
                          <div class="car-title">
                            <h4 class="text-light-black">Quattroporte</h4>
                            <h5 class="text-light-black">YOU'RE NOT LIKE EVERYONE ELSE</h5>
                            <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p>
                          </div>
                          <img src="assets/images/car/car-9.jpg" class="img-fluid full-width" alt="Car Model Image">
                          <div class="car-details"> <a routerLink="/car-detail" class="btn-third transparent-btn">Discover More</a>
                            <a routerLink="/listing" class="btn-second orange-btn">Build &amp; Price</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                      <div class="car-model text-center">
                        <div class="car-sec car-bottom-space">
                          <div class="car-title">
                            <h4 class="text-light-black">Levante</h4>
                            <h5 class="text-light-black">YOU'RE NOT LIKE EVERYONE ELSE</h5>
                            <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p>
                          </div>
                          <img src="assets/images/car/car-9.jpg" class="img-fluid full-width" alt="Car Model Image">
                          <div class="car-details"> <a routerLink="/car-detail" class="btn-third transparent-btn">Discover More</a>
                            <a routerLink="/listing" class="btn-second orange-btn">Build &amp; Price</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                      <div class="car-model text-center">
                        <div class="car-sec car-bottom-space">
                          <div class="car-title">
                            <h4 class="text-light-black">Levante</h4>
                            <h5 class="text-light-black">YOU'RE NOT LIKE EVERYONE ELSE</h5>
                            <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p>
                          </div>
                          <img src="assets/images/car/car-9.jpg" class="img-fluid full-width" alt="Car Model Image">
                          <div class="car-details"> <a routerLink="/car-detail" class="btn-third transparent-btn">Discover More</a>
                            <a routerLink="/listing" class="btn-second orange-btn">Build &amp; Price</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                      <div class="car-model text-center">
                        <div class="car-sec">
                          <div class="car-title">
                            <h4 class="text-light-black">Quattroporte</h4>
                            <h5 class="text-light-black">YOU'RE NOT LIKE EVERYONE ELSE</h5>
                            <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p>
                          </div>
                          <img src="assets/images/car/car-9.jpg" class="img-fluid full-width" alt="Car Model Image">
                          <div class="car-details"> <a routerLink="/car-detail" class="btn-third transparent-btn">Discover More</a>
                            <a routerLink="/listing" class="btn-second orange-btn">Build &amp; Price</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                      <div class="car-model text-center">
                        <div class="car-sec car-bottom-space">
                          <div class="car-title">
                            <h4 class="text-light-black">Ghibli</h4>
                            <h5 class="text-light-black">YOU'RE NOT LIKE EVERYONE ELSE</h5>
                            <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p>
                          </div>
                          <img src="assets/images/car/car-9.jpg" class="img-fluid full-width" alt="Car Model Image">
                          <div class="car-details"> <a routerLink="/car-detail" class="btn-third transparent-btn">Discover More</a>
                            <a routerLink="/listing" class="btn-second orange-btn">Build &amp; Price</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                      <div class="car-model text-center">
                        <div class="car-sec car-bottom-space">
                          <div class="car-title">
                            <h4 class="text-light-black">Levante</h4>
                            <h5 class="text-light-black">YOU'RE NOT LIKE EVERYONE ELSE</h5>
                            <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p>
                          </div>
                          <img src="assets/images/car/car-9.jpg" class="img-fluid full-width" alt="Car Model Image">
                          <div class="car-details"> <a routerLink="/car-detail" class="btn-third transparent-btn">Discover More</a>
                            <a routerLink="/listing" class="btn-second orange-btn">Build &amp; Price</a>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                      <div class="car-model text-center">
                        <div class="car-sec car-bottom-space">
                          <div class="car-title">
                            <h4 class="text-light-black">Levante</h4>
                            <h5 class="text-light-black">YOU'RE NOT LIKE EVERYONE ELSE</h5>
                            <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p>
                          </div>
                          <img src="assets/images/car/car-9.jpg" class="img-fluid full-width" alt="Car Model Image">
                          <div class="car-details"> <a routerLink="/car-detail" class="btn-third transparent-btn">Discover More</a>
                            <a routerLink="/listing" class="btn-second orange-btn">Build &amp; Price</a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12">
          <nav class="custom-pagination">
            <ul class="pagination">
              <li class="page-item "><a class="page-link" routerLink="/listing">Previous</a>
              </li>
              <li class="page-item active"><a class="page-link" routerLink="/listing">1</a>
              </li>
              <li class="page-item"><a class="page-link" routerLink="/listing">2</a>
              </li>
              <li class="page-item"><a class="page-link" routerLink="/listing">3</a>
              </li>
              <li class="page-item"><a class="page-link" routerLink="/listing">Next</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  </section>
  <!-- listing -->
	<app-footer4></app-footer4>
</div>