<!-- main-navigation -->
  <header id="menu-style-4">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 custom-container">
          <nav>
            <a routerLink="/" class="logo">
              <img src="assets/images/logo.jpg" class="img-fluid" alt="logo">
            </a>
            <ul>
              <li class="menu-item dropdown"><a href="javascript:void(0)" class="text-custom-white">Homepages</a>
                <div class="megamenu">
                  <div class="container-fluid custom-container">
                    <div class="row">
                      <div class="col-lg-3">
                        <div class="mega-menu-item">
                          <ul class="w-100">
                            <li class="submenu-item"> <a routerLink="/" class="text-light-black">Landing Page</a>
                            </li>
                            <li class="submenu-item"> <a routerLink="/homepage2" class="text-light-black">Homepage 2</a>
                            </li>
                            <li class="submenu-item"> <a routerLink="/homepage3" class="text-light-black">Homepage 3</a>
                            </li>
                            <li class="submenu-item"> <a routerLink="/homepage4" class="text-light-black">Homepage 4</a>
                            </li>
                            <li class="submenu-item"> <a routerLink="/homepage5" class="text-light-black">Homepage 5</a>
                            </li>
                            <li class="submenu-item active"> <a routerLink="/homepage6" class="text-light-black">Homepage 6</a>
                            </li>
                            <li class="submenu-item"> <a routerLink="/homepage7" class="text-light-black">Homepage 7</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-9">
                        <div class="car-img-fix">
                          <img src="assets/images/nav/nav-1.jpg" class="img-fluid full-width" alt="image">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="menu-item"><a routerLink="/media" class="text-custom-white">Media</a>
              </li>
              <li class="menu-item dropdown"><a href="javascript:void(0)" class="text-custom-white">Pages</a>
                <div class="megamenu">
                  <div class="container-fluid custom-container">
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="mega-menu-item">
                          <ul>
                            <li class="submenu-item active">
                              <a href="javascript:void(0)"> <span class="text-light-black">Internal Pages</span>
                                <div class="car-img">
                                  <img src="assets/images/nav/tab-2.png" class="img-fluid" alt="Image">
                                </div>
                              </a>
                              <div class="sub-sub-megamenu">
                                <div class="car-img-mega-menu">
                                  <img src="assets/images/nav/nav-2.jpg" class="img-fluid full-width" alt="image">
                                </div>
                                <div class="row full-width">
                                  <div class="col-lg-4">
                                    <ul>
                                      <li class="menu-item"><a routerLink="/add-listing" class="text-light-black">Add Listing</a>
                                      </li>
                                      <li class="menu-item"><a routerLink="/aboutus" class="text-light-black">About Us</a>
                                      </li>
                                      <li class="menu-item"><a routerLink="/car-detail" class="text-light-black">Car Details</a>
                                      </li>
                                      <li class="menu-item"><a routerLink="/car-page" class="text-light-black">Car Page</a>
                                      </li>
                                      <li class="menu-item"><a routerLink="/user" class="text-light-black">User</a>
                                      </li>
                                    </ul>
                                  </div>
                                  <div class="col-lg-4">
                                    <ul>
                                      <li class="menu-item"><a routerLink="/register" class="text-light-black">Register</a>
                                      </li>
                                      <li class="menu-item"><a routerLink="/login" class="text-light-black">Login</a>
                                      </li>
                                      <li class="menu-item"><a routerLink="/invoice" class="text-light-black">Invoice</a>
                                      </li>
                                      <li class="menu-item"><a routerLink="/coming-soon" class="text-light-black">Coming Soon</a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li class="submenu-item">
                              <a href="javascript:void(0)"> <span class="text-light-black">Listings</span>
                                <div class="car-img">
                                  <img src="assets/images/nav/tab-3.png" class="img-fluid" alt="Image">
                                </div>
                              </a>
                              <div class="sub-sub-megamenu">
                                <div class="car-img-mega-menu">
                                  <img src="assets/images/nav/nav-3.jpg" class="img-fluid full-width" alt="image">
                                </div>
                                <div class="row full-width">
                                  <div class="col-lg-4">
                                    <ul>
                                      <li class="menu-item"><a routerLink="/listing" class="text-light-black">Listing</a>
                                      </li>
                                      <li class="menu-item"><a routerLink="/listing1" class="text-light-black">Listing 1</a>
                                      </li>
                                      <li class="menu-item"><a routerLink="/listing2" class="text-light-black">Listing 2</a>
                                      </li>
                                      <li class="menu-item"><a routerLink="/listing3" class="text-light-black">Listing 3</a>
                                      </li>
                                      <li class="menu-item"><a routerLink="/listing4" class="text-light-black">Listing 4</a>
                                      </li>
                                      <li class="menu-item"><a routerLink="/listing1-style2" class="text-light-black">Listing 1 Style 2</a>
                                      </li>
                                      <li class="menu-item"><a routerLink="/listing2-style2" class="text-light-black">Listing 2 Style 2</a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </li>
                            <li class="submenu-item">
                              <a href="javascript:void(0)"> <span class="text-light-black">Blog</span>
                                <div class="car-img">
                                  <img src="assets/images/nav/tab-1.png" class="img-fluid" alt="Image">
                                </div>
                              </a>
                              <div class="sub-sub-megamenu">
                                <div class="car-img-mega-menu">
                                  <img src="assets/images/nav/nav-4.jpg" class="img-fluid full-width" alt="image">
                                </div>
                                <div class="row full-width">
                                  <div class="col-lg-4">
                                    <ul>
                                      <li class="menu-item"><a routerLink="/blog-grid" class="text-light-black">Blog Grid</a>
                                      </li>
                                      <li class="menu-item"><a routerLink="/blog-left" class="text-light-black">Blog Left Sidebar</a>
                                      </li>
                                      <li class="menu-item"><a routerLink="/blog-right" class="text-light-black">Blog Right Sidebar</a>
                                      </li>
                                      <li class="menu-item"><a routerLink="/blog-standard" class="text-light-black">Blog Single</a>
                                      </li>
                                      <li class="menu-item"><a routerLink="/blog-gallery" class="text-light-black">Blog Gallery</a>
                                      </li>
                                      <li class="menu-item"><a routerLink="/blog-video" class="text-light-black">Blog Video</a>
                                      </li>
                                      <li class="menu-item"><a routerLink="/blog-quote" class="text-light-black">Blog Quote</a>
                                      </li>
                                      <li class="menu-item"><a routerLink="/blog-detail-left" class="text-light-black">Blog Details Left</a>
                                      </li>
                                      <li class="menu-item"><a routerLink="/blog-detail-right" class="text-light-black">Blog Details Right</a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li class="menu-item"><a routerLink="/pricing" class="text-custom-white">Pricing</a>
              </li>
              <li class="menu-item"><a routerLink="/contact" class="text-custom-white">Contact Us</a>
              </li>
            </ul>
            <div class="hamburger-menu">
              <div class="bar"></div>
            </div>
            <a routerLink="/add-listing" class="add-car-btn text-custom-white">+ Add Listing</a>
          </nav>
          <div class="desk-menu">
            <nav class="box-menu">
              <div class="menu-container">
                <div class="menu-header-container">
                  <ul id="cd-primary-nav" class="menu">
                    <li class="menu-item menu-item-has-children"> <a href="javascript:void(0)">Homepages</a>
                      <ul class="sub-menu">
                        <li class="menu-item"> <a routerLink="/">Landing Page</a>
                        </li>
                        <li class="menu-item"> <a routerLink="/homepage2">Homepage 2</a>
                        </li>
                        <li class="menu-item"> <a routerLink="/homepage3">Homepage 3</a>
                        </li>
                        <li class="menu-item"> <a routerLink="/homepage4">Homepage 4</a>
                        </li>
                        <li class="menu-item"> <a routerLink="/homepage5">Homepage 5</a>
                        </li>
                        <li class="menu-item"> <a routerLink="/homepage6">Homepage 6</a>
                        </li>
                        <li class="menu-item"> <a routerLink="/homepage7">Homepage 7</a>
                        </li>
                      </ul>
                    </li>
                    <li class="menu-item menu-item-has-children"> <a href="javascript:void(0)">Pages</a>
                      <ul class="sub-menu">
                        <li class="menu-item menu-item-has-children"> <a href="javascript:void(0)">Internal Pages</a>
                          <ul class="sub-menu">
                            <li class="menu-item"><a routerLink="/add-listing">Add Listing</a>
                            </li>
                            <li class="menu-item"><a routerLink="/aboutus">About Us</a>
                            </li>
                            <li class="menu-item"><a routerLink="/car-detail">Car Details</a>
                            </li>
                            <li class="menu-item"><a routerLink="/car-page">Car Page</a>
                            </li>
                            <li class="menu-item"><a routerLink="/user">User</a>
                            </li>
                            <li class="menu-item"><a routerLink="/register">Register</a>
                            </li>
                            <li class="menu-item"><a routerLink="/login">Login</a>
                            </li>
                            <li class="menu-item"><a routerLink="/invoice">Invoice</a>
                            </li>
                            <li class="menu-item"><a routerLink="/coming-soon">Coming Soon</a>
                            </li>
                          </ul>
                        </li>
                        <li class="menu-item menu-item-has-children"> <a href="javascript:void(0)">Listings</a>
                          <ul class="sub-menu">
                            <li class="menu-item"><a routerLink="/listing">Listing</a>
                            </li>
                            <li class="menu-item"><a routerLink="/listing1">Listing 1</a>
                            </li>
                            <li class="menu-item"><a routerLink="/listing2">Listing 2</a>
                            </li>
                            <li class="menu-item"><a routerLink="/listing3">Listing 3</a>
                            </li>
                            <li class="menu-item"><a routerLink="/listing4">Listing 4</a>
                            </li>
                            <li class="menu-item"><a routerLink="/listing1-style2">Listing 1 Style 2</a>
                            </li>
                            <li class="menu-item"><a routerLink="/listing2-style2">Listing 2 Style 2</a>
                            </li>
                          </ul>
                        </li>
                        <li class="menu-item menu-item-has-children"> <a href="javascript:void(0)">Blog</a>
                          <ul class="sub-menu">
                            <li class="menu-item"><a routerLink="/blog-grid">Blog Grid</a>
                            </li>
                            <li class="menu-item"><a routerLink="/blog-left">Blog Left Sidebar</a>
                            </li>
                            <li class="menu-item"><a routerLink="/blog-right">Blog Right Sidebar</a>
                            </li>
                            <li class="menu-item menu-item-has-children"><a href="javascript:void(0)">Blog Single</a>
                              <ul class="sub-menu">
                                <li class="menu-item"><a routerLink="/blog-standard">Standard</a>
                                </li>
                                <li class="menu-item"><a routerLink="/blog-gallery">Gallery</a>
                                </li>
                                <li class="menu-item"><a routerLink="/blog-video">Video</a>
                                </li>
                                <li class="menu-item"><a routerLink="/blog-quote">Quote</a>
                                </li>
                              </ul>
                            </li>
                            <li class="menu-item"><a routerLink="/blog-detail-left">Blog Details Left</a>
                            </li>
                            <li class="menu-item"><a routerLink="/blog-detail-right">Blog Details Right</a>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                    <li class="menu-item "> <a routerLink="/media">Media</a>
                    </li>
                    <li class="menu-item "> <a routerLink="/pricing">Pricing</a>
                    </li>
                    <li class="menu-item "> <a routerLink="/contact">Contact Us</a>
                    </li>
                    <a routerLink="/add-listing" class="btn-second orange-btn full-width text-custom-white">+ Add Listing</a>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </header>
  <!-- main-navigation -->