<!-- Desktop Nav -->
    <header id="menu-style-3">
      <div class="container-fluid">
        <div class="dekstop-nav">
          <div class="row">
            <div class="col-12">
              <div class="menu-style-3">
                <div class="navigation">
                  <nav>
                    <ul>
                      <li class="menu-item dropdownbtn" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                        <a href="javascript:void(0)" class="text-custom-white dropdown-toggle">Home</a>
                          <ul class="dropdown-menu">
		                        <li><a routerLink="/" class="text-dark-grey">Landing Page</a></li>
		                         <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a routerLink="/homepage2" class="text-light-black">Homepage 2</a></li>
		                        <li><a routerLink="/homepage3" class="text-light-black">Homepage 3</a></li>
		                        <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a routerLink="/homepage4" class="text-light-black">Homepage 4</a></li>
		                        <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a routerLink="/homepage5" class="text-light-black">Homepage 5</a></li>
		                        <li><a routerLink="/homepage6" class="text-light-black">Homepage 6</a></li>
		                        <li routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}"><a routerLink="/homepage7" class="text-light-black">Homepage 7</a></li>
                       </ul>
                      </li>
                      <li class="menu-item">	<a routerLink="/media" class="text-custom-white">Media</a>
                      </li>
                      <li class="menu-item parent-megamenu">
                        <a href="javascript:void(0)" class="text-custom-white dropdown-toggle">Pages</a>
                        <div class="megamenu">
                          <div class="row">
                            <div class="col-sm-12">
                              <div class="row">
                                <div class="col-sm-4">
                                  <div class="menu-style">
                                    <div class="menu-title">
                                      <h6 class="cat-name"><a href="javascript:void(0)" class="text-light-black">Listing</a></h6>
                                    </div>
                                    <ul>
                                      <li><a routerLink="/listing" class="text-dark-grey">Listing</a>
                                      </li>
                                      <li><a routerLink="/listing1" class="text-dark-grey">Listing 1</a>
                                      </li>
                                      <li><a routerLink="/listing2" class="text-dark-grey">Listing 2</a>
                                      </li>
                                      <li><a routerLink="/listing3" class="text-dark-grey">Listing 3</a>
                                      </li>
                                      <li><a routerLink="/listing4" class="text-dark-grey">Listing 4</a>
                                      </li>
                                      <li><a routerLink="/listing1-style2" class="text-dark-grey">Listing 1 Style 2</a>
                                      </li>
                                      <li><a routerLink="/listing2-style2" class="text-dark-grey">Listing 2 Style 2</a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div class="col-sm-4">
                                  <div class="menu-style">
                                    <div class="menu-title">
                                      <h6 class="cat-name"><a href="javascript:void(0)" class="text-light-black">Addon</a></h6>
                                    </div>
                                    <ul>
                                      <li><a routerLink="/add-listing" class="text-dark-grey">Add Listing</a>
                                      </li>
                                      <li><a routerLink="/aboutus" class="text-dark-grey">About Us</a>
                                      </li>
                                      <li><a routerLink="/car-detail" class="text-dark-grey">Car Details</a>
                                      </li>
                                      <li><a routerLink="/car-page" class="text-dark-grey">Car Page</a>
                                      </li>
                                      <li><a routerLink="/user" class="text-dark-grey">User</a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                                <div class="col-sm-4">
                                  <div class="menu-style">
                                    <div class="menu-title">
                                      <h6 class="cat-name"><a href="javascript:void(0)" class="text-light-black">Extra Pages</a></h6>
                                    </div>
                                    <ul>
                                      <li><a routerLink="/register" class="text-dark-grey">Register</a>
                                      </li>
                                      <li><a routerLink="/login" class="text-dark-grey">Login</a>
                                      </li>
                                      <li><a routerLink="/invoice" class="text-dark-grey">Invoice</a>
                                      </li>
                                      <li><a routerLink="/coming-soon" class="text-dark-grey">Coming Soon</a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                      <li class="menu-item dropdownbtn">
                        <a href="javascript:void(0)" class="text-custom-white dropdown-toggle">Blog</a>
                        <ul class="dropdown-menu">
                          <li><a routerLink="/blog-grid" class="text-light-black">Blog Grid</a>
                          </li>
                          <li><a routerLink="/blog-left" class="text-light-black">Blog Left Sidebar</a>
                          </li>
                          <li><a routerLink="/blog-right" class="text-light-black">Blog Right Sidebar</a>
                          </li>
                          <li class="dropdownbtn">
                            <a href="javascript:void(0)" class="text-light-black dropdown-toggle">Blog Single</a>
                            <ul class="dropdown-menu">
                              <li><a routerLink="/blog-standard" class="text-light-black">Standard</a>
                              </li>
                              <li><a routerLink="/blog-gallery" class="text-light-black">Gallery</a>
                              </li>
                              <li><a routerLink="/blog-video" class="text-light-black">Video</a>
                              </li>
                              <li><a routerLink="/blog-quote" class="text-light-black">Quote</a>
                              </li>
                            </ul>
                          </li>
                          <li><a routerLink="/blog-detail-left" class="text-light-black">Blog Details Left</a>
                          </li>
                          <li><a routerLink="/blog-detail-right" class="text-light-black">Blog Details Right</a>
                          </li>
                        </ul>
                      </li>
                      <li class="menu-item">	<a routerLink="/pricing" class="text-custom-white">Pricing</a>
                      </li>
                      <li class="menu-item">	<a routerLink="/contact" class="text-custom-white">Contact</a>
                      </li>
                    </ul>
                  </nav>
                </div>
                <div class="logo">
                  <a routerLink="/">
                  <img src="assets/images/140x32logo.png" alt="Logo">
                  </a>
                </div>
                <div class="side-nav">
                  <ul>
                    <li class="search-btn">	<a href="javascript:void(0)" class="text-custom-white"><i class="flaticon-search"></i> Search Category</a>
                    </li>
                    <li class="menu-item dropdownbtn">
                      <a href="javascript:void(0)" class="text-custom-white dropdown-toggle"><i class="fas fa-user"></i> Account</a>
                      <ul class="dropdown-menu">
                        <li><a routerLink="/register" class="text-light-black">Register</a>
                        </li>
                        <li><a routerLink="/login" class="text-light-black">Login</a>
                        </li>
                      </ul>
                    </li>
                    <li class="add-car-btn">	<a routerLink="/add-listing" class="text-custom-white">+ Add Listing</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mobile-nav">
          <div class="row">
            <div class="col-6">
              <div class="logo align-item-center">
                <a routerLink="/">
                <img src="assets/images/140x32logo.png" alt="Logo">
                </a>
              </div>
            </div>
            <div class="col-6">
              <div class="mobile-nav-icons">
                <ul>
                  <li class="search-btn"><a href="javascript:void(0)" class="text-custom-white"><i class="flaticon-search"></i></a>
                  </li>
                  <li class="dropdownbtn">
                    <a href="javascript:void(0)" class="text-custom-white"><i class="fas fa-user"></i></a>
                    <ul class="dropdown-menu">
                      <li><a routerLink="/register" class="text-light-black">Register</a>
                      </li>
                      <li><a routerLink="/login" class="text-light-black">Login</a>
                      </li>
                    </ul>
                  </li>
                  <li class="button" id="mobile-open-nav"><a href="javascript:void(0)" class="text-custom-white"><i class="flaticon-menu"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div>
            <div id="mobile-mySidenav" class="sidenav">
              <div class="side-nav-wrapper">
                <span class="float-left ">Rims</span>
                <span class="float-right">
                <a href="javascript:void(0)" class="closebtn" id="mobile-close-nav">X</a>
                </span>
                <ul>
                  <li class="custom-dropdown-nav">
                    <a href="javascript:void(0)" class="text-custom-white">
                      <h3 class="mb-0">Home</h3>
                    </a>
                    <ul class="dropdown-menu">
                      <li><a routerLink="/">Landing Page</a>
                      </li>
                      <li><a routerLink="/homepage2">Home 2</a>
                      </li>
                      <li><a routerLink="/homepage3">Home 3</a>
                      </li>
                      <li><a routerLink="/homepage4">Home 4</a>
                      </li>
                      <li><a routerLink="/homepage5">Home 5</a>
                      </li>
                      <li><a routerLink="/homepage6">Home 6</a>
                      </li>
                      <li><a routerLink="/homepage7">Home 7</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a routerLink="/media">
                      <h3 class="mb-0">Media</h3>
                    </a>
                  </li>
                  <li class="custom-dropdown-nav">
                    <a href="javascript:void(0)">
                      <h3 class="mb-0">Pages</h3>
                    </a>
                    <ul class="dropdown-menu">
                      <li><a routerLink="/listing">Listing</a>
                      </li>
                      <li><a routerLink="/listing1">Listing 1</a>
                      </li>
                      <li><a routerLink="/listing2">Listing 2</a>
                      </li>
                      <li><a routerLink="/listing3">Listing 3</a>
                      </li>
                      <li><a routerLink="/listing4">Listing 4</a>
                      </li>
                      <li><a routerLink="/listing1-style2">Listing 1 Style 2</a>
                      </li>
                      <li><a routerLink="/listing2-style2">Listing 2 Style 2</a>
                      </li>
                      <li><a routerLink="/add-listing">Add Listing</a>
                      </li>
                      <li><a routerLink="/aboutus">About Us</a>
                      </li>
                      <li><a routerLink="/car-detail">Car Details</a>
                      </li>
                      <li><a routerLink="/car-page">Car Page</a>
                      </li>
                      <li><a routerLink="/user">User</a>
                      </li>
                      <li><a routerLink="/register">Register</a>
                      </li>
                      <li><a routerLink="/login">Login</a>
                      </li>
                      <li><a routerLink="/invoice">Invoice</a>
                      </li>
                      <li><a routerLink="/coming-soon">Coming Soon</a>
                      </li>
                    </ul>
                  </li>
                  <li class="custom-dropdown-nav">
                    <a href="javascript:void(0)" class="text-custom-white">
                      <h3 class="mb-0">Blog</h3>
                    </a>
                    <ul class="dropdown-menu">
                      <li><a routerLink="/blog-grid">Blog Grid</a>
                      </li>
                      <li><a routerLink="/blog-left">Blog Left Sidebar</a>
                      </li>
                      <li><a routerLink="/blog-right">Blog Right Sidebar</a>
                      </li>
                      <li><a routerLink="/blog-standard">Blog Single</a>
                      </li>
                      <li><a routerLink="/blog-gallery">Blog Gallery</a>
                      </li>
                      <li><a routerLink="/blog-video">Blog Video</a>
                      </li>
                      <li><a routerLink="/blog-quote">Blog Quote</a>
                      </li>
                      <li><a routerLink="/blog-detail-left">Blog Details Left</a>
                      </li>
                      <li><a routerLink="/blog-detail-right">Blog Details Right</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a routerLink="/pricing">
                      <h3 class="mb-0">Pricing</h3>
                    </a>
                  </li>
                  <li>
                    <a routerLink="/contact">
                      <h3 class="mb-0">Contact</h3>
                    </a>
                  </li>
                </ul>
                <div class="mobile-social-icon">
                  <ul>
                    <li><a href="javascript:void(0)"><i class="fab fa-facebook-f"></i></a>
                    </li>
                    <li><a href="javascript:void(0)"><i class="fab fa-twitter"></i></a>
                    </li>
                    <li><a href="javascript:void(0)"><i class="fab fa-pinterest-p"></i></a>
                    </li>
                    <li><a href="javascript:void(0)"><i class="fab fa-instagram"></i></a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- Desktop Nav -->