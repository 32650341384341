import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-listing-sidebar',
  templateUrl: './listing-sidebar.component.html',
  styleUrls: ['./listing-sidebar.component.css']
})
export class ListingSidebarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
