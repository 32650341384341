<div class="mobile-menu-second">

  <app-header4></app-header4>
	
<!-- Sub header -->
  <div class="sub-header-sec p-relative">
    <img src="assets/images/blog/banner/banner-1.jpg" class="img-fluid" alt="Sub header">
    <div class="sub-header-overlay"></div>
    <div class="sub-header-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-xl-12">
            <div class="sub-header-text-wrapper text-center">
              <h1 class="text-custom-white">Blog Details</h1>
              <ul class="breadcrumbs">
                <li><a routerLink="/" class="text-custom-white">Home</a>
                </li>
                <li><a routerLink="/blog-detail-left" class="text-custom-white">Blog</a>
                </li>
                <li><span class="text-custom-white">Blog Details</span>
                </li>
              </ul>
              <ul class="tags-links">
                <li class="cat-tags"> <a routerLink="/blog-detail-left">Luxury Car</a>
                  <a routerLink="/blog-detail-left">Sports Car</a>
                  <a routerLink="/blog-detail-left">Economy Car</a>
                </li>
                <li><a routerLink="/blog-detail-left" class="text-custom-white"><i class="fas fa-user"></i> <span>Jhon Deo</span></a>
                </li>
                <li><a routerLink="/blog-detail-left" class="text-custom-white"><i class="far fa-comments"></i> <span>No Comments</span></a>
                </li>
                <li><a routerLink="/blog-detail-left" class="text-custom-white"><i class="fas fa-calendar"></i> <span>November 25, 2020</span></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Sub header -->
  <!-- Blog -->
  <section class="section-padding our_articles blog-details">
    <div class="container">
      <div class="row">
        <aside class="col-lg-4">
          <app-blog-sidebar2></app-blog-sidebar2>
        </aside>
        <div class="col-lg-8">
          <div class="row">
            <div class="col-md-12">
              <!-- article 1 -->
              <article class="post">
                <div class="blog-wrap p-relative">
                  <div class="blog-img no-margin p-relative">
                    <a routerLink="/blog-detail-left">
                      <img src="assets/images/blog/blogdetails-4.jpg" class="img-fluid full-width" alt="Blog img">
                    </a>
                    <div class="post-type">
                      <a routerLink="/blog-detail-left">  <i class="flaticon-film"></i>
                      </a>
                    </div>
                    <div class="post-date">
                      <a routerLink="/blog-detail-left">  <span class="date">25</span>
                        <span class="month">November</span>
                      </a>
                    </div>
                  </div>
                  <div class="blog-meta">
                    <div class="blog-caption">
                      <h2 class="blog-title">
                                            <a routerLink="/blog-detail-left" class="text-light-black">the love of power is the demon of men & women</a>
                                          </h2>
                      <p class="text-dark-grey">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic type setting, remaining essentially unchanged.</p>
                      <p class="text-dark-grey">It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                    </div>
                  </div>
                  <div class="blog-img no-margin p-relative">
                    <a routerLink="/blog-detail-left">
                      <img src="assets/images/blog/blogdetails-5.jpg" class="img-fluid full-width" alt="Blog img">
                    </a>
                    <div class="post-type-video">
                      <div class="circle-box">
                        <a href="JavaScript:Void(0);" data-toggle="modal" data-target="#videomodal" class="video-btn">  <i class="flaticon-triangle"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="blog-meta">
                    <div class="blog-caption">
                      <p class="text-dark-grey">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic type setting, remaining essentially unchanged.</p>
                      <p class="text-dark-grey">It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="text-wrap">
                      <blockquote>
                        <p>AutoImage is nisi aliquip consequat duis velit esse cillum dolore fugiat nulla pariatur excepteur sint occaecat lorem ipsum dolor sit amt consectetur adipisicing elit sed eiusmod tempor incididuntu labore et dolore magna aliqua enimad minim veniam quis nostrud exercitation sed ipsum ullamco laboris nisi aliquip.</p>
                        <div class="infor"> <cite class="name text-orange">John Stephan</cite>
                          <cite class="position text-dark-white">Vehicle Buyer</cite>
                        </div>
                      </blockquote>
                    </div>
                  </div>
                </div>
              </article>
              <!-- article 1 -->
              <!-- Social tag -->
              <div class="social-tags"> <span class="text-light-black"> Share Now</span>
                <ul>
                  <li class="fb"> <a routerLink="/blog-detail-left"><i class="fab fa-facebook-f"></i></a>
                  </li>
                  <li class="tweet"> <a routerLink="/blog-detail-left"><i class="fab fa-twitter"></i></a>
                  </li>
                  <li class="messenger"> <a routerLink="/blog-detail-left"><i class="fab fa-facebook-messenger"></i></a>
                  </li>
                  <li class="pintrist"> <a routerLink="/blog-detail-left"><i class="fab fa-pinterest-p"></i></a>
                  </li>
                  <li class="whastsapp"> <a routerLink="/blog-detail-left"><i class="fab fa-whatsapp"></i></a>
                  </li>
                </ul>
              </div>
              <!-- Social tag -->
              <!-- Comments -->
              <div class="comments">
                <h4 class="text-light-black comment-title">Comments</h4>
                <ul class="comment-list">
                  <li class="comment">
                    <div class="comment the-comment">
                      <div class="comment-avatar">
                        <img alt="image" src="assets/images/blog/avatar/avatar-2.jpg" class="avatar rounded-circle">
                      </div>
                      <div class="comment-content">
                        <div class="comment-meta"> <span class="comment-time"> 15th May, 2020</span>
                          <span class="comment-author"> <a routerLink="/blog-detail-left" class="url">Rosalina Pong</a></span>
                        </div>
                        <div class="text">
                          <div class="comment-text">
                            <p class="text-dark-grey">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p> <span class="reply"> <a rel="nofollow" class="text-light-black" routerLink="/blog-detail-left"><i class="fa fa-reply"></i> Reply</a> </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li class="comment">
                    <div class="comment the-comment">
                      <div class="comment-avatar">
                        <img alt="image" src="assets/images/blog/avatar/avatar-3.jpg" class="avatar rounded-circle">
                      </div>
                      <div class="comment-content">
                        <div class="comment-meta"> <span class="comment-time"> 20th May, 2020</span>
                          <span class="comment-author"> <a routerLink="/blog-detail-left" class="url">Arista Williamson</a></span>
                        </div>
                        <div class="text">
                          <div class="comment-text">
                            <p class="text-dark-grey">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p> <span class="reply"> <a rel="nofollow" class="text-light-black" routerLink="/blog-detail-left"><i class="fa fa-reply"></i> Reply</a> </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ul class="comment-answer">
                      <li>
                        <div class="comment the-comment">
                          <div class="comment-avatar">
                            <img alt="image" src="assets/images/blog/avatar/avatar-2.jpg" class="avatar rounded-circle">
                          </div>
                          <div class="comment-content">
                            <div class="comment-meta"> <span class="comment-time"> 15th May, 2020</span>
                              <span class="comment-author"> <a routerLink="/blog-detail-left" class="url">Rosalina Pong</a></span>
                            </div>
                            <div class="text">
                              <div class="comment-text">
                                <p class="text-dark-grey">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p> <span class="reply"> <a rel="nofollow" class="text-light-black" routerLink="/blog-detail-left"><i class="fa fa-reply"></i> Reply</a> </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <!-- Comments -->
              <!-- Comment form -->
              <div class="comment-form">
                <h4 class="text-light-black form-title">Post Comment</h4>
                <form>
                  <div class="form-group">
                    <label class="text-dark-grey">Name<sup>*</sup>
                    </label>
                    <input type="text" class="form-control form-control-custom" name="#" placeholder="Enter your name">
                  </div>
                  <div class="form-group">
                    <label class="text-dark-grey">Email<sup>*</sup>
                    </label>
                    <input type="email" class="form-control form-control-custom" name="#" placeholder="Enter your email i'd">
                  </div>
                  <div class="form-group">
                    <label class="text-dark-grey">Website<sup>*</sup>
                    </label>
                    <input type="text" class="form-control form-control-custom" name="#" placeholder="Enter your website">
                  </div>
                  <div class="form-group">
                    <label class="text-dark-grey">Comments<sup>*</sup>
                    </label>
                    <textarea class="form-control form-control-custom" rows="4" name="#" placeholder="Enter your comments"></textarea>
                  </div>
                  <div class="form-group">
                    <button type="submit" class="btn-second orange-btn mt-5">Post Comment</button>
                  </div>
                </form>
              </div>
              <!-- Comment form -->
            </div>
            <!-- pagination -->
            <div class="col-12">
              <nav class="custom-pagination">
                <ul class="pagination">
                  <li class="page-item "><a class="page-link" routerLink="/blog-detail-left">Previous</a>
                  </li>
                  <li class="page-item active"><a class="page-link" routerLink="/blog-detail-left">1</a>
                  </li>
                  <li class="page-item"><a class="page-link" routerLink="/blog-detail-left">2</a>
                  </li>
                  <li class="page-item"><a class="page-link" routerLink="/blog-detail-left">3</a>
                  </li>
                  <li class="page-item"><a class="page-link" routerLink="/blog-detail-left">Next</a>
                  </li>
                </ul>
              </nav>
            </div>
            <!-- pagination -->
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Blog -->
<app-footer4></app-footer4>
  <div class="modal fade" id="videomodal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        <!-- Modal body -->
        <div class="modal-body">
          <iframe src="https://www.youtube.com/embed/pbiEfJd2Hpw" allow="autoplay"></iframe>
        </div>
      </div>
    </div>
  </div>

</div>