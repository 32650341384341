<div class="mobile-menu-second">
	<app-header4></app-header4>
<!-- Sub header -->
  <div class="sub-header-sec p-relative">
    <img src="assets/images/blog/banner/banner-1.jpg" class="img-fluid" alt="Sub header">
    <div class="sub-header-overlay"></div>
    <div class="sub-header-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-xl-12">
            <div class="sub-header-text-wrapper text-center">
              <h1 class="text-custom-white">Blog</h1>
              <ul class="breadcrumbs">
                <li><a routerLink="/" class="text-custom-white">Home</a>
                </li>
                <li><span class="text-custom-white">Blog</span>
                </li>
              </ul>
              <ul class="tags-links">
                <li class="cat-tags"> <a routerLink="/blog-grid">Luxury Car</a>
                  <a routerLink="/blog-grid">Sports Car</a>
                  <a routerLink="/blog-grid">Economy Car</a>
                </li>
                <li><a routerLink="/blog-grid" class="text-custom-white"><i class="fas fa-user"></i> <span>Jhon Deo</span></a>
                </li>
                <li><a routerLink="/blog-grid" class="text-custom-white"><i class="far fa-comments"></i> <span>No Comments</span></a>
                </li>
                <li><a routerLink="/blog-grid" class="text-custom-white"><i class="fas fa-calendar"></i> <span>November 25, 2020</span></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Sub header -->
  <!-- Blog -->
  <section class="section-padding our_articles">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="row">
            <div class="col-lg-4 col-md-6">
              <!-- article 1 -->
              <article class="post">
                <div class="blog-wrap p-relative">
                  <div class="blog-img no-margin p-relative">
                    <a routerLink="/blog-standard">
                      <img src="assets/images/blog/blog-1.jpg" class="img-fluid full-width" alt="Blog img">
                    </a>
                    <div class="read-more-wrap">  <a routerLink="/blog-standard" class="read-more">
                      READ THIS ARTICLE
                    </a>
                    </div>
                  </div>
                  <div class="post-type">
                    <a routerLink="/blog-grid">  <i class="flaticon-film"></i>
                    </a>
                  </div>
                  <div class="blog-meta">
                    <div class="entry-meta-content">
                      <div class="entry-date"> <span class="text-dark-white">In <a routerLink="/blog-grid" class="text-dark-white">20 November 2020</a></span>
                      </div>
                    </div>
                    <div class="blog-caption">
                      <h2 class="blog-title">
                                            <a routerLink="/blog-standard" class="text-light-black">the love of power is the demon of men & women</a>
                                          </h2>
                    </div>
                  </div>
                </div>
              </article>
              <!-- article 1 -->
            </div>
            <div class="col-lg-4 col-md-6">
              <!-- article 1 -->
              <article class="post">
                <div class="blog-wrap p-relative">
                  <div class="blog-img no-margin p-relative">
                    <a routerLink="/blog-standard">
                      <img src="assets/images/blog/blog-2.jpg" class="img-fluid full-width" alt="Blog img">
                    </a>
                    <div class="read-more-wrap">  <a routerLink="/blog-standard" class="read-more">
                      READ THIS ARTICLE
                    </a>
                    </div>
                  </div>
                  <div class="post-type">
                    <a routerLink="/blog-grid">  <i class="flaticon-photo-camera"></i>
                    </a>
                  </div>
                  <div class="blog-meta">
                    <div class="entry-meta-content">
                      <div class="entry-date"> <span class="text-dark-white">In <a routerLink="/blog-grid" class="text-dark-white">25 November 2020</a></span>
                      </div>
                    </div>
                    <div class="blog-caption">
                      <h2 class="blog-title">
                                            <a routerLink="/blog-standard" class="text-light-black">Life is hard, and a lot of people come home tired from work</a>
                                          </h2>
                    </div>
                  </div>
                </div>
              </article>
              <!-- article 1 -->
            </div>
            <div class="col-lg-4 col-md-6">
              <!-- article 1 -->
              <article class="post">
                <div class="blog-wrap p-relative">
                  <div class="blog-img no-margin p-relative">
                    <a routerLink="/blog-standard">
                      <img src="assets/images/blog/blog-1.jpg" class="img-fluid full-width" alt="Blog img">
                    </a>
                    <div class="read-more-wrap">  <a routerLink="/blog-standard" class="read-more">
                      READ THIS ARTICLE
                    </a>
                    </div>
                  </div>
                  <div class="post-type">
                    <a routerLink="/blog-grid">  <i class="flaticon-photo-camera"></i>
                    </a>
                  </div>
                  <div class="blog-meta">
                    <div class="entry-meta-content">
                      <div class="entry-date"> <span class="text-dark-white">In <a routerLink="/blog-grid" class="text-dark-white">01 December 2020</a></span>
                      </div>
                    </div>
                    <div class="blog-caption">
                      <h2 class="blog-title">
                                            <a routerLink="/blog-standard" class="text-light-black">the love of power is the demon of men & women</a>
                                          </h2>
                    </div>
                  </div>
                </div>
              </article>
              <!-- article 1 -->
            </div>
            <div class="col-lg-4 col-md-6">
              <!-- article 1 -->
              <article class="post">
                <div class="blog-wrap p-relative">
                  <div class="blog-img no-margin p-relative">
                    <a routerLink="/blog-standard">
                      <img src="assets/images/blog/blog-4.jpg" class="img-fluid full-width" alt="Blog img">
                    </a>
                    <div class="read-more-wrap">  <a routerLink="/blog-standard" class="read-more">
                      READ THIS ARTICLE
                    </a>
                    </div>
                  </div>
                  <div class="post-type">
                    <a routerLink="/blog-grid">  <i class="flaticon-film"></i>
                    </a>
                  </div>
                  <div class="blog-meta">
                    <div class="entry-meta-content">
                      <div class="entry-date"> <span class="text-dark-white">In <a routerLink="/blog-grid" class="text-dark-white">04 December 2020</a></span>
                      </div>
                    </div>
                    <div class="blog-caption">
                      <h2 class="blog-title">
                                            <a routerLink="/blog-standard" class="text-light-black">The PC has improved the world in just about every area</a>
                                          </h2>
                    </div>
                  </div>
                </div>
              </article>
              <!-- article 1 -->
            </div>
            <div class="col-lg-4 col-md-6">
              <!-- article 1 -->
              <article class="post">
                <div class="blog-wrap p-relative">
                  <div class="blog-img no-margin p-relative">
                    <a routerLink="/blog-standard">
                      <img src="assets/images/blog/blog-3.jpg" class="img-fluid full-width" alt="Blog img">
                    </a>
                    <div class="read-more-wrap">  <a routerLink="/blog-standard" class="read-more">
                      READ THIS ARTICLE
                    </a>
                    </div>
                  </div>
                  <div class="post-type">
                    <a routerLink="/blog-grid">  <i class="flaticon-photo-camera"></i>
                    </a>
                  </div>
                  <div class="blog-meta">
                    <div class="entry-meta-content">
                      <div class="entry-date"> <span class="text-dark-white">In <a routerLink="/blog-grid" class="text-dark-white">10 December 2020</a></span>
                      </div>
                    </div>
                    <div class="blog-caption">
                      <h2 class="blog-title">
                                            <a routerLink="/blog-standard" class="text-light-black">Life is hard, and a lot of people come home tired from work</a>
                                          </h2>
                    </div>
                  </div>
                </div>
              </article>
              <!-- article 1 -->
            </div>
            <div class="col-lg-4 col-md-6">
              <!-- article 1 -->
              <article class="post">
                <div class="blog-wrap p-relative">
                  <div class="blog-img no-margin p-relative">
                    <a routerLink="/blog-standard">
                      <img src="assets/images/blog/blog-5.jpg" class="img-fluid full-width" alt="Blog img">
                    </a>
                    <div class="read-more-wrap">  <a routerLink="/blog-standard" class="read-more">
                      READ THIS ARTICLE
                    </a>
                    </div>
                  </div>
                  <div class="post-type">
                    <a routerLink="/blog-grid">  <i class="flaticon-photo-camera"></i>
                    </a>
                  </div>
                  <div class="blog-meta">
                    <div class="entry-meta-content">
                      <div class="entry-date"> <span class="text-dark-white">In <a routerLink="/blog-grid" class="text-dark-white">20 December 2020</a></span>
                      </div>
                    </div>
                    <div class="blog-caption">
                      <h2 class="blog-title">
                                            <a routerLink="/blog-standard" class="text-light-black">The PC has improved the world in just about every area</a>
                                          </h2>
                    </div>
                  </div>
                </div>
              </article>
              <!-- article 1 -->
            </div>
            <div class="col-lg-4 col-md-6">
              <!-- article 1 -->
              <article class="post">
                <div class="blog-wrap p-relative">
                  <div class="blog-img no-margin p-relative">
                    <a routerLink="/blog-standard">
                      <img src="assets/images/blog/blog-6.jpg" class="img-fluid full-width" alt="Blog img">
                    </a>
                    <div class="read-more-wrap">  <a routerLink="/blog-standard" class="read-more">
                      READ THIS ARTICLE
                    </a>
                    </div>
                  </div>
                  <div class="post-type">
                    <a routerLink="/blog-grid">  <i class="flaticon-film"></i>
                    </a>
                  </div>
                  <div class="blog-meta">
                    <div class="entry-meta-content">
                      <div class="entry-date"> <span class="text-dark-white">In 25 December 2020</span>
                      </div>
                    </div>
                    <div class="blog-caption">
                      <h2 class="blog-title">
                                            <a routerLink="/blog-standard" class="text-light-black">Life is hard, and a lot of people come home tired from work</a>
                                          </h2>
                    </div>
                  </div>
                </div>
              </article>
              <!-- article 1 -->
            </div>
            <div class="col-lg-4 col-md-6">
              <!-- article 1 -->
              <article class="post">
                <div class="blog-wrap p-relative">
                  <div class="blog-img no-margin p-relative">
                    <a routerLink="/blog-standard">
                      <img src="assets/images/blog/blog-1.jpg" class="img-fluid full-width" alt="Blog img">
                    </a>
                    <div class="read-more-wrap">  <a routerLink="/blog-standard" class="read-more">
                      READ THIS ARTICLE
                    </a>
                    </div>
                  </div>
                  <div class="post-type">
                    <a routerLink="/blog-grid">  <i class="flaticon-photo-camera"></i>
                    </a>
                  </div>
                  <div class="blog-meta">
                    <div class="entry-meta-content">
                      <div class="entry-date"> <span class="text-dark-white">In 25 December 2020</span>
                      </div>
                    </div>
                    <div class="blog-caption">
                      <h2 class="blog-title">
                                            <a routerLink="/blog-standard" class="text-light-black">the love of power is the demon of men & women</a>
                                          </h2>
                    </div>
                  </div>
                </div>
              </article>
              <!-- article 1 -->
            </div>
            <div class="col-lg-4 col-md-6">
              <!-- article 1 -->
              <article class="post">
                <div class="blog-wrap p-relative">
                  <div class="blog-img no-margin p-relative">
                    <a routerLink="/blog-standard">
                      <img src="assets/images/blog/blog-3.jpg" class="img-fluid full-width" alt="Blog img">
                    </a>
                    <div class="read-more-wrap">  <a routerLink="/blog-standard" class="read-more">
                      READ THIS ARTICLE
                    </a>
                    </div>
                  </div>
                  <div class="post-type">
                    <a routerLink="/blog-grid">  <i class="flaticon-film"></i>
                    </a>
                  </div>
                  <div class="blog-meta">
                    <div class="entry-meta-content">
                      <div class="entry-date"> <span class="text-dark-white">In <a routerLink="/blog-grid" class="text-dark-white">20 November 2020</a></span>
                      </div>
                    </div>
                    <div class="blog-caption">
                      <h2 class="blog-title">
                                            <a routerLink="/blog-standard" class="text-light-black">the love of power is the demon of men & women</a>
                                          </h2>
                    </div>
                  </div>
                </div>
              </article>
              <!-- article 1 -->
            </div>
            <div class="col-lg-4 col-md-6">
              <!-- article 1 -->
              <article class="post">
                <div class="blog-wrap p-relative">
                  <div class="blog-img no-margin p-relative">
                    <a routerLink="/blog-standard">
                      <img src="assets/images/blog/blog-4.jpg" class="img-fluid full-width" alt="Blog img">
                    </a>
                    <div class="read-more-wrap">  <a routerLink="/blog-standard" class="read-more">
                      READ THIS ARTICLE
                    </a>
                    </div>
                  </div>
                  <div class="post-type">
                    <a routerLink="/blog-grid">  <i class="flaticon-photo-camera"></i>
                    </a>
                  </div>
                  <div class="blog-meta">
                    <div class="entry-meta-content">
                      <div class="entry-date"> <span class="text-dark-white">In <a routerLink="/blog-grid" class="text-dark-white">25 November 2020</a></span>
                      </div>
                    </div>
                    <div class="blog-caption">
                      <h2 class="blog-title">
                                            <a routerLink="/blog-standard" class="text-light-black">Life is hard, and a lot of people come home tired from work</a>
                                          </h2>
                    </div>
                  </div>
                </div>
              </article>
              <!-- article 1 -->
            </div>
            <div class="col-lg-4 col-md-6">
              <!-- article 1 -->
              <article class="post">
                <div class="blog-wrap p-relative">
                  <div class="blog-img no-margin p-relative">
                    <a routerLink="/blog-standard">
                      <img src="assets/images/blog/blog-7.jpg" class="img-fluid full-width" alt="Blog img">
                    </a>
                    <div class="read-more-wrap">  <a routerLink="/blog-standard" class="read-more">
                      READ THIS ARTICLE
                    </a>
                    </div>
                  </div>
                  <div class="post-type">
                    <a routerLink="/blog-grid">  <i class="flaticon-photo-camera"></i>
                    </a>
                  </div>
                  <div class="blog-meta">
                    <div class="entry-meta-content">
                      <div class="entry-date"> <span class="text-dark-white">In <a routerLink="/blog-grid" class="text-dark-white">01 December 2020</a></span>
                      </div>
                    </div>
                    <div class="blog-caption">
                      <h2 class="blog-title">
                                            <a routerLink="/blog-standard" class="text-light-black">the love of power is the demon of men & women</a>
                                          </h2>
                    </div>
                  </div>
                </div>
              </article>
              <!-- article 1 -->
            </div>
            <div class="col-lg-4 col-md-6">
              <!-- article 1 -->
              <article class="post">
                <div class="blog-wrap p-relative">
                  <div class="blog-img no-margin p-relative">
                    <a routerLink="/blog-standard">
                      <img src="assets/images/blog/blog-8.jpg" class="img-fluid full-width" alt="Blog img">
                    </a>
                    <div class="read-more-wrap">  <a routerLink="/blog-standard" class="read-more">
                      READ THIS ARTICLE
                    </a>
                    </div>
                  </div>
                  <div class="post-type">
                    <a routerLink="/blog-grid">  <i class="flaticon-film"></i>
                    </a>
                  </div>
                  <div class="blog-meta">
                    <div class="entry-meta-content">
                      <div class="entry-date"> <span class="text-dark-white">In <a routerLink="/blog-grid" class="text-dark-white">04 December 2020</a></span>
                      </div>
                    </div>
                    <div class="blog-caption">
                      <h2 class="blog-title">
                                            <a routerLink="/blog-standard" class="text-light-black">The PC has improved the world in just about every area</a>
                                          </h2>
                    </div>
                  </div>
                </div>
              </article>
              <!-- article 1 -->
            </div>
            <div class="col-lg-4 col-md-6">
              <!-- article 1 -->
              <article class="post">
                <div class="blog-wrap p-relative">
                  <div class="blog-img no-margin p-relative">
                    <a routerLink="/blog-standard">
                      <img src="assets/images/blog/blog-9.jpg" class="img-fluid full-width" alt="Blog img">
                    </a>
                    <div class="read-more-wrap">  <a routerLink="/blog-standard" class="read-more">
                      READ THIS ARTICLE
                    </a>
                    </div>
                  </div>
                  <div class="post-type">
                    <a routerLink="/blog-grid">  <i class="flaticon-photo-camera"></i>
                    </a>
                  </div>
                  <div class="blog-meta">
                    <div class="entry-meta-content">
                      <div class="entry-date"> <span class="text-dark-white">In <a routerLink="/blog-grid" class="text-dark-white">10 December 2020</a></span>
                      </div>
                    </div>
                    <div class="blog-caption">
                      <h2 class="blog-title">
                                            <a routerLink="/blog-standard" class="text-light-black">Life is hard, and a lot of people come home tired from work</a>
                                          </h2>
                    </div>
                  </div>
                </div>
              </article>
              <!-- article 1 -->
            </div>
            <div class="col-lg-4 col-md-6">
              <!-- article 1 -->
              <article class="post">
                <div class="blog-wrap p-relative">
                  <div class="blog-img no-margin p-relative">
                    <a routerLink="/blog-standard">
                      <img src="assets/images/blog/blog-10.jpg" class="img-fluid full-width" alt="Blog img">
                    </a>
                    <div class="read-more-wrap">  <a routerLink="/blog-standard" class="read-more">
                      READ THIS ARTICLE
                    </a>
                    </div>
                  </div>
                  <div class="post-type">
                    <a routerLink="/blog-grid">  <i class="flaticon-photo-camera"></i>
                    </a>
                  </div>
                  <div class="blog-meta">
                    <div class="entry-meta-content">
                      <div class="entry-date"> <span class="text-dark-white">In <a routerLink="/blog-grid" class="text-dark-white">20 December 2020</a></span>
                      </div>
                    </div>
                    <div class="blog-caption">
                      <h2 class="blog-title">
                                            <a routerLink="/blog-standard" class="text-light-black">The PC has improved the world in just about every area</a>
                                          </h2>
                    </div>
                  </div>
                </div>
              </article>
              <!-- article 1 -->
            </div>
            <div class="col-lg-4 col-md-6">
              <!-- article 1 -->
              <article class="post">
                <div class="blog-wrap p-relative">
                  <div class="blog-img no-margin p-relative">
                    <a routerLink="/blog-standard">
                      <img src="assets/images/blog/blog-11.jpg" class="img-fluid full-width" alt="Blog img">
                    </a>
                    <div class="read-more-wrap">  <a routerLink="/blog-standard" class="read-more">
                      READ THIS ARTICLE
                    </a>
                    </div>
                  </div>
                  <div class="post-type">
                    <a routerLink="/blog-grid">  <i class="flaticon-film"></i>
                    </a>
                  </div>
                  <div class="blog-meta">
                    <div class="entry-meta-content">
                      <div class="entry-date"> <span class="text-dark-white">In 25 December 2020</span>
                      </div>
                    </div>
                    <div class="blog-caption">
                      <h2 class="blog-title">
                                            <a routerLink="/blog-standard" class="text-light-black">Life is hard, and a lot of people come home tired from work</a>
                                          </h2>
                    </div>
                  </div>
                </div>
              </article>
              <!-- article 1 -->
            </div>
            <!-- pagination -->
            <div class="col-12">
              <nav class="custom-pagination">
                <ul class="pagination">
                  <li class="page-item "><a class="page-link" routerLink="/blog-grid">Previous</a>
                  </li>
                  <li class="page-item"><a class="page-link" routerLink="/blog-grid">1</a>
                  </li>
                  <li class="page-item active"><a class="page-link" routerLink="/blog-grid">2</a>
                  </li>
                  <li class="page-item"><a class="page-link" routerLink="/blog-grid">3</a>
                  </li>
                  <li class="page-item"><a class="page-link" routerLink="/blog-grid">Next</a>
                  </li>
                </ul>
              </nav>
            </div>
            <!-- pagination -->
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Blog -->
<app-footer4></app-footer4>
</div>