import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-car-detail-sidebar',
  templateUrl: './car-detail-sidebar.component.html',
  styleUrls: ['./car-detail-sidebar.component.css']
})
export class CarDetailSidebarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
