<div class="mobile-menu-second">
<app-header4></app-header4>	
<!-- User Details -->
  <div class="user-page-sec p-relative">
    <div class="main-image-top p-relative">
      <img src="assets/images/banner/subbanner.jpg" class="img-fluid full-width" alt="#">
      <div class="overlay overlay-bg"></div>
    </div>
    <div class="user-page-details-sec">
      <div class="container-fluid">
        <div class="row">
          <div class="offset-lg-1 col-lg-10">
            <div class="user-about">
              <div class="row">
                <div class="col-md-6">
                  <div class="user-left-wrapper">
                    <div class="user-img">
                      <img src="assets/images/cardetails/user-1.jpg" class="rounded-circle" alt="#">
                    </div>
                    <div class="user-details">
                      <div class="user-details-top">
                        <h5 class="title text-custom-white">Jhon Deo <span><i class="fas fa-check"></i></span></h5>
                        <span><a routerLink="/user" class="text-custom-white"><i class="fas fa-map-marker-alt"></i> New York</a></span>
                        <span><a routerLink="/user" class="text-custom-white"><i class="fas fa-link"></i> @jhon_deo</a></span>
                      </div>
                      <div class="user-social-links"> <a routerLink="/user" class="facebook"><i class="fab fa-facebook-square"></i></a>
                        <a routerLink="/user" class="twitter"><i class="fab fa-twitter"></i></a>
                        <a routerLink="/user" class="pintrest"><i class="fab fa-pinterest-square"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="user-right-wrapper">
                    <div class="star-rating"> <span class="text-yellow"><i class="fas fa-star"></i></span>
                      <span class="text-yellow"><i class="fas fa-star"></i></span>
                      <span class="text-yellow"><i class="fas fa-star"></i></span>
                      <span class="text-yellow"><i class="fas fa-star"></i></span>
                      <span class="text-yellow"><i class="fas fa-star-half-alt"></i></span>
                      <ul>
                        <li class="text-custom-white">5.00 average</li>
                        <li><a routerLink="/user" class="text-custom-white">3 Reviews</a> 
                        </li>
                      </ul>
                    </div>
                    <div class="comment-details">
                      <ul>
                        <li class="text-dark-white">21 <span>Sold Car</span>
                        </li>
                        <li class="text-dark-white">3 <span>Top Sales</span>
                        </li>
                        <li class="text-dark-white">1 <span>Verified Member</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="space-85"></div>
    <div class="main-image-sec">
      <div class="container-fluid">
        <div class="row">
          <div class="offset-lg-1 col-lg-10">
            <div class="main-image p-relative">
              <img src="assets/images/showcar10.jpg" class="img-fluid" alt="#">
              <div class="main-image-caption">
                <h3 class="text-light-black">Audi TT</h3>
                <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
                <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort. A masterful combination of style, power, sporty handling and comfort.</p>
                <div class="price-sec"> <span class="text-light-black ">$</span>
                  <h1 class="text-light-black price-title">50,000</h1>
                </div>
                <div class="audio-sec">
                  <div class="user-social-links"> <a routerLink="/user" class="facebook"><i class="fab fa-facebook-square"></i></a>
                    <a routerLink="/user" class="twitter"><i class="fab fa-twitter"></i></a>
                    <a routerLink="/user" class="pintrest"><i class="fab fa-pinterest-square"></i></a>
                  </div>
                </div>
                <div class="add-fav-sec"> <span class="text-dark-white">Add Favorite</span>
                  <button type="button" tabindex="0"><i class="flaticon-like"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="offset-lg-1 col-lg-10">
            <div class="car-specification">
              <ul>
                <li><span class="text-dark-white">Max Power at rpm</span>6,500 <sub>rpm</sub>
                </li>
                <li><span class="text-dark-white">Max. engine speed</span>7,500 <sub>rpm</sub>
                </li>
                <li><span class="text-dark-white">Max. power</span>300 <sub>hp</sub>
                </li>
                <li><span class="text-dark-white">Stroke</span>76.4 <sub>mm</sub>
                </li>
                <li><span class="text-dark-white">Traction</span>RWD</li>
                <li><span class="text-dark-white">Max. torque at rpm</span>1,950 <sub>rpm</sub>
                </li>
              </ul>
            </div>
            <div class="car-btns">
              <div class="row justify-content-between">
                <div class="col-xl-4 col-md-6"> <a routerLink="/car-detail" class="btn-second orange-btn">Full Details</a>
                  <a routerLink="/car-detail" class="btn-third transparent-btn">Test Drive</a>
                </div>
                <div class="col-xl-2 col-md-6 align-self-center"> <span class="text-dark-white">Full Specifications <button type="button"><i class="flaticon-add"></i></button></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- User Details -->
  <!-- User page bottom -->
  <div class="listing-with-map bg-light-dark">
    <div class="space-30"></div>
    <div class="container-fluid">
      <div class="row ">
        <aside class="col-xl-3 col-lg-4">
          <app-user-sidebar></app-user-sidebar>
        </aside>
        <div class="col-xl-9 col-lg-8">
          <div class="title-head">
            <h5>Cars <small class="text-dark-white fw-400 rubik">Found: 1,254 Results</small></h5>
          </div>
          <div class="right-section-car-box">
            <div class="row">
              <div class="col-xl-3 col-lg-6 col-md-4 col-sm-6">
                <div class="car-grid-layout-box">
                  <div class="car-grid-layout-inner text-center">
                    <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">Acura TLX </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
                    <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
                    <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p> <span class="text-orange price-text">$25,000</span>
                    <div class="car-grid-layout-img">
                      <img src="assets/images/showcar7.jpg" class="img-fluid full-width" alt="Car img">
                    </div>
                    <div class="car-grid-layout-details"> <span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 20,000 Km</span>
                      <span class="text-dark-white p-relative"><i class="icon-car"></i> 2010 Model</span>
                    </div>
                    <div class="car-grid-layout-location">  <span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> New York</span>
                      <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Automatic</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-6 col-md-4 col-sm-6">
                <div class="car-grid-layout-box">
                  <div class="car-grid-layout-inner text-center">
                    <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">BMW 4 Series </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
                    <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
                    <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p> <span class="text-orange price-text">$25,000</span>
                    <div class="car-grid-layout-img">
                      <img src="assets/images/showcar8.jpg" class="img-fluid full-width" alt="Car img">
                    </div>
                    <div class="car-grid-layout-details"> <span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 20,000 Km</span>
                      <span class="text-dark-white p-relative"><i class="icon-car"></i> 2010 Model</span>
                    </div>
                    <div class="car-grid-layout-location">  <span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> New York</span>
                      <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Manual</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-6 col-md-4 col-sm-6">
                <div class="car-grid-layout-box">
                  <div class="car-grid-layout-inner text-center">
                    <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">Audi A4 </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
                    <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
                    <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p> <span class="text-orange price-text">$25,000</span>
                    <div class="car-grid-layout-img">
                      <img src="assets/images/showcar7.jpg" class="img-fluid full-width" alt="Car img">
                    </div>
                    <div class="car-grid-layout-details"> <span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 20,000 Km</span>
                      <span class="text-dark-white p-relative"><i class="icon-car"></i> 2010 Model</span>
                    </div>
                    <div class="car-grid-layout-location">  <span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> New York</span>
                      <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Automatic</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-6 col-md-4 col-sm-6">
                <div class="car-grid-layout-box">
                  <div class="car-grid-layout-inner text-center">
                    <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">Infiniti Q50 </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
                    <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
                    <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p> <span class="text-orange price-text">$25,000</span>
                    <div class="car-grid-layout-img">
                      <img src="assets/images/showcar2.jpg" class="img-fluid full-width" alt="Car img">
                    </div>
                    <div class="car-grid-layout-details"> <span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 20,000 Km</span>
                      <span class="text-dark-white p-relative"><i class="icon-car"></i> 2010 Model</span>
                    </div>
                    <div class="car-grid-layout-location">  <span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> New York</span>
                      <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Manual</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-6 col-md-4 col-sm-6">
                <div class="car-grid-layout-box">
                  <div class="car-grid-layout-inner text-center">
                    <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">Mercedes-Benz </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
                    <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
                    <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p> <span class="text-orange price-text">$25,000</span>
                    <div class="car-grid-layout-img">
                      <img src="assets/images/showcar7.jpg" class="img-fluid full-width" alt="Car img">
                    </div>
                    <div class="car-grid-layout-details"> <span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 20,000 Km</span>
                      <span class="text-dark-white p-relative"><i class="icon-car"></i> 2010 Model</span>
                    </div>
                    <div class="car-grid-layout-location">  <span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> New York</span>
                      <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Automatic</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-6 col-md-4 col-sm-6">
                <div class="car-grid-layout-box">
                  <div class="car-grid-layout-inner text-center">
                    <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">Lexus ES </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
                    <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
                    <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p> <span class="text-orange price-text">$25,000</span>
                    <div class="car-grid-layout-img">
                      <img src="assets/images/showcar4.jpg" class="img-fluid full-width" alt="Car img">
                    </div>
                    <div class="car-grid-layout-details"> <span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 20,000 Km</span>
                      <span class="text-dark-white p-relative"><i class="icon-car"></i> 2010 Model</span>
                    </div>
                    <div class="car-grid-layout-location">  <span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> New York</span>
                      <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Manual</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-6 col-md-4 col-sm-6">
                <div class="car-grid-layout-box">
                  <div class="car-grid-layout-inner text-center">
                    <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">Tesla Model 3 </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
                    <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
                    <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p> <span class="text-orange price-text">$25,000</span>
                    <div class="car-grid-layout-img">
                      <img src="assets/images/showcar3.jpg" class="img-fluid full-width" alt="Car img">
                    </div>
                    <div class="car-grid-layout-details"> <span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 20,000 Km</span>
                      <span class="text-dark-white p-relative"><i class="icon-car"></i> 2010 Model</span>
                    </div>
                    <div class="car-grid-layout-location">  <span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> New York</span>
                      <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Automatic</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-6 col-md-4 col-sm-6">
                <div class="car-grid-layout-box">
                  <div class="car-grid-layout-inner text-center">
                    <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">Audi TT Rs v2 </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
                    <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
                    <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p> <span class="text-orange price-text">$25,000</span>
                    <div class="car-grid-layout-img">
                      <img src="assets/images/showcar2.jpg" class="img-fluid full-width" alt="Car img">
                    </div>
                    <div class="car-grid-layout-details"> <span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 20,000 Km</span>
                      <span class="text-dark-white p-relative"><i class="icon-car"></i> 2010 Model</span>
                    </div>
                    <div class="car-grid-layout-location">  <span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> New York</span>
                      <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Manual</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-6 col-md-4 col-sm-6">
                <div class="car-grid-layout-box">
                  <div class="car-grid-layout-inner text-center">
                    <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">Audi TT Rs v2 </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
                    <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
                    <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p> <span class="text-orange price-text">$25,000</span>
                    <div class="car-grid-layout-img">
                      <img src="assets/images/showcar1.jpg" class="img-fluid full-width" alt="Car img">
                    </div>
                    <div class="car-grid-layout-details"> <span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 20,000 Km</span>
                      <span class="text-dark-white p-relative"><i class="icon-car"></i> 2010 Model</span>
                    </div>
                    <div class="car-grid-layout-location">  <span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> New York</span>
                      <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Automatic</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-6 col-md-4 col-sm-6">
                <div class="car-grid-layout-box">
                  <div class="car-grid-layout-inner text-center">
                    <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">Audi TT Rs v2 </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
                    <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
                    <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p> <span class="text-orange price-text">$25,000</span>
                    <div class="car-grid-layout-img">
                      <img src="assets/images/showcar8.jpg" class="img-fluid full-width" alt="Car img">
                    </div>
                    <div class="car-grid-layout-details"> <span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 20,000 Km</span>
                      <span class="text-dark-white p-relative"><i class="icon-car"></i> 2010 Model</span>
                    </div>
                    <div class="car-grid-layout-location">  <span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> New York</span>
                      <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Manual</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-6 col-md-4 col-sm-6">
                <div class="car-grid-layout-box">
                  <div class="car-grid-layout-inner text-center">
                    <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">Audi TT Rs v2 </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
                    <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
                    <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p> <span class="text-orange price-text">$25,000</span>
                    <div class="car-grid-layout-img">
                      <img src="assets/images/showcar7.jpg" class="img-fluid full-width" alt="Car img">
                    </div>
                    <div class="car-grid-layout-details"> <span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 20,000 Km</span>
                      <span class="text-dark-white p-relative"><i class="icon-car"></i> 2010 Model</span>
                    </div>
                    <div class="car-grid-layout-location">  <span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> New York</span>
                      <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Automatic</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-lg-6 col-md-4 col-sm-6">
                <div class="car-grid-layout-box">
                  <div class="car-grid-layout-inner text-center">
                    <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">Audi TT Rs v2 </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
                    <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
                    <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p> <span class="text-orange price-text">$25,000</span>
                    <div class="car-grid-layout-img">
                      <img src="assets/images/showcar6.jpg" class="img-fluid full-width" alt="Car img">
                    </div>
                    <div class="car-grid-layout-details"> <span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 20,000 Km</span>
                      <span class="text-dark-white p-relative"><i class="icon-car"></i> 2010 Model</span>
                    </div>
                    <div class="car-grid-layout-location">  <span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> New York</span>
                      <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Automatic</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <nav class="custom-pagination">
              <ul class="pagination">
                <li class="page-item "><a class="page-link" routerLink="/user">Previous</a>
                </li>
                <li class="page-item active"><a class="page-link" routerLink="/user">1</a>
                </li>
                <li class="page-item"><a class="page-link" routerLink="/user">2</a>
                </li>
                <li class="page-item"><a class="page-link" routerLink="/user">3</a>
                </li>
                <li class="page-item"><a class="page-link" routerLink="/user">Next</a>
                </li>
              </ul>
            </nav>
          </div>
          <div class="product-reviews">
            <h5 class="text-light-black comment-reply-title">2 Reviews for Rims</h5>
            <div class="reviews-section">
              <article class="review-post">
                <div class="review-box">
                  <div class="review-thumbnail">
                    <a routerLink="/user">
                      <img src="assets/images/cardetails/user-2.jpg" alt="#">
                    </a>
                  </div>
                  <div class="user-name">
                    <h6><a routerLink="/user" class="text-light-black">Jhon</a></h6>
                    <p class="text-dark-white"><i class="fas fa-star text-yellow"></i> 36 Reviews</p>
                  </div>
                </div>
                <section class="details">
                  <div class="top-section">
                    <h5 class="text-light-black rubik mb-1 fw-600">I loved there Cars.</h5>
                    <span class="text-dark-grey">November 15, 2020 5:30 Pm</span>
                    <div class="review-count">
                      <div class="rating">  <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="far fa-star"></i> 
                      </div>  <span class="rate">4<sup>/ 5</sup></span> 
                    </div>
                  </div>
                  <div class="comment-box">
                    <p class="text-dark-grey">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                    <div class="img-slider-reviews">
                      <a routerLink="/user">
                        <img src="assets/images/cardetails/290x213/comment-5.jpg" class="img-fluid full-width" alt="#">
                      </a>
                      <a routerLink="/user">
                        <img src="assets/images/cardetails/290x213/comment-3.jpg" class="img-fluid full-width" alt="#">
                      </a>
                      <a routerLink="/user">
                        <img src="assets/images/cardetails/290x213/comment-4.jpg" class="img-fluid full-width" alt="#">
                      </a>
                      <a routerLink="/user">
                        <img src="assets/images/cardetails/290x213/comment-6.jpg" class="img-fluid full-width" alt="#">
                      </a>
                      <a routerLink="/user">
                        <img src="assets/images/cardetails/290x213/comment-2.jpg" class="img-fluid full-width" alt="#">
                      </a>
                      <a routerLink="/user">
                        <img src="assets/images/cardetails/290x213/comment-1.jpg" class="img-fluid full-width" alt="#">
                      </a>
                    </div>
                    <div class="comment-footer">  <span class="text-dark-white">Was This Review ...?</span>
                      <ul>
                        <li>  <a routerLink="/user" class="interesting"><i class="far fa-thumbs-up"></i> Interesting <span class="text-dark-white">220</span></a>
                        </li>
                        <li>  <a routerLink="/user" class="lol"><i class="far fa-laugh"></i> Lol <span class="text-dark-white">150</span></a>
                        </li>
                        <li>  <a routerLink="/user" class="love"><i class="far fa-heart"></i> Love <span class="text-dark-white">500</span></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </section>
              </article>
              <article class="review-post">
                <div class="review-box">
                  <div class="review-thumbnail">
                    <a routerLink="/user">
                      <img src="assets/images/cardetails/user-3.jpg" alt="#">
                    </a>
                  </div>
                  <div class="user-name">
                    <h6><a routerLink="/user" class="text-light-black">Deo</a></h6>
                    <p class="text-dark-white"><i class="fas fa-star text-yellow"></i> 20 Reviews</p>
                  </div>
                </div>
                <section class="details">
                  <div class="top-section">
                    <h5 class="text-light-black rubik mb-1 fw-600">Amazing Cars</h5>
                    <span class="text-dark-grey">October 18, 2020 7:26 Pm</span>
                    <div class="review-count">
                      <div class="rating">  <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i> 
                      </div>  <span class="rate">5<sup>/ 5</sup></span> 
                    </div>
                  </div>
                  <div class="comment-box">
                    <p class="text-dark-grey">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                    <div class="img-slider-reviews">
                      <a routerLink="/user">
                        <img src="assets/images/cardetails/290x213/comment-1.jpg" class="img-fluid full-width" alt="#">
                      </a>
                      <a routerLink="/user">
                        <img src="assets/images/cardetails/290x213/comment-2.jpg" class="img-fluid full-width" alt="#">
                      </a>
                      <a routerLink="/user">
                        <img src="assets/images/cardetails/290x213/comment-3.jpg" class="img-fluid full-width" alt="#">
                      </a>
                      <a routerLink="/user">
                        <img src="assets/images/cardetails/290x213/comment-4.jpg" class="img-fluid full-width" alt="#">
                      </a>
                      <a routerLink="/user">
                        <img src="assets/images/cardetails/290x213/comment-5.jpg" class="img-fluid full-width" alt="#">
                      </a>
                      <a routerLink="/user">
                        <img src="assets/images/cardetails/290x213/comment-6.jpg" class="img-fluid full-width" alt="#">
                      </a>
                    </div>
                    <div class="comment-footer">  <span class="text-dark-white">Was This Review ...?</span>
                      <ul>
                        <li>  <a routerLink="/user" class="interesting"><i class="far fa-thumbs-up"></i> Interesting <span class="text-dark-white">180</span></a>
                        </li>
                        <li>  <a routerLink="/user" class="lol"><i class="far fa-laugh"></i> Lol <span class="text-dark-white">125</span></a>
                        </li>
                        <li>  <a routerLink="/user" class="love"><i class="far fa-heart"></i> Love <span class="text-dark-white">350</span></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
          <div class="comment-form-second">
            <h5 class="text-light-black comment-reply-title">Rate us and Write a Review</h5>
            <form>
              <div class="form-group">
                <div class="row align-items-center">
                  <div class="col-sm-6">
                    <label class="text-light-black">Your Rating for this listing</label>
                    <div class="star-rating"> <span>
                        <i class="far fa-star"></i>
                      </span>
                      <span>
                        <i class="far fa-star"></i>
                      </span>
                      <span>
                        <i class="far fa-star"></i>
                      </span>
                      <span>
                        <i class="far fa-star"></i>
                      </span>
                      <span>
                        <i class="far fa-star"></i>
                      </span>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="choose-img">  <span class="text-light-black">Select Image</span>
                      <label class="input-file" for="choose-file">Browse</label>
                      <input type="file" name="#" multiple="multiple" id="choose-file">
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-12">
                    <label class="text-dark-grey">Email<sup>*</sup>
                    </label>
                    <input type="email" class="form-control form-control-custom" name="#" placeholder="Enter your email i'd">
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-12">
                    <label class="text-dark-grey">Title<sup>*</sup>
                    </label>
                    <input type="email" class="form-control form-control-custom" name="#" placeholder="Example: It was an awesome experience to be there">
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-12">
                    <label class="text-dark-grey">Review<sup>*</sup>
                    </label>
                    <textarea class="form-control form-control-custom" name="#" placeholder="Review" rows="6"></textarea>
                    <p>Your review is recommended to be at least 140 characters long</p>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-12">
                    <button type="submit" class="btn-second orange-btn">Submit Review</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- User page bottom -->
	<app-footer4></app-footer4>
</div>