<div class="contact-page mobile-menu-second">
	<app-header4></app-header4>
<!-- Sub header -->
  <div class="sub-header-sec p-relative">
    <img src="assets/images/blog/banner/banner-4.jpg" class="img-fluid" alt="Sub header">
    <div class="sub-header-overlay"></div>
    <div class="sub-header-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-xl-12">
            <div class="sub-header-text-wrapper text-center">
              <h1 class="text-custom-white">Contact Us</h1>
              <ul class="breadcrumbs">
                <li><a routerLink="/" class="text-custom-white">Home</a>
                </li>
                <li><span class="text-custom-white">Contact Us</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Sub header -->
  <!-- Contact form -->
  <section class="section-padding contact-page">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="contact-form-wrapper">
            <div class="contact-header">
              <h3 class="text-light-black fw-500">Contact Us and Get a Free Consultation</h3>
              <p class="text-dark-grey">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>
            <div class="contact-form">
              <form>
                <div class="form-group">
                  <input type="text" class="form-control form-control-custom" name="#" placeholder="Your Name">
                </div>
                <div class="form-group">
                  <input type="text" class="form-control form-control-custom" name="#" placeholder="Subject">
                </div>
                <div class="form-group">
                  <input type="email" class="form-control form-control-custom" name="#" placeholder="Email I'd">
                </div>
                <div class="form-group">
                  <textarea class="form-control form-control-custom" name="#" rows="4" placeholder="Your Message"></textarea>
                </div>
                <div class="form-group">
                  <button class="btn-second orange-btn" type="submit">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="contact-side-sec">
            <div class="contact-img-wrapper">
              <div class="img-sec p-relative">
                <img src="assets/images/contact-1.jpg" alt="#" class="img-fluid full-width">
              </div>
              <div class="video-btn-sec">
                <div class="circle-box">
                  <a href="JavaScript:Void(0);" data-toggle="modal" data-target="#videomodal" class="video-btn">  <i class="flaticon-triangle"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Contact form -->
  <div id="contact-map">
    <iframe src="https://www.google.com/maps/embed?pb=!1m28!1m12!1m3!1d1572264.578242286!2d-76.35629003051189!3d39.67605529079796!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m13!3e6!4m5!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!3m2!1d40.7127753!2d-74.0059728!4m5!1s0x89b7c6de5af6e45b%3A0xc2524522d4885d2a!2sWashington%20D.%20C.%2C%20DC%2C%20USA!3m2!1d38.9071923!2d-77.0368707!5e0!3m2!1sen!2sin!4v1593620930391!5m2!1sen!2sin" width="100%" height="100%" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
  </div>
  <!-- faqs -->
  <section class="section-padding faqs">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="section-header">
            <div class="text-wrapper text-center">
              <h3 class="fw-500 no-margin">Most Common Asked Questions</h3>
              <span class="line-grey"></span><span class="line-orange"></span><span class="line-grey"></span> 
              <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort. A masterful combination of style, power, sporty handling and comfort.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="faqs-tab">
            <ul class="nav nav-pills" role="tablist">
              <li class="nav-item">
                <a class="nav-link active" data-toggle="pill" href="#general">  <span>
                      <i class="flaticon-question"></i>
                    </span>
                  <p class="no-margin text-light-black fw-500">General</p>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="pill" href="#engine">  <span>
                      <i class="flaticon-car-engine"></i>
                    </span>
                  <p class="no-margin text-light-black fw-500">Engine</p>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="pill" href="#mechanical">  <span>
                      <i class="flaticon-car-parts"></i>
                    </span>
                  <p class="no-margin text-light-black fw-500">Mechanical</p>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" data-toggle="pill" href="#navigation">  <span>
                      <i class="flaticon-car-1"></i>
                    </span>
                  <p class="no-margin text-light-black fw-500">Navigation</p>
                </a>
              </li>
            </ul>
          </div>
          <div class="tab-content custom-accordion">
            <div id="general" class="tab-pane active">
              <div class="card">
                <div class="card-header">
                  <h5 class="text-light-black no-margin title">How do i get finance support for my business?</h5>
                </div>
                <div class="card-body none">
                  <h5 class="text-light-black">How to get Started?</h5>
                  <p class="text-dark-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore padani pola magna aliqua.</p>
                </div>
              </div>
              <div class="card">
                <div class="card-header">
                  <h5 class="text-light-black no-margin title">How do i knew about any better opportunity</h5>
                </div>
                <div class="card-body none">
                  <h5 class="text-light-black">How to get Started?</h5>
                  <p class="text-dark-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore padani pola magna aliqua.</p>
                </div>
              </div>
              <div class="card">
                <div class="card-header">
                  <h5 class="text-light-black no-margin title">Does Startup allows to share user data?</h5>
                </div>
                <div class="card-body none">
                  <h5 class="text-light-black">How to get Started?</h5>
                  <p class="text-dark-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore padani pola magna aliqua.</p>
                </div>
              </div>
              <div class="card">
                <div class="card-header">
                  <h5 class="text-light-black no-margin title">What is the average cost for team project yearly ?</h5>
                </div>
                <div class="card-body none">
                  <h5 class="text-light-black">How to get Started?</h5>
                  <p class="text-dark-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore padani pola magna aliqua.</p>
                </div>
              </div>
              <div class="card">
                <div class="card-header">
                  <h5 class="text-light-black no-margin title">How to invite people in any active project ?</h5>
                </div>
                <div class="card-body none">
                  <h5 class="text-light-black">How to get Started?</h5>
                  <p class="text-dark-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore padani pola magna aliqua.</p>
                </div>
              </div>
            </div>
            <div id="engine" class="tab-pane fade">
              <div class="card">
                <div class="card-header">
                  <h5 class="text-light-black no-margin title">How do i get finance support for my business?</h5>
                </div>
                <div class="card-body none">
                  <h5 class="text-light-black">How to get Started?</h5>
                  <p class="text-dark-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore padani pola magna aliqua.</p>
                </div>
              </div>
              <div class="card">
                <div class="card-header">
                  <h5 class="text-light-black no-margin title">How do i knew about any better opportunity</h5>
                </div>
                <div class="card-body none">
                  <h5 class="text-light-black">How to get Started?</h5>
                  <p class="text-dark-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore padani pola magna aliqua.</p>
                </div>
              </div>
              <div class="card">
                <div class="card-header">
                  <h5 class="text-light-black no-margin title">Does Startup allows to share user data?</h5>
                </div>
                <div class="card-body none">
                  <h5 class="text-light-black">How to get Started?</h5>
                  <p class="text-dark-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore padani pola magna aliqua.</p>
                </div>
              </div>
              <div class="card">
                <div class="card-header">
                  <h5 class="text-light-black no-margin title">What is the average cost for team project yearly ?</h5>
                </div>
                <div class="card-body none">
                  <h5 class="text-light-black">How to get Started?</h5>
                  <p class="text-dark-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore padani pola magna aliqua.</p>
                </div>
              </div>
              <div class="card">
                <div class="card-header">
                  <h5 class="text-light-black no-margin title">How to invite people in any active project ?</h5>
                </div>
                <div class="card-body none">
                  <h5 class="text-light-black">How to get Started?</h5>
                  <p class="text-dark-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore padani pola magna aliqua.</p>
                </div>
              </div>
            </div>
            <div id="mechanical" class="tab-pane fade">
              <div class="card">
                <div class="card-header">
                  <h5 class="text-light-black no-margin title">How do i get finance support for my business?</h5>
                </div>
                <div class="card-body none">
                  <h5 class="text-light-black">How to get Started?</h5>
                  <p class="text-dark-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore padani pola magna aliqua.</p>
                </div>
              </div>
              <div class="card">
                <div class="card-header">
                  <h5 class="text-light-black no-margin title">How do i knew about any better opportunity</h5>
                </div>
                <div class="card-body none">
                  <h5 class="text-light-black">How to get Started?</h5>
                  <p class="text-dark-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore padani pola magna aliqua.</p>
                </div>
              </div>
              <div class="card">
                <div class="card-header">
                  <h5 class="text-light-black no-margin title">Does Startup allows to share user data?</h5>
                </div>
                <div class="card-body none">
                  <h5 class="text-light-black">How to get Started?</h5>
                  <p class="text-dark-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore padani pola magna aliqua.</p>
                </div>
              </div>
              <div class="card">
                <div class="card-header">
                  <h5 class="text-light-black no-margin title">What is the average cost for team project yearly ?</h5>
                </div>
                <div class="card-body none">
                  <h5 class="text-light-black">How to get Started?</h5>
                  <p class="text-dark-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore padani pola magna aliqua.</p>
                </div>
              </div>
              <div class="card">
                <div class="card-header">
                  <h5 class="text-light-black no-margin title">How to invite people in any active project ?</h5>
                </div>
                <div class="card-body none">
                  <h5 class="text-light-black">How to get Started?</h5>
                  <p class="text-dark-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore padani pola magna aliqua.</p>
                </div>
              </div>
            </div>
            <div id="navigation" class="tab-pane fade">
              <div class="card">
                <div class="card-header">
                  <h5 class="text-light-black no-margin title">How do i get finance support for my business?</h5>
                </div>
                <div class="card-body none">
                  <h5 class="text-light-black">How to get Started?</h5>
                  <p class="text-dark-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore padani pola magna aliqua.</p>
                </div>
              </div>
              <div class="card">
                <div class="card-header">
                  <h5 class="text-light-black no-margin title">How do i knew about any better opportunity</h5>
                </div>
                <div class="card-body none">
                  <h5 class="text-light-black">How to get Started?</h5>
                  <p class="text-dark-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore padani pola magna aliqua.</p>
                </div>
              </div>
              <div class="card">
                <div class="card-header">
                  <h5 class="text-light-black no-margin title">Does Startup allows to share user data?</h5>
                </div>
                <div class="card-body none">
                  <h5 class="text-light-black">How to get Started?</h5>
                  <p class="text-dark-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore padani pola magna aliqua.</p>
                </div>
              </div>
              <div class="card">
                <div class="card-header">
                  <h5 class="text-light-black no-margin title">What is the average cost for team project yearly ?</h5>
                </div>
                <div class="card-body none">
                  <h5 class="text-light-black">How to get Started?</h5>
                  <p class="text-dark-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore padani pola magna aliqua.</p>
                </div>
              </div>
              <div class="card">
                <div class="card-header">
                  <h5 class="text-light-black no-margin title">How to invite people in any active project ?</h5>
                </div>
                <div class="card-body none">
                  <h5 class="text-light-black">How to get Started?</h5>
                  <p class="text-dark-white">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore padani pola magna aliqua.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- faqs -->
<app-footer4></app-footer4>
 <div class="modal fade" id="videomodal">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        <!-- Modal body -->
        <div class="modal-body">
          <iframe src="https://www.youtube.com/embed/pbiEfJd2Hpw" allow="autoplay"></iframe>
        </div>
      </div>
    </div>
  </div>
	
</div>