<!-- Start Footer -->
  <footer class="bg-custom-black" id="footer-style-1">
    <div class="section-padding">
      <div class="container">
        <div class="row">
          <div class="col-xl-7 col-lg-7 col-md-12">
            <div class="row ft-space">
              <div class="col-xl-7 col-lg-7 col-md-6 col-sm-8">
                <div class="footer-box ft-space-col">
                  <div class="ft-logo">
                    <a routerLink="/">
                      <img src="assets/images/120x50logo.jpg" class="img-fluid" alt="Logo">
                    </a>
                  </div>
                  <p class="text-dark-white ">It is a long establishment fact that a reader will be the distracted by the readable content of a page.</p>
                  <div class="space-20"></div>
                  <label class="text-custom-white fw-600 ">Address:</label>
                  <p class="text-dark-white ">45 Grand Central Terminal New York
                    <br>NY 1245, United State USA</p>
                  <label class="text-custom-white fw-600 ">Email:</label>
                  <p><a href="mailto:" class="text-dark-white ">Support@demosite.com</a>
                  </p>
                  <label class="text-custom-white fw-600 ">Phone:</label>
                  <p><a href="tel:" class="text-dark-white ">+123 4567 8910</a>
                  </p>
                </div>
              </div>
              <div class="offset-xl-1 col-xl-4 offset-lg-1 col-lg-4 col-md-6 col-sm-4">
                <div class="footer-box ft-space-col">
                  <h5 class="text-custom-white">Quick Links</h5>
                  <ul>
                    <li><a href="javascript:void(0)" class="text-dark-white ">Support</a>
                    </li>
                    <li><a href="javascript:void(0)" class="text-dark-white ">Helpline</a>
                    </li>
                    <li><a href="javascript:void(0)" class="text-dark-white ">Affilates</a>
                    </li>
                    <li><a href="javascript:void(0)" class="text-dark-white ">Live Support</a>
                    </li>
                    <li><a href="javascript:void(0)" class="text-dark-white ">Customer Care</a>
                    </li>
                    <li><a href="javascript:void(0)" class="text-dark-white ">FAQs</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-5 col-lg-5 col-md-12">
            <div class="row">
              <div class="col-xl-6 col-md-6 col-sm-8">
                <div class="footer-box ft-space-col">
                  <h5 class="text-custom-white">Informations</h5>
                  <ul>
                    <li><a href="javascript:void(0)" class="text-dark-white ">About Us</a>
                    </li>
                    <li><a href="javascript:void(0)" class="text-dark-white ">Customer Services</a>
                    </li>
                    <li><a href="javascript:void(0)" class="text-dark-white ">Privacy and Policy</a>
                    </li>
                    <li><a href="javascript:void(0)" class="text-dark-white ">Order & Returns</a>
                    </li>
                    <li><a href="javascript:void(0)" class="text-dark-white ">Shipping Policy</a>
                    </li>
                    <li><a href="javascript:void(0)" class="text-dark-white ">Our Sitemap</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-xl-6 col-md-6 col-sm-4">
                <div class="footer-box">
                  <h5 class="text-custom-white">My Account</h5>
                  <ul>
                    <li><a href="javascript:void(0)" class="text-dark-white ">Balance</a>
                    </li>
                    <li><a href="javascript:void(0)" class="text-dark-white ">Checkout</a>
                    </li>
                    <li><a href="javascript:void(0)" class="text-dark-white ">Cards</a>
                    </li>
                    <li><a href="javascript:void(0)" class="text-dark-white ">Track Order</a>
                    </li>
                    <li><a href="javascript:void(0)" class="text-dark-white ">Terms</a>
                    </li>
                    <li><a href="javascript:void(0)" class="text-dark-white ">Site Policy</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copyright">
      <div class="container">
        <div class="row">
          <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12  align-self-center"> <a href="javascript:void(0)" class="text-custom-white ">© Slidesigma - 2020 | All Right Reserved</a>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12">
            <div class="social-icons text-center">
              <ul>
                <li><a href="javascript:void(0)" class="text-dark-white"><i class="fab fa-facebook-f"></i></a>
                </li>
                <li><a href="javascript:void(0)" class="text-dark-white"><i class="fab fa-twitter"></i></a>
                </li>
                <li><a href="javascript:void(0)" class="text-dark-white"><i class="fab fa-pinterest-p"></i></a>
                </li>
                <li><a href="javascript:void(0)" class="text-dark-white"><i class="fab fa-instagram"></i></a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-12">
            <div class="payement-cards">
              <ul>
                <li>
                  <img src="assets/images/card-1.png" alt="Payment Icons">
                </li>
                <li>
                  <img src="assets/images/card-2.jpg" alt="Payment Icons">
                </li>
                <li>
                  <img src="assets/images/card-3.jpg" alt="Payment Icons">
                </li>
                <li>
                  <img src="assets/images/card-4.png" alt="Payment Icons">
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <!-- End Footer -->