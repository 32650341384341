<div class="mobile-menu-second">
	
<app-header4></app-header4>
  <!-- Sub header -->
  <div class="sub-header-sec p-relative">
    <img src="assets/images/blog/banner/banner-4.jpg" class="img-fluid" alt="Sub header">
    <div class="sub-header-overlay"></div>
    <div class="sub-header-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-xl-12">
            <div class="sub-header-text-wrapper text-center">
              <h1 class="text-custom-white">List Your Vehicle</h1>
              <ul class="breadcrumbs">
                <li><a routerLink="/" class="text-custom-white">Home</a>
                </li>
                <li><a routerLink="/add-listing" class="text-custom-white">Pages</a>
                </li>
                <li><span class="text-custom-white">Adding Listing</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Sub header -->
  <!-- Adding Listing -->
  <section class="section-padding add-listing-sec">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div id="add-listing-tab" class="step-app">
            <ul class="step-steps">
              <li class="active">
                <a href="#step1"> <span class="number"></span>
                  <span class="step-name">OVERALL INFORMATION</span>
                </a>
              </li>
              <li>
                <a href="#step2"> <span class="number"></span>
                  <span class="step-name">GALLERY &amp; VIDEO</span>
                </a>
              </li>
              <li>
                <a href="#step3"> <span class="number"></span>
                  <span class="step-name">Location</span>
                </a>
              </li>
              <li>
                <a href="#step4"> <span class="number"></span>
                  <span class="step-name">Amenities</span>
                </a>
              </li>
              <li>
                <a href="#step5"> <span class="number"></span>
                  <span class="step-name">confirmation</span>
                </a>
              </li>
            </ul>
            <div class="step-content">
              <div class="step-tab-panel" id="step1">
                <div class="tab-from-content">
                  <div class="title-icon">
                    <h5 class="title rubik fw-600"><i class="flaticon-comment"></i> Overall Information</h5>
                  </div>
                  <form>
                    <div class="row">
                      <div class="col-lg-4">
                        <div class="form-group">
                          <input type="text" name="#" class="form-control form-control-custom" placeholder="Listing title">
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group">
                          <input type="text" value="tags, here" data-role="tagsinput" class="form-control form-control-custom" />
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group p-relative">
                          <select class="form-control form-control-custom">
                            <option>Category</option>
                            <option>Luxury Car</option>
                            <option>Sports Car</option>
                            <option>Vintage Car</option>
                            <option>Excutive Car</option>
                            <option>Economy Car</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="form-group">
                          <textarea class="form-control form-control-custom" rows="5" placeholder="Description"></textarea>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class="step-tab-panel" id="step2">
                <div class="tab-from-content">
                  <div class="title-icon">
                    <h5 class="title rubik fw-600"><i class="flaticon-frame"></i> Gallery & Video</h5>
                  </div>
                  <div class="p-relative drag-drop-sec">
                    <form class="drag-drop">
                      <div class="row">
                        <div class="col-12">
                          <div class="form-group">
                            <input type="file" multiple>
                            <div class="add-gallery-text text-center"> <i class="far fa-image"></i>
                              <span class="drag-label"> Click here or drop files to upload</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="step-tab-panel" id="step3">
                <div class="tab-from-content">
                  <div class="title-icon">
                    <h5 class="title rubik fw-600"><i class="flaticon-location-pin"></i> Location</h5>
                  </div>
                  <form>
                    <div class="row">
                      <div class="col-lg-4">
                        <div class="form-group">
                          <input type="text" class="form-control form-control-custom" name="#" placeholder="Address">
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group">
                          <input type="text" class="form-control form-control-custom" name="#" placeholder="Temporary Address">
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group p-relative">
                          <select class="form-control form-control-custom">
                            <option>State</option>
                            <option>State one</option>
                            <option>State Two</option>
                            <option>State Three</option>
                            <option>State Four</option>
                            <option>State Five</option>
                            <option>State Six</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group p-relative">
                          <select class="form-control form-control-custom">
                            <option>City</option>
                            <option>City one</option>
                            <option>City Two</option>
                            <option>City Three</option>
                            <option>City Four</option>
                            <option>City Five</option>
                            <option>City Six</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group p-relative">
                          <select class="form-control form-control-custom">
                            <option>Country</option>
                            <option value="Afghanistan">Afghanistan</option>
                            <option value="Åland Islands">Åland Islands</option>
                            <option value="Albania">Albania</option>
                            <option value="Algeria">Algeria</option>
                            <option value="American Samoa">American Samoa</option>
                            <option value="Andorra">Andorra</option>
                            <option value="Angola">Angola</option>
                            <option value="Anguilla">Anguilla</option>
                            <option value="Antarctica">Antarctica</option>
                            <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                            <option value="Argentina">Argentina</option>
                            <option value="Armenia">Armenia</option>
                            <option value="Aruba">Aruba</option>
                            <option value="Australia">Australia</option>
                            <option value="Austria">Austria</option>
                            <option value="Azerbaijan">Azerbaijan</option>
                            <option value="Bahamas">Bahamas</option>
                            <option value="Bahrain">Bahrain</option>
                            <option value="Bangladesh">Bangladesh</option>
                            <option value="Barbados">Barbados</option>
                            <option value="Belarus">Belarus</option>
                            <option value="Belgium">Belgium</option>
                            <option value="Belize">Belize</option>
                            <option value="Benin">Benin</option>
                            <option value="Bermuda">Bermuda</option>
                            <option value="Bhutan">Bhutan</option>
                            <option value="Bolivia">Bolivia</option>
                            <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                            <option value="Botswana">Botswana</option>
                            <option value="Bouvet Island">Bouvet Island</option>
                            <option value="Brazil">Brazil</option>
                            <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                            <option value="Brunei Darussalam">Brunei Darussalam</option>
                            <option value="Bulgaria">Bulgaria</option>
                            <option value="Burkina Faso">Burkina Faso</option>
                            <option value="Burundi">Burundi</option>
                            <option value="Cambodia">Cambodia</option>
                            <option value="Cameroon">Cameroon</option>
                            <option value="Canada">Canada</option>
                            <option value="Cape Verde">Cape Verde</option>
                            <option value="Cayman Islands">Cayman Islands</option>
                            <option value="Central African Republic">Central African Republic</option>
                            <option value="Chad">Chad</option>
                            <option value="Chile">Chile</option>
                            <option value="China">China</option>
                            <option value="Christmas Island">Christmas Island</option>
                            <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                            <option value="Colombia">Colombia</option>
                            <option value="Comoros">Comoros</option>
                            <option value="Congo">Congo</option>
                            <option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
                            <option value="Cook Islands">Cook Islands</option>
                            <option value="Costa Rica">Costa Rica</option>
                            <option value="Cote D'ivoire">Cote D'ivoire</option>
                            <option value="Croatia">Croatia</option>
                            <option value="Cuba">Cuba</option>
                            <option value="Cyprus">Cyprus</option>
                            <option value="Czech Republic">Czech Republic</option>
                            <option value="Denmark">Denmark</option>
                            <option value="Djibouti">Djibouti</option>
                            <option value="Dominica">Dominica</option>
                            <option value="Dominican Republic">Dominican Republic</option>
                            <option value="Ecuador">Ecuador</option>
                            <option value="Egypt">Egypt</option>
                            <option value="El Salvador">El Salvador</option>
                            <option value="Equatorial Guinea">Equatorial Guinea</option>
                            <option value="Eritrea">Eritrea</option>
                            <option value="Estonia">Estonia</option>
                            <option value="Ethiopia">Ethiopia</option>
                            <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                            <option value="Faroe Islands">Faroe Islands</option>
                            <option value="Fiji">Fiji</option>
                            <option value="Finland">Finland</option>
                            <option value="France">France</option>
                            <option value="French Guiana">French Guiana</option>
                            <option value="French Polynesia">French Polynesia</option>
                            <option value="French Southern Territories">French Southern Territories</option>
                            <option value="Gabon">Gabon</option>
                            <option value="Gambia">Gambia</option>
                            <option value="Georgia">Georgia</option>
                            <option value="Germany">Germany</option>
                            <option value="Ghana">Ghana</option>
                            <option value="Gibraltar">Gibraltar</option>
                            <option value="Greece">Greece</option>
                            <option value="Greenland">Greenland</option>
                            <option value="Grenada">Grenada</option>
                            <option value="Guadeloupe">Guadeloupe</option>
                            <option value="Guam">Guam</option>
                            <option value="Guatemala">Guatemala</option>
                            <option value="Guernsey">Guernsey</option>
                            <option value="Guinea">Guinea</option>
                            <option value="Guinea-bissau">Guinea-bissau</option>
                            <option value="Guyana">Guyana</option>
                            <option value="Haiti">Haiti</option>
                            <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                            <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                            <option value="Honduras">Honduras</option>
                            <option value="Hong Kong">Hong Kong</option>
                            <option value="Hungary">Hungary</option>
                            <option value="Iceland">Iceland</option>
                            <option value="India">India</option>
                            <option value="Indonesia">Indonesia</option>
                            <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                            <option value="Iraq">Iraq</option>
                            <option value="Ireland">Ireland</option>
                            <option value="Isle of Man">Isle of Man</option>
                            <option value="Israel">Israel</option>
                            <option value="Italy">Italy</option>
                            <option value="Jamaica">Jamaica</option>
                            <option value="Japan">Japan</option>
                            <option value="Jersey">Jersey</option>
                            <option value="Jordan">Jordan</option>
                            <option value="Kazakhstan">Kazakhstan</option>
                            <option value="Kenya">Kenya</option>
                            <option value="Kiribati">Kiribati</option>
                            <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                            <option value="Korea, Republic of">Korea, Republic of</option>
                            <option value="Kuwait">Kuwait</option>
                            <option value="Kyrgyzstan">Kyrgyzstan</option>
                            <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                            <option value="Latvia">Latvia</option>
                            <option value="Lebanon">Lebanon</option>
                            <option value="Lesotho">Lesotho</option>
                            <option value="Liberia">Liberia</option>
                            <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                            <option value="Liechtenstein">Liechtenstein</option>
                            <option value="Lithuania">Lithuania</option>
                            <option value="Luxembourg">Luxembourg</option>
                            <option value="Macao">Macao</option>
                            <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
                            <option value="Madagascar">Madagascar</option>
                            <option value="Malawi">Malawi</option>
                            <option value="Malaysia">Malaysia</option>
                            <option value="Maldives">Maldives</option>
                            <option value="Mali">Mali</option>
                            <option value="Malta">Malta</option>
                            <option value="Marshall Islands">Marshall Islands</option>
                            <option value="Martinique">Martinique</option>
                            <option value="Mauritania">Mauritania</option>
                            <option value="Mauritius">Mauritius</option>
                            <option value="Mayotte">Mayotte</option>
                            <option value="Mexico">Mexico</option>
                            <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                            <option value="Moldova, Republic of">Moldova, Republic of</option>
                            <option value="Monaco">Monaco</option>
                            <option value="Mongolia">Mongolia</option>
                            <option value="Montenegro">Montenegro</option>
                            <option value="Montserrat">Montserrat</option>
                            <option value="Morocco">Morocco</option>
                            <option value="Mozambique">Mozambique</option>
                            <option value="Myanmar">Myanmar</option>
                            <option value="Namibia">Namibia</option>
                            <option value="Nauru">Nauru</option>
                            <option value="Nepal">Nepal</option>
                            <option value="Netherlands">Netherlands</option>
                            <option value="Netherlands Antilles">Netherlands Antilles</option>
                            <option value="New Caledonia">New Caledonia</option>
                            <option value="New Zealand">New Zealand</option>
                            <option value="Nicaragua">Nicaragua</option>
                            <option value="Niger">Niger</option>
                            <option value="Nigeria">Nigeria</option>
                            <option value="Niue">Niue</option>
                            <option value="Norfolk Island">Norfolk Island</option>
                            <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                            <option value="Norway">Norway</option>
                            <option value="Oman">Oman</option>
                            <option value="Pakistan">Pakistan</option>
                            <option value="Palau">Palau</option>
                            <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                            <option value="Panama">Panama</option>
                            <option value="Papua New Guinea">Papua New Guinea</option>
                            <option value="Paraguay">Paraguay</option>
                            <option value="Peru">Peru</option>
                            <option value="Philippines">Philippines</option>
                            <option value="Pitcairn">Pitcairn</option>
                            <option value="Poland">Poland</option>
                            <option value="Portugal">Portugal</option>
                            <option value="Puerto Rico">Puerto Rico</option>
                            <option value="Qatar">Qatar</option>
                            <option value="Reunion">Reunion</option>
                            <option value="Romania">Romania</option>
                            <option value="Russian Federation">Russian Federation</option>
                            <option value="Rwanda">Rwanda</option>
                            <option value="Saint Helena">Saint Helena</option>
                            <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                            <option value="Saint Lucia">Saint Lucia</option>
                            <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                            <option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
                            <option value="Samoa">Samoa</option>
                            <option value="San Marino">San Marino</option>
                            <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                            <option value="Saudi Arabia">Saudi Arabia</option>
                            <option value="Senegal">Senegal</option>
                            <option value="Serbia">Serbia</option>
                            <option value="Seychelles">Seychelles</option>
                            <option value="Sierra Leone">Sierra Leone</option>
                            <option value="Singapore">Singapore</option>
                            <option value="Slovakia">Slovakia</option>
                            <option value="Slovenia">Slovenia</option>
                            <option value="Solomon Islands">Solomon Islands</option>
                            <option value="Somalia">Somalia</option>
                            <option value="South Africa">South Africa</option>
                            <option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
                            <option value="Spain">Spain</option>
                            <option value="Sri Lanka">Sri Lanka</option>
                            <option value="Sudan">Sudan</option>
                            <option value="Suriname">Suriname</option>
                            <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                            <option value="Swaziland">Swaziland</option>
                            <option value="Sweden">Sweden</option>
                            <option value="Switzerland">Switzerland</option>
                            <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                            <option value="Taiwan, Province of China">Taiwan, Province of China</option>
                            <option value="Tajikistan">Tajikistan</option>
                            <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                            <option value="Thailand">Thailand</option>
                            <option value="Timor-leste">Timor-leste</option>
                            <option value="Togo">Togo</option>
                            <option value="Tokelau">Tokelau</option>
                            <option value="Tonga">Tonga</option>
                            <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                            <option value="Tunisia">Tunisia</option>
                            <option value="Turkey">Turkey</option>
                            <option value="Turkmenistan">Turkmenistan</option>
                            <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                            <option value="Tuvalu">Tuvalu</option>
                            <option value="Uganda">Uganda</option>
                            <option value="Ukraine">Ukraine</option>
                            <option value="United Arab Emirates">United Arab Emirates</option>
                            <option value="United Kingdom">United Kingdom</option>
                            <option value="United States">United States</option>
                            <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                            <option value="Uruguay">Uruguay</option>
                            <option value="Uzbekistan">Uzbekistan</option>
                            <option value="Vanuatu">Vanuatu</option>
                            <option value="Venezuela">Venezuela</option>
                            <option value="Viet Nam">Viet Nam</option>
                            <option value="Virgin Islands, British">Virgin Islands, British</option>
                            <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                            <option value="Wallis and Futuna">Wallis and Futuna</option>
                            <option value="Western Sahara">Western Sahara</option>
                            <option value="Yemen">Yemen</option>
                            <option value="Zambia">Zambia</option>
                            <option value="Zimbabwe">Zimbabwe</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="form-group">
                          <input type="number" class="form-control form-control-custom" name="#" placeholder="Zip Code">
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class="step-tab-panel" id="step4">
                <div class="tab-from-content">
                  <div class="title-icon">
                    <h5 class="title rubik fw-600"><i class="flaticon-price-tag"></i> Amenities</h5>
                  </div>
                  <form>
                    <div class="row">
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label class="custom-checkbox">
                            <input type="checkbox"> <span class="checkmark"></span>Coupons</label>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label class="custom-checkbox">
                            <input type="checkbox"> <span class="checkmark"></span>Smoking Allowed</label>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label class="custom-checkbox">
                            <input type="checkbox"> <span class="checkmark"></span>Accessories</label>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label class="custom-checkbox">
                            <input type="checkbox"> <span class="checkmark"></span>Camping</label>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label class="custom-checkbox">
                            <input type="checkbox"> <span class="checkmark"></span>Field Trips</label>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label class="custom-checkbox">
                            <input type="checkbox"> <span class="checkmark"></span>Getway</label>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label class="custom-checkbox">
                            <input type="checkbox"> <span class="checkmark"></span>Hot Spots</label>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div class="step-tab-panel" id="step5">
                <div class="tab-from-content">
                  <div class="title-icon">
                    <h5 class="title rubik fw-600"><i class="flaticon-check-square"></i> Confirmation</h5>
                  </div>
                  <form>
                    <div class="row">
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label class="custom-checkbox">
                            <input type="checkbox"> <span class="checkmark"></span>I Agree To Qamous's <a routerLink="/add-listing" class="text-light-black">Privacy Policy</a>
                          </label>
                        </div>
                      </div>
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label class="custom-checkbox">
                            <input type="checkbox"> <span class="checkmark"></span>I Agree To Qamous's <a routerLink="/add-listing" class="text-light-black">Terms Of Services</a>
                          </label>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="step-footer step-tab-pager">
              <button data-direction="prev" class="btn-second orange-btn">Previous</button>
              <button data-direction="next" class="btn-second orange-btn">Next</button>
              <button data-direction="finish" class="btn-second orange-btn">Preview & Save</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Adding Listing -->

  <app-footer4></app-footer4>

</div>