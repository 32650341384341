<div class="mobile-menu-second">
<app-header4></app-header4>	
<!-- Sub header -->
  <div class="sub-header-sec p-relative">
    <img src="assets/images/blog/banner/banner-4.jpg" class="img-fluid" alt="Sub header">
    <div class="sub-header-overlay"></div>
    <div class="sub-header-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-xl-12">
            <div class="sub-header-text-wrapper text-center">
              <h1 class="text-custom-white">Pricing & Packages</h1>
              <ul class="breadcrumbs">
                <li><a routerLink="/" class="text-custom-white">Home</a>
                </li>
                <li><span class="text-custom-white">Pricing & Packages</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Sub header -->
  <!-- Pricing & Packages -->
  <section class="section-padding price-package">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <!-- tabs -->
          <div class="pricing-tab">
            <ul class="nav nav-tabs">
              <li class="nav-item"> <a class="nav-link text-custom-white active" data-toggle="tab" href="#user">I'm User</a>
              </li>
              <li class="nav-item"> <a class="nav-link text-custom-white" data-toggle="tab" href="#bussiness">I'm Bussiness</a>
              </li>
            </ul>
          </div>
          <!-- tabs -->
          <!-- tab-box -->
          <div class="tab-content">
            <div id="user" class="tab-pane active">
              <div class="row">
                <div class="col-lg-4 col-md-6">
                  <div class="pricing-box">
                    <div class="pricing-header">
                      <div class="package-type">
                        <h4 class="no-margin text-custom-white">Standard</h4>
                      </div>
                      <div class="package-price">
                        <h3 class="no-margin">Free</h3>Per month</div>
                    </div>
                    <div class="pricing-content">
                      <ul class="list">
                        <li>Location Listings: <span>5</span>
                        </li>
                        <li>Duration: <span>3 months</span>
                        </li>
                        <li>Business Planning</li>
                        <li>Online Booking: <span>Yes</span>
                        </li>
                        <li>Sponsored Listings: <span>No</span>
                        </li>
                      </ul>
                    </div>
                    <div class="pricing-footer"> <a routerLink="/pricing" class="btn-second orange-btn">GET NOW</a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6">
                  <div class="pricing-box active">
                    <div class="pricing-header">
                      <div class="package-type">
                        <h4 class="no-margin text-custom-white">Silver</h4>
                      </div>
                      <div class="package-price">
                        <h3 class="no-margin">30.00 <sub>$</sub></h3>Per month</div>
                    </div>
                    <div class="pricing-content">
                      <ul class="list">
                        <li>Location Listings: <span>20</span>
                        </li>
                        <li>Duration: <span>1 Year</span>
                        </li>
                        <li>Business Planning</li>
                        <li>Online Booking: <span>Yes</span>
                        </li>
                        <li>Sponsored Listings: <span>No</span>
                        </li>
                      </ul>
                    </div>
                    <div class="pricing-footer"> <a routerLink="/pricing" class="btn-second black-btn">GET NOW</a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6">
                  <div class="pricing-box">
                    <div class="pricing-header">
                      <div class="package-type">
                        <h4 class="no-margin text-custom-white">Golden</h4>
                      </div>
                      <div class="package-price">
                        <h3 class="no-margin">60.00 <sub>$</sub></h3>Per month</div>
                    </div>
                    <div class="pricing-content">
                      <ul class="list">
                        <li>Location Listings: <span>100</span>
                        </li>
                        <li>Duration: <span>5 Year</span>
                        </li>
                        <li>Business Planning</li>
                        <li>Online Booking: <span>Yes</span>
                        </li>
                        <li>Sponsored Listings: <span>Yes</span>
                        </li>
                      </ul>
                    </div>
                    <div class="pricing-footer"> <a routerLink="/pricing" class="btn-second orange-btn">GET NOW</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="bussiness" class="tab-pane fade">
              <div class="row">
                <div class="col-lg-4 col-md-6">
                  <div class="pricing-box">
                    <div class="pricing-header">
                      <div class="package-type">
                        <h4 class="no-margin text-custom-white">Standard</h4>
                      </div>
                      <div class="package-price">
                        <h3 class="no-margin">Free</h3>Per month</div>
                    </div>
                    <div class="pricing-content">
                      <ul class="list">
                        <li>Location Listings: <span>5</span>
                        </li>
                        <li>Duration: <span>3 months</span>
                        </li>
                        <li>Business Planning</li>
                        <li>Online Booking: <span>Yes</span>
                        </li>
                        <li>Sponsored Listings: <span>No</span>
                        </li>
                      </ul>
                    </div>
                    <div class="pricing-footer"> <a routerLink="/pricing" class="btn-second orange-btn">GET NOW</a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6">
                  <div class="pricing-box">
                    <div class="pricing-header">
                      <div class="package-type">
                        <h4 class="no-margin text-custom-white">Silver</h4>
                      </div>
                      <div class="package-price">
                        <h3 class="no-margin">30.00 <sub>$</sub></h3>Per month</div>
                    </div>
                    <div class="pricing-content">
                      <ul class="list">
                        <li>Location Listings: <span>20</span>
                        </li>
                        <li>Duration: <span>1 Year</span>
                        </li>
                        <li>Business Planning</li>
                        <li>Online Booking: <span>Yes</span>
                        </li>
                        <li>Sponsored Listings: <span>No</span>
                        </li>
                      </ul>
                    </div>
                    <div class="pricing-footer"> <a routerLink="/pricing" class="btn-second orange-btn">GET NOW</a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-6">
                  <div class="pricing-box active">
                    <div class="pricing-header">
                      <div class="package-type">
                        <h4 class="no-margin text-custom-white">Golden</h4>
                      </div>
                      <div class="package-price">
                        <h3 class="no-margin">60.00 <sub>$</sub></h3>Per month</div>
                    </div>
                    <div class="pricing-content">
                      <ul class="list">
                        <li>Location Listings: <span>100</span>
                        </li>
                        <li>Duration: <span>5 Year</span>
                        </li>
                        <li>Business Planning</li>
                        <li>Online Booking: <span>Yes</span>
                        </li>
                        <li>Sponsored Listings: <span>Yes</span>
                        </li>
                      </ul>
                    </div>
                    <div class="pricing-footer"> <a routerLink="/pricing" class="btn-second black-btn">GET NOW</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- tab-box -->
        </div>
      </div>
      <div class="right-section-car-box">
        <div class="row">
          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
            <div class="car-grid-layout-box">
              <div class="car-grid-layout-inner text-center">
                <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">Acura TLX </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
                <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
                <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p> <span class="text-orange price-text">$25,000</span>
                <div class="car-grid-layout-img">
                  <img src="assets/images/showcar5.jpg" class="img-fluid full-width" alt="Car img">
                </div>
                <div class="car-grid-layout-details"> <span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 20,000 Km</span>
                  <span class="text-dark-white p-relative"><i class="icon-car"></i> 2010 Model</span>
                </div>
                <div class="car-grid-layout-location">  <span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> New York</span>
                  <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Automatic</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
            <div class="car-grid-layout-box">
              <div class="car-grid-layout-inner text-center">
                <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">BMW 4 Series </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
                <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
                <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p> <span class="text-orange price-text">$25,000</span>
                <div class="car-grid-layout-img">
                  <img src="assets/images/showcar3.jpg" class="img-fluid full-width" alt="Car img">
                </div>
                <div class="car-grid-layout-details"> <span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 20,000 Km</span>
                  <span class="text-dark-white p-relative"><i class="icon-car"></i> 2010 Model</span>
                </div>
                <div class="car-grid-layout-location">  <span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> New York</span>
                  <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Automatic</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
            <div class="car-grid-layout-box">
              <div class="car-grid-layout-inner text-center">
                <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">Audi A4 </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
                <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
                <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p> <span class="text-orange price-text">$25,000</span>
                <div class="car-grid-layout-img">
                  <img src="assets/images/showcar7.jpg" class="img-fluid full-width" alt="Car img">
                </div>
                <div class="car-grid-layout-details"> <span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 20,000 Km</span>
                  <span class="text-dark-white p-relative"><i class="icon-car"></i> 2010 Model</span>
                </div>
                <div class="car-grid-layout-location">  <span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> New York</span>
                  <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Automatic</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-12">
            <div class="car-grid-layout-box">
              <div class="car-grid-layout-inner text-center">
                <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">Infiniti Q50 </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
                <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
                <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p> <span class="text-orange price-text">$25,000</span>
                <div class="car-grid-layout-img">
                  <img src="assets/images/showcar2.jpg" class="img-fluid full-width" alt="Car img">
                </div>
                <div class="car-grid-layout-details"> <span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 20,000 Km</span>
                  <span class="text-dark-white p-relative"><i class="icon-car"></i> 2010 Model</span>
                </div>
                <div class="car-grid-layout-location">  <span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> New York</span>
                  <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Automatic</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Pricing & Packages -->
	<app-footer4></app-footer4>
</div>