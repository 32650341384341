<div class="side-bar">
            <!-- social -->
            <div class="sidebar-widget">
              <h4 class="text-light-black widget-title"><i class="flaticon-share"></i> Follow Us</h4>
              <div class="widget-content">
                <div class="social-profile-box-wrapper">
                  <div class="social-profile-box">
                    <a href="javascript:void(0)" target="_blank" class="icon"> <span class="social-icon facebook">
                        <i class="fab fa-facebook-f"></i>
                      </span>
                      <span class="count">30.2k</span>
                      <span class="social-type">Likes</span>
                    </a>
                  </div>
                  <div class="social-profile-box">
                    <a href="javascript:void(0)" target="_blank" class="icon"> <span class="social-icon google-plus">
                        <i class="fab fa-google-plus-g"></i>
                      </span>
                      <span class="count">10.2k</span>
                      <span class="social-type">Followers</span>
                    </a>
                  </div>
                  <div class="social-profile-box">
                    <a href="javascript:void(0)" target="_blank" class="icon"> <span class="social-icon twitter">
                        <i class="fab fa-twitter"></i>
                      </span>
                      <span class="count">62.4k</span>
                      <span class="social-type">Followers</span>
                    </a>
                  </div>
                  <div class="social-profile-box">
                    <a href="javascript:void(0)" target="_blank" class="icon"> <span class="social-icon youtube">
                        <i class="fab fa-youtube"></i>
                      </span>
                      <span class="count">30.2k</span>
                      <span class="social-type">Subscribe</span>
                    </a>
                  </div>
                  <div class="social-profile-box">
                    <a href="javascript:void(0)" target="_blank" class="icon"> <span class="social-icon instagram">
                        <i class="fab fa-instagram"></i>
                      </span>
                      <span class="count">30.2k</span>
                      <span class="social-type">Likes</span>
                    </a>
                  </div>
                  <div class="social-profile-box">
                    <a href="javascript:void(0)" target="_blank" class="icon"> <span class="social-icon vimeo">
                        <i class="fab fa-vimeo-v"></i>
                      </span>
                      <span class="count">10.4k</span>
                      <span class="social-type">Followers</span>
                    </a>
                  </div>
                  <div class="social-profile-box">
                    <a href="javascript:void(0)" target="_blank" class="icon"> <span class="social-icon pinterest">
                        <i class="fab fa-pinterest"></i>
                      </span>
                      <span class="count">10.2k</span>
                      <span class="social-type">Followers</span>
                    </a>
                  </div>
                  <div class="social-profile-box">
                    <a href="javascript:void(0)" target="_blank" class="icon"> <span class="social-icon soundcloud">
                        <i class="fab fa-soundcloud"></i>
                      </span>
                      <span class="count">5.2k</span>
                      <span class="social-type">Followers</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <!-- social -->
            <!-- Popular News -->
            <div class="sidebar-widget">
              <h4 class="text-light-black widget-title"><i class="flaticon-checklist"></i> Popular News</h4>
              <div class="widget-content">
                <!--./ thumb-wrap -->
                <article class="side-post">
                  <div class="thumb-img">
                    <a routerLink="/blog-standard">
                      <img src="assets/images/blog/sidebar/blog-1.jpg" alt="thumb-img">
                    </a>  <span class="count">1</span>
                  </div>
                  <div class="content-wrap">
                    <div class="entry-meta-content">
                      <div class="entry-date"> <span class="text-dark-white">In 25 December 2020</span>
                      </div>
                    </div>
                    <h6 class="entry-title no-margin">
                                          <a routerLink="/blog-standard" class="text-light-black">the love of power is the demon of men & women</a>
                                        </h6>
                  </div>
                </article>
                <!--./ thumb-wrap -->
                <!--./ thumb-wrap -->
                <article class="side-post">
                  <div class="thumb-img">
                    <a routerLink="/blog-standard">
                      <img src="assets/images/blog/sidebar/blog-2.jpg" alt="thumb-img">
                    </a>  <span class="count">2</span>
                  </div>
                  <div class="content-wrap">
                    <div class="entry-meta-content">
                      <div class="entry-date"> <span class="text-dark-white">In 25 December 2020</span>
                      </div>
                    </div>
                    <h6 class="entry-title no-margin">
                                          <a routerLink="/blog-standard" class="text-light-black">The PC has improved the world in just about every area</a>
                                        </h6>
                  </div>
                </article>
                <!--./ thumb-wrap -->
                <!--./ thumb-wrap -->
                <article class="side-post">
                  <div class="thumb-img">
                    <a routerLink="/blog-standard">
                      <img src="assets/images/blog/sidebar/blog-3.jpg" alt="thumb-img">
                    </a>  <span class="count">3</span>
                  </div>
                  <div class="content-wrap">
                    <div class="entry-meta-content">
                      <div class="entry-date"> <span class="text-dark-white">In 25 December 2020</span>
                      </div>
                    </div>
                    <h6 class="entry-title no-margin">
                                          <a routerLink="/blog-standard" class="text-light-black">Life is hard, and a lot of people come home tired from work</a>
                                        </h6>
                  </div>
                </article>
                <!--./ thumb-wrap -->
                <!--./ thumb-wrap -->
                <article class="side-post">
                  <div class="thumb-img">
                    <a routerLink="/blog-standard">
                      <img src="assets/images/blog/sidebar/blog-4.jpg" alt="thumb-img">
                    </a>  <span class="count">4</span>
                  </div>
                  <div class="content-wrap">
                    <div class="entry-meta-content">
                      <div class="entry-date"> <span class="text-dark-white">In 25 December 2020</span>
                      </div>
                    </div>
                    <h6 class="entry-title no-margin">
                                          <a routerLink="/blog-standard" class="text-light-black">the love of power is the demon of men & women</a>
                                        </h6>
                  </div>
                </article>
                <!--./ thumb-wrap -->
              </div>
            </div>
            <!-- Popular News -->
            <!-- tags -->
            <div class="sidebar-widget">
              <h4 class="text-light-black widget-title"><i class="flaticon-price-tag"></i> Tags</h4>
              <div class="widget-content">
                <div class="tag-cloud">
                  <a href="javascript:void(0)">#luxury car</a>
                  <a href="javascript:void(0)">#excutive car</a>
                  <a href="javascript:void(0)">#economy car</a>
                  <a href="javascript:void(0)">#sports car</a>
                  <a href="javascript:void(0)">#vintage car</a>
                </div>
              </div>
            </div>
            <!-- tags -->
            <!-- Instagram Gallery -->
            <div class="sidebar-widget">
              <h4 class="text-light-black widget-title"><i class="flaticon-review"></i> Instagram Gallery</h4>
              <div class="widget-content">
                <ul class="instagram-img">
                  <li class="insta-item">
                    <a href="javascript:void(0)">
                      <img src="assets/images/blog/sidebar/blog-1.jpg" class="img-fluid full-width" alt="instagram post">
                    </a>
                  </li>
                  <li class="insta-item">
                    <a href="javascript:void(0)">
                      <img src="assets/images/blog/sidebar/blog-2.jpg" class="img-fluid full-width" alt="instagram post">
                    </a>
                  </li>
                  <li class="insta-item">
                    <a href="javascript:void(0)">
                      <img src="assets/images/blog/sidebar/blog-3.jpg" class="img-fluid full-width" alt="instagram post">
                    </a>
                  </li>
                  <li class="insta-item">
                    <a href="javascript:void(0)">
                      <img src="assets/images/blog/sidebar/blog-4.jpg" class="img-fluid full-width" alt="instagram post">
                    </a>
                  </li>
                  <li class="insta-item">
                    <a href="javascript:void(0)">
                      <img src="assets/images/blog/sidebar/blog-5.jpg" class="img-fluid full-width" alt="instagram post">
                    </a>
                  </li>
                  <li class="insta-item">
                    <a href="javascript:void(0)">
                      <img src="assets/images/blog/sidebar/blog-6.jpg" class="img-fluid full-width" alt="instagram post">
                    </a>
                  </li>
                  <li class="insta-item">
                    <a href="javascript:void(0)">
                      <img src="assets/images/blog/sidebar/blog-7.jpg" class="img-fluid full-width" alt="instagram post">
                    </a>
                  </li>
                  <li class="insta-item">
                    <a href="javascript:void(0)">
                      <img src="assets/images/blog/sidebar/blog-8.jpg" class="img-fluid full-width" alt="instagram post">
                    </a>
                  </li>
                  <li class="insta-item">
                    <a href="javascript:void(0)">
                      <img src="assets/images/blog/sidebar/blog-9.jpg" class="img-fluid full-width" alt="instagram post">
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <!-- Instagram Gallery -->
          </div>