<div class="mobile-menu-second">
<div id="banner-adv">
    <a routerLink="/car-page">
      <img src="assets/images/highbanner.jpg" class="img-fluid" alt="advertisement"> <span class="text">MID MONTH SALE USE CODE: <strong>SALE50</strong></span>
    </a> <span class="close-banner"></span>
  </div>
<app-header4></app-header4>
  <!-- Sub header -->
  <div class="sub-header-sec p-relative">
    <img src="assets/images/blog/banner/banner-1.jpg" class="img-fluid" alt="Sub header">
    <div class="sub-header-overlay"></div>
    <div class="sub-header-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-xl-12">
            <div class="sub-header-text-wrapper text-center">
              <h1 class="text-custom-white">About Us</h1>
              <ul class="breadcrumbs">
                <li><a routerLink="/" class="text-custom-white">Home</a>
                </li>
                <li><a routerLink="/car-page" class="text-custom-white">Pages</a>
                </li>
                <li><span class="text-custom-white">Car Page</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Sub header -->
  <!-- skew-banner -->
  <div class="p-relative skew-banner-sec">
    <div class="skew-banner"></div>
    <div class="large-car-img">
      <div class="container">
        <div class="row">
          <div class="col-12 p-relative">
            <h2 class="text-custom-white car-title">Rims v2 811</h2>
            <img src="assets/images/car-lg.png" class="img-fluid" alt="#">
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- skew-banner -->
  <!-- testimonial -->
  <div class="section-padding-b0 testimonials">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="testimonial-slider">
            <div class="testimonial-wrapper">
              <div class="text-wrap">
                <blockquote>
                  <p>AutoImage is nisi aliquip consequat duis velit esse cillum dolore fugiat nulla pariatur excepteur sint occaecat lorem ipsum dolor sit amt consectetur adipisicing elit sed eiusmod tempor incididuntu labore et dolore magna aliqua enimad minim veniam quis nostrud exercitation sed ipsum ullamco laboris nisi aliquip.</p>
                  <div class="infor"> <cite class="name text-orange">John Stephan</cite>
                    <cite class="position text-dark-white">Vehicle Buyer</cite>
                  </div>
                </blockquote>
              </div>
            </div>
            <div class="testimonial-wrapper">
              <div class="text-wrap">
                <blockquote>
                  <p>No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally.</p>
                  <div class="infor"> <cite class="name text-orange">Nick William</cite>
                    <cite class="position text-dark-white">Customer</cite>
                  </div>
                </blockquote>
              </div>
            </div>
            <div class="testimonial-wrapper">
              <div class="text-wrap">
                <blockquote>
                  <p>Our support and response has been amazing, helping me with several issues I came across and got them solved almost the same day. A pleasure to work with them!</p>
                  <div class="infor"> <cite class="name text-orange">Smith John</cite>
                    <cite class="position text-dark-white">Happy Customer</cite>
                  </div>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- testimonial -->
  <!-- Car Models -->
  <section class="section-padding car-model-sec">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="section-header">
            <div class="text-wrapper text-center">
              <h3 class="fw-500 no-margin">Discover Our Featured Models</h3>
              <span class="line-grey"></span><span class="line-orange"></span><span class="line-grey"></span> 
              <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort. A masterful combination of style, power, sporty handling and comfort.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="right-section-car-box">
        <div class="row">
          <div class="col-lg-4 col-md-6">
            <div class="car-grid-layout-box">
              <div class="car-grid-layout-inner text-center">
                <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">Acura TLX </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
                <h6 class="text-light-black text-uppercase mb-2">Lorem ipsum dolor sit amet</h6>
                <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p> <span class="text-orange price-text">$25,000</span>
                <div class="car-grid-layout-img">
                  <img src="assets/images/showcar2.jpg" class="img-fluid full-width" alt="Car img">
                </div>
                <div class="car-grid-layout-details"> <span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 20,000 Km</span>
                  <span class="text-dark-white p-relative"><i class="icon-car"></i> 2010 Model</span>
                </div>
                <div class="car-grid-layout-location">  <span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> New York</span>
                  <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Automatic</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="car-grid-layout-box">
              <div class="car-grid-layout-inner text-center">
                <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">BMW 4 Series </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
                <h6 class="text-light-black text-uppercase mb-2">Excepteur sint occaecat cupidatat</h6>
                <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p> <span class="text-orange price-text">$25,000</span>
                <div class="car-grid-layout-img">
                  <img src="assets/images/showcar1.jpg" class="img-fluid full-width" alt="Car img">
                </div>
                <div class="car-grid-layout-details"> <span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 20,000 Km</span>
                  <span class="text-dark-white p-relative"><i class="icon-car"></i> 2010 Model</span>
                </div>
                <div class="car-grid-layout-location">  <span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> New York</span>
                  <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Automatic</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-md-6">
            <div class="car-grid-layout-box">
              <div class="car-grid-layout-inner text-center">
                <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">Audi A4 </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
                <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
                <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p> <span class="text-orange price-text">$25,000</span>
                <div class="car-grid-layout-img">
                  <img src="assets/images/showcar3.jpg" class="img-fluid full-width" alt="Car img">
                </div>
                <div class="car-grid-layout-details"> <span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 20,000 Km</span>
                  <span class="text-dark-white p-relative"><i class="icon-car"></i> 2010 Model</span>
                </div>
                <div class="car-grid-layout-location">  <span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> New York</span>
                  <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Automatic</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Car Models -->
  <!-- newsletter -->
  <div class="newsletter p-relative section-padding">
    <div class="container">
      <div class="row justify-content-md-center">
        <div class="col-lg-8">
          <div class="content-wrapper text-center">
            <h3 class="text-custom-white rubik">Sign up and get <strong>10% OFF</strong> your first order</h3>
            <div class="input-group">
              <input type="text" class="form-control form-control-custom" placeholder="Enter your Email I'd">
              <div class="input-group-append">
                <button class="btn-second orange-btn" type="submit">SUBSCRIBE</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- newsletter -->
	<app-footer4></app-footer4>
</div>