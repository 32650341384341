<!-- footer -->
  <footer class="bg-custom-black" id="footer-style-3">
    <div class="container-fluid custom-container">
      <div class="row">
        <div class="col-12">
          <div class="select-box">
            <div class="row justify-content-md-center">
              <div class="col-12">
                <label class="text-custom-white">Change country/region:</label>
              </div>
              <div class="col-lg-3 col-md-6">
                <div class="input-group bg-white">
                  <input type="text" class="form-control form-control-custom" placeholder="Search">
                  <div class="input-group-append">
                    <button class="btn-second orange-btn" type="submit"><i class="flaticon-search"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 col-md-6">
                <select class="form-control form-control-custom">
                  <option selected>Select a region</option>
                  <option>North America</option>
                  <option>Latin America</option>
                  <option>Europe</option>
                  <option>Middle East</option>
                  <option>Africa</option>
                  <option>Asia</option>
                  <option>Australia/Oceania</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="copyright">
        <div class="row align-self-center no-use-row">
          <div class="col-md-12">
            <p class="text-custom-white">© 2020 Rims Cars North America, Inc. <a href="javascript:void(0)" class="text-custom-white">Legal notice.</a><a href="javascript:void(0)" class="text-custom-white">Privacy policy.</a>
            </p>
            <div class="ft-btns"> <a href="javascript:void(0)" class="btn-second black-btn">Recall information</a>
              <a routerLink="/contact" class="btn-second black-btn">Contact Us</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
  <!-- footer -->