<div class="mobile-menu-second">
	<app-header4></app-header4>
<!-- User Details -->
  <div class="user-page-sec p-relative car-details">
    <div class="car-details-slider">
      <div class="slider-item">
        <img src="assets/images/cardetails/cardetails-1.jpg" class="img-fluid full-width" alt="#">
      </div>
      <div class="slider-item">
        <img src="assets/images/cardetails/cardetails-2.jpg" class="img-fluid full-width" alt="#">
      </div>
      <div class="slider-item">
        <img src="assets/images/cardetails/cardetails-3.jpg" class="img-fluid full-width" alt="#">
      </div>
      <div class="slider-item">
        <img src="assets/images/cardetails/cardetails-4.jpg" class="img-fluid full-width" alt="#">
      </div>
    </div>
    <div class="slider-contant-wrapper offset-lg-1 col-lg-4 col-md-6">
      <div class="row">
        <div class="col-md-12">
          <div class="slider-contant">
            <div class="content-box">
              <h1 class="text-custom-white">BMW 535 v6</h1>
              <p class="text-custom-white"><i class="fas fa-map-marker-alt"></i> 198 West 21th Street, Suite 721 NewYork, NY 10010</p>
              <div class="social-share">  <span>Share: </span>
                <div class="user-social-links"> <a routerLink="/car-detail" class="facebook"><i class="fab fa-facebook-square"></i></a>
                  <a routerLink="/car-detail" class="twitter"><i class="fab fa-twitter"></i></a>
                  <a routerLink="/car-detail" class="pintrest"><i class="fab fa-pinterest-square"></i></a>
                </div>
              </div>
            </div>
            <div class="price-btn"> <span>$ 50,000</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="user-page-details-sec">
      <div class="container-fluid">
        <div class="row">
          <div class="offset-lg-1 col-lg-10">
            <div class="user-about">
              <div class="row">
                <div class="col-md-6">
                  <div class="user-left-wrapper">
                    <div class="user-img">
                      <img src="assets/images/cardetails/user-1.jpg" class="rounded-circle" alt="#">
                    </div>
                    <div class="user-details">
                      <div class="user-details-top">
                        <h5 class="title text-custom-white">Jhon Deo <span><i class="fas fa-check"></i></span></h5>
                        <span><a routerLink="/car-detail" class="text-custom-white"><i class="fas fa-map-marker-alt"></i> New York</a></span>
                        <span><a routerLink="/car-detail" class="text-custom-white"><i class="fas fa-link"></i> @jhon_deo</a></span>
                      </div>
                      <div class="user-social-links"> <a routerLink="/car-detail" class="facebook"><i class="fab fa-facebook-square"></i></a>
                        <a routerLink="/car-detail" class="twitter"><i class="fab fa-twitter"></i></a>
                        <a routerLink="/car-detail" class="pintrest"><i class="fab fa-pinterest-square"></i></a>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="user-right-wrapper">
                    <div class="star-rating"> <span class="text-yellow"><i class="fas fa-star"></i></span>
                      <span class="text-yellow"><i class="fas fa-star"></i></span>
                      <span class="text-yellow"><i class="fas fa-star"></i></span>
                      <span class="text-yellow"><i class="fas fa-star"></i></span>
                      <span class="text-yellow"><i class="fas fa-star-half-alt"></i></span>
                      <ul>
                        <li class="text-custom-white">5.00 average</li>
                        <li><a routerLink="/car-detail" class="text-custom-white">3 Reviews</a> 
                        </li>
                      </ul>
                    </div>
                    <div class="comment-details">
                      <ul>
                        <li class="text-dark-white">21 <span>Sold Car</span>
                        </li>
                        <li class="text-dark-white">3 <span>Top Sales</span>
                        </li>
                        <li class="text-dark-white">1 <span>Latest Sale</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="space-85"></div>
  </div>
  <!-- User Details -->
  <!-- User page bottom -->
  <div class="listing-with-map car-details">
    <div class="container">
      <div class="row no-use-row">
        <div class="col-lg-8">
          <div class="page-title">
            <h3 class="text-light-black">Ghibli Versions</h3> 
            <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.A masterful combination of style, power, sporty handling and comfort.</p>
          </div>
          <div class="car-details-tab">
            <ul class="nav nav-tabs">
              <li class="nav-item"> <a class="nav-link" data-toggle="tab" href="#vehicle-overview">Vehicle Overview</a>
              </li>
              <li class="nav-item"> <a class="nav-link active" data-toggle="tab" href="#tech-spec">Technical Specification</a>
              </li>
              <li class="nav-item"> <a class="nav-link" data-toggle="tab" href="#accessories">Accessories</a>
              </li>
              <li class="nav-item"> <a class="nav-link" data-toggle="tab" href="#performance">Performance</a>
              </li>
            </ul>
            <div class="tab-content">
              <div class="tab-pane fade" id="vehicle-overview">
                <div class="car-details-box">
                  <div class="text-wrapper">
                    <h4 class="text-light-black">What is Lorem Ipsum?</h4>
                    <p class="text-dark-grey">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                    <p class="text-dark-grey">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                  </div>
                  <div class="text-wrapper">
                    <h4 class="text-light-black">What is Lorem Ipsum?</h4>
                    <p class="text-dark-grey">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                  </div>
                </div>
              </div>
              <div class="tab-pane active" id="tech-spec">
                <div class="car-details-box">
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th colspan="2">BASIC INFO</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Model Year</td>
                          <td>2010</td>
                        </tr>
                        <tr>
                          <td>No. of Owners</td>
                          <td>4</td>
                        </tr>
                        <tr>
                          <td>KMs Driven</td>
                          <td>30,000</td>
                        </tr>
                        <tr>
                          <td>Fuel Type</td>
                          <td>Diesel</td>
                        </tr>
                      </tbody>
                    </table>
                    <table class="table table-bordered">
                      <thead>
                        <tr>
                          <th colspan="2">Technical Specification</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Engine Type</td>
                          <td>TDCI Diesel Engine</td>
                        </tr>
                        <tr>
                          <td>Engine Description</td>
                          <td>1.5KW</td>
                        </tr>
                        <tr>
                          <td>No. of Cylinders</td>
                          <td>4</td>
                        </tr>
                        <tr>
                          <td>Mileage-City</td>
                          <td>22.4kmpl</td>
                        </tr>
                        <tr>
                          <td>Mileage-Highway</td>
                          <td>25.83kmpl</td>
                        </tr>
                        <tr>
                          <td>Fuel Tank Capacity</td>
                          <td>40 (Liters)</td>
                        </tr>
                        <tr>
                          <td>Seating Capacity</td>
                          <td>5</td>
                        </tr>
                        <tr>
                          <td>Transmission Type</td>
                          <td>Manual</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="accessories">
                <div class="car-details-box">
                  <div class="table-responsive">
                    <table>
                      <thead>
                        <tr>
                          <th colspan="2">Accessories</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Air Conditioner</td>
                          <td><i class="fas fa-check" aria-hidden="true"></i>
                          </td>
                        </tr>
                        <tr>
                          <td>AntiLock Braking System</td>
                          <td><i class="fas fa-check" aria-hidden="true"></i>
                          </td>
                        </tr>
                        <tr>
                          <td>Power Steering</td>
                          <td><i class="fas fa-check" aria-hidden="true"></i>
                          </td>
                        </tr>
                        <tr>
                          <td>Power Windows</td>
                          <td><i class="fas fa-check" aria-hidden="true"></i>
                          </td>
                        </tr>
                        <tr>
                          <td>CD Player</td>
                          <td><i class="fas fa-times" aria-hidden="true"></i>
                          </td>
                        </tr>
                        <tr>
                          <td>Leather Seats</td>
                          <td><i class="fas fa-check" aria-hidden="true"></i>
                          </td>
                        </tr>
                        <tr>
                          <td>Central Locking</td>
                          <td><i class="fas fa-check" aria-hidden="true"></i>
                          </td>
                        </tr>
                        <tr>
                          <td>Power Door Locks</td>
                          <td><i class="fas fa-check" aria-hidden="true"></i>
                          </td>
                        </tr>
                        <tr>
                          <td>Brake Assist</td>
                          <td><i class="fas fa-check" aria-hidden="true"></i>
                          </td>
                        </tr>
                        <tr>
                          <td>Driver Airbag</td>
                          <td><i class="fas fa-check" aria-hidden="true"></i>
                          </td>
                        </tr>
                        <tr>
                          <td>Passenger Airbag</td>
                          <td><i class="fas fa-check" aria-hidden="true"></i>
                          </td>
                        </tr>
                        <tr>
                          <td>Crash Sensor</td>
                          <td><i class="fas fa-check" aria-hidden="true"></i>
                          </td>
                        </tr>
                        <tr>
                          <td>Engine Check Warning</td>
                          <td><i class="fas fa-check" aria-hidden="true"></i>
                          </td>
                        </tr>
                        <tr>
                          <td>Automatic Headlamps</td>
                          <td><i class="fas fa-check" aria-hidden="true"></i>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="performance">
                <div class="car-details-box">
                  <div class="text-wrapper">
                    <h4 class="text-light-black">What is Lorem Ipsum?</h4>
                    <p class="text-dark-grey">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                    <p class="text-dark-grey">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
                  </div>
                  <div class="text-wrapper">
                    <h4 class="text-light-black">What is Lorem Ipsum?</h4>
                    <p class="text-dark-grey">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="image-slider-sec">
            <div class="car-detail-slider-for">
              <div class="slide-item">
                <img src="assets/images/cardetails/gallery-1.jpg" class="img-fluid full-width" alt="#">
              </div>
              <div class="slide-item">
                <img src="assets/images/cardetails/gallery-2.jpg" class="img-fluid full-width" alt="#">
              </div>
              <div class="slide-item">
                <img src="assets/images/cardetails/gallery-3.jpg" class="img-fluid full-width" alt="#">
              </div>
              <div class="slide-item">
                <img src="assets/images/cardetails/gallery-4.jpg" class="img-fluid full-width" alt="#">
              </div>
              <div class="slide-item">
                <img src="assets/images/cardetails/gallery-6.jpg" class="img-fluid full-width" alt="#">
              </div>
              <div class="slide-item">
                <img src="assets/images/cardetails/gallery-2.jpg" class="img-fluid full-width" alt="#">
              </div>
            </div>
            <div class="car-detail-slider-nav">
              <div class="slide-item">
                <img src="assets/images/cardetails/gallery-1a.jpg" class="img-fluid full-width" alt="#">
              </div>
              <div class="slide-item">
                <img src="assets/images/cardetails/gallery-2a.jpg" class="img-fluid full-width" alt="#">
              </div>
              <div class="slide-item">
                <img src="assets/images/cardetails/gallery-3a.jpg" class="img-fluid full-width" alt="#">
              </div>
              <div class="slide-item">
                <img src="assets/images/cardetails/gallery-6a.jpg" class="img-fluid full-width" alt="#">
              </div>
              <div class="slide-item">
                <img src="assets/images/cardetails/gallery-5a.jpg" class="img-fluid full-width" alt="#">
              </div>
              <div class="slide-item">
                <img src="assets/images/cardetails/gallery-6a.jpg" class="img-fluid full-width" alt="#">
              </div>
            </div>
          </div>
          <div class="contant-wrapper">
            <h4 class="text-light-black">What is Lorem Ipsum?</h4>
            <p class="text-dark-grey">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
            <p class="text-dark-grey">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>
          </div>
          <div class="contant-wrapper">
            <h4 class="text-light-black">What is Lorem Ipsum?</h4>
            <p class="text-dark-grey">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable.</p>
          </div>
          <div class="video-wrapper">
            <h4 class="text-light-black">Watch Video</h4>
            <div class="video_wrapper video_wrapper_full js-videoWrapper">
              <iframe class="videoIframe js-videoIframe" src="https://www.youtube.com/embed/pbiEfJd2Hpw" data-src="https://www.youtube.com/embed/pbiEfJd2Hpw?autoplay=1" allow="autoplay"></iframe>
              <div class="videoPoster js-videoPoster">
                <img src="assets/images/cardetails/video-1.jpg" class="img-fluid full-width" alt="#">
                <div class="video-inner"> <i class="flaticon-triangle"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="product-reviews">
            <h4 class="text-light-black comment-reply-title">2 Reviews for Rims</h4>
            <div class="reviews-section">
              <article class="review-post">
                <div class="review-box">
                  <div class="review-thumbnail">
                    <a routerLink="/car-detail">
                      <img src="assets/images/cardetails/user-2.jpg" alt="#">
                    </a>
                  </div>
                  <div class="user-name">
                    <h6><a routerLink="/car-detail" class="text-light-black">Jhon</a></h6>
                    <p class="text-dark-white"><i class="fas fa-star text-yellow"></i> 36 Reviews</p>
                  </div>
                </div>
                <section class="details">
                  <div class="top-section">
                    <h5 class="text-light-black rubik mb-1 fw-600">I loved there Cars.</h5>
                    <span class="text-dark-grey">November 15, 2020 5:30 Pm</span>
                    <div class="review-count">
                      <div class="rating">  <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="far fa-star"></i> 
                      </div>  <span class="rate">4<sup>/ 5</sup></span> 
                    </div>
                  </div>
                  <div class="comment-box">
                    <p class="text-dark-grey">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                    <div class="img-slider-reviews">
                      <a routerLink="/car-detail">
                        <img src="assets/images/cardetails/290x213/comment-5.jpg" class="img-fluid full-width" alt="#">
                      </a>
                      <a routerLink="/car-detail">
                        <img src="assets/images/cardetails/290x213/comment-4.jpg" class="img-fluid full-width" alt="#">
                      </a>
                      <a routerLink="/car-detail">
                        <img src="assets/images/cardetails/290x213/comment-1.jpg" class="img-fluid full-width" alt="#">
                      </a>
                      <a routerLink="/car-detail">
                        <img src="assets/images/cardetails/290x213/comment-3.jpg" class="img-fluid full-width" alt="#">
                      </a>
                      <a routerLink="/car-detail">
                        <img src="assets/images/cardetails/290x213/comment-6.jpg" class="img-fluid full-width" alt="#">
                      </a>
                      <a routerLink="/car-detail">
                        <img src="assets/images/cardetails/290x213/comment-2.jpg" class="img-fluid full-width" alt="#">
                      </a>
                    </div>
                    <div class="comment-footer">  <span class="text-dark-white">Was This Review ...?</span>
                      <ul>
                        <li>  <a routerLink="/car-detail" class="interesting"><i class="far fa-thumbs-up"></i> Interesting <span class="text-dark-white">220</span></a>
                        </li>
                        <li>  <a routerLink="/car-detail" class="lol"><i class="far fa-laugh"></i> Lol <span class="text-dark-white">150</span></a>
                        </li>
                        <li>  <a routerLink="/car-detail" class="love"><i class="far fa-heart"></i> Love <span class="text-dark-white">500</span></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </section>
              </article>
              <article class="review-post">
                <div class="review-box">
                  <div class="review-thumbnail">
                    <a routerLink="/car-detail">
                      <img src="assets/images/cardetails/user-3.jpg" alt="#">
                    </a>
                  </div>
                  <div class="user-name">
                    <h6><a routerLink="/car-detail" class="text-light-black">Deo</a></h6>
                    <p class="text-dark-white"><i class="fas fa-star text-yellow"></i> 20 Reviews</p>
                  </div>
                </div>
                <section class="details">
                  <div class="top-section">
                    <h5 class="text-light-black rubik mb-1 fw-600">Amazing Cars</h5>
                    <span class="text-dark-grey">October 18, 2020 7:26 Pm</span>
                    <div class="review-count">
                      <div class="rating">  <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i> 
                      </div>  <span class="rate">5<sup>/ 5</sup></span> 
                    </div>
                  </div>
                  <div class="comment-box">
                    <p class="text-dark-grey">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
                    <div class="img-slider-reviews">
                      <a routerLink="/car-detail">
                        <img src="assets/images/cardetails/290x213/comment-1.jpg" class="img-fluid full-width" alt="#">
                      </a>
                      <a routerLink="/car-detail">
                        <img src="assets/images/cardetails/290x213/comment-2.jpg" class="img-fluid full-width" alt="#">
                      </a>
                      <a routerLink="/car-detail">
                        <img src="assets/images/cardetails/290x213/comment-3.jpg" class="img-fluid full-width" alt="#">
                      </a>
                      <a routerLink="/car-detail">
                        <img src="assets/images/cardetails/290x213/comment-4.jpg" class="img-fluid full-width" alt="#">
                      </a>
                      <a routerLink="/car-detail">
                        <img src="assets/images/cardetails/290x213/comment-5.jpg" class="img-fluid full-width" alt="#">
                      </a>
                      <a routerLink="/car-detail">
                        <img src="assets/images/cardetails/290x213/comment-6.jpg" class="img-fluid full-width" alt="#">
                      </a>
                    </div>
                    <div class="comment-footer">  <span class="text-dark-white">Was This Review ...?</span>
                      <ul>
                        <li>  <a routerLink="/car-detail" class="interesting"><i class="far fa-thumbs-up"></i> Interesting <span class="text-dark-white">180</span></a>
                        </li>
                        <li>  <a routerLink="/car-detail" class="lol"><i class="far fa-laugh"></i> Lol <span class="text-dark-white">125</span></a>
                        </li>
                        <li>  <a routerLink="/car-detail" class="love"><i class="far fa-heart"></i> Love <span class="text-dark-white">350</span></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
          <div class="comment-form-second bg-white">
            <h4 class="text-light-black comment-reply-title">Rate us and Write a Review</h4>
            <form>
              <div class="form-group">
                <div class="row align-items-center">
                  <div class="col-sm-6">
                    <label class="text-light-black">Your Rating for this listing</label>
                    <div class="star-rating"> <span>
                        <i class="far fa-star"></i>
                      </span>
                      <span>
                        <i class="far fa-star"></i>
                      </span>
                      <span>
                        <i class="far fa-star"></i>
                      </span>
                      <span>
                        <i class="far fa-star"></i>
                      </span>
                      <span>
                        <i class="far fa-star"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-12">
                    <label class="text-dark-grey">Email<sup>*</sup>
                    </label>
                    <input type="email" class="form-control form-control-custom" name="#" placeholder="Enter your email i'd">
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-12">
                    <label class="text-dark-grey">Title<sup>*</sup>
                    </label>
                    <input type="email" class="form-control form-control-custom" name="#" placeholder="Example: It was an awesome experience to be there">
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-12">
                    <label class="text-dark-grey">Review<sup>*</sup>
                    </label>
                    <textarea class="form-control form-control-custom" name="#" placeholder="Review" rows="6"></textarea>
                    <p>Your review is recommended to be at least 140 characters long</p>
                  </div>
                </div>
              </div>
              <div class="form-group">
                <div class="row">
                  <div class="col-12">
                    <button type="submit" class="btn-second orange-btn">Submit Review</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <aside class="col-lg-4">
          <app-car-detail-sidebar></app-car-detail-sidebar>
        </aside>
      </div>
    </div>
  </div>
  <!-- User page bottom -->
	<app-footer4></app-footer4>
</div>