import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homepage6',
  templateUrl: './homepage6.component.html',
  styleUrls: ['./homepage6.component.css']
})
export class Homepage6Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
