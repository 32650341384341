<div class="mobile-menu-second">
	<app-header4></app-header4>
<!-- main-slider-6 -->
  <section class="p-relative">
    <div class="main-slider-6">
      <div class="slide-item">
        <img src="assets/images/slider/slider-4.jpg" class="img-fluid full-width" alt="Slider Image">
        <div class="content-wrapper">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <div class="content-box">
                  <a routerLink="/homepage6">
                    <h1 class="text-custom-white rubik mb-2">The new Rims.</h1>
                    <h4 class="text-custom-white rubik no-margin">Five doors that are open to any adventure.</h4>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="slide-item">
        <img src="assets/images/slider/slider-2.jpg" class="img-fluid full-width" alt="Slider Image">
        <div class="content-wrapper">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <div class="content-box">
                  <a routerLink="/homepage6">
                    <h1 class="text-custom-white rubik mb-2">Timeless Machine.</h1>
                    <h4 class="text-custom-white rubik no-margin">The new 911 Carrera S.</h4>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="slide-item">
        <img src="assets/images/slider/slider-3.jpg" class="img-fluid full-width" alt="Slider Image">
        <div class="content-wrapper">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <div class="content-box">
                  <a routerLink="/homepage6">
                    <h1 class="text-custom-white rubik mb-2">Soul, Electrified.</h1>
                    <h4 class="text-custom-white rubik no-margin">The new Taycan 4S.</h4>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="slide-item">
        <img src="assets/images/slider/slider-1.jpg" class="img-fluid full-width" alt="Slider Image">
        <div class="content-wrapper">
          <div class="container-fluid">
            <div class="row">
              <div class="col-12">
                <div class="content-box">
                  <a routerLink="/homepage6">
                    <h1 class="text-custom-white rubik mb-2">Courage Changes Everything.</h1>
                    <h4 class="text-custom-white rubik no-margin">Panamera Turbo S E-Hybrid Executive.</h4>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- main-slider-6 -->
  <!-- Shopping Tools -->
  <section class="section-padding-b0">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="section-header">
            <div class="text-wrapper text-center">
              <h3 class="fw-500 no-margin">What Are You Looking For?</h3>
              <span class="line-grey"></span><span class="line-orange"></span><span class="line-grey"></span> 
              <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort. A masterful combination of style, power, sporty handling and comfort.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid custom-container">
      <div class="shopping-sec">
        <div class="row">
          <div class="col-xl-5 col-lg-5 col-md-6">
            <div class="img-box">
              <a routerLink="/listing">
                <img src="assets/images/looking.jpg" class="img-fluid full-width" alt="Image">
                <div class="text-box">
                  <h4 class="text-custom-white rubik no-margin"> Find A Car Within Budget</h4>
                </div>
              </a>
            </div>
          </div>
          <div class="col-xl col-lg col-md-6">
            <div class="img-box">
              <a routerLink="/add-listing">
                <img src="assets/images/looking-2.jpg" class="img-fluid full-width" alt="Image">
                <div class="text-box">
                  <h4 class="text-custom-white rubik no-margin"> List A Vehicle</h4>
                </div>
              </a>
            </div>
          </div>
          <div class="col-xl col-lg col-md-6">
            <div class="img-box">
              <a routerLink="/register">
                <img src="assets/images/looking-3.jpg" class="img-fluid full-width" alt="Image">
                <div class="text-box">
                  <h4 class="text-custom-white rubik no-margin"> Register An Account</h4>
                </div>
              </a>
            </div>
          </div>
          <div class="col-xl col-lg col-md-6">
            <div class="img-box">
              <a routerLink="/contact">
                <img src="assets/images/looking-4.jpg" class="img-fluid full-width" alt="Image">
                <div class="text-box">
                  <h4 class="text-custom-white rubik no-margin"> Contact Us</h4>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Shopping Tools -->
  <section class="section-padding-b0 car-models-layout-2">
    <div class="container-fluid custom-container">
      <div class="row">
        <div class="col-md-12">
          <div class="section-header">
            <div class="text-wrapper text-center">
              <h3 class="fw-500 no-margin"> Car Models</h3>
              <span class="line-grey"></span><span class="line-orange"></span><span class="line-grey"></span> 
              <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort. A masterful combination of style, power, sporty handling and comfort.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="car-slider-index-6 row">
        <div class="slide-item">
          <div class="car-grid-layout-box">
            <div class="car-grid-layout-inner text-center">
              <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">Acura TLX </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
              <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
              <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p> <span class="text-orange price-text">$25,000</span>
              <div class="car-grid-layout-img">
                <img src="assets/images/showcar7.jpg" class="img-fluid full-width" alt="Car img">
              </div>
              <div class="car-grid-layout-details"> <span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 22,000 Km</span>
                <span class="text-dark-white p-relative"><i class="icon-car"></i> 2012 Model</span>
              </div>
              <div class="car-grid-layout-location"> <span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> New York</span>
                <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Automatic</span>
              </div>
            </div>
          </div>
        </div>
        <div class="slide-item">
          <div class="car-grid-layout-box">
            <div class="car-grid-layout-inner text-center">
              <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">BMW 4 Series </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
              <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
              <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p> <span class="text-orange price-text">$20,000</span>
              <div class="car-grid-layout-img">
                <img src="assets/images/showcar8.jpg" class="img-fluid full-width" alt="Car img">
              </div>
              <div class="car-grid-layout-details"> <span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 40,000 Km</span>
                <span class="text-dark-white p-relative"><i class="icon-car"></i> 2008 Model</span>
              </div>
              <div class="car-grid-layout-location"> <span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> London</span>
                <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Manual</span>
              </div>
            </div>
          </div>
        </div>
        <div class="slide-item">
          <div class="car-grid-layout-box">
            <div class="car-grid-layout-inner text-center">
              <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">Audi A4 </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
              <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
              <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p> <span class="text-orange price-text">$30,000</span>
              <div class="car-grid-layout-img">
                <img src="assets/images/showcar9.jpg" class="img-fluid full-width" alt="Car img">
              </div>
              <div class="car-grid-layout-details"> <span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 15,000 Km</span>
                <span class="text-dark-white p-relative"><i class="icon-car"></i> 2014 Model</span>
              </div>
              <div class="car-grid-layout-location"> <span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> Germany</span>
                <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Automatic</span>
              </div>
            </div>
          </div>
        </div>
        <div class="slide-item">
          <div class="car-grid-layout-box">
            <div class="car-grid-layout-inner text-center">
              <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">Infiniti Q50 </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
              <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
              <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p> <span class="text-orange price-text">$23,000</span>
              <div class="car-grid-layout-img">
                <img src="assets/images/showcar7.jpg" class="img-fluid full-width" alt="Car img">
              </div>
              <div class="car-grid-layout-details"> <span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 28,000 Km</span>
                <span class="text-dark-white p-relative"><i class="icon-car"></i> 2010 Model</span>
              </div>
              <div class="car-grid-layout-location"> <span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> New York</span>
                <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Manual</span>
              </div>
            </div>
          </div>
        </div>
        <div class="slide-item">
          <div class="car-grid-layout-box">
            <div class="car-grid-layout-inner text-center">
              <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">Mercedes-Benz </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
              <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
              <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p> <span class="text-orange price-text">$28,000</span>
              <div class="car-grid-layout-img">
                <img src="assets/images/showcar8.jpg" class="img-fluid full-width" alt="Car img">
              </div>
              <div class="car-grid-layout-details"> <span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 27,000 Km</span>
                <span class="text-dark-white p-relative"><i class="icon-car"></i> 2011 Model</span>
              </div>
              <div class="car-grid-layout-location"> <span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> New York</span>
                <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Automatic</span>
              </div>
            </div>
          </div>
        </div>
        <div class="slide-item">
          <div class="car-grid-layout-box">
            <div class="car-grid-layout-inner text-center">
              <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">Acura TLX </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
              <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
              <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p> <span class="text-orange price-text">$25,000</span>
              <div class="car-grid-layout-img">
                <img src="assets/images/showcar7.jpg" class="img-fluid full-width" alt="Car img">
              </div>
              <div class="car-grid-layout-details"> <span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 22,000 Km</span>
                <span class="text-dark-white p-relative"><i class="icon-car"></i> 2012 Model</span>
              </div>
              <div class="car-grid-layout-location"> <span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> New York</span>
                <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Automatic</span>
              </div>
            </div>
          </div>
        </div>
        <div class="slide-item">
          <div class="car-grid-layout-box">
            <div class="car-grid-layout-inner text-center">
              <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">BMW 4 Series </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
              <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
              <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p> <span class="text-orange price-text">$20,000</span>
              <div class="car-grid-layout-img">
                <img src="assets/images/showcar8.jpg" class="img-fluid full-width" alt="Car img">
              </div>
              <div class="car-grid-layout-details"> <span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 40,000 Km</span>
                <span class="text-dark-white p-relative"><i class="icon-car"></i> 2008 Model</span>
              </div>
              <div class="car-grid-layout-location"> <span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> London</span>
                <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Manual</span>
              </div>
            </div>
          </div>
        </div>
        <div class="slide-item">
          <div class="car-grid-layout-box">
            <div class="car-grid-layout-inner text-center">
              <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">Audi A4 </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
              <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
              <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p> <span class="text-orange price-text">$30,000</span>
              <div class="car-grid-layout-img">
                <img src="assets/images/showcar9.jpg" class="img-fluid full-width" alt="Car img">
              </div>
              <div class="car-grid-layout-details"> <span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 15,000 Km</span>
                <span class="text-dark-white p-relative"><i class="icon-car"></i> 2014 Model</span>
              </div>
              <div class="car-grid-layout-location"> <span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> Germany</span>
                <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Automatic</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- In Media -->
  <section class="in-media-sec section-padding-b0 Rims-live">
    <div class="container-fluid custom-container">
      <div class="row">
        <div class="col-md-12">
          <div class="section-header">
            <div class="text-wrapper text-center">
              <h3 class="fw-500 no-margin"> Socialize With Rims</h3>
              <span class="line-grey"></span><span class="line-orange"></span><span class="line-grey"></span> 
              <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort. A masterful combination of style, power, sporty handling and comfort.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <!-- sec 1 -->
        <div class="in-media-box">
          <div class="grid-box large-img-box">
            <img src="assets/images/socialmedia/social1.jpg" class="img-fluid" alt="In media">
            <div class="in-media-box-caption video-btn">
              <div class="main-icon">
                <a routerLink="/homepage6"> <i class="fab fa-instagram"></i>
                </a>
              </div>
              <div class="media-box-caption-wrapper ">
                <p class="text-light-black">The new Rims - Not ordinary. But thrilling.</p>
                <div class="icon-box row">
                  <div class="col-12">
                    <img src="assets/images/user-1.jpg" alt="image"> <span class="text-light-black">Rims</span>
                    <a routerLink="/homepage6"> <i class="fab fa-instagram"></i>
                    </a>
                  </div>
                </div> <a routerLink="/homepage6" class="btn-first orange-btn full-width">View Category</a>
              </div>
            </div>
          </div>
          <div class="grid-box grid-box-small small-img-box padding-right-5">
            <img src="assets/images/socialmedia/social6.jpg" class="img-fluid" alt="In media">
            <div class="in-media-box-caption">
              <div class="main-icon">
                <a routerLink="/homepage6"> <i class="fab fa-twitter"></i>
                </a>
              </div>
              <div class="media-box-caption-wrapper">
                <div class="icon-box row">
                  <div class="col-12">
                    <img src="assets/images/user-1.jpg" alt="image"> <span class="text-light-black d-block">Rims</span> 
                  </div>
                </div>
                <p class="text-light-black">The new Rims
                  <a routerLink="/homepage6"> <i class="fab fa-twitter"></i>
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div class="grid-box grid-box-small small-img-box ">
            <img src="assets/images/socialmedia/social4.jpg" class="img-fluid" alt="In media">
            <div class="in-media-box-caption">
              <div class="main-icon">
                <a routerLink="/homepage6"> <i class="fab fa-facebook-f"></i>
                </a>
              </div>
              <div class="media-box-caption-wrapper">
                <div class="icon-box row">
                  <div class="col-12">
                    <img src="assets/images/user-2.jpg" alt="image"> <span class="text-light-black d-block">Rims</span> 
                  </div>
                </div>
                <p class="text-light-black">The new Rims
                  <a routerLink="/homepage6"> <i class="fab fa-facebook-f"></i>
                  </a>
                </p>
              </div>
            </div>
          </div>
          <!-- sec 1 -->
          <!-- sec 2 -->
          <div class="grid-box grid-box-small small-img-box padding-right-5">
            <img src="assets/images/socialmedia/social9.jpg" class="img-fluid" alt="In media">
            <div class="in-media-box-caption">
              <div class="main-icon">
                <a routerLink="/homepage6"> <i class="fab fa-instagram"></i>
                </a>
              </div>
              <div class="media-box-caption-wrapper">
                <div class="icon-box row">
                  <div class="col-12">
                    <img src="assets/images/user-3.jpg" alt="image"> <span class="text-light-black d-block">Rims</span> 
                  </div>
                </div>
                <p class="text-light-black">The new Rims
                  <a routerLink="/homepage6"> <i class="fab fa-instagram"></i>
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div class="grid-box grid-box-small small-img-box">
            <img src="assets/images/socialmedia/social6.jpg" class="img-fluid" alt="In media">
            <div class="in-media-box-caption">
              <div class="main-icon">
                <a routerLink="/homepage6"> <i class="fab fa-facebook-f"></i>
                </a>
              </div>
              <div class="media-box-caption-wrapper">
                <div class="icon-box row">
                  <div class="col-12">
                    <img src="assets/images/user-2.jpg" alt="image"> <span class="text-light-black d-block">Rims</span> 
                  </div>
                </div>
                <p class="text-light-black">The new Rims
                  <a routerLink="/homepage6"> <i class="fab fa-facebook-f"></i>
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div class="grid-box large-img-box">
            <img src="assets/images/socialmedia/social2.jpg" class="img-fluid" alt="In media">
            <div class="in-media-box-caption video-btn">
              <div class="main-icon">
                <a routerLink="/homepage6"> <i class="fab fa-youtube"></i>
                </a>
              </div>
              <div class="media-box-caption-wrapper">
                <p class="text-light-black">The new Rims - Not ordinary. But thrilling.</p>
                <div class="icon-box row">
                  <div class="col-6">
                    <img src="assets/images/user-3.jpg" alt="image"> <span class="text-light-black">Rims</span>
                  </div>
                  <div class="col-6">
                    <a routerLink="/homepage6"> <i class="fab fa-youtube"></i>
                    </a>
                  </div>
                </div> <a routerLink="/homepage6" class="btn-first orange-btn full-width">Learn More</a>
              </div>
            </div>
          </div>
          <!-- sec 2 -->
          <!-- sec 3 -->
          <div class="grid-box large-img-box">
            <img src="assets/images/socialmedia/social8.jpg" class="img-fluid" alt="In media">
            <div class="in-media-box-caption">
              <div class="main-icon">
                <a routerLink="/homepage6"> <i class="fab fa-instagram"></i>
                </a>
              </div>
              <div class="media-box-caption-wrapper">
                <p class="text-light-black">The new Rims - Not ordinary. But thrilling.</p>
                <div class="icon-box row">
                  <div class="col-6">
                    <img src="assets/images/user-1.jpg" alt="image"> <span class="text-light-black">Rims</span>
                  </div>
                  <div class="col-6">
                    <a routerLink="/homepage6"> <i class="fab fa-instagram"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-box grid-box-small small-img-box padding-right-5">
            <img src="assets/images/socialmedia/social7.jpg" class="img-fluid" alt="In media">
            <div class="in-media-box-caption">
              <div class="main-icon">
                <a routerLink="/homepage6"> <i class="fab fa-twitter"></i>
                </a>
              </div>
              <div class="media-box-caption-wrapper">
                <div class="icon-box row">
                  <div class="col-12">
                    <img src="assets/images/user-3.jpg" alt="image"> <span class="text-light-black d-block">Rims</span> 
                  </div>
                </div>
                <p class="text-light-black">The new Rims
                  <a routerLink="/homepage6"> <i class="fab fa-twitter"></i>
                  </a>
                </p>
              </div>
            </div>
          </div>
          <div class="grid-box grid-box-small small-img-box">
            <img src="assets/images/socialmedia/social6.jpg" class="img-fluid" alt="In media">
            <div class="in-media-box-caption">
              <div class="main-icon">
                <a routerLink="/homepage6"> <i class="fab fa-facebook-f"></i>
                </a>
              </div>
              <div class="media-box-caption-wrapper">
                <div class="icon-box row">
                  <div class="col-12">
                    <img src="assets/images/user-2.jpg" alt="image"> <span class="text-light-black d-block">Rims</span> 
                  </div>
                </div>
                <p class="text-light-black">The new Rims
                  <a routerLink="/homepage6"> <i class="fab fa-facebook-f"></i>
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- sec 3 -->
      </div>
    </div>
  </section>
  <!-- In Media -->
  <!-- Discover -->
  <section class="section-padding discover-sec-main">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="section-header">
            <div class="text-wrapper text-center">
              <h3 class="fw-500 no-margin">Discover</h3>
              <span class="line-grey"></span><span class="line-orange"></span><span class="line-grey"></span> 
              <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort. A masterful combination of style, power, sporty handling and comfort.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid custom-container">
      <div class="discover-sec">
        <div class="row">
          <div class="discover-slider col-12">
            <a routerLink="/homepage6">
              <img src="assets/images/discover/discover-4.jpg" class="img-fluid full-width" alt="Image">
              <div class="text-box">
                <h6 class="text-light-black no-margin rubik">Rims E-Performance</h6>
              </div>
            </a>
            <a routerLink="/homepage6">
              <img src="assets/images/discover/discover-3.jpg" class="img-fluid full-width" alt="Image">
              <div class="text-box">
                <h6 class="text-light-black no-margin rubik">Tequipment Sound & Design.</h6>
              </div>
            </a>
            <a routerLink="/homepage6">
              <img src="assets/images/discover/discover-2.jpg" class="img-fluid full-width" alt="Image">
              <div class="text-box">
                <h6 class="text-light-black no-margin rubik">Rims Drive.</h6>
              </div>
            </a>
            <a routerLink="/homepage6">
              <img src="assets/images/discover/discover-1.jpg" class="img-fluid full-width" alt="Image">
              <div class="text-box">
                <h6 class="text-light-black no-margin rubik">Rims Exclusive Manufaktur.</h6>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Discover -->
  <!-- Models -->
  <section class="section-padding model-gallery">
		<div class="container-fluid">
			<div class="row">
				<div class="col-md-12">
					<div class="section-header">
						<div class="text-wrapper text-center">
							<h3 class="fw-500 no-margin">Models Currently Trending</h3>
							<span class="line-grey"></span><span class="line-orange"></span><span class="line-grey"></span> 
							<p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort. A masterful combination of style, power, sporty handling and comfort.</p>
						</div>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="custom-col">
					<img src="assets/images/trendingmodels/trending1.jpg" class="img-fluid full-width" alt="Image">
					<div class="model-gallery-caption">
						<div class="model-gallery-wrapper-1">
							<h3 class="text-custom-white model-gal-title">Acura</h3>
							<h6 class="text-custom-white rubik">From <strong>$ 25,900.00*</strong></h6>
						</div>
						<div class="model-gallery-wrapper-2"> <a routerLink="/listing" class="btn-second orange-btn">View Category</a>
							<a routerLink="/listing" class="btn-fourth border-btn">All Models</a>
						</div>
					</div>
				</div>
				<div class="custom-col">
					<img src="assets/images/trendingmodels/trending2.jpg" class="img-fluid full-width" alt="Image">
					<div class="model-gallery-caption">
						<div class="model-gallery-wrapper-1">
							<h3 class="text-custom-white model-gal-title">Audi</h3>
							<h6 class="text-custom-white rubik">From <strong>$ 32,500.00*</strong></h6>
						</div>
						<div class="model-gallery-wrapper-2"> <a routerLink="/listing" class="btn-second orange-btn">View Category</a>
							<a routerLink="/listing" class="btn-fourth border-btn">All Models</a>
						</div>
					</div>
				</div>
				<div class="custom-col">
					<img src="assets/images/trendingmodels/trending3.jpg" class="img-fluid full-width" alt="Image">
					<div class="model-gallery-caption">
						<div class="model-gallery-wrapper-1">
							<h3 class="text-custom-white model-gal-title">BMW</h3>
							<h6 class="text-custom-white rubik">From <strong>$ 34,950.00*</strong></h6>
						</div>
						<div class="model-gallery-wrapper-2"> <a routerLink="/listing" class="btn-second orange-btn">View Category</a>
							<a routerLink="/listing" class="btn-fourth border-btn">All Models</a>
						</div>
					</div>
				</div>
				<div class="custom-col">
					<img src="assets/images/trendingmodels/trending4.jpg" class="img-fluid full-width" alt="Image">
					<div class="model-gallery-caption">
						<div class="model-gallery-wrapper-1">
							<h3 class="text-custom-white model-gal-title">Ford</h3>
							<h6 class="text-custom-white rubik">From <strong>$ 14,260.00*</strong></h6>
						</div>
						<div class="model-gallery-wrapper-2"> <a routerLink="/listing" class="btn-second orange-btn">View Category</a>
							<a routerLink="/listing" class="btn-fourth border-btn">All Models</a>
						</div>
					</div>
				</div>
				<div class="custom-col">
					<img src="assets/images/trendingmodels/trending5.jpg" class="img-fluid full-width" alt="Image">
					<div class="model-gallery-caption">
						<div class="model-gallery-wrapper-1">
							<h3 class="text-custom-white model-gal-title">Range Rover</h3>
							<h6 class="text-custom-white rubik">From <strong>$ 37,800.00*</strong></h6>
						</div>
						<div class="model-gallery-wrapper-2"> <a routerLink="/listing" class="btn-second orange-btn">View Category</a>
							<a routerLink="/listing" class="btn-fourth border-btn">All Models</a>
						</div>
					</div>
				</div>
				<div class="custom-col">
					<img src="assets/images/trendingmodels/trending6.jpg" class="img-fluid full-width" alt="Image">
					<div class="model-gallery-caption">
						<div class="model-gallery-wrapper-1">
							<h3 class="text-custom-white model-gal-title">Maserati</h3>
							<h6 class="text-custom-white rubik">From <strong>$ 74,980.00*</strong></h6>
						</div>
						<div class="model-gallery-wrapper-2"> <a routerLink="/listing" class="btn-second orange-btn">View Category</a>
							<a routerLink="/listing" class="btn-fourth border-btn">All Models</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
  <!-- Models -->
  <!-- find a dealer -->
  <div class="section-padding-b0">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="section-header">
            <div class="text-wrapper text-center">
              <h3 class="fw-500 no-margin">Find A Dealer</h3>
              <span class="line-grey"></span><span class="line-orange"></span><span class="line-grey"></span> 
              <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort. A masterful combination of style, power, sporty handling and comfort.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-map p-relative">
      <div class="map-container">
          <div id="map"></div>
        </div>
      <div class="location-button">
        <div class="input-group">
          <div class="location-box p-relative">
            <input type="text" class="form-control form-control-custom" placeholder="City or ZIP" id="result">
            <button><i class="fas fa-crosshairs"></i>
            </button>
          </div>
          <div class="input-group-append">
            <button class="btn-second orange-btn" type="submit"><span><i class="flaticon-right-chevron"></i></span>  <span>Search</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- find a dealer -->
<app-footer5></app-footer5>
  <div class="modal fade" id="register">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title text-left no-margin"><i class="fas fa-unlock-alt mr-2"></i> <span id="signup-text">Sign Up</span><span
             id="signin-text" class="none">Sign In</span></h6>
          <button type="button" class="close" data-dismiss="modal">&times;</button>
        </div>
        <!-- Sign up form -->
        <div class="modal-body" id="signup-form">
          <form>
            <div class="form-group">
              <input type="email" class="form-control" name="#" placeholder="Email">
            </div>
            <div class="form-group">
              <input type="password" class="form-control" name="#" placeholder="Password">
            </div>
            <div class="form-group">
              <button type="submit" class="btn-second orange-btn full-width">Sign Up</button>
            </div>
          </form>
          <div class="form-excerpts">
            <ul>
              <li>Already a member? <a href="javascript:void(0)" id="signin-btn">Sign In</a>
              </li>
              <li><a routerLink="/homepage6">Recover Password</a>
              </li>
            </ul>
          </div>
          <div class="social-login"> <span>Or Signup with</span>
            <div class="social-btn"> <a routerLink="/homepage6" class="facebook"><i class="fab fa-facebook-f"></i> Facebook</a>
              <a routerLink="/homepage6" class="google"><i class="fab fa-google-plus-g"></i> Google</a>
            </div>
          </div>
        </div>
        <!-- Sign up form -->
        <!-- Sign in form -->
        <div class="modal-body none" id="signin-form">
          <form>
            <div class="form-group">
              <input type="text" class="form-control" name="#" placeholder="Username or Email">
            </div>
            <div class="form-group">
              <input type="password" class="form-control" name="#" placeholder="Password">
            </div>
            <div class="form-group">
              <label class="custom-checkbox">
                <input type="checkbox" name="#">Keep me signed in <span class="checkmark"></span>
              </label>
            </div>
            <div class="form-group">
              <button type="submit" class="btn-second orange-btn full-width">Sign In</button>
            </div>
          </form>
          <div class="form-excerpts">
            <ul>
              <li>Not a member? <a href="javascript:void(0)" id="signup-btn">Sign Up</a>
              </li>
              <li><a routerLink="/homepage6">Recover Password</a>
              </li>
            </ul>
          </div>
          <div class="social-login"> <span>Or connect with</span>
            <div class="social-btn"> <a routerLink="/homepage6" class="facebook"><i class="fab fa-facebook-f"></i> Facebook</a>
              <a routerLink="/homepage6" class="google"><i class="fab fa-google-plus-g"></i> Google</a>
            </div>
          </div>
        </div>
        <!-- Sign in form -->
      </div>
    </div>
  </div>
	
</div>