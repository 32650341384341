<div id="banner-adv">
      <a routerLink="/homepage7">
      <img src="assets/images/highbanner.jpg" class="img-fluid" alt="advertisement"> <span class="text">BUY A CAR AND GET FREE INSURANCE USE CODE: <strong>INSURANCE</strong></span>
      </a> <span class="close-banner"></span>
    </div>
<app-header2></app-header2>
    <!-- Banner sec -->
    <section class="banner-slider p-relative">
      <img src="assets/images/banner/bannerh4.jpg" class="img-fluid full-width" alt="Banner">
      <div class="bannerimg-overlay"></div>
      <div class="banner-wrapper">
        <div class="container">
          <div class="offset-lg-2 col-lg-8">
            <div class="banner-text-wrapper-index-3">
              <h2 class="text-custom-white">Find a car in <span class="text-orange">New York City</span></h2>
              <p class="text-custom-white">Let's uncover the best car showroom nearest to you.</p>
              <div class="space-20"></div>
              <div class="space-10"></div>
              <!-- dekstop search -->
              <div class="search-bar-index-3 align-item-center d-none d-md-block">
                <div class="input-group" id="input-group">
                  <div class="input-group-prepend"> <span class="input-group-text">What</span>
                  </div>
                  <input type="text" class="form-control border-0" id="myInput" onkeyup="filterFunction()" placeholder="Ex: Luxury Car, Sports Car, Vintage Car, Excutive Car, Economy Car">
                  <ul class="filter-option">
                    <li><a routerLink="/homepage7">Luxury Car</a>
                    </li>
                    <li><a routerLink="/homepage7">Sports Car</a>
                    </li>
                    <li><a routerLink="/homepage7">Vintage Car</a>
                    </li>
                    <li><a routerLink="/homepage7">Excutive Car</a>
                    </li>
                    <li><a routerLink="/homepage7">Economy Car</a>
                    </li>
                  </ul>
                  <div class="input-group-prepend"> <span class="input-group-text">Where</span>
                    <button class="gps" type="button"><i class="fas fa-crosshairs"></i>
                    </button>
                  </div>
                  <input type="text" class="form-control border-0" placeholder="Your City">
                  <div class="input-group-prepend">
                    <button class="btn-second orange-btn" type="button"><i class="flaticon-search"></i> Search</button>
                  </div>
                </div>
              </div>
              <!-- dekstop search -->
              <!-- mobile search -->
              <div class="search-bar-index-3 align-item-center d-block d-md-none">
                <div class="input-group mb-3">
                  <div class="input-group-prepend"> <span class="input-group-text">What</span>
                  </div>
                  <input type="text" class="form-control border-0" placeholder="Ex: Luxury Car, Sports Car, Vintage Car, Excutive Car, Economy Car">
                </div>
                <div class="input-group mb-3">
                  <div class="input-group-prepend"> <span class="input-group-text">Where</span>
                    <button class="gps" type="button"><i class="fas fa-crosshairs"></i>
                    </button>
                  </div>
                  <input type="text" class="form-control border-0" placeholder="Your City">
                </div>
                <button class="btn-second orange-btn full-width" type="button"><i class="flaticon-search"></i> Search</button>
              </div>
              <!-- mobile search -->
              <div class="space-20"></div>
              <div class="space-20"></div>
              <p class="text-custom-white small-content">Just looking around? Let us suggest you something hot & happening!</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Banner sec -->
    <div class="banner-tab-sec">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="banner-tabs align-item-center">
              <ul>
                <li>
                  <a routerLink="/homepage7" class="align-item-center">
                  <img src="assets/images/luxury-car.png" alt="Car Image">	<span>Luxury Car</span>
                  </a>
                </li>
                <li>
                  <a routerLink="/homepage7" class="align-item-center">
                  <img src="assets/images/sports-car.png" alt="Car Image">	<span>Sports Car</span>
                  </a>
                </li>
                <li>
                  <a routerLink="/homepage7" class="align-item-center">
                  <img src="assets/images/vintage-car.png" alt="Car Image">	<span>Vintage Car</span>
                  </a>
                </li>
                <li>
                  <a routerLink="/homepage7" class="align-item-center">
                  <img src="assets/images/excutive-car.png" alt="Car Image">	<span>Excutive Car</span>
                  </a>
                </li>
                <li>
                  <a routerLink="/homepage7" class="align-item-center">
                  <img src="assets/images/economy-car.png" alt="Car Image">	<span>Economy Car</span>
                  </a>
                </li>
              </ul>
            </div>
            <div class="space-60"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- full with cat details -->
    <div class="user-page-sec home-4 p-relative section-padding">
      <div class="main-image-sec">
        <div class="container-fluid">
          <div class="row">
            <div class="offset-lg-1 col-lg-10">
              <div class="tab-sec col-xl-2 col-lg-3 col-md-12">
                <h4 class="text-light-black rubik">Select Version</h4>
                <div class="side-slide-prevtab slick-arrow"> <i class="fas fa-caret-up"></i>
                </div>
                <ul class="side-slid-nav">
                  <li class="nav-item">	<a class="nav-link text-dark-white">Ghibli S</a>
                  </li>
                  <li class="nav-item"> <a class="nav-link text-dark-white">Ghibli S Q4</a>
                  </li>
                  <li class="nav-item"> <a class="nav-link text-dark-white">Ghibli</a>
                  </li>
                  <li class="nav-item"> <a class="nav-link text-dark-white">Ghibli Diesel</a>
                  </li>
                </ul>
                <div class="side-slide-nexttab slick-arrow"> <i class="fas fa-caret-down"></i>
                </div>
              </div>
              <div class="side-slid-for">
                <div class="slide-item">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="main-image p-relative">
                        <img src="assets/images/showcar1.jpg" class="img-fluid" alt="#">
                        <div class="main-image-caption">
                          <h3 class="text-light-black">Ghibli S</h3>
                          <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
                          <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort. A masterful combination of style, power, sporty handling and comfort.</p>
                          <div class="price-sec">
                            <span class="text-light-black ">$</span>
                            <h1 class="text-light-black price-title">50,000</h1>
                          </div>
                          <div class="add-fav-sec">	<span class="text-custom-black">Add Favorite</span>
                            <button type="button"><i class="flaticon-like"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="car-specification">
                        <ul>
                          <li><span class="text-dark-white">Max Power</span><span class="count">430</span><sub>HP</sub>
                          </li>
                          <li><span class="text-dark-white">Max speed</span><span class="count">286</span><sub>km/h</sub>
                          </li>
                          <li><span class="text-dark-white">Acceleration</span>4.9 <sub>sec</sub>
                          </li>
                          <li><span class="text-dark-white">Engine Layout</span>V6</li>
                          <li><span class="text-dark-white">Traction</span>RWD</li>
                          <li><span class="text-dark-white">Max torque</span>580 Nm</li>
                        </ul>
                      </div>
                      <div class="car-btns">
                        <div class="row justify-content-between">
                          <div class="col-xl-4 col-md-6">	<a routerLink="/car-detail" class="btn-second orange-btn">Full Details</a>
                            <a routerLink="/car-detail" class="btn-third transparent-btn">Test Drive</a>
                          </div>
                          <div class="col-xl-2 col-md-6 align-self-center">	<span class="text-dark-white">Full Specifications <button type="button"><i class="flaticon-add"></i></button></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="slide-item">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="main-image p-relative">
                        <img src="assets/images/showcar1.jpg" class="img-fluid" alt="#">
                        <div class="main-image-caption">
                          <h3 class="text-light-black">Ghibli S Q4</h3>
                          <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
                          <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort. A masterful combination of style, power, sporty handling and comfort.</p>
                          <div class="price-sec">
                            <span class="text-light-black ">$</span>
                            <h1 class="text-light-black price-title">60,000</h1>
                          </div>
                          <div class="add-fav-sec">	<span class="text-custom-black">Add Favorite</span>
                            <button type="button"><i class="flaticon-like"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="car-specification">
                        <ul>
                          <li><span class="text-dark-white">Max Power</span><span class="count">430</span><sub>HP</sub>
                          </li>
                          <li><span class="text-dark-white">Max speed</span><span class="count">286</span><sub>km/h</sub>
                          </li>
                          <li><span class="text-dark-white">Acceleration</span>4.7 <sub>sec</sub>
                          </li>
                          <li><span class="text-dark-white">Engine Layout</span>V6</li>
                          <li><span class="text-dark-white">Traction</span>AWD</li>
                          <li><span class="text-dark-white">Max torque</span>580 Nm</li>
                        </ul>
                      </div>
                      <div class="car-btns">
                        <div class="row justify-content-between">
                          <div class="col-xl-4 col-md-6">	<a routerLink="/car-detail" class="btn-second orange-btn">Full Details</a>
                            <a routerLink="/car-detail" class="btn-third transparent-btn">Test Drive</a>
                          </div>
                          <div class="col-xl-2 col-md-6 align-self-center">	<span class="text-dark-white">Full Specifications <button type="button"><i class="flaticon-add"></i></button></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="slide-item">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="main-image p-relative">
                        <img src="assets/images/showcar1a.jpg" class="img-fluid" alt="#">
                        <div class="main-image-caption">
                          <h3 class="text-light-black">Ghibli</h3>
                          <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
                          <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort. A masterful combination of style, power, sporty handling and comfort.</p>
                          <div class="price-sec">
                            <span class="text-light-black ">$</span>
                            <h1 class="text-light-black price-title">75,000</h1>
                          </div>
                          <div class="add-fav-sec">	<span class="text-custom-black">Add Favorite</span>
                            <button type="button"><i class="flaticon-like"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="car-specification">
                        <ul>
                          <li><span class="text-dark-white">Max Power</span><span class="count">350</span><sub>HP</sub>
                          </li>
                          <li><span class="text-dark-white">Max speed</span><span class="count">267</span><sub>km/h</sub>
                          </li>
                          <li><span class="text-dark-white">Acceleration</span>5.5 <sub>sec</sub>
                          </li>
                          <li><span class="text-dark-white">Engine Layout</span>V6</li>
                          <li><span class="text-dark-white">Traction</span>RWD</li>
                          <li><span class="text-dark-white">Max torque</span>500 Nm</li>
                        </ul>
                      </div>
                      <div class="car-btns">
                        <div class="row justify-content-between">
                          <div class="col-xl-4 col-md-6">	<a routerLink="/car-detail" class="btn-second orange-btn">Full Details</a>
                            <a routerLink="/car-detail" class="btn-third transparent-btn">Test Drive</a>
                          </div>
                          <div class="col-xl-2 col-md-6 align-self-center">	<span class="text-dark-white">Full Specifications <button type="button"><i class="flaticon-add"></i></button></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="slide-item">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="main-image p-relative">
                        <img src="assets/images/showcar1.jpg" class="img-fluid" alt="#">
                        <div class="main-image-caption">
                          <h3 class="text-light-black">Ghibli Diesel</h3>
                          <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
                          <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort. A masterful combination of style, power, sporty handling and comfort.</p>
                          <div class="price-sec">
                            <span class="text-light-black ">$</span>
                            <h1 class="text-light-black price-title">40,000</h1>
                          </div>
                          <div class="add-fav-sec">	<span class="text-custom-black">Add Favorite</span>
                            <button type="button"><i class="flaticon-like"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <div class="car-specification">
                        <ul>
                          <li><span class="text-dark-white">Max Power</span><span class="count">275</span><sub>HP</sub>
                          </li>
                          <li><span class="text-dark-white">Max speed</span><span class="count">250</span><sub>km/h</sub>
                          </li>
                          <li><span class="text-dark-white">Acceleration</span>6.3 <sub>sec</sub>
                          </li>
                          <li><span class="text-dark-white">Engine Layout</span>V6</li>
                          <li><span class="text-dark-white">Traction</span>RWD</li>
                          <li><span class="text-dark-white">Max torque</span>600 Nm</li>
                        </ul>
                      </div>
                      <div class="car-btns">
                        <div class="row justify-content-between">
                          <div class="col-xl-4 col-md-6">	<a routerLink="/car-detail" class="btn-second orange-btn">Full Details</a>
                            <a routerLink="/car-detail" class="btn-third transparent-btn">Test Drive</a>
                          </div>
                          <div class="col-xl-2 col-md-6 align-self-center">	<span class="text-dark-white">Full Specifications <button type="button"><i class="flaticon-add"></i></button></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- full with cat details -->
    <!-- In Media -->
    <section class="in-media-sec">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="section-header">
              <div class="text-wrapper text-center">
                <h3 class="fw-500 no-margin">Car Valley In Media</h3>
                <span class="line-grey"></span><span class="line-orange"></span><span class="line-grey"></span> 
                <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort. A masterful combination of style, power, sporty handling and comfort.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- sec 1 -->
          <div class="in-media-box">
            <div class="grid-box large-img-box">
              <img src="assets/images/socialmedia/social1.jpg" class="img-fluid" alt="In media">
              <div class="in-media-box-caption video-btn">
                <div class="main-icon">
                  <a routerLink="/homepage7">	<i class="fab fa-instagram"></i>
                  </a>
                </div>
                <div class="media-box-caption-wrapper ">
                  <p class="text-light-black">The new Rims - Not ordinary. But thrilling.</p>
                  <div class="icon-box row">
                    <div class="col-12">
                      <img src="assets/images/user-1.jpg" alt="image">	<span class="text-light-black">Rims</span>
                      <a routerLink="/homepage7">	<i class="fab fa-instagram"></i>
                      </a>
                    </div>
                  </div>
                  <a routerLink="/homepage7" class="btn-first orange-btn full-width">View Category</a>
                </div>
              </div>
            </div>
            <div class="grid-box grid-box-small small-img-box padding-right-5">
              <img src="assets/images/socialmedia/social3.jpg" class="img-fluid" alt="In media">
              <div class="in-media-box-caption">
                <div class="main-icon">
                  <a routerLink="/homepage7">	<i class="fab fa-twitter"></i>
                  </a>
                </div>
                <div class="media-box-caption-wrapper">
                  <div class="icon-box row">
                    <div class="col-12">
                      <img src="assets/images/user-2.jpg" alt="image">	<span class="text-light-black d-block">Rims</span>	
                    </div>
                  </div>
                  <p class="text-light-black">The new Rims
                    <a routerLink="/homepage7">	<i class="fab fa-twitter"></i>
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div class="grid-box grid-box-small small-img-box ">
              <img src="assets/images/socialmedia/social4.jpg" class="img-fluid" alt="In media">
              <div class="in-media-box-caption">
                <div class="main-icon">
                  <a routerLink="/homepage7">	<i class="fab fa-facebook-f"></i>
                  </a>
                </div>
                <div class="media-box-caption-wrapper">
                  <div class="icon-box row">
                    <div class="col-12">
                      <img src="assets/images/user-3.jpg" alt="image">	<span class="text-light-black d-block">Rims</span>	
                    </div>
                  </div>
                  <p class="text-light-black">The new Rims
                    <a routerLink="/homepage7">	<i class="fab fa-facebook-f"></i>
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <!-- sec 1 -->
            <!-- sec 2 -->
            <div class="grid-box grid-box-small small-img-box padding-right-5">
              <img src="assets/images/socialmedia/social9.jpg" class="img-fluid" alt="In media">
              <div class="in-media-box-caption">
                <div class="main-icon">
                  <a routerLink="/homepage7">	<i class="fab fa-instagram"></i>
                  </a>
                </div>
                <div class="media-box-caption-wrapper">
                  <div class="icon-box row">
                    <div class="col-12">
                      <img src="assets/images/user-3.jpg" alt="image">	<span class="text-light-black d-block">Rims</span>	
                    </div>
                  </div>
                  <p class="text-light-black">The new Rims
                    <a routerLink="/homepage7">	<i class="fab fa-instagram"></i>
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div class="grid-box grid-box-small small-img-box">
              <img src="assets/images/socialmedia/social6.jpg" class="img-fluid" alt="In media">
              <div class="in-media-box-caption">
                <div class="main-icon">
                  <a routerLink="/homepage7">	<i class="fab fa-facebook-f"></i>
                  </a>
                </div>
                <div class="media-box-caption-wrapper">
                  <div class="icon-box row">
                    <div class="col-12">
                      <img src="assets/images/user-2.jpg" alt="image">	<span class="text-light-black d-block">Rims</span>	
                    </div>
                  </div>
                  <p class="text-light-black">The new Rims
                    <a routerLink="/homepage7">	<i class="fab fa-facebook-f"></i>
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div class="grid-box large-img-box">
              <img src="assets/images/socialmedia/social2.jpg" class="img-fluid" alt="In media">
              <div class="in-media-box-caption video-btn">
                <div class="main-icon">
                  <a routerLink="/homepage7">	<i class="fab fa-youtube"></i>
                  </a>
                </div>
                <div class="media-box-caption-wrapper">
                  <p class="text-light-black">The new Rims - Not ordinary. But thrilling.</p>
                  <div class="icon-box row">
                    <div class="col-6">
                      <img src="assets/images/user-1.jpg" alt="image">	<span class="text-light-black">Rims</span>
                    </div>
                    <div class="col-6">
                      <a routerLink="/homepage7">	<i class="fab fa-youtube"></i>
                      </a>
                    </div>
                  </div>
                  <a routerLink="/homepage7" class="btn-first orange-btn full-width">Learn More</a>
                </div>
              </div>
            </div>
            <!-- sec 2 -->
            <!-- sec 3 -->
            <div class="grid-box large-img-box">
              <img src="assets/images/socialmedia/social8.jpg" class="img-fluid" alt="In media">
              <div class="in-media-box-caption">
                <div class="main-icon">
                  <a routerLink="/homepage7">	<i class="fab fa-instagram"></i>
                  </a>
                </div>
                <div class="media-box-caption-wrapper">
                  <p class="text-light-black">The new Rims - Not ordinary. But thrilling.</p>
                  <div class="icon-box row">
                    <div class="col-6">
                      <img src="assets/images/user-2.jpg" alt="image">	<span class="text-light-black">Rims</span>
                    </div>
                    <div class="col-6">
                      <a routerLink="/homepage7">	<i class="fab fa-instagram"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="grid-box grid-box-small small-img-box padding-right-5">
              <img src="assets/images/socialmedia/social7.jpg" class="img-fluid" alt="In media">
              <div class="in-media-box-caption">
                <div class="main-icon">
                  <a routerLink="/homepage7">	<i class="fab fa-twitter"></i>
                  </a>
                </div>
                <div class="media-box-caption-wrapper">
                  <div class="icon-box row">
                    <div class="col-12">
                      <img src="assets/images/user-3.jpg" alt="image">	<span class="text-light-black d-block">Rims</span>	
                    </div>
                  </div>
                  <p class="text-light-black">The new Rims
                    <a routerLink="/homepage7">	<i class="fab fa-twitter"></i>
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div class="grid-box grid-box-small small-img-box">
              <img src="assets/images/socialmedia/social6.jpg" class="img-fluid" alt="In media">
              <div class="in-media-box-caption">
                <div class="main-icon">
                  <a routerLink="/homepage7">	<i class="fab fa-facebook-f"></i>
                  </a>
                </div>
                <div class="media-box-caption-wrapper">
                  <div class="icon-box row">
                    <div class="col-12">
                      <img src="assets/images/user-1.jpg" alt="image">	<span class="text-light-black d-block">Rims</span>	
                    </div>
                  </div>
                  <p class="text-light-black">The new Rims
                    <a routerLink="/homepage7">	<i class="fab fa-facebook-f"></i>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- sec 3 -->
        </div>
      </div>
    </section>
    <!-- In Media -->
    <!-- Find Your Best Match -->
    <section class="section-padding vehicles-slider-sec">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="section-header">
              <div class="text-wrapper text-center">
                <h3 class="fw-500 no-margin">Find Your Best Match</h3>
                <span class="line-grey"></span><span class="line-orange"></span><span class="line-grey"></span> 
                <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort. A masterful combination of style, power, sporty handling and comfort.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 p-relative">
            <div class="vehicles-slider-for">
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/homepage7">
                <img src="assets/images/logos/logo1.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">Audi</span>
                </a>
              </div>
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/homepage7">
                <img src="assets/images/logos/logo2.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">BMW</span>
                </a>
              </div>
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/homepage7">
                <img src="assets/images/logos/logo3.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">Cord</span>
                </a>
              </div>
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/homepage7">
                <img src="assets/images/logos/logo4.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">Dodge</span>
                </a>
              </div>
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/homepage7">
                <img src="assets/images/logos/logo5.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">Eagle</span>
                </a>
              </div>
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/homepage7">
                <img src="assets/images/logos/logo6.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">Ferrari</span>
                </a>
              </div>
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/homepage7">
                <img src="assets/images/logos/logo7.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">GTA</span>
                </a>
              </div>
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/homepage7">
                <img src="assets/images/logos/logo8.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">Hyundai</span>
                </a>
              </div>
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/homepage7">
                <img src="assets/images/logos/logo1.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">Imperial</span>
                </a>
              </div>
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/homepage7">
                <img src="assets/images/logos/logo2.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">Jaguar</span>
                </a>
              </div>
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/homepage7">
                <img src="assets/images/logos/logo3.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">Kia</span>
                </a>
              </div>
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/homepage7">
                <img src="assets/images/logos/logo4.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">Lamborghini</span>
                </a>
              </div>
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/homepage7">
                <img src="assets/images/logos/logo5.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">Maserati</span>
                </a>
              </div>
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/homepage7">
                <img src="assets/images/logos/logo6.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">Mercedes-Benz</span>
                </a>
              </div>
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/homepage7">
                <img src="assets/images/logos/logo1.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">Nissan</span>
                </a>
              </div>
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/homepage7">
                <img src="assets/images/logos/logo7.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">Orca</span>
                </a>
              </div>
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/homepage7">
                <img src="assets/images/logos/logo2.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">Porsche</span>
                </a>
              </div>
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/homepage7">
                <img src="assets/images/logos/logo8.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">Quant</span>
                </a>
              </div>
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/homepage7">
                <img src="assets/images/logos/logo1.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">RMR</span>
                </a>
              </div>
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/homepage7">
                <img src="assets/images/logos/logo2.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">Suzuki</span>
                </a>
              </div>
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/homepage7">
                <img src="assets/images/logos/logo2" class="img-fluid full-width" alt="Image"> <span class="slider-caption">Toyota</span>
                </a>
              </div>
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/homepage7">
                <img src="assets/images/logos/logo5.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">Ultima</span>
                </a>
              </div>
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/homepage7">
                <img src="assets/images/logos/logo5.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">Vauxhall</span>
                </a>
              </div>
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/homepage7">
                <img src="assets/images/logos/logo6.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">Wanderer</span>
                </a>
              </div>
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/homepage7">
                <img src="assets/images/logos/logo7.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">Yamaha</span>
                </a>
              </div>
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/homepage7">
                <img src="assets/images/logos/logo8.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">Zagato</span>
                </a>
              </div>
            </div>
            <div class="row vehicles-border">
              <div class="vehicles-slider-nav col-12">
                <div class="vehicles-slider-nav-item"> <span>A</span>
                </div>
                <div class="vehicles-slider-nav-item"> <span>B</span>
                </div>
                <div class="vehicles-slider-nav-item"> <span>C</span>
                </div>
                <div class="vehicles-slider-nav-item"> <span>D</span>
                </div>
                <div class="vehicles-slider-nav-item"> <span>E</span>
                </div>
                <div class="vehicles-slider-nav-item"> <span>F</span>
                </div>
                <div class="vehicles-slider-nav-item"> <span>G</span>
                </div>
                <div class="vehicles-slider-nav-item"> <span>H</span>
                </div>
                <div class="vehicles-slider-nav-item"> <span>I</span>
                </div>
                <div class="vehicles-slider-nav-item"> <span>J</span>
                </div>
                <div class="vehicles-slider-nav-item"> <span>K</span>
                </div>
                <div class="vehicles-slider-nav-item"> <span>L</span>
                </div>
                <div class="vehicles-slider-nav-item"> <span>M</span>
                </div>
                <div class="vehicles-slider-nav-item"> <span>N</span>
                </div>
                <div class="vehicles-slider-nav-item"> <span>O</span>
                </div>
                <div class="vehicles-slider-nav-item"> <span>P</span>
                </div>
                <div class="vehicles-slider-nav-item"> <span>Q</span>
                </div>
                <div class="vehicles-slider-nav-item"> <span>R</span>
                </div>
                <div class="vehicles-slider-nav-item"> <span>S</span>
                </div>
                <div class="vehicles-slider-nav-item"> <span>T</span>
                </div>
                <div class="vehicles-slider-nav-item"> <span>U</span>
                </div>
                <div class="vehicles-slider-nav-item"> <span>V</span>
                </div>
                <div class="vehicles-slider-nav-item"> <span>W</span>
                </div>
                <div class="vehicles-slider-nav-item"> <span>X</span>
                </div>
                <div class="vehicles-slider-nav-item"> <span>Y</span>
                </div>
                <div class="vehicles-slider-nav-item"> <span>Z</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="right-section-car-box">
          <div class="row">
            <div class="col-xl col-lg-4 col-md-6 col-sm-6">
              <div class="car-grid-layout-box">
                <div class="car-grid-layout-inner text-center">
                  <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">Acura TLX </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
                  <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
                  <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p>
                  <span class="text-orange price-text">$25,000</span>
                  <div class="car-grid-layout-img">
                    <img src="assets/images/showcar7.jpg" class="img-fluid full-width" alt="Car img">
                  </div>
                  <div class="car-grid-layout-details">	<span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 22,000 Km</span>
                    <span class="text-dark-white p-relative"><i class="icon-car"></i> 2012 Model</span>
                  </div>
                  <div class="car-grid-layout-location">	<span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> New York</span>
                    <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Automatic</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl col-lg-4 col-md-6 col-sm-6">
              <div class="car-grid-layout-box">
                <div class="car-grid-layout-inner text-center">
                  <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">BMW 4 Series </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
                  <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
                  <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p>
                  <span class="text-orange price-text">$20,000</span>
                  <div class="car-grid-layout-img">
                    <img src="assets/images/showcar8.jpg" class="img-fluid full-width" alt="Car img">
                  </div>
                  <div class="car-grid-layout-details">	<span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 40,000 Km</span>
                    <span class="text-dark-white p-relative"><i class="icon-car"></i> 2008 Model</span>
                  </div>
                  <div class="car-grid-layout-location">	<span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> London</span>
                    <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Manual</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl col-lg-4 col-md-6 col-sm-6">
              <div class="car-grid-layout-box">
                <div class="car-grid-layout-inner text-center">
                  <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">Audi A4 </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
                  <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
                  <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p>
                  <span class="text-orange price-text">$30,000</span>
                  <div class="car-grid-layout-img">
                    <img src="assets/images/showcar9.jpg" class="img-fluid full-width" alt="Car img">
                  </div>
                  <div class="car-grid-layout-details">	<span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 15,000 Km</span>
                    <span class="text-dark-white p-relative"><i class="icon-car"></i> 2014 Model</span>
                  </div>
                  <div class="car-grid-layout-location">	<span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> Germany</span>
                    <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Automatic</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl col-lg-4 col-md-6 col-sm-6">
              <div class="car-grid-layout-box">
                <div class="car-grid-layout-inner text-center">
                  <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">Infiniti Q50 </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
                  <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
                  <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p>
                  <span class="text-orange price-text">$23,000</span>
                  <div class="car-grid-layout-img">
                    <img src="assets/images/showcar7.jpg" class="img-fluid full-width" alt="Car img">
                  </div>
                  <div class="car-grid-layout-details">	<span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 28,000 Km</span>
                    <span class="text-dark-white p-relative"><i class="icon-car"></i> 2010 Model</span>
                  </div>
                  <div class="car-grid-layout-location">	<span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> New York</span>
                    <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Manual</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl col-lg-4 col-md-6 col-sm-6">
              <div class="car-grid-layout-box">
                <div class="car-grid-layout-inner text-center">
                  <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">Mercedes-Benz </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
                  <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
                  <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p>
                  <span class="text-orange price-text">$28,000</span>
                  <div class="car-grid-layout-img">
                    <img src="assets/images/showcar8.jpg" class="img-fluid full-width" alt="Car img">
                  </div>
                  <div class="car-grid-layout-details">	<span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 27,000 Km</span>
                    <span class="text-dark-white p-relative"><i class="icon-car"></i> 2011 Model</span>
                  </div>
                  <div class="car-grid-layout-location">	<span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> New York</span>
                    <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Automatic</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Find Your Best Match -->
    <!-- What Are You Looking For? -->
    <section class="section-padding">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="section-header">
              <div class="text-wrapper text-center">
                <h3 class="fw-500 no-margin">What Are You Looking For?</h3>
                <span class="line-grey"></span><span class="line-orange"></span><span class="line-grey"></span> 
                <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort. A masterful combination of style, power, sporty handling and comfort.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="shopping-sec">
          <div class="row">
            <div class="col-xl-5 col-lg-5 col-md-6">
              <div class="img-box">
                <a routerLink="/add-listing">
                  <img src="assets/images/list.jpg" class="img-fluid full-width" alt="Image">
                  <div class="text-box">
                    <h4 class="text-custom-white rubik no-margin"> List A Vehicle</h4>
                  </div>
                </a>
              </div>
            </div>
            <div class="col-xl col-lg col-md-6">
              <div class="img-box">
                <a routerLink="/listing">
                  <img src="assets/images/list2.jpg" class="img-fluid full-width" alt="Image">
                  <div class="text-box">
                    <h4 class="text-custom-white rubik no-margin"> Find A Car Within Budget</h4>
                  </div>
                </a>
              </div>
            </div>
            <div class="col-xl col-lg col-md-6">
              <div class="img-box">
                <a routerLink="/register">
                  <img src="assets/images/list3.jpg" class="img-fluid full-width" alt="Image">
                  <div class="text-box">
                    <h4 class="text-custom-white rubik no-margin"> Register An Account</h4>
                  </div>
                </a>
              </div>
            </div>
            <div class="col-xl col-lg col-md-6">
              <div class="img-box">
                <a routerLink="/contact">
                  <img src="assets/images/list4.jpg" class="img-fluid full-width" alt="Image">
                  <div class="text-box">
                    <h4 class="text-custom-white rubik no-margin"> Contact Us</h4>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- What Are You Looking For? -->
    <!-- Models & Specs -->
    <section class="section-padding model-spec-sec  bg-light-theme pb-0">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="section-header">
              <div class="text-wrapper text-center">
                <h3 class="fw-500 no-margin">Sponsored Models</h3>
                <span class="line-grey"></span><span class="line-orange"></span><span class="line-grey"></span> 
                <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort. A masterful combination of style, power, sporty handling and comfort.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="p-relative">
              <div class="model-spec-slider">
                <div class="model-spec-slider-item p-relative">
                  <div class="model-spec-top-sec bg-light-theme">
                    <a routerLink="/car-detail">
                      <h4 class="text-dark-grey">BMW 535i</h4>
                      <p class="text-dark-grey">From <strong>$ 34,950.00</strong>
                      </p>
                      <p class="text-dark-grey no-margin">*Manufacturer’s Suggested Retail Price. Excludes options; taxes; title; registration; delivery, processing and handling fee; dealer charges.</p>
                    </a>
                  </div>
                  <img src="assets/images/showcar2.jpg" class="img-fluid full-width" alt="Model Image">
                  <div class="bg-white">
                    <div class="model-spec-wrapper">
                      <div class="model-spec-bottom-sec">
                        <ul>
                          <li>
                            <h5 class="text-custom-black">379 hp</h5>
                            <p class="text-custom-black">Max. power</p>
                          </li>
                          <li>
                            <h5 class="text-custom-black">4.2 s</h5>
                            <p class="text-custom-black">0-60 mph</p>
                          </li>
                          <li>
                            <h5 class="text-custom-black">180 mph</h5>
                            <p class="text-custom-black">Top Track Speed</p>
                          </li>
                          <li>
                            <h5 class="text-custom-black">6,500 rpm</h5>
                            <p class="text-custom-black">Max. power at rpm</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="model-spec-slider-item p-relative">
                  <div class="model-spec-top-sec bg-light-theme">
                    <a routerLink="/car-detail">
                      <h4 class="text-dark-grey">Audi TT v2</h4>
                      <p class="text-dark-grey">From <strong>$ 32,500.00</strong>
                      </p>
                      <p class="text-dark-grey no-margin">*Manufacturer’s Suggested Retail Price. Excludes options; taxes; title; registration; delivery, processing and handling fee; dealer charges.</p>
                    </a>
                  </div>
                  <img src="assets/images/showcar3.jpg" class="img-fluid full-width" alt="Model Image">
                  <div class="bg-white">
                    <div class="model-spec-wrapper">
                      <div class="model-spec-bottom-sec">
                        <ul>
                          <li>
                            <h5 class="text-custom-black">379 hp</h5>
                            <p class="text-custom-black">Max. power</p>
                          </li>
                          <li>
                            <h5 class="text-custom-black">4.0 s</h5>
                            <p class="text-custom-black">0-60 mph</p>
                          </li>
                          <li>
                            <h5 class="text-custom-black">180 mph</h5>
                            <p class="text-custom-black">Top Track Speed</p>
                          </li>
                          <li>
                            <h5 class="text-custom-black">6,500 rpm</h5>
                            <p class="text-custom-black">Max. power at rpm</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="model-spec-slider-item p-relative">
                  <div class="model-spec-top-sec bg-light-theme">
                    <a routerLink="/car-detail">
                      <h4 class="text-dark-grey">Mercedes-Benz E-Class</h4>
                      <p class="text-dark-grey">From <strong>$ 25,900.00</strong>
                      </p>
                      <p class="text-dark-grey no-margin">*Manufacturer’s Suggested Retail Price. Excludes options; taxes; title; registration; delivery, processing and handling fee; dealer charges.</p>
                    </a>
                  </div>
                  <img src="assets/images/showcar4.jpg" class="img-fluid full-width" alt="Model Image">
                  <div class="bg-white">
                    <div class="model-spec-wrapper">
                      <div class="model-spec-bottom-sec">
                        <ul>
                          <li>
                            <h5 class="text-custom-black">379 hp</h5>
                            <p class="text-custom-black">Max. power</p>
                          </li>
                          <li>
                            <h5 class="text-custom-black">4.2 s</h5>
                            <p class="text-custom-black">0-60 mph</p>
                          </li>
                          <li>
                            <h5 class="text-custom-black">179 mph</h5>
                            <p class="text-custom-black">Top Track Speed</p>
                          </li>
                          <li>
                            <h5 class="text-custom-black">6,500 rpm</h5>
                            <p class="text-custom-black">Max. power at rpm</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="model-spec-slider-item p-relative">
                  <div class="model-spec-top-sec bg-light-theme">
                    <a routerLink="/car-detail">
                      <h4 class="text-dark-grey">Panamera Turbo S E-Hybrid Executive</h4>
                      <p class="text-dark-grey">From <strong>$ 198, 100.00</strong>
                      </p>
                      <p class="text-dark-grey no-margin">*Manufacturer’s Suggested Retail Price. Excludes options; taxes; title; registration; delivery, processing and handling fee; dealer charges.</p>
                    </a>
                  </div>
                  <img src="assets/images/showcar5.jpg" class="img-fluid full-width" alt="Model Image">
                  <div class="bg-white">
                    <div class="model-spec-wrapper">
                      <div class="model-spec-bottom-sec">
                        <ul>
                          <li>
                            <h5 class="text-custom-black">433 hp</h5>
                            <p class="text-custom-black">Max. power</p>
                          </li>
                          <li>
                            <h5 class="text-custom-black">3.3 s</h5>
                            <p class="text-custom-black">0-60 mph</p>
                          </li>
                          <li>
                            <h5 class="text-custom-black">191 mph</h5>
                            <p class="text-custom-black">Top Track Speed</p>
                          </li>
                          <li>
                            <h5 class="text-custom-black">6,500 rpm</h5>
                            <p class="text-custom-black">Max. power at rpm</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="model-spec-slider-arrows">
                <div class="model-spec-slider-prevtab slick-arrow"> <i class="flaticon-left-chevron"></i>
                </div>
                <div class="model-spec-slider-nexttab slick-arrow"> <i class="flaticon-right-chevron"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Models & Specs -->
    <!-- Models Currently Trending -->
    <section class="section-padding model-gallery">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="section-header">
              <div class="text-wrapper text-center">
                <h3 class="fw-500 no-margin">Models Currently Trending</h3>
                <span class="line-grey"></span><span class="line-orange"></span><span class="line-grey"></span> 
                <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort. A masterful combination of style, power, sporty handling and comfort.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="custom-col">
            <img src="assets/images/trendingmodels/trending1.jpg" class="img-fluid full-width" alt="Image">
            <div class="model-gallery-caption">
              <div class="model-gallery-wrapper-1">
                <h3 class="text-custom-white model-gal-title">Acura</h3>
                <h6 class="text-custom-white rubik">From <strong>$ 25,900.00*</strong></h6>
              </div>
              <div class="model-gallery-wrapper-2"> <a routerLink="/listing" class="btn-second orange-btn">View Category</a>
                <a routerLink="/listing" class="btn-fourth border-btn">All Models</a>
              </div>
            </div>
          </div>
          <div class="custom-col">
            <img src="assets/images/trendingmodels/trending2.jpg" class="img-fluid full-width" alt="Image">
            <div class="model-gallery-caption">
              <div class="model-gallery-wrapper-1">
                <h3 class="text-custom-white model-gal-title">Audi</h3>
                <h6 class="text-custom-white rubik">From <strong>$ 32,500.00*</strong></h6>
              </div>
              <div class="model-gallery-wrapper-2"> <a routerLink="/listing" class="btn-second orange-btn">View Category</a>
                <a routerLink="/listing" class="btn-fourth border-btn">All Models</a>
              </div>
            </div>
          </div>
          <div class="custom-col">
            <img src="assets/images/trendingmodels/trending3.jpg" class="img-fluid full-width" alt="Image">
            <div class="model-gallery-caption">
              <div class="model-gallery-wrapper-1">
                <h3 class="text-custom-white model-gal-title">BMW</h3>
                <h6 class="text-custom-white rubik">From <strong>$ 34,950.00*</strong></h6>
              </div>
              <div class="model-gallery-wrapper-2"> <a routerLink="/listing" class="btn-second orange-btn">View Category</a>
                <a routerLink="/listing" class="btn-fourth border-btn">All Models</a>
              </div>
            </div>
          </div>
          <div class="custom-col">
            <img src="assets/images/trendingmodels/trending4.jpg" class="img-fluid full-width" alt="Image">
            <div class="model-gallery-caption">
              <div class="model-gallery-wrapper-1">
                <h3 class="text-custom-white model-gal-title">Ford</h3>
                <h6 class="text-custom-white rubik">From <strong>$ 14,260.00*</strong></h6>
              </div>
              <div class="model-gallery-wrapper-2"> <a routerLink="/listing" class="btn-second orange-btn">View Category</a>
                <a routerLink="/listing" class="btn-fourth border-btn">All Models</a>
              </div>
            </div>
          </div>
          <div class="custom-col">
            <img src="assets/images/trendingmodels/trending5.jpg" class="img-fluid full-width" alt="Image">
            <div class="model-gallery-caption">
              <div class="model-gallery-wrapper-1">
                <h3 class="text-custom-white model-gal-title">Range Rover</h3>
                <h6 class="text-custom-white rubik">From <strong>$ 37,800.00*</strong></h6>
              </div>
              <div class="model-gallery-wrapper-2"> <a routerLink="/listing" class="btn-second orange-btn">View Category</a>
                <a routerLink="/listing" class="btn-fourth border-btn">All Models</a>
              </div>
            </div>
          </div>
          <div class="custom-col">
            <img src="assets/images/trendingmodels/trending6.jpg" class="img-fluid full-width" alt="Image">
            <div class="model-gallery-caption">
              <div class="model-gallery-wrapper-1">
                <h3 class="text-custom-white model-gal-title">Maserati</h3>
                <h6 class="text-custom-white rubik">From <strong>$ 74,980.00*</strong></h6>
              </div>
              <div class="model-gallery-wrapper-2"> <a routerLink="/listing" class="btn-second orange-btn">View Category</a>
                <a routerLink="/listing" class="btn-fourth border-btn">All Models</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Models Currently Trending -->
    <!-- newsletter -->
    <div class="newsletter p-relative section-padding">
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="col-lg-8">
            <div class="content-wrapper text-center">
              <h3 class="text-custom-white rubik">Sign up and get <strong>10% OFF</strong> your first order</h3>
              <div class="input-group">
                <input type="text" class="form-control form-control-custom" placeholder="Enter your Email I'd">
                <div class="input-group-append">
                  <button class="btn-second orange-btn" type="submit">SUBSCRIBE</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- newsletter -->
<app-footer3></app-footer3>
    <div class="modal fade" id="register">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title text-left no-margin"><i class="fas fa-unlock-alt mr-2"></i> <span id="signup-text">Sign Up</span><span
              id="signin-text" class="none">Sign In</span></h6>
            <button type="button" class="close" data-dismiss="modal">&times;</button>
          </div>
          <!-- Sign up form -->
          <div class="modal-body" id="signup-form">
            <form>
              <div class="form-group">
                <input type="email" class="form-control" name="#" placeholder="Email">
              </div>
              <div class="form-group">
                <input type="password" class="form-control" name="#" placeholder="Password">
              </div>
              <div class="form-group">
                <button type="submit" class="btn-second orange-btn full-width">Sign Up</button>
              </div>
            </form>
            <div class="form-excerpts">
              <ul>
                <li>Already a member? <a href="javascript:void(0)" id="signin-btn">Sign In</a>
                </li>
                <li><a routerLink="/homepage7">Recover Password</a>
                </li>
              </ul>
            </div>
            <div class="social-login">
              <span>Or Signup with</span>
              <div class="social-btn">	<a routerLink="/homepage7" class="facebook"><i class="fab fa-facebook-f"></i> Facebook</a>
                <a routerLink="/homepage7" class="google"><i class="fab fa-google-plus-g"></i> Google</a>
              </div>
            </div>
          </div>
          <!-- Sign up form -->
          <!-- Sign in form -->
          <div class="modal-body none" id="signin-form">
            <form>
              <div class="form-group">
                <input type="text" class="form-control" name="#" placeholder="Username or Email">
              </div>
              <div class="form-group">
                <input type="password" class="form-control" name="#" placeholder="Password">
              </div>
              <div class="form-group">
                <label class="custom-checkbox">
                <input type="checkbox" name="#">Keep me signed in	<span class="checkmark"></span>
                </label>
              </div>
              <div class="form-group">
                <button type="submit" class="btn-second orange-btn full-width">Sign In</button>
              </div>
            </form>
            <div class="form-excerpts">
              <ul>
                <li>Not a member? <a href="javascript:void(0)" id="signup-btn">Sign Up</a>
                </li>
                <li><a routerLink="/homepage7">Recover Password</a>
                </li>
              </ul>
            </div>
            <div class="social-login">
              <span>Or connect with</span>
              <div class="social-btn">	<a routerLink="/homepage7" class="facebook"><i class="fab fa-facebook-f"></i> Facebook</a>
                <a routerLink="/homepage7" class="google"><i class="fab fa-google-plus-g"></i> Google</a>
              </div>
            </div>
          </div>
          <!-- Sign in form -->
        </div>
      </div>
    </div>
    <div class="menu-style-3">
      <div class="search-box search-elem">
        <button class="close">x</button>
        <div class="inner row">
          <div class="col-12">
            <label class="text-custom-white">Search Your Category</label>
            <div class="input-group">
              <input type="text" class="form-control form-control-custom" placeholder="Search keywords, category, brand of part">
              <div class="input-group-prepend">
                <select class="menu-style-3-search">
                  <option>All Category</option>
                  <option>Luxury Car</option>
                  <option>Sports Car</option>
                  <option>Vintage Car</option>
                  <option>Excutive Car</option>
                  <option>Economy Car</option>
                </select>
              </div>
              <div class="input-group-append">
                <button class="btn-second orange-btn full-width" type="submit">Search</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>