<!-- Footer -->
    <footer class="bg-custom-black" id="footer-style-2">
      <div class="offset-xl-1 col-xl-10">
        <div class="section-padding pb-0">
          <div class="container-fluid">
            <div class="row">
              <div class="footer-top-sec col-md-12">
                <div class="cols5-head">
                  <div class="cols">
                    <div class="inner-box">
                      <a routerLink="/car-page">
                        <h6 class="text-custom-white">Sell Your Car <i class="flaticon-right-chevron"></i></h6>
                      </a>
                    </div>
                  </div>
                  <div class="cols">
                    <div class="inner-box">
                      <a routerLink="/car-page">
                        <h6 class="text-custom-white">Find A Car <i class="flaticon-right-chevron"></i></h6>
                      </a>
                    </div>
                  </div>
                  <div class="cols">
                    <div class="inner-box">
                      <a routerLink="/contact">
                        <h6 class="text-custom-white">Find A Dealer <i class="flaticon-right-chevron"></i></h6>
                      </a>
                    </div>
                  </div>
                  <div class="cols">
                    <div class="inner-box">
                      <a routerLink="/login">
                        <h6 class="text-custom-white">Create Profile <i class="flaticon-right-chevron"></i></h6>
                      </a>
                    </div>
                  </div>
                  <div class="cols">
                    <div class="inner-box">
                      <a routerLink="/contact">
                        <h6 class="text-custom-white">Contact Us <i class="flaticon-right-chevron"></i></h6>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="footer-bottom">
                  <div class="ft-section-1">
                    <ul>
                      <li class="text-custom-white">Passenger Cars</li>
                      <li class="float-right"><a href="javascript:void(0)" class="text-custom-white">Up <i class="flaticon-up-chevron"></i></a>
                      </li>
                    </ul>
                  </div>
                  <div class="ft-section-2">
                    <p class="text-custom-white">Rims on the social web:</p>
                    <ul class="social-icons">
                      <li><a href="javascript:void(0)" class="text-dark-white"><i class="fab fa-facebook-f"></i></a>
                      </li>
                      <li><a href="javascript:void(0)" class="text-dark-white"><i class="fab fa-twitter"></i></a>
                      </li>
                      <li><a href="javascript:void(0)" class="text-dark-white"><i class="fab fa-pinterest-p"></i></a>
                      </li>
                      <li><a href="javascript:void(0)" class="text-dark-white"><i class="fab fa-instagram"></i></a>
                      </li>
                    </ul>
                  </div>
                  <div class="ft-section-3">
                    <div class="row">
                      <div class="col-xl-2 col-lg-2 col-md-4 col-sm-12">
                        <div class="footer-box">
                          <h6 class="text-custom-white">About us</h6>
                          <ul>
                            <li><a href="javascript:void(0)" class="text-dark-white">Contact Us</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-xl-2 col-lg-2 col-md-4 col-sm-12">
                        <div class="footer-box">
                          <h6 class="text-custom-white">Online services</h6>
                          <ul>
                            <li><a href="javascript:void(0)" class="text-dark-white">Dealer Search</a>
                            </li>
                            <li><a href="javascript:void(0)" class="text-dark-white">Book a test drive</a>
                            </li>
                            <li><a href="javascript:void(0)" class="text-dark-white">Book Service Appointment</a>
                            </li>
                            <li><a href="javascript:void(0)" class="text-dark-white">Pre-Owned Cars</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-xl-2 col-lg-2 col-md-4 col-sm-12">
                        <div class="footer-box">
                          <h6 class="text-custom-white">Purchase Advice</h6>
                          <ul>
                            <li><a href="javascript:void(0)" class="text-dark-white">Online Service Estimate</a>
                            </li>
                            <li><a href="javascript:void(0)" class="text-dark-white">Accessories Configurator</a>
                            </li>
                            <li><a href="javascript:void(0)" class="text-dark-white">Service Package Configurator</a>
                            </li>
                            <li><a href="javascript:void(0)" class="text-dark-white">Advance Assurance Program</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-xl-2 col-lg-2 col-md-4 col-sm-12">
                        <div class="footer-box">
                          <h6 class="text-custom-white">DFSI</h6>
                          <ul>
                            <li><a href="javascript:void(0)" class="text-dark-white">Grievance Redressal</a>
                            </li>
                            <li><a href="javascript:void(0)" class="text-dark-white">Fair Practice Code</a>
                            </li>
                            <li><a href="javascript:void(0)" class="text-dark-white">Corporate Governance Policy</a>
                            </li>
                            <li><a href="javascript:void(0)" class="text-dark-white">Ombudsman Scheme for NBFC</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-xl-2 col-lg-2 col-md-4 col-sm-12">
                        <div class="footer-box">
                          <h6 class="text-custom-white">Heading</h6>
                          <ul>
                            <li><a href="javascript:void(0)" class="text-dark-white">Nodal Officer DFSI</a>
                            </li>
                            <li><a href="javascript:void(0)" class="text-dark-white">CSR Policy</a>
                            </li>
                            <li><a href="javascript:void(0)" class="text-dark-white">Interest Rate Model</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-xl-2 col-lg-2 col-md-4 col-sm-12">
                        <div class="footer-box">
                          <h6 class="text-custom-white">CSR Policy</h6>
                          <ul>
                            <li><a href="javascript:void(0)" class="text-dark-white">CSR Policy</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-xl-2 col-lg-2 col-md-4 col-sm-12">
                        <div class="footer-box">
                          <h6 class="text-custom-white">Annual Return</h6>
                          <ul>
                            <li><a href="javascript:void(0)" class="text-dark-white">Extract of Annual Return</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-xl-2 col-lg-2 col-md-4 col-sm-12">
                        <div class="footer-box">
                          <h6 class="text-custom-white">Notice of 25th AGM</h6>
                          <ul>
                            <li><a href="javascript:void(0)" class="text-dark-white">AGM Notice</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-12">
              <div class="copyright">
                <ul class="text-center">
                  <li><a href="javascript:void(0)" class="text-dark-white">© 2020. Rims India Pvt. Ltd. All Rights Reserved (provider)</a>
                  </li>
                  <li><a href="javascript:void(0)" class="text-dark-white">Cookies</a>
                  </li>
                  <li><a href="javascript:void(0)" class="text-dark-white">Data Protection</a>
                  </li>
                  <li><a href="javascript:void(0)" class="text-dark-white">Legal Notice</a>
                  </li>
                  <li><a href="javascript:void(0)" class="text-dark-white">Caution - Fraudulent Job offerings</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- Footer -->