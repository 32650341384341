<app-header2></app-header2>
<!-- Banner sec -->
    <section class="fix-banner p-relative">
      <img src="assets/images/banner/bannerh5.jpg" class="img-fluid full-width" alt="Banner">
      <div class="fix-banner-overlay"></div>
      <div class="fix-banner-wrapper">
        <div class="container">
          <div class="row">
            <div class="offset-lg-1 col-lg-10">
              <div class="fix-banner-text-wrapper">
                <h1 class="text-custom-white fs-50">Discover The Car Of Your Dreams</h1>
                <!-- Search -->
                <div class="search-box">
                  <div class="input-group">
                    <input type="search" class="form-control" autocomplete="off" name="#" placeholder="What are you looking for?">
                    <div class="input-group-prepend">
                      <ul class="cat-selc">
                        <li class="init">Location</li>
                        <li data-value="New York">New York</li>
                        <li data-value="California">California</li>
                        <li data-value="Washington">Washington</li>
                        <li data-value="New Jersey">New Jersey</li>
                      </ul>
                    </div>
                    <div class="input-group-append">
                      <button class="btn-second orange-btn" type="button">Explore Now</button>
                    </div>
                  </div>
                </div>
                <!-- Search -->
              </div>
            </div>
            <div class="offset-lg-1 col-lg-10">
              <div class="fix-banner-text-wrapper">
                <div class="banner-tab-slider">
                  <div class="icon-item">
                    <a routerLink="/homepage5">	<span class="icon">
                    <img src="assets/images/luxury-car.png" alt="#">
                    </span>
                    <span class="cat-name text-custom-white fw-600">Luxury Car</span>
                    </a>
                  </div>
                  <div class="icon-item">
                    <a routerLink="/homepage5">	<span class="icon">
                    <img src="assets/images/sports-car.png" alt="#">
                    </span>
                    <span class="cat-name text-custom-white fw-600">Sports Car</span>
                    </a>
                  </div>
                  <div class="icon-item">
                    <a routerLink="/homepage5">	<span class="icon">
                    <img src="assets/images/vintage-car.png" alt="#">
                    </span>
                    <span class="cat-name text-custom-white fw-600">Vintage Car</span>
                    </a>
                  </div>
                  <div class="icon-item">
                    <a routerLink="/homepage5">	<span class="icon">
                    <img src="assets/images/excutive-car.png" alt="#">
                    </span>
                    <span class="cat-name text-custom-white fw-600">Excutive Car</span>
                    </a>
                  </div>
                  <div class="icon-item">
                    <a routerLink="/homepage5">	<span class="icon">
                    <img src="assets/images/economy-car.png" alt="#">
                    </span>
                    <span class="cat-name text-custom-white fw-600">Economy Car</span>
                    </a>
                  </div>
                  <div class="icon-item">
                    <a routerLink="/homepage5">	<span class="icon">
                    <img src="assets/images/luxury-car.png" alt="#">
                    </span>
                    <span class="cat-name text-custom-white fw-600">Luxury Car</span>
                    </a>
                  </div>
                  <div class="icon-item">
                    <a routerLink="/homepage5">	<span class="icon">
                    <img src="assets/images/sports-car.png" alt="#">
                    </span>
                    <span class="cat-name text-custom-white fw-600">Sports Car</span>
                    </a>
                  </div>
                  <div class="icon-item">
                    <a routerLink="/homepage5">	<span class="icon">
                    <img src="assets/images/vintage-car.png" alt="#">
                    </span>
                    <span class="cat-name text-custom-white fw-600">Vintage Car</span>
                    </a>
                  </div>
                  <div class="icon-item">
                    <a routerLink="/homepage5">	<span class="icon">
                    <img src="assets/images/excutive-car.png" alt="#">
                    </span>
                    <span class="cat-name text-custom-white fw-600">Excutive Car</span>
                    </a>
                  </div>
                  <div class="icon-item">
                    <a routerLink="/homepage5">	<span class="icon">
                    <img src="assets/images/economy-car.png" alt="#">
                    </span>
                    <span class="cat-name text-custom-white fw-600">Economy Car</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Banner sec -->
    <!-- Our vehicles -->
    <!-- Desktop -->
    <section class="section-padding our-vehicles-inner-page pb-0">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="section-header">
              <div class="text-wrapper text-center">
                <h3 class="fw-500 no-margin">Recent Listed Vehicles</h3>
                <span class="line-grey"></span><span class="line-orange"></span><span class="line-grey"></span> 
                <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort. A masterful combination of style, power, sporty handling and comfort.</p>
              </div>
            </div>
            <button type="button" class="model-reset-top"><i class="far fa-times-circle"></i> Reset all filters</button>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="accordion" id="accordionExample">
              <div class="card">
                <div class="card-header">
                  <div class="row">
                    <div class="col-6">
                      <div class="accordion-tabs align-item-center collapsed vehiclemodel" data-toggle="collapse" data-target="#vehiclemodel">	<i class="flaticon-supercar"></i>
                        <span class="text-light-black"> Filter by Model</span>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="accordion-tabs align-item-center collapsed vehicleprice" data-toggle="collapse" data-target="#vehicleprice">	<i class="flaticon-badge"></i>
                        <span class="text-light-black">Filter by Price</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="vehiclemodel" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                  <div class="card-body bg-white">
                    <div class="content-box filters">
                      <h5 class="text-light-black">Vehicle model <span class="closebtn collapsed" data-toggle="collapse" data-target="#vehiclemodel">X</span></h5>
                      <ul class="button-group js-radio-button-group">
                        <li class="inner-tab-sec" data-filter=".saloon">
                          <img src="assets/images/saloon-black.png" alt="Car Image">
                          <img src="assets/images/saloon-orange.png" alt="Car Image">	<span class="text-light-black">Saloon</span>
                        </li>
                        <li class="inner-tab-sec" data-filter=".coupe">
                          <img src="assets/images/coupe-black.png" alt="Car Image">
                          <img src="assets/images/coupe-orange.png" alt="Car Image">	<span class="text-light-black">Coupe</span>
                        </li>
                        <li class="inner-tab-sec" data-filter=".cabriolet">
                          <img src="assets/images/cabriolet-black.png" alt="Car Image">
                          <img src="assets/images/cabriolet-orange.png" alt="Car Image">	<span class="text-light-black">Cabriolet</span>
                        </li>
                        <li class="inner-tab-sec" data-filter=".sportstourer">
                          <img src="assets/images/sportstourer-black.png" alt="Car Image">
                          <img src="assets/images/sportstourer-orange.png" alt="Car Image">	<span class="text-light-black">Sports Tourer</span>
                        </li>
                        <li class="inner-tab-sec" data-filter=".suv">
                          <img src="assets/images/suv-black.png" alt="Car Image">
                          <img src="assets/images/suv-orange.png" alt="Car Image">	<span class="text-light-black">SUV</span>
                        </li>
                      </ul>
                      <button type="button" class="model-reset"><i class="far fa-times-circle"></i> Reset all filters</button>
                    </div>
                  </div>
                </div>
                <div id="vehicleprice" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                  <div class="card-body bg-white">
                    <div class="content-box">
                      <h5 class="text-light-black">Price <span class="closebtn collapsed" data-toggle="collapse" data-target="#vehicleprice" >X</span></h5>
                      <div class="price-filter">
                        <div class="row slider-labels">
                          <div class="col-md-2 col-sm-6 caption">
                            <input type="text" class="text-right" id="slider-range-value1" readonly>
                          </div>
                          <div class="col-md-8 col-sm-12 align-self-center">
                            <div id="slider-range"></div>
                          </div>
                          <div class="col-md-2 col-sm-6  caption">
                            <input type="text" id="slider-range-value2" readonly>
                          </div>
                        </div>
                      </div>
                      <button type="button" class="model-reset"><i class="far fa-times-circle"></i> Reset all filters</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row grid">
          <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 filter-box saloon">
            <div class="product-main-box p-relative">
              <div class="product-box">
                <div class="product-img-sec">
                  <a routerLink="/car-detail">
                  <img src="assets/images/listing/listing2.jpg" class="img-fluid" alt="Product Image">
                  </a>
                </div>
                <div class="product-caption">
                  <h5 class="text-light-black">L-Class</h5>
                  <p class="text-dark-white fw-500">Starting from $
                    <br>$25.000 / $35,000
                  </p>
                </div>
              </div>
              <div class="product-box product-box-section-2">
                <div class="product-img-sec">
                  <a routerLink="/car-detail">
                  <img src="assets/images/listing/listing1.jpg" class="img-fluid" alt="Product Image">
                  </a>
                </div>
                <div class="product-caption">
                  <h5 class="text-light-black">L-Class</h5>
                  <p class="text-dark-white fw-500">Starting from $
                    <br>$25.000 / $35,000
                  </p>
                </div>
                <div class="button-sec">	<a routerLink="/car-detail" class="btn-second orange-btn full-width">Learn more</a>
                  <a routerLink="/homepage5" class="btn-first black-btn full-width">More from owner</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 filter-box coupe">
            <div class="product-main-box p-relative">
              <div class="product-box">
                <div class="product-img-sec">
                  <a routerLink="/car-detail">
                  <img src="assets/images/listing/listing1.jpg" class="img-fluid" alt="Product Image">
                  </a>
                </div>
                <div class="product-caption">
                  <h5 class="text-light-black">O-Class</h5>
                  <p class="text-dark-white fw-500">Starting from $
                    <br>$25.000 / $35,000
                  </p>
                </div>
              </div>
              <div class="product-box product-box-section-2">
                <div class="product-img-sec">
                  <a routerLink="/car-detail">
                  <img src="assets/images/listing/listing2.jpg" class="img-fluid" alt="Product Image">
                  </a>
                </div>
                <div class="product-caption">
                  <h5 class="text-light-black">O-Class</h5>
                  <p class="text-dark-white fw-500">Starting from $
                    <br>$25.000 / $35,000
                  </p>
                </div>
                <div class="button-sec">	<a routerLink="/car-detail" class="btn-second orange-btn full-width">Learn more</a>
                  <a routerLink="/homepage5" class="btn-first black-btn full-width">More from owner</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 filter-box cabriolet">
            <div class="product-main-box p-relative">
              <div class="product-box">
                <div class="product-img-sec">
                  <a routerLink="/car-detail">
                  <img src="assets/images/listing/listing4.jpg" class="img-fluid" alt="Product Image">
                  </a>
                </div>
                <div class="product-caption">
                  <h5 class="text-light-black">R-Class</h5>
                  <p class="text-dark-white fw-500">Starting from $
                    <br>$25.000 / $35,000
                  </p>
                </div>
              </div>
              <div class="product-box product-box-section-2">
                <div class="product-img-sec">
                  <a routerLink="/car-detail">
                  <img src="assets/images/listing/listing3.jpg" class="img-fluid" alt="Product Image">
                  </a>
                </div>
                <div class="product-caption">
                  <h5 class="text-light-black">R-Class</h5>
                  <p class="text-dark-white fw-500">Starting from $
                    <br>$25.000 / $35,000
                  </p>
                </div>
                <div class="button-sec">	<a routerLink="/car-detail" class="btn-second orange-btn full-width">Learn more</a>
                  <a routerLink="/homepage5" class="btn-first black-btn full-width">More from owner</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 filter-box sportstourer">
            <div class="product-main-box p-relative">
              <div class="product-box">
                <div class="product-img-sec">
                  <a routerLink="/car-detail">
                  <img src="assets/images/listing/listing3.jpg" class="img-fluid" alt="Product Image">
                  </a>
                </div>
                <div class="product-caption">
                  <h5 class="text-light-black">E-Class</h5>
                  <p class="text-dark-white fw-500">Starting from $
                    <br>$25.000 / $35,000
                  </p>
                </div>
              </div>
              <div class="product-box product-box-section-2">
                <div class="product-img-sec">
                  <a routerLink="/car-detail">
                  <img src="assets/images/listing/listing4.jpg" class="img-fluid" alt="Product Image">
                  </a>
                </div>
                <div class="product-caption">
                  <h5 class="text-light-black">E-Class</h5>
                  <p class="text-dark-white fw-500">Starting from $
                    <br>$25.000 / $35,000
                  </p>
                </div>
                <div class="button-sec">	<a routerLink="/car-detail" class="btn-second orange-btn full-width">Learn more</a>
                  <a routerLink="/homepage5" class="btn-first black-btn full-width">More from owner</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 filter-box suv">
            <div class="product-main-box p-relative">
              <div class="product-box">
                <div class="product-img-sec">
                  <a routerLink="/car-detail">
                  <img src="assets/images/listing/listing6.jpg" class="img-fluid" alt="Product Image">
                  </a>
                </div>
                <div class="product-caption">
                  <h5 class="text-light-black">M-Class</h5>
                  <p class="text-dark-white fw-500">Starting from $
                    <br>$25.000 / $35,000
                  </p>
                </div>
              </div>
              <div class="product-box product-box-section-2">
                <div class="product-img-sec">
                  <a routerLink="/car-detail">
                  <img src="assets/images/listing/listing5.jpg" class="img-fluid" alt="Product Image">
                  </a>
                </div>
                <div class="product-caption">
                  <h5 class="text-light-black">M-Class</h5>
                  <p class="text-dark-white fw-500">Starting from $
                    <br>$25.000 / $35,000
                  </p>
                </div>
                <div class="button-sec">	<a routerLink="/car-detail" class="btn-second orange-btn full-width">Learn more</a>
                  <a routerLink="/homepage5" class="btn-first black-btn full-width">More from owner</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 filter-box coupe">
            <div class="product-main-box p-relative">
              <div class="product-box">
                <div class="product-img-sec">
                  <a routerLink="/car-detail">
                  <img src="assets/images/listing/listing5.jpg" class="img-fluid" alt="Product Image">
                  </a>
                </div>
                <div class="product-caption">
                  <h5 class="text-light-black">I-Class</h5>
                  <p class="text-dark-white fw-500">Starting from $
                    <br>$25.000 / $35,000
                  </p>
                </div>
              </div>
              <div class="product-box product-box-section-2">
                <div class="product-img-sec">
                  <a routerLink="/car-detail">
                  <img src="assets/images/listing/listing6.jpg" class="img-fluid" alt="Product Image">
                  </a>
                </div>
                <div class="product-caption">
                  <h5 class="text-light-black">I-Class</h5>
                  <p class="text-dark-white fw-500">Starting from $
                    <br>$25.000 / $35,000
                  </p>
                </div>
                <div class="button-sec">	<a routerLink="/car-detail" class="btn-second orange-btn full-width">Learn more</a>
                  <a routerLink="/homepage5" class="btn-first black-btn full-width">More from owner</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 filter-box cabriolet">
            <div class="product-main-box p-relative">
              <div class="product-box">
                <div class="product-img-sec">
                  <a routerLink="/car-detail">
                  <img src="assets/images/listing/listing2.jpg" class="img-fluid" alt="Product Image">
                  </a>
                </div>
                <div class="product-caption">
                  <h5 class="text-light-black">P-Class</h5>
                  <p class="text-dark-white fw-500">Starting from $
                    <br>$25.000 / $35,000
                  </p>
                </div>
              </div>
              <div class="product-box product-box-section-2">
                <div class="product-img-sec">
                  <a routerLink="/car-detail">
                  <img src="assets/images/listing/listing1.jpg" class="img-fluid" alt="Product Image">
                  </a>
                </div>
                <div class="product-caption">
                  <h5 class="text-light-black">P-Class</h5>
                  <p class="text-dark-white fw-500">Starting from $
                    <br>$25.000 / $35,000
                  </p>
                </div>
                <div class="button-sec">	<a routerLink="/car-detail" class="btn-second orange-btn full-width">Learn more</a>
                  <a routerLink="/homepage5" class="btn-first black-btn full-width">More from owner</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 filter-box sportstourer">
            <div class="product-main-box p-relative">
              <div class="product-box">
                <div class="product-img-sec">
                  <a routerLink="/car-detail">
                  <img src="assets/images/listing/listing1.jpg" class="img-fluid" alt="Product Image">
                  </a>
                </div>
                <div class="product-caption">
                  <h5 class="text-light-black">S-Class</h5>
                  <p class="text-dark-white fw-500">Starting from $
                    <br>$25.000 / $35,000
                  </p>
                </div>
              </div>
              <div class="product-box product-box-section-2">
                <div class="product-img-sec">
                  <a routerLink="/car-detail">
                  <img src="assets/images/listing/listing2.jpg" class="img-fluid" alt="Product Image">
                  </a>
                </div>
                <div class="product-caption">
                  <h5 class="text-light-black">S-Class</h5>
                  <p class="text-dark-white fw-500">Starting from $
                    <br>$25.000 / $35,000
                  </p>
                </div>
                <div class="button-sec">	<a routerLink="/car-detail" class="btn-second orange-btn full-width">Learn more</a>
                  <a routerLink="/homepage5" class="btn-first black-btn full-width">More from owner</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 filter-box suv">
            <div class="product-main-box p-relative">
              <div class="product-box">
                <div class="product-img-sec">
                  <a routerLink="/car-detail">
                  <img src="assets/images/listing/listing4.jpg" class="img-fluid" alt="Product Image">
                  </a>
                </div>
                <div class="product-caption">
                  <h5 class="text-light-black">U-Class</h5>
                  <p class="text-dark-white fw-500">Starting from $
                    <br>$25.000 / $35,000
                  </p>
                </div>
              </div>
              <div class="product-box product-box-section-2">
                <div class="product-img-sec">
                  <a routerLink="/car-detail">
                  <img src="assets/images/listing/listing3.jpg" class="img-fluid" alt="Product Image">
                  </a>
                </div>
                <div class="product-caption">
                  <h5 class="text-light-black">U-Class</h5>
                  <p class="text-dark-white fw-500">Starting from $
                    <br>$25.000 / $35,000
                  </p>
                </div>
                <div class="button-sec">	<a routerLink="/car-detail" class="btn-second orange-btn full-width">Learn more</a>
                  <a routerLink="/homepage5" class="btn-first black-btn full-width">More from owner</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 filter-box coupe">
            <div class="product-main-box p-relative">
              <div class="product-box">
                <div class="product-img-sec">
                  <a routerLink="/car-detail">
                  <img src="assets/images/listing/listing3.jpg" class="img-fluid" alt="Product Image">
                  </a>
                </div>
                <div class="product-caption">
                  <h5 class="text-light-black">D-Class</h5>
                  <p class="text-dark-white fw-500">Starting from $
                    <br>$25.000 / $35,000
                  </p>
                </div>
              </div>
              <div class="product-box product-box-section-2">
                <div class="product-img-sec">
                  <a routerLink="/car-detail">
                  <img src="assets/images/listing/listing4.jpg" class="img-fluid" alt="Product Image">
                  </a>
                </div>
                <div class="product-caption">
                  <h5 class="text-light-black">D-Class</h5>
                  <p class="text-dark-white fw-500">Starting from $
                    <br>$25.000 / $35,000
                  </p>
                </div>
                <div class="button-sec">	<a routerLink="/car-detail" class="btn-second orange-btn full-width">Learn more</a>
                  <a routerLink="/homepage5" class="btn-first black-btn full-width">More from owner</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 filter-box cabriolet">
            <div class="product-main-box p-relative">
              <div class="product-box">
                <div class="product-img-sec">
                  <a routerLink="/car-detail">
                  <img src="assets/images/listing/listing6.jpg" class="img-fluid" alt="Product Image">
                  </a>
                </div>
                <div class="product-caption">
                  <h5 class="text-light-black">T-Class</h5>
                  <p class="text-dark-white fw-500">Starting from $
                    <br>$25.000 / $35,000
                  </p>
                </div>
              </div>
              <div class="product-box product-box-section-2">
                <div class="product-img-sec">
                  <a routerLink="/car-detail">
                  <img src="assets/images/listing/listing5.jpg" class="img-fluid" alt="Product Image">
                  </a>
                </div>
                <div class="product-caption">
                  <h5 class="text-light-black">T-Class</h5>
                  <p class="text-dark-white fw-500">Starting from $
                    <br>$25.000 / $35,000
                  </p>
                </div>
                <div class="button-sec">	<a routerLink="/car-detail" class="btn-second orange-btn full-width">Learn more</a>
                  <a routerLink="/homepage5" class="btn-first black-btn full-width">More from owner</a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 filter-box sportstourer">
            <div class="product-main-box p-relative">
              <div class="product-box">
                <div class="product-img-sec">
                  <a routerLink="/car-detail">
                  <img src="assets/images/listing/listing5.jpg" class="img-fluid" alt="Product Image">
                  </a>
                </div>
                <div class="product-caption">
                  <h5 class="text-light-black">A-Class</h5>
                  <p class="text-dark-white fw-500">Starting from $
                    <br>$25.000 / $35,000
                  </p>
                </div>
              </div>
              <div class="product-box product-box-section-2">
                <div class="product-img-sec">
                  <a routerLink="/car-detail">
                  <img src="assets/images/listing/listing6.jpg" class="img-fluid" alt="Product Image">
                  </a>
                </div>
                <div class="product-caption">
                  <h5 class="text-light-black">A-Class</h5>
                  <p class="text-dark-white fw-500">Starting from $
                    <br>$25.000 / $35,000
                  </p>
                </div>
                <div class="button-sec">	<a routerLink="/car-detail" class="btn-second orange-btn full-width">Learn more</a>
                  <a routerLink="/homepage5" class="btn-first black-btn full-width">More from owner</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Desktop -->
    <!-- Mobile -->
    <section class="section-padding our-vehicles-mobile">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="section-header">
              <div class="text-wrapper text-center">
                <h3 class="fw-500 no-margin">Our vehicles</h3>
                <span class="line-grey"></span><span class="line-orange"></span><span class="line-grey"></span> 
                <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort. A masterful combination of style, power, sporty handling and comfort.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="accordion">
              <div class="card">
                <div class="card-header" id="headingOne">
                  <h5 class="mb-0 rubik text-custom-white collapsed" data-toggle="collapse" data-target="#collapseOne">Filter models</h5>
                </div>
                <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                  <div class="card-body">
                    <div class="accordion">
                      <div class="card">
                        <div class="card-header">
                          <h5 class="mb-0 rubik text-custom-white vehiclemodel collapsed" data-toggle="collapse" data-target="#vehiclemodelmobile"><i class="flaticon-supercar"></i> Vehicle model</h5>
                        </div>
                        <div id="vehiclemodelmobile" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                          <div class="card-body bg-white">
                            <div class="filters">
                              <ul>
                                <li class="inner-tab-sec" data-filter=".saloon">
                                  <img src="assets/images/saloon-black.png" alt="Car Image">
                                  <img src="assets/images/saloon-orange.png" alt="Car Image">	<span class="text-light-black">Saloon</span>
                                </li>
                                <li class="inner-tab-sec" data-filter=".coupe">
                                  <img src="assets/images/coupe-black.png" alt="Car Image">
                                  <img src="assets/images/coupe-orange.png" alt="Car Image">	<span class="text-light-black">Coupe</span>
                                </li>
                                <li class="inner-tab-sec" data-filter=".cabriolet">
                                  <img src="assets/images/cabriolet-black.png" alt="Car Image">
                                  <img src="assets/images/cabriolet-orange.png" alt="Car Image">	<span class="text-light-black">Cabriolet</span>
                                </li>
                                <li class="inner-tab-sec" data-filter=".sportstourer">
                                  <img src="assets/images/sportstourer-black.png" alt="Car Image">
                                  <img src="assets/images/sportstourer-orange.png" alt="Car Image">	<span class="text-light-black">Sports Tourer</span>
                                </li>
                                <li class="inner-tab-sec" data-filter=".suv">
                                  <img src="assets/images/suv-black.png" alt="Car Image">
                                  <img src="assets/images/suv-orange.png" alt="Car Image">	<span class="text-light-black">SUV</span>
                                </li>
                              </ul>
                              <button type="button" class="model-reset"><i class="far fa-times-circle"></i> Reset all filters</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card  border-0">
                        <div class="card-header" id="headingTwo">
                          <h5 class="mb-0 rubik text-custom-white collapsed" data-toggle="collapse" data-target="#vehiclepricemobile"><i class="flaticon-badge"></i> Price</h5>
                        </div>
                        <div id="vehiclepricemobile" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                          <div class="card-body border-0">
                            <div class="filters">
                              <div class="price-filter">
                                <div class="row slider-labels">
                                  <div class="col-6 caption text-left align-self-center">
                                    <input type="text" id="slider-range-mobile-value1" readonly>
                                  </div>
                                  <div class="col-6  caption  text-right align-self-center">
                                    <input type="text" class=" text-right" id="slider-range-mobile-value2" readonly>
                                  </div>
                                  <div class="col-12 align-self-center">
                                    <div id="slider-range-mobile"></div>
                                  </div>
                                </div>
                              </div>
                              <button type="button" class="model-reset"><i class="far fa-times-circle"></i> Reset all filters</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card model-reset-top">
                <div class="card-header">
                  <h5 class="mb-0 rubik text-custom-white collapsed model-reset-top"><i class="far fa-times-circle"></i> Reset all filters</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row grid">
          <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 filter-box saloon">
            <div class="product-box">
              <label for="L-Class"></label>
              <input type="checkbox" id="L-Class">
              <div class="card">
                <div class="front">
                  <div class="product-img-sec">
                    <img src="assets/images/car-mobile-2.png" class="img-fluid" alt="Product Image">
                  </div>
                  <div class="product-caption">
                    <h5 class="text-light-black">L-Class</h5>
                    <p class="text-dark-white fw-500">Starting from $ $25.000 / $35,000</p>
                  </div>
                  <div class="icon-sec">
                    <button type="button">+</button>
                  </div>
                </div>
                <div class="back">
                  <div class="product-img-sec">
                    <img src="assets/images/car-mobile-1.png" class="img-fluid" alt="Product Image">
                  </div>
                  <div class="product-caption">
                    <h5 class="text-light-black">L-Class</h5>
                    <p class="text-dark-white fw-500">Starting from $ $25.000 / $35,000</p>
                  </div>
                  <div class="button-sec">	<a routerLink="/car-detail" class="btn-second orange-btn full-width">Learn more</a>
                    <a routerLink="/homepage5" class="btn-first black-btn full-width">More from owner</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 filter-box coupe">
            <div class="product-box">
              <label for="O-Class"></label>
              <input type="checkbox" id="O-Class">
              <div class="card">
                <div class="front">
                  <div class="product-img-sec">
                    <img src="assets/images/car-mobile-2.png" class="img-fluid" alt="Product Image">
                  </div>
                  <div class="product-caption">
                    <h5 class="text-light-black">O-Class</h5>
                    <p class="text-dark-white fw-500">Starting from $ $25.000 / $35,000</p>
                  </div>
                  <div class="icon-sec">
                    <button type="button">+</button>
                  </div>
                </div>
                <div class="back">
                  <div class="product-img-sec">
                    <img src="assets/images/car-mobile-1.png" class="img-fluid" alt="Product Image">
                  </div>
                  <div class="product-caption">
                    <h5 class="text-light-black">O-Class</h5>
                    <p class="text-dark-white fw-500">Starting from $ $25.000 / $35,000</p>
                  </div>
                  <div class="button-sec">	<a routerLink="/car-detail" class="btn-second orange-btn full-width">Learn more</a>
                    <a routerLink="/homepage5" class="btn-first black-btn full-width">More from owner</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 filter-box cabriolet">
            <div class="product-box">
              <label for="R-Class"></label>
              <input type="checkbox" id="R-Class">
              <div class="card">
                <div class="front">
                  <div class="product-img-sec">
                    <img src="assets/images/car-mobile-2.png" class="img-fluid" alt="Product Image">
                  </div>
                  <div class="product-caption">
                    <h5 class="text-light-black">R-Class</h5>
                    <p class="text-dark-white fw-500">Starting from $ $25.000 / $35,000</p>
                  </div>
                  <div class="icon-sec">
                    <button type="button">+</button>
                  </div>
                </div>
                <div class="back">
                  <div class="product-img-sec">
                    <img src="assets/images/car-mobile-1.png" class="img-fluid" alt="Product Image">
                  </div>
                  <div class="product-caption">
                    <h5 class="text-light-black">R-Class</h5>
                    <p class="text-dark-white fw-500">Starting from $ $25.000 / $35,000</p>
                  </div>
                  <div class="button-sec">	<a routerLink="/car-detail" class="btn-second orange-btn full-width">Learn more</a>
                    <a routerLink="/homepage5" class="btn-first black-btn full-width">More from owner</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 filter-box sportstourer">
            <div class="product-box">
              <label for="E-Class"></label>
              <input type="checkbox" id="E-Class">
              <div class="card">
                <div class="front">
                  <div class="product-img-sec">
                    <img src="assets/images/car-mobile-1.png" class="img-fluid" alt="Product Image">
                  </div>
                  <div class="product-caption">
                    <h5 class="text-light-black">E-Class</h5>
                    <p class="text-dark-white fw-500">Starting from $ $25.000 / $35,000</p>
                  </div>
                  <div class="icon-sec">
                    <button type="button">+</button>
                  </div>
                </div>
                <div class="back">
                  <div class="product-img-sec">
                    <img src="assets/images/car-mobile-2.png" class="img-fluid" alt="Product Image">
                  </div>
                  <div class="product-caption">
                    <h5 class="text-light-black">E-Class</h5>
                    <p class="text-dark-white fw-500">Starting from $ $25.000 / $35,000</p>
                  </div>
                  <div class="button-sec">	<a routerLink="/car-detail" class="btn-second orange-btn full-width">Learn more</a>
                    <a routerLink="/homepage5" class="btn-first black-btn full-width">More from owner</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 filter-box suv">
            <div class="product-box">
              <label for="M-Class"></label>
              <input type="checkbox" id="M-Class">
              <div class="card">
                <div class="front">
                  <div class="product-img-sec">
                    <img src="assets/images/car-mobile-1.png" class="img-fluid" alt="Product Image">
                  </div>
                  <div class="product-caption">
                    <h5 class="text-light-black">M-Class</h5>
                    <p class="text-dark-white fw-500">Starting from $ $25.000 / $35,000</p>
                  </div>
                  <div class="icon-sec">
                    <button type="button">+</button>
                  </div>
                </div>
                <div class="back">
                  <div class="product-img-sec">
                    <img src="assets/images/car-mobile-1.png" class="img-fluid" alt="Product Image">
                  </div>
                  <div class="product-caption">
                    <h5 class="text-light-black">M-Class</h5>
                    <p class="text-dark-white fw-500">Starting from $ $25.000 / $35,000</p>
                  </div>
                  <div class="button-sec">	<a routerLink="/car-detail" class="btn-second orange-btn full-width">Learn more</a>
                    <a routerLink="/homepage5" class="btn-first black-btn full-width">More from owner</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 filter-box saloon">
            <div class="product-box">
              <label for="I-Class"></label>
              <input type="checkbox" id="I-Class">
              <div class="card">
                <div class="front">
                  <div class="product-img-sec">
                    <img src="assets/images/car-mobile-1.png" class="img-fluid" alt="Product Image">
                  </div>
                  <div class="product-caption">
                    <h5 class="text-light-black">I-Class</h5>
                    <p class="text-dark-white fw-500">Starting from $ $25.000 / $35,000</p>
                  </div>
                  <div class="icon-sec">
                    <button type="button">+</button>
                  </div>
                </div>
                <div class="back">
                  <div class="product-img-sec">
                    <img src="assets/images/car-mobile-2.png" class="img-fluid" alt="Product Image">
                  </div>
                  <div class="product-caption">
                    <h5 class="text-light-black">I-Class</h5>
                    <p class="text-dark-white fw-500">Starting from $ $25.000 / $35,000</p>
                  </div>
                  <div class="button-sec">	<a routerLink="/car-detail" class="btn-second orange-btn full-width">Learn more</a>
                    <a routerLink="/homepage5" class="btn-first black-btn full-width">More from owner</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 filter-box coupe">
            <div class="product-box">
              <label for="P-Class"></label>
              <input type="checkbox" id="P-Class">
              <div class="card">
                <div class="front">
                  <div class="product-img-sec">
                    <img src="assets/images/car-mobile-1.png" class="img-fluid" alt="Product Image">
                  </div>
                  <div class="product-caption">
                    <h5 class="text-light-black">P-Class</h5>
                    <p class="text-dark-white fw-500">Starting from $ $25.000 / $35,000</p>
                  </div>
                  <div class="icon-sec">
                    <button type="button">+</button>
                  </div>
                </div>
                <div class="back">
                  <div class="product-img-sec">
                    <img src="assets/images/car-mobile-2.png" class="img-fluid" alt="Product Image">
                  </div>
                  <div class="product-caption">
                    <h5 class="text-light-black">P-Class</h5>
                    <p class="text-dark-white fw-500">Starting from $ $25.000 / $35,000</p>
                  </div>
                  <div class="button-sec">	<a routerLink="/car-detail" class="btn-second orange-btn full-width">Learn more</a>
                    <a routerLink="/homepage5" class="btn-first black-btn full-width">More from owner</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 filter-box cabriolet">
            <div class="product-box">
              <label for="U-Class"></label>
              <input type="checkbox" id="U-Class">
              <div class="card">
                <div class="front">
                  <div class="product-img-sec">
                    <img src="assets/images/car-mobile-1.png" class="img-fluid" alt="Product Image">
                  </div>
                  <div class="product-caption">
                    <h5 class="text-light-black">U-Class</h5>
                    <p class="text-dark-white fw-500">Starting from $ $25.000 / $35,000</p>
                  </div>
                  <div class="icon-sec">
                    <button type="button">+</button>
                  </div>
                </div>
                <div class="back">
                  <div class="product-img-sec">
                    <img src="assets/images/car-mobile-2.png" class="img-fluid" alt="Product Image">
                  </div>
                  <div class="product-caption">
                    <h5 class="text-light-black">U-Class</h5>
                    <p class="text-dark-white fw-500">Starting from $ $25.000 / $35,000</p>
                  </div>
                  <div class="button-sec">	<a routerLink="/car-detail" class="btn-second orange-btn full-width">Learn more</a>
                    <a routerLink="/homepage5" class="btn-first black-btn full-width">More from owner</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 filter-box sportstourer">
            <div class="product-box">
              <label for="D-Class"></label>
              <input type="checkbox" id="D-Class">
              <div class="card">
                <div class="front">
                  <div class="product-img-sec">
                    <img src="assets/images/car-mobile-1.png" class="img-fluid" alt="Product Image">
                  </div>
                  <div class="product-caption">
                    <h5 class="text-light-black">D-Class</h5>
                    <p class="text-dark-white fw-500">Starting from $ $25.000 / $35,000</p>
                  </div>
                  <div class="icon-sec">
                    <button type="button">+</button>
                  </div>
                </div>
                <div class="back">
                  <div class="product-img-sec">
                    <img src="assets/images/car-mobile-2.png" class="img-fluid" alt="Product Image">
                  </div>
                  <div class="product-caption">
                    <h5 class="text-light-black">D-Class</h5>
                    <p class="text-dark-white fw-500">Starting from $ $25.000 / $35,000</p>
                  </div>
                  <div class="button-sec">	<a routerLink="/car-detail" class="btn-second orange-btn full-width">Learn more</a>
                    <a routerLink="/homepage5" class="btn-first black-btn full-width">More from owner</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 filter-box suv">
            <div class="product-box">
              <label for="S-Class"></label>
              <input type="checkbox" id="S-Class">
              <div class="card">
                <div class="front">
                  <div class="product-img-sec">
                    <img src="assets/images/car-mobile-1.png" class="img-fluid" alt="Product Image">
                  </div>
                  <div class="product-caption">
                    <h5 class="text-light-black">S-Class</h5>
                    <p class="text-dark-white fw-500">Starting from $ $25.000 / $35,000</p>
                  </div>
                  <div class="icon-sec">
                    <button type="button">+</button>
                  </div>
                </div>
                <div class="back">
                  <div class="product-img-sec">
                    <img src="assets/images/car-mobile-2.png" class="img-fluid" alt="Product Image">
                  </div>
                  <div class="product-caption">
                    <h5 class="text-light-black">S-Class</h5>
                    <p class="text-dark-white fw-500">Starting from $ $25.000 / $35,000</p>
                  </div>
                  <div class="button-sec">	<a routerLink="/car-detail" class="btn-second orange-btn full-width">Learn more</a>
                    <a routerLink="/homepage5" class="btn-first black-btn full-width">More from owner</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 filter-box saloon">
            <div class="product-box">
              <label for="T-Class"></label>
              <input type="checkbox" id="T-Class">
              <div class="card">
                <div class="front">
                  <div class="product-img-sec">
                    <img src="assets/images/car-mobile-1.png" class="img-fluid" alt="Product Image">
                  </div>
                  <div class="product-caption">
                    <h5 class="text-light-black">T-Class</h5>
                    <p class="text-dark-white fw-500">Starting from $ $25.000 / $35,000</p>
                  </div>
                  <div class="icon-sec">
                    <button type="button">+</button>
                  </div>
                </div>
                <div class="back">
                  <div class="product-img-sec">
                    <img src="assets/images/car-mobile-2.png" class="img-fluid" alt="Product Image">
                  </div>
                  <div class="product-caption">
                    <h5 class="text-light-black">T-Class</h5>
                    <p class="text-dark-white fw-500">Starting from $ $25.000 / $35,000</p>
                  </div>
                  <div class="button-sec">	<a routerLink="/car-detail" class="btn-second orange-btn full-width">Learn more</a>
                    <a routerLink="/homepage5" class="btn-first black-btn full-width">More from owner</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-2 col-lg-3 col-md-4 col-sm-6 filter-box coupe">
            <div class="product-box">
              <label for="A-Class"></label>
              <input type="checkbox" id="A-Class">
              <div class="card">
                <div class="front">
                  <div class="product-img-sec">
                    <img src="assets/images/car-mobile-1.png" class="img-fluid" alt="Product Image">
                  </div>
                  <div class="product-caption">
                    <h5 class="text-light-black">A-Class</h5>
                    <p class="text-dark-white fw-500">Starting from $ $25.000 / $35,000</p>
                  </div>
                  <div class="icon-sec">
                    <button type="button">+</button>
                  </div>
                </div>
                <div class="back">
                  <div class="product-img-sec">
                    <img src="assets/images/car-mobile-2.png" class="img-fluid" alt="Product Image">
                  </div>
                  <div class="product-caption">
                    <h5 class="text-light-black">A-Class</h5>
                    <p class="text-dark-white fw-500">Starting from $ $25.000 / $35,000</p>
                  </div>
                  <div class="button-sec">	<a routerLink="/car-detail" class="btn-second orange-btn full-width">Learn more</a>
                    <a routerLink="/homepage5" class="btn-first black-btn full-width">More from owner</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Mobile -->
    <!-- Our vehicles -->
    <!-- Models Currently Trending -->
    <section class="section-padding model-gallery">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="section-header">
              <div class="text-wrapper text-center">
                <h3 class="fw-500 no-margin">Models Currently Trending</h3>
                <span class="line-grey"></span><span class="line-orange"></span><span class="line-grey"></span> 
                <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort. A masterful combination of style, power, sporty handling and comfort.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="custom-col">
            <img src="assets/images/trendingmodels/trending1.jpg" class="img-fluid full-width" alt="Image">
            <div class="model-gallery-caption">
              <div class="model-gallery-wrapper-1">
                <h3 class="text-custom-white model-gal-title">Acura</h3>
                <h6 class="text-custom-white rubik">From <strong>$ 25,900.00*</strong></h6>
              </div>
              <div class="model-gallery-wrapper-2"> <a routerLink="/listing" class="btn-second orange-btn">View Category</a>
                <a routerLink="/listing" class="btn-fourth border-btn">All Models</a>
              </div>
            </div>
          </div>
          <div class="custom-col">
            <img src="assets/images/trendingmodels/trending2.jpg" class="img-fluid full-width" alt="Image">
            <div class="model-gallery-caption">
              <div class="model-gallery-wrapper-1">
                <h3 class="text-custom-white model-gal-title">Audi</h3>
                <h6 class="text-custom-white rubik">From <strong>$ 32,500.00*</strong></h6>
              </div>
              <div class="model-gallery-wrapper-2"> <a routerLink="/listing" class="btn-second orange-btn">View Category</a>
                <a routerLink="/listing" class="btn-fourth border-btn">All Models</a>
              </div>
            </div>
          </div>
          <div class="custom-col">
            <img src="assets/images/trendingmodels/trending3.jpg" class="img-fluid full-width" alt="Image">
            <div class="model-gallery-caption">
              <div class="model-gallery-wrapper-1">
                <h3 class="text-custom-white model-gal-title">BMW</h3>
                <h6 class="text-custom-white rubik">From <strong>$ 34,950.00*</strong></h6>
              </div>
              <div class="model-gallery-wrapper-2"> <a routerLink="/listing" class="btn-second orange-btn">View Category</a>
                <a routerLink="/listing" class="btn-fourth border-btn">All Models</a>
              </div>
            </div>
          </div>
          <div class="custom-col">
            <img src="assets/images/trendingmodels/trending4.jpg" class="img-fluid full-width" alt="Image">
            <div class="model-gallery-caption">
              <div class="model-gallery-wrapper-1">
                <h3 class="text-custom-white model-gal-title">Ford</h3>
                <h6 class="text-custom-white rubik">From <strong>$ 14,260.00*</strong></h6>
              </div>
              <div class="model-gallery-wrapper-2"> <a routerLink="/listing" class="btn-second orange-btn">View Category</a>
                <a routerLink="/listing" class="btn-fourth border-btn">All Models</a>
              </div>
            </div>
          </div>
          <div class="custom-col">
            <img src="assets/images/trendingmodels/trending5.jpg" class="img-fluid full-width" alt="Image">
            <div class="model-gallery-caption">
              <div class="model-gallery-wrapper-1">
                <h3 class="text-custom-white model-gal-title">Range Rover</h3>
                <h6 class="text-custom-white rubik">From <strong>$ 37,800.00*</strong></h6>
              </div>
              <div class="model-gallery-wrapper-2"> <a routerLink="/listing" class="btn-second orange-btn">View Category</a>
                <a routerLink="/listing" class="btn-fourth border-btn">All Models</a>
              </div>
            </div>
          </div>
          <div class="custom-col">
            <img src="assets/images/trendingmodels/trending6.jpg" class="img-fluid full-width" alt="Image">
            <div class="model-gallery-caption">
              <div class="model-gallery-wrapper-1">
                <h3 class="text-custom-white model-gal-title">Maserati</h3>
                <h6 class="text-custom-white rubik">From <strong>$ 74,980.00*</strong></h6>
              </div>
              <div class="model-gallery-wrapper-2"> <a routerLink="/listing" class="btn-second orange-btn">View Category</a>
                <a routerLink="/listing" class="btn-fourth border-btn">All Models</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Models Currently Trending -->
    <!-- Our Favorite Brand -->
    <section class="section-padding vehicle-tab-sec bg-light-theme">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="section-header">
              <div class="text-wrapper text-center">
                <h3 class="fw-500 no-margin">Top Selling Brands On Car Valley</h3>
                <span class="line-grey"></span><span class="line-orange"></span><span class="line-grey"></span> 
                <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort. A masterful combination of style, power, sporty handling and comfort.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="offset-lg-2 col-lg-8">
            <ul class="nav nav-pills">
              <li class="nav-item">
                <a class="nav-link text-center" routerLink="/homepage5"> <span class="car-image">
                <img src="assets/images/car1.jpg" class="img-fluid full-width" alt="Image">
                </span>
                <span class="car-name text-dark-grey">Suv</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link text-center" routerLink="/homepage5"> <span class="car-image">
                <img src="assets/images/car2.jpg" class="img-fluid full-width" alt="Image">
                </span>
                <span class="car-name text-dark-grey">sedan</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link text-center" routerLink="/homepage5"> <span class="car-image">
                <img src="assets/images/car3.jpg" class="img-fluid full-width" alt="Image">
                </span>
                <span class="car-name text-dark-grey">pickup</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link text-center" routerLink="/homepage5"> <span class="car-image">
                <img src="assets/images/car4.jpg" class="img-fluid full-width" alt="Image">
                </span>
                <span class="car-name text-dark-grey">coupe</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link text-center" routerLink="/homepage5"> <span class="car-image">
                <img src="assets/images/car5.jpg" class="img-fluid full-width" alt="Image">
                </span>
                <span class="car-name text-dark-grey">convertible</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link text-center" routerLink="/homepage5"> <span class="car-image">
                <img src="assets/images/car6.jpg" class="img-fluid full-width" alt="Image">
                </span>
                <span class="car-name text-dark-grey">hatchback</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link text-center" routerLink="/homepage5"> <span class="car-image">
                <img src="assets/images/car7.jpg" class="img-fluid full-width" alt="Image">
                </span>
                <span class="car-name text-dark-grey">wagon</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link text-center" routerLink="/homepage5"> <span class="car-image">
                <img src="assets/images/car1.jpg" class="img-fluid full-width" alt="Image">
                </span>
                <span class="car-name text-dark-grey">minivan</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <!-- Our Favorite Brand -->
    <!-- car-design-layout-details -->
    <section class="section-padding car-design-layout-details">
      <div class="container-fluid custom-container">
        <div class="row">
          <div class="col-lg-6">
            <div class="car-design-tab-sec">
              <div class="content-box">
                <p class="fs-16 text-dark-white">By the readable content</p>
                <h4 class="rubik text-custom-white fw-600">It is a long established fact that a reader will bedistracted by the readable</h4>
              </div>
              <div class="tab-box">
                <ul class="nav nav-tabs">
                  <li class="nav-item">
                    <a class="nav-link active text-custom-white" data-toggle="tab" href="#concept">Concept</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link text-custom-white" data-toggle="tab" href="#design">Design</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link text-custom-white" data-toggle="tab" href="#deployment">Deployment</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link text-custom-white" data-toggle="tab" href="#support">Support</a>
                  </li>
                </ul>
                <div class="tab-content">
                  <div class="tab-pane active" id="concept">
                    <p class="text-dark-white">
                      Metus quam cras vehicula ante, potenti eget. Vel est integer, vivamus proin torquent, sodales aliquam tincidunt laoreet est, at in sollicitudin laoreet etiam sit suspendisse, ligula ut vestibulum dapibus et neque. Nibh et risus ipsum amet pede, eros arcu non, velit ridiculus elit, mauris cursus et. Vel cursus sagittis sem nullam odio pede.
                    </p>
                    <a routerLink="/homepage5" class="btn-second orange-btn">Learn More..</a>
                  </div>
                  <div class="tab-pane fade" id="design">
                    <p class="text-dark-white">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                    </p>
                    <a routerLink="/homepage5" class="btn-second orange-btn">Learn More..</a>
                  </div>
                  <div class="tab-pane fade" id="deployment">
                    <p class="text-dark-white">
                      Nibh et risus ipsum amet pede, eros arcu non, velit ridiculus elit, mauris cursus et. Vel cursus sagittis sem nullam odio pede. Metus quam cras vehicula ante, potenti eget. Vel est integer, vivamus proin torquent, sodales aliquam tincidunt laoreet est, at in sollicitudin laoreet etiam sit suspendisse, ligula ut vestibulum dapibus et neque.
                    </p>
                    <a routerLink="/homepage5" class="btn-second orange-btn">Learn More..</a>
                  </div>
                  <div class="tab-pane fade" id="support">
                    <p class="text-dark-white">
                      Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                    </p>
                    <a routerLink="/homepage5" class="btn-second orange-btn">Learn More..</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="car-design-details-sec">
              <div class="car-design-layout p-relative">
                <div class="car-image">
                  <img src="assets/images/car-detail.png" class="img-fluid full-width" alt="car image">
                </div>
                <div class="car-pointers">
                  <div class="car-detail-point first-point">
                    <div class="car-detail-box">
                      <p class="text-light-black">
                        Elementum congue amet et eget, odio gravida taciti risus turpis risus, venenatis sem odio sagittis harum, nulla non tempor libero aliquam duis
                      </p>
                    </div>
                  </div>
                  <div class="car-detail-point second-point">
                    <div class="car-detail-box">
                      <p class="text-light-black">
                        Elementum congue amet et eget, odio gravida taciti risus turpis risus, venenatis sem odio sagittis harum, nulla non tempor libero aliquam duis
                      </p>
                    </div>
                  </div>
                  <div class="car-detail-point third-point">
                    <div class="car-detail-box">
                      <p class="text-light-black">
                        Elementum congue amet et eget, odio gravida taciti risus turpis risus, venenatis sem odio sagittis harum, nulla non tempor libero aliquam duis
                      </p>
                    </div>
                  </div>
                  <div class="car-detail-point fourth-point">
                    <div class="car-detail-box">
                      <p class="text-light-black">
                        Elementum congue amet et eget, odio gravida taciti risus turpis risus, venenatis sem odio sagittis harum, nulla non tempor libero aliquam duis
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- car-design-layout-details -->
<app-footer4></app-footer4>
    <div class="modal fade" id="register">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title text-left no-margin"><i class="fas fa-unlock-alt mr-2"></i> <span id="signup-text">Sign Up</span><span
              id="signin-text" class="none">Sign In</span></h6>
            <button type="button" class="close" data-dismiss="modal">&times;</button>
          </div>
          <!-- Sign up form -->
          <div class="modal-body" id="signup-form">
            <form>
              <div class="form-group">
                <input type="email" class="form-control" name="#" placeholder="Email">
              </div>
              <div class="form-group">
                <input type="password" class="form-control" name="#" placeholder="Password">
              </div>
              <div class="form-group">
                <button type="submit" class="btn-second orange-btn full-width">Sign Up</button>
              </div>
            </form>
            <div class="form-excerpts">
              <ul>
                <li>Already a member? <a href="javascript:void(0)" id="signin-btn">Sign In</a>
                </li>
                <li><a routerLink="/homepage5">Recover Password</a>
                </li>
              </ul>
            </div>
            <div class="social-login">
              <span>Or Signup with</span>
              <div class="social-btn">	<a routerLink="/homepage5" class="facebook"><i class="fab fa-facebook-f"></i> Facebook</a>
                <a routerLink="/homepage5" class="google"><i class="fab fa-google-plus-g"></i> Google</a>
              </div>
            </div>
          </div>
          <!-- Sign up form -->
          <!-- Sign in form -->
          <div class="modal-body none" id="signin-form">
            <form>
              <div class="form-group">
                <input type="text" class="form-control" name="#" placeholder="Username or Email">
              </div>
              <div class="form-group">
                <input type="password" class="form-control" name="#" placeholder="Password">
              </div>
              <div class="form-group">
                <label class="custom-checkbox">
                <input type="checkbox" name="#">Keep me signed in	<span class="checkmark"></span>
                </label>
              </div>
              <div class="form-group">
                <button type="submit" class="btn-second orange-btn full-width">Sign In</button>
              </div>
            </form>
            <div class="form-excerpts">
              <ul>
                <li>Not a member? <a href="javascript:void(0)" id="signup-btn">Sign Up</a>
                </li>
                <li><a routerLink="/homepage5">Recover Password</a>
                </li>
              </ul>
            </div>
            <div class="social-login">
              <span>Or connect with</span>
              <div class="social-btn">	<a routerLink="/homepage5" class="facebook"><i class="fab fa-facebook-f"></i> Facebook</a>
                <a routerLink="/homepage5" class="google"><i class="fab fa-google-plus-g"></i> Google</a>
              </div>
            </div>
          </div>
          <!-- Sign in form -->
        </div>
      </div>
    </div>
    <div class="menu-style-3">
      <div class="search-box search-elem">
        <button class="close">x</button>
        <div class="inner row">
          <div class="col-12">
            <label class="text-custom-white">Search Your Category</label>
            <div class="input-group">
              <input type="text" class="form-control form-control-custom" placeholder="Search keywords, category, brand of part">
              <div class="input-group-prepend">
                <select class="menu-style-3-search">
                  <option>All Category</option>
                  <option>Luxury Car</option>
                  <option>Sports Car</option>
                  <option>Vintage Car</option>
                  <option>Excutive Car</option>
                  <option>Economy Car</option>
                </select>
              </div>
              <div class="input-group-append">
                <button class="btn-second orange-btn full-width" type="submit">Search</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>