<app-header2></app-header2>
<!-- Banner sec -->
    <section class="banner-slider p-relative">
      <img src="assets/images/banner/bannerh6.jpg" class="img-fluid full-width" alt="Banner">
      <div class="bannerimg-overlay"></div>
      <div class="banner-wrapper">
        <div class="container">
          <div class="offset-lg-2 col-lg-8">
            <div class="banner-text-wrapper-index-3">
              <h1 class="text-custom-white">Find a car in <span class="text-orange">New York City</span></h1>
              <p class="text-custom-white">Let's uncover the best car showroom nearest to you.</p>
              <div class="space-20"></div>
              <div class="space-10"></div>
              <!-- dekstop search -->
              <div class="search-bar-index-3 align-item-center d-none d-md-block">
                <div class="input-group" id="input-group">
                  <div class="input-group-prepend"> <span class="input-group-text">What</span>
                  </div>
                  <input type="text" class="form-control border-0" id="myInput" onkeyup="filterFunction()" placeholder="Ex: Luxury Car, Sports Car, Vintage Car, Excutive Car, Economy Car">
                  <ul class="filter-option">
                    <li><a routerLink="/homepage2">Luxury Car</a>
                    </li>
                    <li><a routerLink="/homepage2">Sports Car</a>
                    </li>
                    <li><a routerLink="/homepage2">Vintage Car</a>
                    </li>
                    <li><a routerLink="/homepage2">Excutive Car</a>
                    </li>
                    <li><a routerLink="/homepage2">Economy Car</a>
                    </li>
                  </ul>
                  <div class="input-group-prepend"> <span class="input-group-text">Where</span>
                    <button class="gps" type="button"><i class="fas fa-crosshairs"></i>
                    </button>
                  </div>
                  <input type="text" class="form-control border-0" placeholder="Your City">
                  <div class="input-group-prepend">
                    <button class="btn-second orange-btn" type="button"><i class="flaticon-search"></i> Search</button>
                  </div>
                </div>
              </div>
              <!-- dekstop search -->
              <!-- mobile search -->
              <div class="search-bar-index-3 align-item-center d-block d-md-none">
                <div class="input-group mb-3">
                  <div class="input-group-prepend"> <span class="input-group-text">What</span>
                  </div>
                  <input type="text" class="form-control border-0" placeholder="Ex: Luxury Car, Sports Car, Vintage Car, Excutive Car, Economy Car">
                </div>
                <div class="input-group mb-3">
                  <div class="input-group-prepend"> <span class="input-group-text">Where</span>
                    <button class="gps" type="button"><i class="fas fa-crosshairs"></i>
                    </button>
                  </div>
                  <input type="text" class="form-control border-0" placeholder="Your City">
                </div>
                <button class="btn-second orange-btn full-width" type="button"><i class="flaticon-search"></i> Search</button>
              </div>
              <!-- mobile search -->
              <div class="space-20"></div>
              <div class="space-20"></div>
              <p class="text-custom-white small-content">Just looking around? Let us suggest you something hot & happening!</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Banner sec -->
    <div class="banner-tab-sec">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="banner-tabs align-item-center">
              <ul>
                <li>
                  <a routerLink="/homepage2" class="align-item-center">
                  <img src="assets/images/luxury-car.png" alt="Car Image">	<span>Luxury Car</span>
                  </a>
                </li>
                <li>
                  <a routerLink="/homepage2" class="align-item-center">
                  <img src="assets/images/sports-car.png" alt="Car Image">	<span>Sports Car</span>
                  </a>
                </li>
                <li>
                  <a routerLink="/homepage2" class="align-item-center">
                  <img src="assets/images/vintage-car.png" alt="Car Image">	<span>Vintage Car</span>
                  </a>
                </li>
                <li>
                  <a routerLink="/homepage2" class="align-item-center">
                  <img src="assets/images/excutive-car.png" alt="Car Image">	<span>Excutive Car</span>
                  </a>
                </li>
                <li>
                  <a routerLink="/homepage2" class="align-item-center">
                  <img src="assets/images/economy-car.png" alt="Car Image">	<span>Economy Car</span>
                  </a>
                </li>
              </ul>
            </div>
            <div class="space-60"></div>
          </div>
        </div>
      </div>
    </div>
    <!-- Find Your Best Match -->
    <section class="section-padding vehicles-slider-sec">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="section-header">
              <div class="text-wrapper text-center">
                <h3 class="fw-500 no-margin">Find Your Best Match</h3>
                <span class="line-grey"></span><span class="line-orange"></span><span class="line-grey"></span> 
                <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort. A masterful combination of style, power, sporty handling and comfort.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 p-relative">
            <div class="vehicles-slider-for">
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/car-page">
                <img src="assets/images/logos/logo1.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">Audi</span>
                </a>
              </div>
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/car-page">
                <img src="assets/images/logos/logo2.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">BMW</span>
                </a>
              </div>
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/car-page">
                <img src="assets/images/logos/logo3.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">Cord</span>
                </a>
              </div>
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/car-page">
                <img src="assets/images/logos/logo4.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">Dodge</span>
                </a>
              </div>
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/car-page">
                <img src="assets/images/logos/logo5.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">Eagle</span>
                </a>
              </div>
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/car-page">
                <img src="assets/images/logos/logo6.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">Ferrari</span>
                </a>
              </div>
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/car-page">
                <img src="assets/images/logos/logo7.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">GTA</span>
                </a>
              </div>
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/car-page">
                <img src="assets/images/logos/logo8.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">Hyundai</span>
                </a>
              </div>
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/car-page">
                <img src="assets/images/logos/logo1.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">Imperial</span>
                </a>
              </div>
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/car-page">
                <img src="assets/images/logos/logo2.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">Jaguar</span>
                </a>
              </div>
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/car-page">
                <img src="assets/images/logos/logo3.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">Kia</span>
                </a>
              </div>
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/car-page">
                <img src="assets/images/logos/logo4.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">Lamborghini</span>
                </a>
              </div>
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/car-page">
                <img src="assets/images/logos/logo5.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">Maserati</span>
                </a>
              </div>
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/car-page">
                <img src="assets/images/logos/logo6.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">Mercedes-Benz</span>
                </a>
              </div>
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/car-page">
                <img src="assets/images/logos/logo1.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">Nissan</span>
                </a>
              </div>
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/car-page">
                <img src="assets/images/logos/logo7.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">Orca</span>
                </a>
              </div>
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/car-page">
                <img src="assets/images/logos/logo2.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">Porsche</span>
                </a>
              </div>
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/car-page">
                <img src="assets/images/logos/logo8.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">Quant</span>
                </a>
              </div>
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/car-page">
                <img src="assets/images/logos/logo1.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">RMR</span>
                </a>
              </div>
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/car-page">
                <img src="assets/images/logos/logo2.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">Suzuki</span>
                </a>
              </div>
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/car-page">
                <img src="assets/images/logos/logo8.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">Toyota</span>
                </a>
              </div>
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/car-page">
                <img src="assets/images/logos/logo5.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">Ultima</span>
                </a>
              </div>
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/car-page">
                <img src="assets/images/logos/logo5.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">Vauxhall</span>
                </a>
              </div>
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/car-page">
                <img src="assets/images/logos/logo6.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">Wanderer</span>
                </a>
              </div>
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/car-page">
                <img src="assets/images/logos/logo7.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">Yamaha</span>
                </a>
              </div>
              <div class="vehicles-slider-item  text-center padding-10">
                <a routerLink="/car-page">
                <img src="assets/images/logos/logo8.png" class="img-fluid full-width" alt="Image"> <span class="slider-caption">Zagato</span>
                </a>
              </div>
            </div>
            <div class="row vehicles-border">
              <div class="vehicles-slider-nav col-12">
                <div class="vehicles-slider-nav-item"> <span>A</span>
                </div>
                <div class="vehicles-slider-nav-item"> <span>B</span>
                </div>
                <div class="vehicles-slider-nav-item"> <span>C</span>
                </div>
                <div class="vehicles-slider-nav-item"> <span>D</span>
                </div>
                <div class="vehicles-slider-nav-item"> <span>E</span>
                </div>
                <div class="vehicles-slider-nav-item"> <span>F</span>
                </div>
                <div class="vehicles-slider-nav-item"> <span>G</span>
                </div>
                <div class="vehicles-slider-nav-item"> <span>H</span>
                </div>
                <div class="vehicles-slider-nav-item"> <span>I</span>
                </div>
                <div class="vehicles-slider-nav-item"> <span>J</span>
                </div>
                <div class="vehicles-slider-nav-item"> <span>K</span>
                </div>
                <div class="vehicles-slider-nav-item"> <span>L</span>
                </div>
                <div class="vehicles-slider-nav-item"> <span>M</span>
                </div>
                <div class="vehicles-slider-nav-item"> <span>N</span>
                </div>
                <div class="vehicles-slider-nav-item"> <span>O</span>
                </div>
                <div class="vehicles-slider-nav-item"> <span>P</span>
                </div>
                <div class="vehicles-slider-nav-item"> <span>Q</span>
                </div>
                <div class="vehicles-slider-nav-item"> <span>R</span>
                </div>
                <div class="vehicles-slider-nav-item"> <span>S</span>
                </div>
                <div class="vehicles-slider-nav-item"> <span>T</span>
                </div>
                <div class="vehicles-slider-nav-item"> <span>U</span>
                </div>
                <div class="vehicles-slider-nav-item"> <span>V</span>
                </div>
                <div class="vehicles-slider-nav-item"> <span>W</span>
                </div>
                <div class="vehicles-slider-nav-item"> <span>X</span>
                </div>
                <div class="vehicles-slider-nav-item"> <span>Y</span>
                </div>
                <div class="vehicles-slider-nav-item"> <span>Z</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Find Your Best Match -->
    <!-- car boxes -->
    <div class="listing-with-map section-padding">
      <div class="container-fluid">
        <div class="row">
          <aside class="col-xl-3 col-lg-4">
            <div class="side-search-bar h-auto mb-4">
              <div class="title-icon">
                <h5 class="title"><i class="flaticon-search"></i> Search Listing</h5>
              </div>
              <div class="widget-search-filter">
                <div class="widget-content">
                  <form>
                    <div class="form-group">
                      <input type="text" name="#" class="form-control form-control-custom" placeholder="What are you looking for?">
                    </div>
                    <div class="form-group">
                      <select class="form-control form-control-custom">
                        <option>Filter By Category</option>
                        <option>Luxury Car</option>
                        <option>Sports Car</option>
                        <option>Vintage Car</option>
                        <option>Excutive Car</option>
                        <option>Economy Car</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <select class="form-control form-control-custom">
                        <option>Filter By Country</option>
                        <option value="Afghanistan">Afghanistan</option>
                        <option value="Åland Islands">Åland Islands</option>
                        <option value="Albania">Albania</option>
                        <option value="Algeria">Algeria</option>
                        <option value="American Samoa">American Samoa</option>
                        <option value="Andorra">Andorra</option>
                        <option value="Angola">Angola</option>
                        <option value="Anguilla">Anguilla</option>
                        <option value="Antarctica">Antarctica</option>
                        <option value="Antigua and Barbuda">Antigua and Barbuda</option>
                        <option value="Argentina">Argentina</option>
                        <option value="Armenia">Armenia</option>
                        <option value="Aruba">Aruba</option>
                        <option value="Australia">Australia</option>
                        <option value="Austria">Austria</option>
                        <option value="Azerbaijan">Azerbaijan</option>
                        <option value="Bahamas">Bahamas</option>
                        <option value="Bahrain">Bahrain</option>
                        <option value="Bangladesh">Bangladesh</option>
                        <option value="Barbados">Barbados</option>
                        <option value="Belarus">Belarus</option>
                        <option value="Belgium">Belgium</option>
                        <option value="Belize">Belize</option>
                        <option value="Benin">Benin</option>
                        <option value="Bermuda">Bermuda</option>
                        <option value="Bhutan">Bhutan</option>
                        <option value="Bolivia">Bolivia</option>
                        <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                        <option value="Botswana">Botswana</option>
                        <option value="Bouvet Island">Bouvet Island</option>
                        <option value="Brazil">Brazil</option>
                        <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                        <option value="Brunei Darussalam">Brunei Darussalam</option>
                        <option value="Bulgaria">Bulgaria</option>
                        <option value="Burkina Faso">Burkina Faso</option>
                        <option value="Burundi">Burundi</option>
                        <option value="Cambodia">Cambodia</option>
                        <option value="Cameroon">Cameroon</option>
                        <option value="Canada">Canada</option>
                        <option value="Cape Verde">Cape Verde</option>
                        <option value="Cayman Islands">Cayman Islands</option>
                        <option value="Central African Republic">Central African Republic</option>
                        <option value="Chad">Chad</option>
                        <option value="Chile">Chile</option>
                        <option value="China">China</option>
                        <option value="Christmas Island">Christmas Island</option>
                        <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                        <option value="Colombia">Colombia</option>
                        <option value="Comoros">Comoros</option>
                        <option value="Congo">Congo</option>
                        <option value="Congo, The Democratic Republic of The">Congo, The Democratic Republic of The</option>
                        <option value="Cook Islands">Cook Islands</option>
                        <option value="Costa Rica">Costa Rica</option>
                        <option value="Cote D'ivoire">Cote D'ivoire</option>
                        <option value="Croatia">Croatia</option>
                        <option value="Cuba">Cuba</option>
                        <option value="Cyprus">Cyprus</option>
                        <option value="Czech Republic">Czech Republic</option>
                        <option value="Denmark">Denmark</option>
                        <option value="Djibouti">Djibouti</option>
                        <option value="Dominica">Dominica</option>
                        <option value="Dominican Republic">Dominican Republic</option>
                        <option value="Ecuador">Ecuador</option>
                        <option value="Egypt">Egypt</option>
                        <option value="El Salvador">El Salvador</option>
                        <option value="Equatorial Guinea">Equatorial Guinea</option>
                        <option value="Eritrea">Eritrea</option>
                        <option value="Estonia">Estonia</option>
                        <option value="Ethiopia">Ethiopia</option>
                        <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                        <option value="Faroe Islands">Faroe Islands</option>
                        <option value="Fiji">Fiji</option>
                        <option value="Finland">Finland</option>
                        <option value="France">France</option>
                        <option value="French Guiana">French Guiana</option>
                        <option value="French Polynesia">French Polynesia</option>
                        <option value="French Southern Territories">French Southern Territories</option>
                        <option value="Gabon">Gabon</option>
                        <option value="Gambia">Gambia</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Germany">Germany</option>
                        <option value="Ghana">Ghana</option>
                        <option value="Gibraltar">Gibraltar</option>
                        <option value="Greece">Greece</option>
                        <option value="Greenland">Greenland</option>
                        <option value="Grenada">Grenada</option>
                        <option value="Guadeloupe">Guadeloupe</option>
                        <option value="Guam">Guam</option>
                        <option value="Guatemala">Guatemala</option>
                        <option value="Guernsey">Guernsey</option>
                        <option value="Guinea">Guinea</option>
                        <option value="Guinea-bissau">Guinea-bissau</option>
                        <option value="Guyana">Guyana</option>
                        <option value="Haiti">Haiti</option>
                        <option value="Heard Island and Mcdonald Islands">Heard Island and Mcdonald Islands</option>
                        <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                        <option value="Honduras">Honduras</option>
                        <option value="Hong Kong">Hong Kong</option>
                        <option value="Hungary">Hungary</option>
                        <option value="Iceland">Iceland</option>
                        <option value="India">India</option>
                        <option value="Indonesia">Indonesia</option>
                        <option value="Iran, Islamic Republic of">Iran, Islamic Republic of</option>
                        <option value="Iraq">Iraq</option>
                        <option value="Ireland">Ireland</option>
                        <option value="Isle of Man">Isle of Man</option>
                        <option value="Israel">Israel</option>
                        <option value="Italy">Italy</option>
                        <option value="Jamaica">Jamaica</option>
                        <option value="Japan">Japan</option>
                        <option value="Jersey">Jersey</option>
                        <option value="Jordan">Jordan</option>
                        <option value="Kazakhstan">Kazakhstan</option>
                        <option value="Kenya">Kenya</option>
                        <option value="Kiribati">Kiribati</option>
                        <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                        <option value="Korea, Republic of">Korea, Republic of</option>
                        <option value="Kuwait">Kuwait</option>
                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                        <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                        <option value="Latvia">Latvia</option>
                        <option value="Lebanon">Lebanon</option>
                        <option value="Lesotho">Lesotho</option>
                        <option value="Liberia">Liberia</option>
                        <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                        <option value="Liechtenstein">Liechtenstein</option>
                        <option value="Lithuania">Lithuania</option>
                        <option value="Luxembourg">Luxembourg</option>
                        <option value="Macao">Macao</option>
                        <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
                        <option value="Madagascar">Madagascar</option>
                        <option value="Malawi">Malawi</option>
                        <option value="Malaysia">Malaysia</option>
                        <option value="Maldives">Maldives</option>
                        <option value="Mali">Mali</option>
                        <option value="Malta">Malta</option>
                        <option value="Marshall Islands">Marshall Islands</option>
                        <option value="Martinique">Martinique</option>
                        <option value="Mauritania">Mauritania</option>
                        <option value="Mauritius">Mauritius</option>
                        <option value="Mayotte">Mayotte</option>
                        <option value="Mexico">Mexico</option>
                        <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                        <option value="Moldova, Republic of">Moldova, Republic of</option>
                        <option value="Monaco">Monaco</option>
                        <option value="Mongolia">Mongolia</option>
                        <option value="Montenegro">Montenegro</option>
                        <option value="Montserrat">Montserrat</option>
                        <option value="Morocco">Morocco</option>
                        <option value="Mozambique">Mozambique</option>
                        <option value="Myanmar">Myanmar</option>
                        <option value="Namibia">Namibia</option>
                        <option value="Nauru">Nauru</option>
                        <option value="Nepal">Nepal</option>
                        <option value="Netherlands">Netherlands</option>
                        <option value="Netherlands Antilles">Netherlands Antilles</option>
                        <option value="New Caledonia">New Caledonia</option>
                        <option value="New Zealand">New Zealand</option>
                        <option value="Nicaragua">Nicaragua</option>
                        <option value="Niger">Niger</option>
                        <option value="Nigeria">Nigeria</option>
                        <option value="Niue">Niue</option>
                        <option value="Norfolk Island">Norfolk Island</option>
                        <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                        <option value="Norway">Norway</option>
                        <option value="Oman">Oman</option>
                        <option value="Pakistan">Pakistan</option>
                        <option value="Palau">Palau</option>
                        <option value="Palestinian Territory, Occupied">Palestinian Territory, Occupied</option>
                        <option value="Panama">Panama</option>
                        <option value="Papua New Guinea">Papua New Guinea</option>
                        <option value="Paraguay">Paraguay</option>
                        <option value="Peru">Peru</option>
                        <option value="Philippines">Philippines</option>
                        <option value="Pitcairn">Pitcairn</option>
                        <option value="Poland">Poland</option>
                        <option value="Portugal">Portugal</option>
                        <option value="Puerto Rico">Puerto Rico</option>
                        <option value="Qatar">Qatar</option>
                        <option value="Reunion">Reunion</option>
                        <option value="Romania">Romania</option>
                        <option value="Russian Federation">Russian Federation</option>
                        <option value="Rwanda">Rwanda</option>
                        <option value="Saint Helena">Saint Helena</option>
                        <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                        <option value="Saint Lucia">Saint Lucia</option>
                        <option value="Saint Pierre and Miquelon">Saint Pierre and Miquelon</option>
                        <option value="Saint Vincent and The Grenadines">Saint Vincent and The Grenadines</option>
                        <option value="Samoa">Samoa</option>
                        <option value="San Marino">San Marino</option>
                        <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                        <option value="Saudi Arabia">Saudi Arabia</option>
                        <option value="Senegal">Senegal</option>
                        <option value="Serbia">Serbia</option>
                        <option value="Seychelles">Seychelles</option>
                        <option value="Sierra Leone">Sierra Leone</option>
                        <option value="Singapore">Singapore</option>
                        <option value="Slovakia">Slovakia</option>
                        <option value="Slovenia">Slovenia</option>
                        <option value="Solomon Islands">Solomon Islands</option>
                        <option value="Somalia">Somalia</option>
                        <option value="South Africa">South Africa</option>
                        <option value="South Georgia and The South Sandwich Islands">South Georgia and The South Sandwich Islands</option>
                        <option value="Spain">Spain</option>
                        <option value="Sri Lanka">Sri Lanka</option>
                        <option value="Sudan">Sudan</option>
                        <option value="Suriname">Suriname</option>
                        <option value="Svalbard and Jan Mayen">Svalbard and Jan Mayen</option>
                        <option value="Swaziland">Swaziland</option>
                        <option value="Sweden">Sweden</option>
                        <option value="Switzerland">Switzerland</option>
                        <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                        <option value="Taiwan, Province of China">Taiwan, Province of China</option>
                        <option value="Tajikistan">Tajikistan</option>
                        <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                        <option value="Thailand">Thailand</option>
                        <option value="Timor-leste">Timor-leste</option>
                        <option value="Togo">Togo</option>
                        <option value="Tokelau">Tokelau</option>
                        <option value="Tonga">Tonga</option>
                        <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                        <option value="Tunisia">Tunisia</option>
                        <option value="Turkey">Turkey</option>
                        <option value="Turkmenistan">Turkmenistan</option>
                        <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                        <option value="Tuvalu">Tuvalu</option>
                        <option value="Uganda">Uganda</option>
                        <option value="Ukraine">Ukraine</option>
                        <option value="United Arab Emirates">United Arab Emirates</option>
                        <option value="United Kingdom">United Kingdom</option>
                        <option value="United States">United States</option>
                        <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                        <option value="Uruguay">Uruguay</option>
                        <option value="Uzbekistan">Uzbekistan</option>
                        <option value="Vanuatu">Vanuatu</option>
                        <option value="Venezuela">Venezuela</option>
                        <option value="Viet Nam">Viet Nam</option>
                        <option value="Virgin Islands, British">Virgin Islands, British</option>
                        <option value="Virgin Islands, U.S.">Virgin Islands, U.S.</option>
                        <option value="Wallis and Futuna">Wallis and Futuna</option>
                        <option value="Western Sahara">Western Sahara</option>
                        <option value="Yemen">Yemen</option>
                        <option value="Zambia">Zambia</option>
                        <option value="Zimbabwe">Zimbabwe</option>
                      </select>
                    </div>
                    <div class="form-group">
                      <select class="form-control form-control-custom">
                        <option>Filter By City</option>
                        <option>City one</option>
                        <option>City Two</option>
                        <option>City Three</option>
                        <option>City Four</option>
                        <option>City Five</option>
                        <option>City Six</option>
                      </select>
                    </div>
                    <div class="form-group location-box p-relative">
                      <input type="text" class="form-control form-control-custom" placeholder="Location">
                    </div>
                    <div class="form-group">
                      <div class="search-distance-value">Radius: <input type="text" class="range-slider-value distance-value" readonly></div>
                      <div id="search_distance"></div>
                    </div>
                    <div class="form-group">
                      <div class="search-search-price">Price: $ 1 -
                        <input type="text" class="range-slider-value price-value" readonly>
                      </div>
                      <div id="search_price"></div>
                    </div>
                    <div class="form-group">
                      <strong>Filter By Features</strong>
                      <div class="tags-form-group">
                        <div class="form-group">
                          <label class="custom-checkbox">
                          <input type="checkbox" name="#">	<span class="checkmark"></span> Coupons</label>
                        </div>
                        <div class="form-group">
                          <label class="custom-checkbox">
                          <input type="checkbox" name="#">	<span class="checkmark"></span> Smoking Allowed</label>
                        </div>
                        <div class="form-group">
                          <label class="custom-checkbox">
                          <input type="checkbox" name="#">	<span class="checkmark"></span> Accessories</label>
                        </div>
                        <div class="form-group">
                          <label class="custom-checkbox">
                          <input type="checkbox" name="#">	<span class="checkmark"></span> Camping</label>
                        </div>
                        <div class="form-group">
                          <label class="custom-checkbox">
                          <input type="checkbox" name="#">	<span class="checkmark"></span> Field trips</label>
                        </div>
                        <div class="form-group">
                          <label class="custom-checkbox">
                          <input type="checkbox" name="#">	<span class="checkmark"></span> Getway</label>
                        </div>
                        <div class="form-group">
                          <label class="custom-checkbox">
                          <input type="checkbox" name="#">	<span class="checkmark"></span> Hot Spots</label>
                        </div>
                      </div>
                    </div>
                    <div class="form-submit text-center">
                      <button type="submit" class="btn-second orange-btn">APPLY FILTER</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="side-search-bar h-auto mb-4">
              <div class="title-icon">
                <h5 class="title"><i class="flaticon-checklist"></i> Advertisement</h5>
              </div>
              <div class="widget-search-filter mb-0">
                <div class="widget-content">
                  <div class="advertisement">
                    <a routerLink="/homepage2" target="_blank">
                    <img src="assets/images/ad.jpg" class="img-fluid full-width" alt="advertisement">
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </aside>
          <div class="col-xl-9 col-lg-8">
            <div class="title-head">
              <h5>Cars <small class="text-dark-white fw-400 rubik">Showing 12 results</small></h5>
            </div>
            <div class="right-section-car-box">
              <div class="row">
                <div class="col-xl-3 col-lg-6 col-md-4 col-sm-6">
                  <div class="car-grid-layout-box">
                    <div class="car-grid-layout-inner text-center">
                      <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">Acura TLX </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
                      <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
                      <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p>
                      <span class="text-orange price-text">$25,000</span>
                      <div class="car-grid-layout-img">
                        <img src="assets/images/showcar7.jpg" class="img-fluid full-width" alt="Car img">
                      </div>
                      <div class="car-grid-layout-details">	<span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 20,000 Km</span>
                        <span class="text-dark-white p-relative"><i class="icon-car"></i> 2010 Model</span>
                      </div>
                      <div class="car-grid-layout-location">	<span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> New York</span>
                        <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Automatic</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-4 col-sm-6">
                  <div class="car-grid-layout-box">
                    <div class="car-grid-layout-inner text-center">
                      <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">BMW 4 Series </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
                      <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
                      <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p>
                      <span class="text-orange price-text">$25,000</span>
                      <div class="car-grid-layout-img">
                        <img src="assets/images/showcar8.jpg" class="img-fluid full-width" alt="Car img">
                      </div>
                      <div class="car-grid-layout-details">	<span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 20,000 Km</span>
                        <span class="text-dark-white p-relative"><i class="icon-car"></i> 2010 Model</span>
                      </div>
                      <div class="car-grid-layout-location">	<span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> New York</span>
                        <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Manual</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-4 col-sm-6">
                  <div class="car-grid-layout-box">
                    <div class="car-grid-layout-inner text-center">
                      <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">Audi A4 </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
                      <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
                      <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p>
                      <span class="text-orange price-text">$25,000</span>
                      <div class="car-grid-layout-img">
                        <img src="assets/images/showcar7.jpg" class="img-fluid full-width" alt="Car img">
                      </div>
                      <div class="car-grid-layout-details">	<span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 20,000 Km</span>
                        <span class="text-dark-white p-relative"><i class="icon-car"></i> 2010 Model</span>
                      </div>
                      <div class="car-grid-layout-location">	<span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> New York</span>
                        <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Automatic</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-4 col-sm-6">
                  <div class="car-grid-layout-box">
                    <div class="car-grid-layout-inner text-center">
                      <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">Infiniti Q50 </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
                      <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
                      <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p>
                      <span class="text-orange price-text">$25,000</span>
                      <div class="car-grid-layout-img">
                        <img src="assets/images/showcar2.jpg" class="img-fluid full-width" alt="Car img">
                      </div>
                      <div class="car-grid-layout-details">	<span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 20,000 Km</span>
                        <span class="text-dark-white p-relative"><i class="icon-car"></i> 2010 Model</span>
                      </div>
                      <div class="car-grid-layout-location">	<span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> New York</span>
                        <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Manual</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-4 col-sm-6">
                  <div class="car-grid-layout-box">
                    <div class="car-grid-layout-inner text-center">
                      <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">Mercedes-Benz </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
                      <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
                      <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p>
                      <span class="text-orange price-text">$25,000</span>
                      <div class="car-grid-layout-img">
                        <img src="assets/images/showcar7.jpg" class="img-fluid full-width" alt="Car img">
                      </div>
                      <div class="car-grid-layout-details">	<span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 20,000 Km</span>
                        <span class="text-dark-white p-relative"><i class="icon-car"></i> 2010 Model</span>
                      </div>
                      <div class="car-grid-layout-location">	<span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> New York</span>
                        <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Automatic</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-4 col-sm-6">
                  <div class="car-grid-layout-box">
                    <div class="car-grid-layout-inner text-center">
                      <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">Lexus ES </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
                      <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
                      <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p>
                      <span class="text-orange price-text">$25,000</span>
                      <div class="car-grid-layout-img">
                        <img src="assets/images/showcar4.jpg" class="img-fluid full-width" alt="Car img">
                      </div>
                      <div class="car-grid-layout-details">	<span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 20,000 Km</span>
                        <span class="text-dark-white p-relative"><i class="icon-car"></i> 2010 Model</span>
                      </div>
                      <div class="car-grid-layout-location">	<span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> New York</span>
                        <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Manual</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-4 col-sm-6">
                  <div class="car-grid-layout-box">
                    <div class="car-grid-layout-inner text-center">
                      <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">Tesla Model 3 </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
                      <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
                      <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p>
                      <span class="text-orange price-text">$25,000</span>
                      <div class="car-grid-layout-img">
                        <img src="assets/images/showcar3.jpg" class="img-fluid full-width" alt="Car img">
                      </div>
                      <div class="car-grid-layout-details">	<span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 20,000 Km</span>
                        <span class="text-dark-white p-relative"><i class="icon-car"></i> 2010 Model</span>
                      </div>
                      <div class="car-grid-layout-location">	<span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> New York</span>
                        <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Automatic</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-4 col-sm-6">
                  <div class="car-grid-layout-box">
                    <div class="car-grid-layout-inner text-center">
                      <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">Audi TT Rs v2 </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
                      <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
                      <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p>
                      <span class="text-orange price-text">$25,000</span>
                      <div class="car-grid-layout-img">
                        <img src="assets/images/showcar2.jpg" class="img-fluid full-width" alt="Car img">
                      </div>
                      <div class="car-grid-layout-details">	<span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 20,000 Km</span>
                        <span class="text-dark-white p-relative"><i class="icon-car"></i> 2010 Model</span>
                      </div>
                      <div class="car-grid-layout-location">	<span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> New York</span>
                        <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Manual</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-4 col-sm-6">
                  <div class="car-grid-layout-box">
                    <div class="car-grid-layout-inner text-center">
                      <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">Audi TT Rs v2 </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
                      <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
                      <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p>
                      <span class="text-orange price-text">$25,000</span>
                      <div class="car-grid-layout-img">
                        <img src="assets/images/showcar1.jpg" class="img-fluid full-width" alt="Car img">
                      </div>
                      <div class="car-grid-layout-details">	<span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 20,000 Km</span>
                        <span class="text-dark-white p-relative"><i class="icon-car"></i> 2010 Model</span>
                      </div>
                      <div class="car-grid-layout-location">	<span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> New York</span>
                        <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Automatic</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-4 col-sm-6">
                  <div class="car-grid-layout-box">
                    <div class="car-grid-layout-inner text-center">
                      <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">Audi TT Rs v2 </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
                      <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
                      <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p>
                      <span class="text-orange price-text">$25,000</span>
                      <div class="car-grid-layout-img">
                        <img src="assets/images/showcar8.jpg" class="img-fluid full-width" alt="Car img">
                      </div>
                      <div class="car-grid-layout-details">	<span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 20,000 Km</span>
                        <span class="text-dark-white p-relative"><i class="icon-car"></i> 2010 Model</span>
                      </div>
                      <div class="car-grid-layout-location">	<span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> New York</span>
                        <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Manual</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-4 col-sm-6">
                  <div class="car-grid-layout-box">
                    <div class="car-grid-layout-inner text-center">
                      <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">Audi TT Rs v2 </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
                      <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
                      <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p>
                      <span class="text-orange price-text">$25,000</span>
                      <div class="car-grid-layout-img">
                        <img src="assets/images/showcar7.jpg" class="img-fluid full-width" alt="Car img">
                      </div>
                      <div class="car-grid-layout-details">	<span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 20,000 Km</span>
                        <span class="text-dark-white p-relative"><i class="icon-car"></i> 2010 Model</span>
                      </div>
                      <div class="car-grid-layout-location">	<span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> New York</span>
                        <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Automatic</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-3 col-lg-6 col-md-4 col-sm-6">
                  <div class="car-grid-layout-box">
                    <div class="car-grid-layout-inner text-center">
                      <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">Audi TT Rs v2 </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
                      <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
                      <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p>
                      <span class="text-orange price-text">$25,000</span>
                      <div class="car-grid-layout-img">
                        <img src="assets/images/showcar6.jpg" class="img-fluid full-width" alt="Car img">
                      </div>
                      <div class="car-grid-layout-details">	<span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 20,000 Km</span>
                        <span class="text-dark-white p-relative"><i class="icon-car"></i> 2010 Model</span>
                      </div>
                      <div class="car-grid-layout-location">	<span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> New York</span>
                        <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Automatic</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="right-section-car-box">
              <div class="row">
                <div class="col-xl col-lg-4 col-md-6 col-sm-6">
                  <div class="car-grid-layout-box">
                    <div class="car-grid-layout-inner text-center">
                      <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">Acura TLX </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
                      <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
                      <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p>
                      <span class="text-orange price-text">$25,000</span>
                      <div class="car-grid-layout-img">
                        <img src="assets/images/showcar7.jpg" class="img-fluid full-width" alt="Car img">
                      </div>
                      <div class="car-grid-layout-details">	<span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 22,000 Km</span>
                        <span class="text-dark-white p-relative"><i class="icon-car"></i> 2012 Model</span>
                      </div>
                      <div class="car-grid-layout-location">	<span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> New York</span>
                        <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Automatic</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl col-lg-4 col-md-6 col-sm-6">
                  <div class="car-grid-layout-box">
                    <div class="car-grid-layout-inner text-center">
                      <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">BMW 4 Series </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
                      <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
                      <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p>
                      <span class="text-orange price-text">$20,000</span>
                      <div class="car-grid-layout-img">
                        <img src="assets/images/showcar8.jpg" class="img-fluid full-width" alt="Car img">
                      </div>
                      <div class="car-grid-layout-details">	<span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 40,000 Km</span>
                        <span class="text-dark-white p-relative"><i class="icon-car"></i> 2008 Model</span>
                      </div>
                      <div class="car-grid-layout-location">	<span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> London</span>
                        <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Manual</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl col-lg-4 col-md-6 col-sm-6">
                  <div class="car-grid-layout-box">
                    <div class="car-grid-layout-inner text-center">
                      <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">Audi A4 </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
                      <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
                      <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p>
                      <span class="text-orange price-text">$30,000</span>
                      <div class="car-grid-layout-img">
                        <img src="assets/images/showcar9.jpg" class="img-fluid full-width" alt="Car img">
                      </div>
                      <div class="car-grid-layout-details">	<span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 15,000 Km</span>
                        <span class="text-dark-white p-relative"><i class="icon-car"></i> 2014 Model</span>
                      </div>
                      <div class="car-grid-layout-location">	<span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> Germany</span>
                        <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Automatic</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl col-lg-4 col-md-6 col-sm-6">
                  <div class="car-grid-layout-box">
                    <div class="car-grid-layout-inner text-center">
                      <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">Infiniti Q50 </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
                      <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
                      <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p>
                      <span class="text-orange price-text">$23,000</span>
                      <div class="car-grid-layout-img">
                        <img src="assets/images/showcar7.jpg" class="img-fluid full-width" alt="Car img">
                      </div>
                      <div class="car-grid-layout-details">	<span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 28,000 Km</span>
                        <span class="text-dark-white p-relative"><i class="icon-car"></i> 2010 Model</span>
                      </div>
                      <div class="car-grid-layout-location">	<span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> New York</span>
                        <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Automatic</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl col-lg-4 col-md-6 col-sm-6">
                  <div class="car-grid-layout-box">
                    <div class="car-grid-layout-inner text-center">
                      <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">Mercedes-Benz </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
                      <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
                      <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p>
                      <span class="text-orange price-text">$28,000</span>
                      <div class="car-grid-layout-img">
                        <img src="assets/images/showcar8.jpg" class="img-fluid full-width" alt="Car img">
                      </div>
                      <div class="car-grid-layout-details">	<span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 27,000 Km</span>
                        <span class="text-dark-white p-relative"><i class="icon-car"></i> 2011 Model</span>
                      </div>
                      <div class="car-grid-layout-location">	<span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> New York</span>
                        <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Automatic</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="right-section-car-box">
              <div class="row">
                <div class="col-xl col-lg-4 col-md-6 col-sm-6">
                  <div class="car-grid-layout-box">
                    <div class="car-grid-layout-inner text-center">
                      <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">Acura TLX </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
                      <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
                      <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p>
                      <span class="text-orange price-text">$25,000</span>
                      <div class="car-grid-layout-img">
                        <img src="assets/images/showcar7.jpg" class="img-fluid full-width" alt="Car img">
                      </div>
                      <div class="car-grid-layout-details">	<span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 22,000 Km</span>
                        <span class="text-dark-white p-relative"><i class="icon-car"></i> 2012 Model</span>
                      </div>
                      <div class="car-grid-layout-location">	<span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> New York</span>
                        <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Automatic</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl col-lg-4 col-md-6 col-sm-6">
                  <div class="car-grid-layout-box">
                    <div class="car-grid-layout-inner text-center">
                      <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">BMW 4 Series </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
                      <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
                      <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p>
                      <span class="text-orange price-text">$20,000</span>
                      <div class="car-grid-layout-img">
                        <img src="assets/images/showcar8.jpg" class="img-fluid full-width" alt="Car img">
                      </div>
                      <div class="car-grid-layout-details">	<span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 40,000 Km</span>
                        <span class="text-dark-white p-relative"><i class="icon-car"></i> 2008 Model</span>
                      </div>
                      <div class="car-grid-layout-location">	<span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> London</span>
                        <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Manual</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl col-lg-4 col-md-6 col-sm-6">
                  <div class="car-grid-layout-box">
                    <div class="car-grid-layout-inner text-center">
                      <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">Audi A4 </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
                      <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
                      <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p>
                      <span class="text-orange price-text">$30,000</span>
                      <div class="car-grid-layout-img">
                        <img src="assets/images/showcar9.jpg" class="img-fluid full-width" alt="Car img">
                      </div>
                      <div class="car-grid-layout-details">	<span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 15,000 Km</span>
                        <span class="text-dark-white p-relative"><i class="icon-car"></i> 2014 Model</span>
                      </div>
                      <div class="car-grid-layout-location">	<span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> Germany</span>
                        <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Automatic</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl col-lg-4 col-md-6 col-sm-6">
                  <div class="car-grid-layout-box">
                    <div class="car-grid-layout-inner text-center">
                      <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">Infiniti Q50 </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
                      <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
                      <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p>
                      <span class="text-orange price-text">$23,000</span>
                      <div class="car-grid-layout-img">
                        <img src="assets/images/showcar7.jpg" class="img-fluid full-width" alt="Car img">
                      </div>
                      <div class="car-grid-layout-details">	<span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 28,000 Km</span>
                        <span class="text-dark-white p-relative"><i class="icon-car"></i> 2010 Model</span>
                      </div>
                      <div class="car-grid-layout-location">	<span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> New York</span>
                        <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Automatic</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl col-lg-4 col-md-6 col-sm-6">
                  <div class="car-grid-layout-box">
                    <div class="car-grid-layout-inner text-center">
                      <h5 class="car-name"><a routerLink="/car-detail"  class="text-light-black">Mercedes-Benz </a><span class="text-orange float-right custom-tooltip" data-tip="Add to compare"><i class="flaticon-add"></i></span></h5>
                      <h6 class="text-light-black text-uppercase mb-2">YOU'RE NOT LIKE EVERYONE ELSE</h6>
                      <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort.</p>
                      <span class="text-orange price-text">$28,000</span>
                      <div class="car-grid-layout-img">
                        <img src="assets/images/showcar8.jpg" class="img-fluid full-width" alt="Car img">
                      </div>
                      <div class="car-grid-layout-details">	<span class="text-dark-white"><i class="flaticon-dashboard text-orange mr-2"></i> 27,000 Km</span>
                        <span class="text-dark-white p-relative"><i class="icon-car"></i> 2011 Model</span>
                      </div>
                      <div class="car-grid-layout-location">	<span class="text-dark-white"><i class="fas fa-map-marker-alt mr-2"></i> New York</span>
                        <span class="text-dark-white"><i class="fas fa-cog mr-2"></i> Automatic</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- car boxes -->
    <!-- In the Media -->
    <section class="section-padding side-video-img-sec-main p-relative pb-0">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="section-header">
              <div class="text-wrapper text-center">
                <h3 class="fw-500 no-margin">In the Media</h3>
                <span class="line-grey"></span><span class="line-orange"></span><span class="line-grey"></span> 
                <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort. A masterful combination of style, power, sporty handling and comfort.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row changedirct">
          <div class="col-xl-2 col-lg-3 col-md-3">
            <div class="first-video-img-sec">
              <ul class="gallery-slider-nav d-none d-md-block">
                <li class="w-50 gallery-active">
                  <a routerLink="/homepage2">
                  <img src="assets/images/media/media-1a.jpg" class="img-fluid full-width" alt="#">
                  </a>
                  <div class="overlay"></div>
                </li>
                <li class="w-50">
                  <a routerLink="/homepage2">
                  <img src="assets/images/media/media-2a.jpg" class="img-fluid full-width" alt="#">
                  </a>
                  <div class="overlay"></div>
                </li>
                <li class="w-50">
                  <a routerLink="/homepage2">
                  <img src="assets/images/media/media-3a.jpg" class="img-fluid full-width" alt="#">
                  </a>
                  <div class="overlay"></div>
                </li>
                <li class="w-50">
                  <a routerLink="/homepage2">
                  <img src="assets/images/media/media-4a.jpg" class="img-fluid full-width" alt="#">
                  </a>
                  <div class="overlay"></div>
                </li>
                <li class="w-50">
                  <a routerLink="/homepage2">
                  <img src="assets/images/media/media-5a.jpg" class="img-fluid full-width" alt="#">
                  </a>
                  <div class="overlay"></div>
                </li>
                <li class="w-50">
                  <a routerLink="/homepage2">
                  <img src="assets/images/media/media-6a.jpg" class="img-fluid full-width" alt="#">
                  </a>
                  <div class="overlay"></div>
                </li>
                <li class="w-50">
                  <a routerLink="/homepage2">
                  <img src="assets/images/media/media-2b.jpg" class="img-fluid full-width" alt="#">
                  </a>
                  <div class="overlay"></div>
                </li>
                <li class="w-50">
                  <a routerLink="/homepage2">
                  <img src="assets/images/media/media-3b.jpg" class="img-fluid full-width" alt="#">
                  </a>
                  <div class="overlay"></div>
                </li>
                <li class="w-50">
                  <a routerLink="/homepage2">
                  <img src="assets/images/media/media-4b.jpg" class="img-fluid full-width" alt="#">
                  </a>
                  <div class="overlay"></div>
                </li>
                <li class="w-50">
                  <a routerLink="/homepage2">
                  <img src="assets/images/media/media-5b.jpg" class="img-fluid full-width" alt="#">
                  </a>
                  <div class="overlay"></div>
                </li>
                <li class="w-50">
                  <a routerLink="/homepage2">
                  <img src="assets/images/media/media-1a.jpg" class="img-fluid full-width" alt="#">
                  </a>
                  <div class="overlay"></div>
                </li>
                <li class="w-50">
                  <a routerLink="/homepage2">
                  <img src="assets/images/media/media-6b.jpg" class="img-fluid full-width" alt="#">
                  </a>
                  <div class="overlay"></div>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-xl-10 col-lg-9 col-md-9">
            <div class="second-video-img-sec">
              <div class="gallery-slider-for">
                <div class="gallery-slider-item">
                  <!-- <iframe src="https://www.youtube.com/embed/AdZrEIo6UYU" allow="autoplay"></iframe> -->
                  <div class="video_wrapper video_wrapper_full js-videoWrapper">
                    <iframe class="videoIframe js-videoIframe" src="https://www.youtube.com/embed/pbiEfJd2Hpw" data-src="https://www.youtube.com/embed/pbiEfJd2Hpw?autoplay=1&rel=0" allow="autoplay"></iframe>
                    <div class="videoPoster js-videoPoster">
                      <img src="assets/images/media/media-1.jpg" class="img-fluid full-width" alt="#">
                      <div class="video-inner"> <i class="flaticon-triangle"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="gallery-slider-item">
                  <img src="assets/images/media/media-2.jpg" class="img-fluid full-width" alt="#">
                </div>
                <div class="gallery-slider-item">
                  <img src="assets/images/media/media-3.jpg" class="img-fluid full-width" alt="#">
                </div>
                <div class="gallery-slider-item">
                  <img src="assets/images/media/media-4.jpg" class="img-fluid full-width" alt="#">
                </div>
                <div class="gallery-slider-item">
                  <img src="assets/images/media/media-5.jpg" class="img-fluid full-width" alt="#">
                </div>
                <div class="gallery-slider-item">
                  <img src="assets/images/media/media-6.jpg" class="img-fluid full-width" alt="#">
                </div>
                <div class="gallery-slider-item">
                  <img src="assets/images/media/media-1.jpg" class="img-fluid full-width" alt="#">
                </div>
                <div class="gallery-slider-item">
                  <img src="assets/images/media/media-2.jpg" class="img-fluid full-width" alt="#">
                </div>
                <div class="gallery-slider-item">
                  <img src="assets/images/media/media-3.jpg" class="img-fluid full-width" alt="#">
                </div>
                <div class="gallery-slider-item">
                  <img src="assets/images/media/media-4.jpg" class="img-fluid full-width" alt="#">
                </div>
                <div class="gallery-slider-item">
                  <img src="assets/images/media/media-1.jpg" class="img-fluid full-width" alt="#">
                </div>
                <div class="gallery-slider-item">
                  <img src="assets/images/media/media-6.jpg" class="img-fluid full-width" alt="#">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- In the Media -->
    <!-- Sponsored Sports Cars -->
    <section class="section-padding model-spec-sec  bg-light-theme pb-0">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="section-header">
              <div class="text-wrapper text-center">
                <h3 class="fw-500 no-margin">Sponsored Sports Cars </h3>
                <span class="line-grey"></span><span class="line-orange"></span><span class="line-grey"></span> 
                <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort. A masterful combination of style, power, sporty handling and comfort.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="p-relative">
              <div class="model-spec-slider">
                <div class="model-spec-slider-item p-relative">
                  <div class="model-spec-top-sec bg-light-theme">
                    <a routerLink="/car-detail">
                      <h4 class="text-dark-grey">BMW 535i</h4>
                      <p class="text-dark-grey">From <strong>$ 34,950.00</strong>
                      </p>
                      <p class="text-dark-grey no-margin">*Manufacturer’s Suggested Retail Price. Excludes options; taxes; title; registration; delivery, processing and handling fee; dealer charges.</p>
                    </a>
                  </div>
                  <img src="assets/images/showcar2.jpg" class="img-fluid full-width" alt="Model Image">
                  <div class="bg-white">
                    <div class="model-spec-wrapper">
                      <div class="model-spec-bottom-sec">
                        <ul>
                          <li>
                            <h5 class="text-custom-black">379 hp</h5>
                            <p class="text-custom-black">Max. power</p>
                          </li>
                          <li>
                            <h5 class="text-custom-black">4.2 s</h5>
                            <p class="text-custom-black">0-60 mph</p>
                          </li>
                          <li>
                            <h5 class="text-custom-black">180 mph</h5>
                            <p class="text-custom-black">Top Track Speed</p>
                          </li>
                          <li>
                            <h5 class="text-custom-black">6,500 rpm</h5>
                            <p class="text-custom-black">Max. power at rpm</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="model-spec-slider-item p-relative">
                  <div class="model-spec-top-sec bg-light-theme">
                    <a routerLink="/car-detail">
                      <h4 class="text-dark-grey">Audi TT v2</h4>
                      <p class="text-dark-grey">From <strong>$ 32,500.00</strong>
                      </p>
                      <p class="text-dark-grey no-margin">*Manufacturer’s Suggested Retail Price. Excludes options; taxes; title; registration; delivery, processing and handling fee; dealer charges.</p>
                    </a>
                  </div>
                  <img src="assets/images/showcar1.jpg" class="img-fluid full-width" alt="Model Image">
                  <div class="bg-white">
                    <div class="model-spec-wrapper">
                      <div class="model-spec-bottom-sec">
                        <ul>
                          <li>
                            <h5 class="text-custom-black">379 hp</h5>
                            <p class="text-custom-black">Max. power</p>
                          </li>
                          <li>
                            <h5 class="text-custom-black">4.0 s</h5>
                            <p class="text-custom-black">0-60 mph</p>
                          </li>
                          <li>
                            <h5 class="text-custom-black">180 mph</h5>
                            <p class="text-custom-black">Top Track Speed</p>
                          </li>
                          <li>
                            <h5 class="text-custom-black">6,500 rpm</h5>
                            <p class="text-custom-black">Max. power at rpm</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="model-spec-slider-item p-relative">
                  <div class="model-spec-top-sec bg-light-theme">
                    <a routerLink="/car-detail">
                      <h4 class="text-dark-grey">Mercedes-Benz E-Class</h4>
                      <p class="text-dark-grey">From <strong>$ 25,900.00</strong>
                      </p>
                      <p class="text-dark-grey no-margin">*Manufacturer’s Suggested Retail Price. Excludes options; taxes; title; registration; delivery, processing and handling fee; dealer charges.</p>
                    </a>
                  </div>
                  <img src="assets/images/showcar3.jpg" class="img-fluid full-width" alt="Model Image">
                  <div class="bg-white">
                    <div class="model-spec-wrapper">
                      <div class="model-spec-bottom-sec">
                        <ul>
                          <li>
                            <h5 class="text-custom-black">379 hp</h5>
                            <p class="text-custom-black">Max. power</p>
                          </li>
                          <li>
                            <h5 class="text-custom-black">4.2 s</h5>
                            <p class="text-custom-black">0-60 mph</p>
                          </li>
                          <li>
                            <h5 class="text-custom-black">179 mph</h5>
                            <p class="text-custom-black">Top Track Speed</p>
                          </li>
                          <li>
                            <h5 class="text-custom-black">6,500 rpm</h5>
                            <p class="text-custom-black">Max. power at rpm</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="model-spec-slider-item p-relative">
                  <div class="model-spec-top-sec bg-light-theme">
                    <a routerLink="/car-detail">
                      <h4 class="text-dark-grey">Panamera Turbo S E-Hybrid Executive</h4>
                      <p class="text-dark-grey">From <strong>$ 198, 100.00</strong>
                      </p>
                      <p class="text-dark-grey no-margin">*Manufacturer’s Suggested Retail Price. Excludes options; taxes; title; registration; delivery, processing and handling fee; dealer charges.</p>
                    </a>
                  </div>
                  <img src="assets/images/showcar2.jpg" class="img-fluid full-width" alt="Model Image">
                  <div class="bg-white">
                    <div class="model-spec-wrapper">
                      <div class="model-spec-bottom-sec">
                        <ul>
                          <li>
                            <h5 class="text-custom-black">433 hp</h5>
                            <p class="text-custom-black">Max. power</p>
                          </li>
                          <li>
                            <h5 class="text-custom-black">3.3 s</h5>
                            <p class="text-custom-black">0-60 mph</p>
                          </li>
                          <li>
                            <h5 class="text-custom-black">191 mph</h5>
                            <p class="text-custom-black">Top Track Speed</p>
                          </li>
                          <li>
                            <h5 class="text-custom-black">6,500 rpm</h5>
                            <p class="text-custom-black">Max. power at rpm</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="model-spec-slider-arrows">
                <div class="model-spec-slider-prevtab slick-arrow"> <i class="flaticon-left-chevron"></i>
                </div>
                <div class="model-spec-slider-nexttab slick-arrow"> <i class="flaticon-right-chevron"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Sponsored Sports Cars -->
    <!-- Models Currently Trending -->
    <section class="section-padding model-gallery">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="section-header">
              <div class="text-wrapper text-center">
                <h3 class="fw-500 no-margin">Models Currently Trending</h3>
                <span class="line-grey"></span><span class="line-orange"></span><span class="line-grey"></span> 
                <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort. A masterful combination of style, power, sporty handling and comfort.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="custom-col">
            <img src="assets/images/trendingmodels/trending1.jpg" class="img-fluid full-width" alt="Image">
            <div class="model-gallery-caption">
              <div class="model-gallery-wrapper-1">
                <h3 class="text-custom-white model-gal-title">Acura</h3>
                <h6 class="text-custom-white rubik">From <strong>$ 25,900.00*</strong></h6>
              </div>
              <div class="model-gallery-wrapper-2"> <a routerLink="/listing" class="btn-second orange-btn">View Category</a>
                <a routerLink="/listing" class="btn-fourth border-btn">All Models</a>
              </div>
            </div>
          </div>
          <div class="custom-col">
            <img src="assets/images/trendingmodels/trending2.jpg" class="img-fluid full-width" alt="Image">
            <div class="model-gallery-caption">
              <div class="model-gallery-wrapper-1">
                <h3 class="text-custom-white model-gal-title">Audi</h3>
                <h6 class="text-custom-white rubik">From <strong>$ 32,500.00*</strong></h6>
              </div>
              <div class="model-gallery-wrapper-2"> <a routerLink="/listing" class="btn-second orange-btn">View Category</a>
                <a routerLink="/listing" class="btn-fourth border-btn">All Models</a>
              </div>
            </div>
          </div>
          <div class="custom-col">
            <img src="assets/images/trendingmodels/trending3.jpg" class="img-fluid full-width" alt="Image">
            <div class="model-gallery-caption">
              <div class="model-gallery-wrapper-1">
                <h3 class="text-custom-white model-gal-title">BMW</h3>
                <h6 class="text-custom-white rubik">From <strong>$ 34,950.00*</strong></h6>
              </div>
              <div class="model-gallery-wrapper-2"> <a routerLink="/listing" class="btn-second orange-btn">View Category</a>
                <a routerLink="/listing" class="btn-fourth border-btn">All Models</a>
              </div>
            </div>
          </div>
          <div class="custom-col">
            <img src="assets/images/trendingmodels/trending4.jpg" class="img-fluid full-width" alt="Image">
            <div class="model-gallery-caption">
              <div class="model-gallery-wrapper-1">
                <h3 class="text-custom-white model-gal-title">Ford</h3>
                <h6 class="text-custom-white rubik">From <strong>$ 14,260.00*</strong></h6>
              </div>
              <div class="model-gallery-wrapper-2"> <a routerLink="/listing" class="btn-second orange-btn">View Category</a>
                <a routerLink="/listing" class="btn-fourth border-btn">All Models</a>
              </div>
            </div>
          </div>
          <div class="custom-col">
            <img src="assets/images/trendingmodels/trending5.jpg" class="img-fluid full-width" alt="Image">
            <div class="model-gallery-caption">
              <div class="model-gallery-wrapper-1">
                <h3 class="text-custom-white model-gal-title">Range Rover</h3>
                <h6 class="text-custom-white rubik">From <strong>$ 37,800.00*</strong></h6>
              </div>
              <div class="model-gallery-wrapper-2"> <a routerLink="/listing" class="btn-second orange-btn">View Category</a>
                <a routerLink="/listing" class="btn-fourth border-btn">All Models</a>
              </div>
            </div>
          </div>
          <div class="custom-col">
            <img src="assets/images/trendingmodels/trending6.jpg" class="img-fluid full-width" alt="Image">
            <div class="model-gallery-caption">
              <div class="model-gallery-wrapper-1">
                <h3 class="text-custom-white model-gal-title">Maserati</h3>
                <h6 class="text-custom-white rubik">From <strong>$ 74,980.00*</strong></h6>
              </div>
              <div class="model-gallery-wrapper-2"> <a routerLink="/listing" class="btn-second orange-btn">View Category</a>
                <a routerLink="/listing" class="btn-fourth border-btn">All Models</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Models Currently Trending -->
    <!-- In Media -->
    <section class="in-media-sec">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12">
            <div class="section-header">
              <div class="text-wrapper text-center">
                <h3 class="fw-500 no-margin">Car Valley In Media</h3>
                <span class="line-grey"></span><span class="line-orange"></span><span class="line-grey"></span> 
                <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort. A masterful combination of style, power, sporty handling and comfort.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <!-- sec 1 -->
          <div class="in-media-box">
            <div class="grid-box large-img-box">
              <img src="assets/images/socialmedia/social1.jpg" class="img-fluid" alt="In media">
              <div class="in-media-box-caption video-btn">
                <div class="main-icon">
                  <a routerLink="/homepage2">	<i class="fab fa-instagram"></i>
                  </a>
                </div>
                <div class="media-box-caption-wrapper ">
                  <p class="text-light-black">The new Rims - Not ordinary. But thrilling.</p>
                  <div class="icon-box row">
                    <div class="col-12">
                      <img src="assets/images/user-1.jpg" alt="image">	<span class="text-light-black">Rims</span>
                      <a routerLink="/homepage2">	<i class="fab fa-instagram"></i>
                      </a>
                    </div>
                  </div>
                  <a routerLink="/homepage2" class="btn-first orange-btn full-width">View Category</a>
                </div>
              </div>
            </div>
            <div class="grid-box grid-box-small small-img-box padding-right-5">
              <img src="assets/images/socialmedia/social3.jpg" class="img-fluid" alt="In media">
              <div class="in-media-box-caption">
                <div class="main-icon">
                  <a routerLink="/homepage2">	<i class="fab fa-twitter"></i>
                  </a>
                </div>
                <div class="media-box-caption-wrapper">
                  <div class="icon-box row">
                    <div class="col-12">
                      <img src="assets/images/user-2.jpg" alt="image">	<span class="text-light-black d-block">Rims</span>	
                    </div>
                  </div>
                  <p class="text-light-black">The new Rims
                    <a routerLink="/homepage2">	<i class="fab fa-twitter"></i>
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div class="grid-box grid-box-small small-img-box ">
              <img src="assets/images/socialmedia/social4.jpg" class="img-fluid" alt="In media">
              <div class="in-media-box-caption">
                <div class="main-icon">
                  <a routerLink="/homepage2">	<i class="fab fa-facebook-f"></i>
                  </a>
                </div>
                <div class="media-box-caption-wrapper">
                  <div class="icon-box row">
                    <div class="col-12">
                      <img src="assets/images/user-3.jpg" alt="image">	<span class="text-light-black d-block">Rims</span>	
                    </div>
                  </div>
                  <p class="text-light-black">The new Rims
                    <a routerLink="/homepage2">	<i class="fab fa-facebook-f"></i>
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <!-- sec 1 -->
            <!-- sec 2 -->
            <div class="grid-box grid-box-small small-img-box padding-right-5">
              <img src="assets/images/socialmedia/social9.jpg" class="img-fluid" alt="In media">
              <div class="in-media-box-caption">
                <div class="main-icon">
                  <a routerLink="/homepage2">	<i class="fab fa-instagram"></i>
                  </a>
                </div>
                <div class="media-box-caption-wrapper">
                  <div class="icon-box row">
                    <div class="col-12">
                      <img src="assets/images/user-1.jpg" alt="image">	<span class="text-light-black d-block">Rims</span>	
                    </div>
                  </div>
                  <p class="text-light-black">The new Rims
                    <a routerLink="/homepage2">	<i class="fab fa-instagram"></i>
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div class="grid-box grid-box-small small-img-box">
              <img src="assets/images/socialmedia/social6.jpg" class="img-fluid" alt="In media">
              <div class="in-media-box-caption">
                <div class="main-icon">
                  <a routerLink="/homepage2">	<i class="fab fa-facebook-f"></i>
                  </a>
                </div>
                <div class="media-box-caption-wrapper">
                  <div class="icon-box row">
                    <div class="col-12">
                      <img src="assets/images/user-2.jpg" alt="image">	<span class="text-light-black d-block">Rims</span>	
                    </div>
                  </div>
                  <p class="text-light-black">The new Rims
                    <a routerLink="/homepage2">	<i class="fab fa-facebook-f"></i>
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div class="grid-box large-img-box">
              <img src="assets/images/socialmedia/social2.jpg" class="img-fluid" alt="In media">
              <div class="in-media-box-caption video-btn">
                <div class="main-icon">
                  <a routerLink="/homepage2">	<i class="fab fa-youtube"></i>
                  </a>
                </div>
                <div class="media-box-caption-wrapper">
                  <p class="text-light-black">The new Rims - Not ordinary. But thrilling.</p>
                  <div class="icon-box row">
                    <div class="col-6">
                      <img src="assets/images/user-3.jpg" alt="image">	<span class="text-light-black">Rims</span>
                    </div>
                    <div class="col-6">
                      <a routerLink="/homepage2">	<i class="fab fa-youtube"></i>
                      </a>
                    </div>
                  </div>
                  <a routerLink="/homepage2" class="btn-first orange-btn full-width">Learn More</a>
                </div>
              </div>
            </div>
            <!-- sec 2 -->
            <!-- sec 3 -->
            <div class="grid-box large-img-box">
              <img src="assets/images/socialmedia/social8.jpg" class="img-fluid" alt="In media">
              <div class="in-media-box-caption">
                <div class="main-icon">
                  <a routerLink="/homepage2">	<i class="fab fa-instagram"></i>
                  </a>
                </div>
                <div class="media-box-caption-wrapper">
                  <p class="text-light-black">The new Rims - Not ordinary. But thrilling.</p>
                  <div class="icon-box row">
                    <div class="col-6">
                      <img src="assets/images/user-2.jpg" alt="image">	<span class="text-light-black">Rims</span>
                    </div>
                    <div class="col-6">
                      <a routerLink="/homepage2">	<i class="fab fa-instagram"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="grid-box grid-box-small small-img-box padding-right-5">
              <img src="assets/images/socialmedia/social7.jpg" class="img-fluid" alt="In media">
              <div class="in-media-box-caption">
                <div class="main-icon">
                  <a routerLink="/homepage2">	<i class="fab fa-twitter"></i>
                  </a>
                </div>
                <div class="media-box-caption-wrapper">
                  <div class="icon-box row">
                    <div class="col-12">
                      <img src="assets/images/user-1.jpg" alt="image">	<span class="text-light-black d-block">Rims</span>	
                    </div>
                  </div>
                  <p class="text-light-black">The new Rims
                    <a routerLink="/homepage2">	<i class="fab fa-twitter"></i>
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div class="grid-box grid-box-small small-img-box">
              <img src="assets/images/socialmedia/social6.jpg" class="img-fluid" alt="In media">
              <div class="in-media-box-caption">
                <div class="main-icon">
                  <a routerLink="/homepage2">	<i class="fab fa-facebook-f"></i>
                  </a>
                </div>
                <div class="media-box-caption-wrapper">
                  <div class="icon-box row">
                    <div class="col-12">
                      <img src="assets/images/user-3.jpg" alt="image">	<span class="text-light-black d-block">Rims</span>	
                    </div>
                  </div>
                  <p class="text-light-black">The new Rims
                    <a routerLink="/homepage2">	<i class="fab fa-facebook-f"></i>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- sec 3 -->
        </div>
      </div>
    </section>
    <!-- In Media -->
<app-footer2></app-footer2>

    <div class="modal fade" id="register">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h6 class="modal-title text-left no-margin"><i class="fas fa-unlock-alt mr-2"></i> <span id="signup-text">Sign Up</span><span
              id="signin-text" class="none">Sign In</span></h6>
            <button type="button" class="close" data-dismiss="modal">&times;</button>
          </div>
          <!-- Sign up form -->
          <div class="modal-body" id="signup-form">
            <form>
              <div class="form-group">
                <input type="email" class="form-control" name="#" placeholder="Email">
              </div>
              <div class="form-group">
                <input type="password" class="form-control" name="#" placeholder="Password">
              </div>
              <div class="form-group">
                <button type="submit" class="btn-second orange-btn full-width">Sign Up</button>
              </div>
            </form>
            <div class="form-excerpts">
              <ul>
                <li>Already a member? <a href="javascript:void(0)" id="signin-btn">Sign In</a>
                </li>
                <li><a routerLink="/homepage2">Recover Password</a>
                </li>
              </ul>
            </div>
            <div class="social-login">
              <span>Or Signup with</span>
              <div class="social-btn">	<a routerLink="/homepage2" class="facebook"><i class="fab fa-facebook-f"></i> Facebook</a>
                <a routerLink="/homepage2" class="google"><i class="fab fa-google-plus-g"></i> Google</a>
              </div>
            </div>
          </div>
          <!-- Sign up form -->
          <!-- Sign in form -->
          <div class="modal-body none" id="signin-form">
            <form>
              <div class="form-group">
                <input type="text" class="form-control" name="#" placeholder="Username or Email">
              </div>
              <div class="form-group">
                <input type="password" class="form-control" name="#" placeholder="Password">
              </div>
              <div class="form-group">
                <label class="custom-checkbox">
                <input type="checkbox" name="#">Keep me signed in	<span class="checkmark"></span>
                </label>
              </div>
              <div class="form-group">
                <button type="submit" class="btn-second orange-btn full-width">Sign In</button>
              </div>
            </form>
            <div class="form-excerpts">
              <ul>
                <li>Not a member? <a href="javascript:void(0)" id="signup-btn">Sign Up</a>
                </li>
                <li><a routerLink="/homepage2">Recover Password</a>
                </li>
              </ul>
            </div>
            <div class="social-login">
              <span>Or connect with</span>
              <div class="social-btn">	<a routerLink="/homepage2" class="facebook"><i class="fab fa-facebook-f"></i> Facebook</a>
                <a routerLink="/homepage2" class="google"><i class="fab fa-google-plus-g"></i> Google</a>
              </div>
            </div>
          </div>
          <!-- Sign in form -->
        </div>
      </div>
    </div>
    <div class="menu-style-3">
      <div class="search-box search-elem">
        <button class="close">x</button>
        <div class="inner row">
          <div class="col-12">
            <label class="text-custom-white">Search Your Category</label>
            <div class="input-group">
              <input type="text" class="form-control form-control-custom" placeholder="Search keywords, category, brand of part">
              <div class="input-group-prepend">
                <select class="menu-style-3-search">
                  <option>All Category</option>
                  <option>Luxury Car</option>
                  <option>Sports Car</option>
                  <option>Vintage Car</option>
                  <option>Excutive Car</option>
                  <option>Economy Car</option>
                </select>
              </div>
              <div class="input-group-append">
                <button class="btn-second orange-btn full-width" type="submit">Search</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>