<!-- Start Header -->
  <!-- Start Desktop Menu -->
  <div class="d-none d-md-block">
    <div class="top-bar">
      <div class="container">
        <div class="row">
          <div class="col-xl-6 col-lg-6 col-md-7 col-sm-12">
            <div class="top-contact">
              <ul>
                <li><a href="javascript:void(0)" class="text-dark-grey "><i class="fas fa-phone-alt"></i> +123 4567 8910</a>
                </li>
                <li><a href="javascript:void(0)" class="text-dark-grey"><i class="fas fa-map-marker-alt"></i> Sell Your Car</a>
                </li>
                <li><a href="javascript:void(0)" class="text-dark-grey"><i class="fas fa-crosshairs"></i> Cars Around me</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-5 col-sm-12">
            <div class="top-contact float-right">
              <ul>
                <li class="border-left-0">
                  <a routerLink="/login" class="text-dark-grey">
                    <img src="assets/images/user.png" class="rounded-circle" alt="#">Sign In</a>
                </li>
                <li class="dropdown">
                  <a href="javascript:void(0)" class="text-dark-grey" data-toggle="dropdown">
                    <img src="assets/images/usa.png" class="rounded-circle" alt="#">USD <i class="fas fa-caret-down"></i>
                  </a>
                  <ul class="dropdown-menu">
                    <li>
                      <a href="javascript:void(0)" class="text-dark-grey">
                        <img src="assets/images/italy.png" class="rounded-circle" alt="#">EUR</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)" class="text-dark-grey">
                        <img src="assets/images/germany.png" class="rounded-circle" alt="#">GBP</a>
                    </li>
                  </ul>
                </li>
                <li class="dropdown"><a href="javascript:void(0)" class="text-dark-grey" data-toggle="dropdown">Help <i class="fas fa-caret-down"></i></a>
                  <ul class="dropdown-menu">
                    <li><a routerLink="/contact" class="text-dark-grey">Contact Us </a>
                    </li>
                    <li><a routerLink="/contact" class="text-dark-grey">Support Desk </a>
                    </li>
                  </ul>
                </li>
                <li class="border-right">
                  <button type="button" id="open-nav"><i class="flaticon-menu"></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Header sec 1 -->
    <header id="menu-style-1">
      <div class="container">
        <div class="row">
          <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
            <div class="logo">
              <a routerLink="/">
                <img src="assets/images/rims.png" class="img-fluid" alt="Logo">
              </a>
            </div>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
            <div class="searchbox align-item-center">
              <div class="input-group">
                <input type="text" class="form-control h-100" placeholder="Search keywords, category, brand of part">
                <div class="input-group-prepend catergory-selct">
                  <ul class="list-unstyled cat-selc">
                    <li class="init">All Categories <i class="fas fa-caret-down"></i> 
                    </li>
                    <li data-value="Luxury Car"><i class="flaticon-supercar"></i> Luxury Car</li>
                    <li data-value="Sports Car"><i class="flaticon-sportive-car"></i> Sports Car</li>
                    <li data-value="Economy Car"><i class="flaticon-coupe-car"></i> Economy Car</li>
                    <li data-value="Vintage Car"><i class="flaticon-vintage-car"></i> Vintage Car</li>
                    <li data-value="Excutive Car"><i class="flaticon-minivan-car"></i> Excutive Car</li>
                  </ul>
                </div>
                <div class="input-group-prepend">
                  <button class="btn-second orange-btn" type="submit">Search</button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
            <div class="cart-sec float-right">
              <ul>
                <li><a href="javascript:void(0)" class="text-dark-grey"><i class="flaticon-reload"></i></a>
                </li>
                <li><a href="javascript:void(0)" class="text-dark-grey"><i class="flaticon-like"></i></a>
                  <span class="pro-value bg-orange">5</span>
                </li>
                <li><a href="javascript:void(0)" class="text-light-black"><i class="flaticon-shopping-cart text-dark-grey"></i>$ 155</a>
                  <span class="pro-value bg-orange">5</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="mobile-nav no-padding">
          <div id="mySidenav" class="sidenav">
            <div class="side-nav-wrapper"> <span class="float-left ">Rims</span>
              <span class="float-right">
              <a href="javascript:void(0)" class="closebtn" id="close-nav">X</a>
              </span>
              <ul>
                <li class="custom-dropdown-nav"><a href="javascript:void(0)" class="text-custom-white"><h3 class="mb-0">Home</h3></a>
                  <ul class="dropdown-menu">
                    <li><a routerLink="/">Landing Page</a>
                    </li>
                    <li><a routerLink="/homepage2">Home 2</a>
                    </li>
                    <li><a routerLink="/homepage3">Home 3</a>
                    </li>
                    <li><a routerLink="/homepage4">Home 4</a>
                    </li>
                    <li><a routerLink="/homepage5">Home 5</a>
                    </li>
                    <li><a routerLink="/homepage6">Home 6</a>
                    </li>
                    <li><a routerLink="/homepage7">Home 7</a>
                    </li>
                  </ul>
                </li>
                <li><a routerLink="/media"><h3 class="mb-0">Media</h3></a>
                </li>
                <li class="custom-dropdown-nav"><a href="javascript:void(0)"><h3 class="mb-0">Pages</h3></a>
                  <ul class="dropdown-menu">
                    <li><a routerLink="/listing">Listing</a>
                    </li>
                    <li><a routerLink="/listing1">Listing 1</a>
                    </li>
                    <li><a routerLink="/listing2">Listing 2</a>
                    </li>
                    <li><a routerLink="/listing3">Listing 3</a>
                    </li>
                    <li><a routerLink="/listing4">Listing 4</a>
                    </li>
                    <li><a routerLink="/listing1-style2">Listing 1 Style 2</a>
                    </li>
                    <li><a routerLink="/listing2-style2">Listing 2 Style 2</a>
                    </li>
                    <li><a routerLink="/add-listing">Add Listing</a>
                    </li>
                    <li><a routerLink="/aboutus">About Us</a>
                    </li>
                    <li><a routerLink="/car-detail">Car Details</a>
                    </li>
                    <li><a routerLink="/car-page">Car Page</a>
                    </li>
                    <li><a routerLink="/user">User</a>
                    </li>
                    <li><a routerLink="/register">Register</a>
                    </li>
                    <li><a routerLink="/login">Login</a>
                    </li>
                    <li><a routerLink="/invoice">Invoice</a>
                    </li>
                    <li><a routerLink="/coming-soon">Coming Soon</a>
                    </li>
                  </ul>
                </li>
                <li class="custom-dropdown-nav"><a href="javascript:void(0)" class="text-custom-white"><h3 class="mb-0">Blog</h3></a>
                  <ul class="dropdown-menu">
                    <li><a routerLink="/blog-grid">Blog Grid</a>
                    </li>
                    <li><a routerLink="/blog-left">Blog Left Sidebar</a>
                    </li>
                    <li><a routerLink="/blog-right">Blog Right Sidebar</a>
                    </li>
                    <li><a routerLink="/blog-standard">Blog Single</a>
                    </li>
                    <li><a routerLink="/blog-gallery">Blog Gallery</a>
                    </li>
                    <li><a routerLink="/blog-video">Blog Video</a>
                    </li>
                    <li><a routerLink="/blog-quote">Blog Quote</a>
                    </li>
                    <li><a routerLink="/blog-detail-left">Blog Details Left</a>
                    </li>
                    <li><a routerLink="/blog-detail-right">Blog Details Right</a>
                    </li>
                  </ul>
                </li>
                <li><a routerLink="/pricing"><h3 class="mb-0">Pricing</h3></a>
                </li>
                <li><a routerLink="/contact"><h3 class="mb-0">Contact</h3></a>
                </li>
              </ul>
              <div class="mobile-social-icon">
                <ul>
                  <li><a href="javascript:void(0)"><i class="fab fa-facebook-f"></i></a>
                  </li>
                  <li><a href="javascript:void(0)"><i class="fab fa-twitter"></i></a>
                  </li>
                  <li><a href="javascript:void(0)"><i class="fab fa-pinterest-p"></i></a>
                  </li>
                  <li><a href="javascript:void(0)"><i class="fab fa-instagram"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- Header sec 1 -->
  </div>
  <!-- End Desktop Menu -->
  <!-- Start Mobile Menu -->
  <div class="mobile-menu d-block d-md-none">
    <div class="top-bar">
      <div class="container">
        <div class="row">
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
            <div class="top-contact">
              <ul>
                <li><a href="javascript:void(0)" class="text-dark-grey "><i class="fas fa-phone-alt"></i> +123 4567 8910</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
            <div class="top-contact float-right">
              <ul>
                <li class="border-left-0">
                  <a routerLink="/login" class="text-dark-grey">
                    <img src="assets/images/user.png" class="rounded-circle" alt="#">Sign In</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mobile-nav">
      <div class="container">
        <div class="row" id="main-sec">
          <div class="col-sm-9 col-6">
            <div class="logo">
              <a routerLink="/">
                <img src="assets/images/rims.png" class="img-fluid" alt="Logo">
              </a>
            </div>
            <div class="mobile-search align-item-center">
              <div class="input-group">
                <input type="text" class="form-control h-100" placeholder="Search keywords, category, brand of part">
                <div class="input-group-append">
                  <button class="btn-second orange-btn" type="submit"><i class="flaticon-search"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-sm-3 col-6">
            <div class="mobile-search align-item-center">
              <div class="mobile-cart  float-right">
                <div class="cart-sec">
                  <ul>
                    <li class="mob-search" id="search-btn"><a href="javascript:void(0)" class="text-dark-grey"><i class="flaticon-search"></i></a>
                    </li>
                    <li><a href="javascript:void(0)" class="text-dark-grey"><i class="flaticon-like"></i></a>
                      <span class="pro-value bg-orange">5</span>
                    </li>
                    <li><a href="javascript:void(0)" class="text-light-black"><i class="flaticon-shopping-cart text-dark-grey"></i></a>
                      <span class="pro-value bg-orange">5</span>
                    </li>
                    <li>
                      <button type="button" id="mobile-open-nav"><i class="flaticon-menu"></i>
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row none" id="show-search">
          <div class="col-12">
            <div class="mobile-search-new align-item-center"> <span class="close-icon" id="close">X</span>
              <div class="input-group">
                <input type="text" class="form-control h-100" placeholder="Search keywords, category, brand of part">
                <div class="input-group-append">
                  <button class="btn-second orange-btn" type="submit"><i class="flaticon-search"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div id="mobile-mySidenav" class="sidenav">
            <div class="side-nav-wrapper"> <span class="float-left ">Rims</span>
              <span class="float-right">
              <a href="javascript:void(0)" class="closebtn" id="mobile-close-nav">X</a>
              </span>
              <ul>
                <li class="custom-dropdown-nav"><a href="javascript:void(0)" class="text-custom-white"><h3 class="mb-0">Home</h3></a>
                  <ul class="dropdown-menu">
                    <li><a routerLink="/">Landing Page</a>
                    </li>
                    <li><a routerLink="/homepage2">Home 2</a>
                    </li>
                    <li><a routerLink="/homepage3">Home 3</a>
                    </li>
                    <li><a routerLink="/homepage4">Home 4</a>
                    </li>
                    <li><a routerLink="/homepage5">Home 5</a>
                    </li>
                    <li><a routerLink="/homepage6">Home 6</a>
                    </li>
                    <li><a routerLink="/homepage7">Home 7</a>
                    </li>
                  </ul>
                </li>
                <li><a routerLink="/media"><h3 class="mb-0">Media</h3></a>
                </li>
                <li class="custom-dropdown-nav"><a href="javascript:void(0)"><h3 class="mb-0">Pages</h3></a>
                  <ul class="dropdown-menu">
                    <li><a routerLink="/listing">Listing</a>
                    </li>
                    <li><a routerLink="/listing1">Listing 1</a>
                    </li>
                    <li><a routerLink="/listing2">Listing 2</a>
                    </li>
                    <li><a routerLink="/listing3">Listing 3</a>
                    </li>
                    <li><a routerLink="/listing4">Listing 4</a>
                    </li>
                    <li><a routerLink="/listing1-style2">Listing 1 Style 2</a>
                    </li>
                    <li><a routerLink="/listing2-style2">Listing 2 Style 2</a>
                    </li>
                    <li><a routerLink="/add-listing">Add Listing</a>
                    </li>
                    <li><a routerLink="/aboutus">About Us</a>
                    </li>
                    <li><a routerLink="/car-detail">Car Details</a>
                    </li>
                    <li><a routerLink="/car-page">Car Page</a>
                    </li>
                    <li><a routerLink="/user">User</a>
                    </li>
                    <li><a routerLink="/register">Register</a>
                    </li>
                    <li><a routerLink="/login">Login</a>
                    </li>
                    <li><a routerLink="/invoice">Invoice</a>
                    </li>
                    <li><a routerLink="/coming-soon">Coming Soon</a>
                    </li>
                  </ul>
                </li>
                <li class="custom-dropdown-nav"><a href="javascript:void(0)" class="text-custom-white"><h3 class="mb-0">Blog</h3></a>
                  <ul class="dropdown-menu">
                    <li><a routerLink="/blog-grid">Blog Grid</a>
                    </li>
                    <li><a routerLink="/blog-left">Blog Left Sidebar</a>
                    </li>
                    <li><a routerLink="/blog-right">Blog Right Sidebar</a>
                    </li>
                    <li><a routerLink="/blog-standard">Blog Single</a>
                    </li>
                    <li><a routerLink="/blog-gallery">Blog Gallery</a>
                    </li>
                    <li><a routerLink="/blog-video">Blog Video</a>
                    </li>
                    <li><a routerLink="/blog-quote">Blog Quote</a>
                    </li>
                    <li><a routerLink="/blog-detail-left">Blog Details Left</a>
                    </li>
                    <li><a routerLink="/blog-detail-right">Blog Details Right</a>
                    </li>
                  </ul>
                </li>
                <li><a routerLink="/pricing"><h3 class="mb-0">Pricing</h3></a>
                </li>
                <li><a routerLink="/contact"><h3 class="mb-0">Contact</h3></a>
                </li>
              </ul>
              <div class="mobile-social-icon">
                <ul>
                  <li><a href="javascript:void(0)"><i class="fab fa-facebook-f"></i></a>
                  </li>
                  <li><a href="javascript:void(0)"><i class="fab fa-twitter"></i></a>
                  </li>
                  <li><a href="javascript:void(0)"><i class="fab fa-pinterest-p"></i></a>
                  </li>
                  <li><a href="javascript:void(0)"><i class="fab fa-instagram"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End Mobile Menu -->
  <!-- End Header -->