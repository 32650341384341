<div class="mobile-menu-second">

	<app-header4></app-header4>
  <!-- Sub header -->
  <div class="sub-header-sec p-relative">
    <img src="assets/images/banner/bannerh9.jpg" class="img-fluid" alt="Sub header">
    <div class="sub-header-overlay"></div>
    <div class="sub-header-wrapper">
      <div class="container">
        <div class="row">
          <div class="col-xl-12">
            <div class="sub-header-text-wrapper text-center">
              <h1 class="text-custom-white">About Us</h1>
              <ul class="breadcrumbs">
                <li><a routerLink="/" class="text-custom-white">Home</a>
                </li>
                <li><a routerLink="/aboutus" class="text-custom-white">Pages</a>
                </li>
                <li><span class="text-custom-white">About Us</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Sub header -->
  <!-- About -->
  <section class="section-padding about-us-sec">
    <div class="container">
      <div class="row">
        <div class="col-lg-5">
          <div class="about-img-sec p-relative">
            <img src="assets/images/about/aboutus-1.jpg" class="img-fluid full-width" alt="about-img">
          </div>
        </div>
        <div class="col-lg-7 align-self-center">
          <div class="about-content-wrapper">
            <div class="section-header no-margin">
              <div class="text-wrapper text-left">
                <h6 class="text-orange fw-400 mb-2">ABOUT THE Rims</h6>
                <h3 class="fw-500 no-margin">WE ARE THE BEST CAR SERVICE PROVIDE IN THE WORLD</h3>
                <span class="line-grey"></span><span class="line-orange"></span><span class="line-grey"></span> 
              </div>
            </div>
            <p class="text-dark-grey">On the other hand, we denounce with righteous indignation the foult anuals dislike men who are so beguiled and demoralized by the nuhaiicharms of pleasure of the moment, so blinded by desire, that they cannot foresee the pain and trouble that are bound toen sue; and equal blame belongs to those who fail in their duty.</p>
            <ul>
              <li class="text-dark-grey">Lorem ipsum dolor sit amet.</li>
              <li class="text-dark-grey">Ut enim ad minim veniam.</li>
              <li class="text-dark-grey">Duis aute irure dolor in reprehenderit.</li>
              <li class="text-dark-grey">Excepteur sint occaecat cupidatat non proident.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- About -->
  <!-- About counter -->
  <section class="about-counter-sec">
    <div class="container-fluid">
      <div class="row">
        <div class="col-xl-2 col-lg-2 col-md-3 col-sm-3">
          <div class="counter-sec-content">
            <h3 class="text-custom-white mb-2"><span class="count">500</span> <i class="flaticon-add"></i></h3>
            <h5 class="text-custom-white no-margin text-uppercase rubik">Happy client</h5>
          </div>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-3 col-sm-3">
          <div class="counter-sec">
            <img src="assets/images/about/aboutus-2.jpg" class="img-fluid full-width" alt="#">
          </div>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-3 col-sm-3">
          <div class="counter-sec-content">
            <h3 class="text-custom-white mb-2"><span class="count">1000</span> <i class="flaticon-add"></i></h3>
            <h5 class="text-custom-white no-margin text-uppercase rubik">EQUIPMENTS</h5>
          </div>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-3 col-sm-3">
          <div class="counter-sec">
            <img src="assets/images/about/aboutus-3.jpg" class="img-fluid full-width" alt="#">
          </div>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-3 col-sm-3">
          <div class="counter-sec-content">
            <h3 class="text-custom-white mb-2"><span class="count">100</span> <i class="flaticon-add"></i></h3>
            <h5 class="text-custom-white no-margin text-uppercase rubik">EXPERT TRAINERS</h5>
          </div>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-3 col-sm-3">
          <div class="counter-sec">
            <img src="assets/images/about/aboutus-2.jpg" class="img-fluid full-width" alt="#">
          </div>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-3 col-sm-3">
          <div class="counter-sec">
            <img src="assets/images/about/aboutus-4.jpg" class="img-fluid full-width" alt="#">
          </div>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-3 col-sm-3">
          <div class="counter-sec-content">
            <h3 class="text-custom-white mb-2"><span class="count">150</span> <i class="flaticon-add"></i></h3>
            <h5 class="text-custom-white no-margin text-uppercase rubik">AWARD WIN</h5>
          </div>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-3 col-sm-3">
          <div class="counter-sec">
            <img src="assets/images/about/aboutus-5.jpg" class="img-fluid full-width" alt="#">
          </div>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-3 col-sm-3">
          <div class="counter-sec-content">
            <h3 class="text-custom-white mb-2"><span class="count">20</span> <i class="flaticon-add"></i></h3>
            <h5 class="text-custom-white no-margin text-uppercase rubik">PERSONAL TRAINERS</h5>
          </div>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-3 col-sm-3">
          <div class="counter-sec">
            <img src="assets/images/about/aboutus-6.jpg" class="img-fluid full-width" alt="#">
          </div>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-3 col-sm-3">
          <div class="counter-sec-content">
            <h3 class="text-custom-white mb-2"><span class="count">100</span> <i class="flaticon-add"></i></h3>
            <h5 class="text-custom-white no-margin text-uppercase rubik">CLASS ROOM</h5>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- About counter -->
  <!-- how it works -->
  <section class="section-padding how-it-works">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="section-header">
            <div class="text-wrapper text-center">
              <h3 class="fw-500 no-margin">How It Works</h3>
              <span class="line-grey"></span><span class="line-orange"></span><span class="line-grey"></span> 
              <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort. A masterful combination of style, power, sporty handling and comfort.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div class="content-wrapper align-items-center text-center ">
            <div class="icon-box">  <i class="flaticon-location-pin text-orange"></i>
            </div>
            <div class="content-box">
              <h5 class="mb-2"><a routerLink="/aboutus" class="text-light-black">FIND INTERESTING PLACE</a></h5>
              <p class="text-dark-grey">Silent sir say desire fat him letter. Whatever settling goodness too and honoured she building answered her</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="content-wrapper align-items-center text-center">
            <div class="icon-box">  <i class="flaticon-checklist text-orange"></i>
            </div>
            <div class="content-box">
              <h5 class="mb-2"><a routerLink="/aboutus" class="text-light-black">PICK A CATEGORY</a></h5>
              <p class="text-dark-grey">Silent sir say desire fat him letter. Whatever settling goodness too and honoured she building answered her</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="content-wrapper align-items-center text-center pb-0">
            <div class="icon-box">  <i class="flaticon-steering-wheel text-orange"></i>
            </div>
            <div class="content-box">
              <h5 class="mb-2"><a routerLink="/aboutus" class="text-light-black">TRY TO TEST DRIVE</a></h5>
              <p class="text-dark-grey">Silent sir say desire fat him letter. Whatever settling goodness too and honoured she building answered her</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- how it works -->
  <!-- Our team -->
  <section class="section-padding our-team-sec">
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="section-header">
            <div class="text-wrapper text-center">
              <h3 class="fw-500 no-margin">Our Team</h3>
              <span class="line-grey"></span><span class="line-orange"></span><span class="line-grey"></span> 
              <p class="text-dark-grey">A masterful combination of style, power, sporty handling and comfort. A masterful combination of style, power, sporty handling and comfort.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div class="team-wrapper">
            <div class="team-content">
              <h3 class="text-custom-white team-title">John Marshall</h3>
              <h6 class="text-custom-white team-designation rubik">Team Head</h6>
              <p class="text-custom-white">A masterful combination of style, power, sporty handling and comfort.</p>
            </div>
            <div class="thumb-img">
              <img src="assets/images/team-about.png" class="img-fluid full-width" alt="team">
            </div>
            <div class="social-media">
              <ul>
                <li><a routerLink="/aboutus" class="text-custom-white"><i class="fab fa-facebook-f"></i></a>
                </li>
                <li><a routerLink="/aboutus" class="text-custom-white"><i class="fab fa-twitter"></i></a>
                </li>
                <li><a routerLink="/aboutus" class="text-custom-white"><i class="fab fa-pinterest-p"></i></a>
                </li>
                <li><a routerLink="/aboutus" class="text-custom-white"><i class="fab fa-instagram"></i></a>
                </li>
                <li><a routerLink="/aboutus" class="text-custom-white"><i class="fab fa-youtube"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="team-wrapper">
            <div class="team-content">
              <h3 class="text-custom-white team-title">Steve Roberts</h3>
              <h6 class="text-custom-white team-designation rubik">Technician</h6>
              <p class="text-custom-white">A masterful combination of style, power, sporty handling and comfort.</p>
            </div>
            <div class="thumb-img">
              <img src="assets/images/team-about-1.png" class="img-fluid full-width" alt="team">
            </div>
            <div class="social-media">
              <ul>
                <li><a routerLink="/aboutus" class="text-custom-white"><i class="fab fa-facebook-f"></i></a>
                </li>
                <li><a routerLink="/aboutus" class="text-custom-white"><i class="fab fa-twitter"></i></a>
                </li>
                <li><a routerLink="/aboutus" class="text-custom-white"><i class="fab fa-pinterest-p"></i></a>
                </li>
                <li><a routerLink="/aboutus" class="text-custom-white"><i class="fab fa-instagram"></i></a>
                </li>
                <li><a routerLink="/aboutus" class="text-custom-white"><i class="fab fa-youtube"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="team-wrapper mb-0">
            <div class="team-content">
              <h3 class="text-custom-white team-title">Michael Cartney</h3>
              <h6 class="text-custom-white team-designation rubik">Sales Manager</h6>
              <p class="text-custom-white">A masterful combination of style, power, sporty handling and comfort.</p>
            </div>
            <div class="thumb-img">
              <img src="assets/images/team-about-2.png" class="img-fluid full-width" alt="team">
            </div>
            <div class="social-media">
              <ul>
                <li><a routerLink="/aboutus" class="text-custom-white"><i class="fab fa-facebook-f"></i></a>
                </li>
                <li><a routerLink="/aboutus" class="text-custom-white"><i class="fab fa-twitter"></i></a>
                </li>
                <li><a routerLink="/aboutus" class="text-custom-white"><i class="fab fa-pinterest-p"></i></a>
                </li>
                <li><a routerLink="/aboutus" class="text-custom-white"><i class="fab fa-instagram"></i></a>
                </li>
                <li><a routerLink="/aboutus" class="text-custom-white"><i class="fab fa-youtube"></i></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Our team -->
<app-footer4></app-footer4>

</div>
